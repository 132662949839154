/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { SensitiveValue } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary, Text, Sensitive, Note, SensitiveFileUpload } from "~/components/form";
export enum AuthenticationType {
    Anonymous = "Anonymous Access",
    UsernameAndPassword = "Username and Password",
    Token = "Token",
    GoogleCloudJsonKey = "Google Cloud Json Key"
}
interface FeedCredentialsState {
    authType: AuthenticationType;
}
interface FeedCredentialsProps {
    isNew: boolean;
    model: {
        username?: string;
        password?: SensitiveValue;
    };
    notes?: JSX.Element;
    supportedAuthenticationTypes: AuthenticationType[];
    onChanged(model: {
        username?: string;
        password?: SensitiveValue;
    }): void;
}
const googleAuthUsername: string = "_json_key";
export default class AdvancedFeedCredentialsFormSection extends React.Component<FeedCredentialsProps, FeedCredentialsState> {
    constructor(props: FeedCredentialsProps) {
        super(props);
        this.state = {
            authType: props.supportedAuthenticationTypes.length === 1 ? props.supportedAuthenticationTypes[0] : AuthenticationType.Anonymous,
        };
    }
    componentDidMount() {
        this.setState({
            authType: this.props.supportedAuthenticationTypes.length === 1 ? this.props.supportedAuthenticationTypes[0] : this.props.isNew ? AuthenticationType.Anonymous : this.getAuthType(this.props.model.username, this.props.model.password),
        });
    }
    render() {
        const authenticationTypeOptions: JSX.Element[] = [];
        this.props.supportedAuthenticationTypes.forEach((authType) => {
            authenticationTypeOptions.push(<RadioButton value={authType} label={authType.toString()} key={authType.toString()}/>);
        });
        return (<ExpandableFormSection errorKey="Credentials" title="Credentials" summary={this.renderSummary(this.state.authType)} help={this.props.supportedAuthenticationTypes.length === 1 ? this.props.supportedAuthenticationTypes[0].toString() : "Select an authentication type."}>
                {this.props.supportedAuthenticationTypes.length !== 1 && (<RadioButtonGroup value={this.state.authType} onChange={this.handleAuthTypeChange}>
                        {authenticationTypeOptions}
                    </RadioButtonGroup>)}
                {this.renderFields(this.state.authType)}
                {this.props.notes && <Note>{this.props.notes}</Note>}
            </ExpandableFormSection>);
    }
    private handleAuthTypeChange = (authType: AuthenticationType) => {
        this.setState({ authType }, () => {
            switch (this.state.authType) {
                case AuthenticationType.Token:
                    this.props.onChanged({ ...this.props.model, username: undefined });
                    break;
                case AuthenticationType.GoogleCloudJsonKey:
                    this.props.onChanged({ ...this.props.model, username: googleAuthUsername, password: undefined });
                    break;
                default:
                    this.props.onChanged({ username: undefined, password: undefined });
                    break;
            }
        });
    };
    private renderSummary = (authType: AuthenticationType): SummaryNode => {
        switch (authType) {
            case AuthenticationType.UsernameAndPassword:
                return Summary.summary(`Credentials have been entered; username is ${this.props.model.username}`);
            case AuthenticationType.Token:
                return Summary.summary(`A token has been entered`);
            case AuthenticationType.GoogleCloudJsonKey:
                return Summary.summary(`A JSON key file has been uploaded`);
            default:
                return Summary.placeholder("Add authentication details if the feed requires authentication");
        }
    };
    private renderFields = (authType: AuthenticationType) => {
        const model = this.props.model;
        if (authType === AuthenticationType.Anonymous) {
            return null;
        }
        if (authType === AuthenticationType.UsernameAndPassword) {
            return (<React.Fragment>
                    <Text value={model.username!} onChange={(username) => this.props.onChanged({ ...model, username })} label="Feed Username"/>
                    <Sensitive value={model.password!} onChange={(password) => this.props.onChanged({ ...model, password })} label={"Feed Password"}/>
                </React.Fragment>);
        }
        if (authType === AuthenticationType.Token) {
            return <Sensitive value={model.password!} onChange={(password) => this.props.onChanged({ ...model, password })} label={"Personal Access Token"}/>;
        }
        if (authType === AuthenticationType.GoogleCloudJsonKey) {
            return (<React.Fragment>
                    <SensitiveFileUpload value={model.password!} onChange={(password) => this.props.onChanged({ ...model, password: { ...password, NewValue: atob(password.NewValue ?? "") } })} label="Key File"/>
                    <Note>
                        Refer to the <ExternalLink href="GoogleCloudServiceAccountKey">Google cloud documentation</ExternalLink> for information on generating json key for service accounts.
                    </Note>
                </React.Fragment>);
        }
        return null;
    };
    private getAuthType(username?: string, password?: SensitiveValue, accountId?: string) {
        if (username === googleAuthUsername)
            return AuthenticationType.GoogleCloudJsonKey;
        return !!username ? AuthenticationType.UsernameAndPassword : password && password.HasValue ? AuthenticationType.Token : AuthenticationType.Anonymous;
    }
    static displayName = "AdvancedFeedCredentialsFormSection";
}
