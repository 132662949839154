import { Permission } from "@octopusdeploy/octopus-server-client";
import type { PageDefinition, PageUrl } from "@octopusdeploy/portal-routes";
import { licenseRoute, pageId } from "@octopusdeploy/portal-routes";
import configurationPageIds from "~/areas/configuration/components/ConfigurationRoutes/configurationPageIds";
import configurationRouteLinks from "~/areas/configuration/components/ConfigurationRoutes/configurationRouteLinks";
import dashboardPageIds from "~/areas/dashboard/DashboardOverview/dashboardPageIds";
import dashboardRouteLinks from "~/areas/dashboard/DashboardOverview/dashboardRouteLinks";
import { RawAccountTypeDetailsMap } from "~/areas/infrastructure/InfrastructureDetails";
import infrastructurePageIds from "~/areas/infrastructure/components/InfrastructureRoutes/infrastructurePageIds";
import infrastructureRouteLinks from "~/areas/infrastructure/components/InfrastructureRoutes/infrastructureRouteLinks";
import libraryPageIds from "~/areas/library/components/LibraryRoutes/libraryPageIds";
import libraryRouteLinks from "~/areas/library/components/LibraryRoutes/libraryRouteLinks";
import projectPageIds from "~/areas/projects/components/ProjectsRoutes/projectPageIds";
import projectRouteLinks from "~/areas/projects/components/ProjectsRoutes/projectRouteLinks";
import taskPageIds from "~/areas/tasks/components/TaskRoutes/taskPageIds";
import taskRouteLinks from "~/areas/tasks/components/TaskRoutes/taskRouteLinks";
import tenantPageIds from "~/areas/tenants/TenantsRoutes/tenantPageIds";
import tenantRouteLinks from "~/areas/tenants/TenantsRoutes/tenantRouteLinks";
import userProfilePageIds from "~/areas/users/UserProfileRoutes/userProfilePageIds";
import userProfileRouteLinks from "~/areas/users/UserProfileRoutes/userProfileRouteLinks";
import { client } from "~/clientInstance";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { convertQueryToQueryString } from "~/components/QueryStringFilters/QueryStringFilters";
import routeLinks from "~/routeLinks";
export interface PageDefinitionWithRoutingInfo extends PageDefinition {
    RouteLink: string | (() => string) | PageUrl; // The internal routelink to navigate to this page.
    RoutePermission?: PermissionCheckProps;
}
const dashboardLinks = dashboardRouteLinks(client.spaceId ?? "");
const dashboardPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...dashboardPageIds.root,
        RouteLink: dashboardLinks.root,
    },
    {
        ...dashboardPageIds.configure,
        RouteLink: dashboardLinks.configure,
    },
];
const libraryLinks = libraryRouteLinks(client.spaceId ?? "");
const libraryPageIdsInstance = libraryPageIds();
const libraryPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...libraryPageIdsInstance.root,
        RouteLink: libraryLinks.root,
        RoutePermission: { permission: [Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView, Permission.CertificateView], wildcard: true },
    },
    {
        ...libraryPageIdsInstance.feeds,
        RouteLink: libraryLinks.feeds,
        RoutePermission: { permission: Permission.FeedView },
    },
    {
        ...libraryPageIdsInstance.feedCreate,
        RouteLink: libraryLinks.feedCreate,
        RoutePermission: { permission: Permission.FeedEdit },
    },
    {
        ...libraryPageIdsInstance.stepTemplates.root,
        RouteLink: libraryLinks.stepTemplates.root,
        RoutePermission: { permission: Permission.ActionTemplateView },
    },
    {
        ...libraryPageIdsInstance.stepTemplates.builtIn,
        RouteLink: libraryLinks.stepTemplates.builtIn,
        RoutePermission: { permission: Permission.ActionTemplateView },
    },
    {
        ...libraryPageIdsInstance.stepTemplates.community,
        RouteLink: libraryLinks.stepTemplates.community,
        RoutePermission: { permission: Permission.ActionTemplateView },
    },
    {
        ...libraryPageIdsInstance.lifecycles,
        RouteLink: libraryLinks.lifecycles,
        RoutePermission: { permission: Permission.LifecycleView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.lifecyclesCreate,
        RouteLink: libraryLinks.lifecyclesCreate,
        RoutePermission: { permission: Permission.LifecycleCreate, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.certificates.root,
        RouteLink: libraryLinks.certificates.root,
        RoutePermission: { permission: Permission.CertificateView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.certificates.archive,
        RouteLink: libraryLinks.certificates.archive,
        RoutePermission: { permission: Permission.CertificateView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.certificates.create,
        RouteLink: libraryLinks.certificates.create,
        RoutePermission: { permission: Permission.CertificateCreate, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.variableSets.root,
        RouteLink: libraryLinks.variableSets,
        RoutePermission: { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true },
    },
    {
        ...libraryPageIdsInstance.tagSets.root,
        RouteLink: libraryLinks.tagSets.root,
        RoutePermission: { permission: Permission.VariableView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.tagSets.create,
        RouteLink: libraryLinks.tagSets.create,
        RoutePermission: { permission: Permission.TagSetCreate },
    },
    {
        ...libraryPageIdsInstance.builtInRepository.root,
        RouteLink: libraryLinks.builtInRepository.root,
        RoutePermission: { permission: Permission.FeedView },
    },
    {
        ...libraryPageIdsInstance.buildInformationRepository.root,
        RouteLink: libraryLinks.buildInformationRepository.root,
        RoutePermission: { permission: Permission.FeedView },
    },
    {
        ...libraryPageIdsInstance.scriptModules.root,
        RouteLink: libraryLinks.scripts.root,
        RoutePermission: { permission: Permission.LibraryVariableSetView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.scriptModules.create,
        RouteLink: libraryLinks.scripts.create,
        RoutePermission: { permission: Permission.LibraryVariableSetCreate, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.gitCredentials.root,
        RouteLink: libraryLinks.gitCredentials.root,
        RoutePermission: { permission: [Permission.GitCredentialView], wildcard: true },
    },
    {
        ...libraryPageIdsInstance.gitCredentials.create,
        RouteLink: libraryLinks.gitCredentials.root,
        RoutePermission: { permission: [Permission.GitCredentialEdit], wildcard: true },
    },
];
const configurationLinks = configurationRouteLinks(client.spaceId ?? "");
const configurationPageIdsInstance = configurationPageIds();
const configurationPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...configurationPageIdsInstance.root,
        RouteLink: configurationLinks.root,
        RoutePermission: {
            permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView, Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
            wildcard: true,
        },
    },
    {
        ...configurationPageIdsInstance.userInvites,
        RouteLink: configurationLinks.userInvites,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserInvite] },
    },
    {
        ...configurationPageIdsInstance.auditBaseRoute,
        RouteLink: configurationLinks.auditBaseRoute,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true },
    },
    {
        ...configurationPageIdsInstance.auditArchive,
        RouteLink: configurationLinks.auditArchive,
        RoutePermission: { permission: [Permission.EventRetentionView, Permission.EventRetentionDelete], wildcard: true },
    },
    {
        ...configurationPageIdsInstance.backup,
        RouteLink: configurationLinks.backup,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.features,
        RouteLink: configurationLinks.features,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.license,
        RouteLink: licenseRoute.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.smtp,
        RouteLink: configurationLinks.smtp,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.nodes.root,
        RouteLink: configurationLinks.nodes.root,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.nodes.serverSettings,
        RouteLink: configurationLinks.nodes.serverSettings,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.thumbprint,
        RouteLink: configurationLinks.thumbprint,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true },
    },
    {
        ...configurationPageIdsInstance.maintenance,
        RouteLink: configurationLinks.maintenance,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.letsEncrypt.root,
        RouteLink: configurationLinks.letsEncrypt.root,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.letsEncrypt.configure,
        RouteLink: configurationLinks.letsEncrypt.configure,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.testPermissions,
        RouteLink: configurationLinks.testPermissions,
        RoutePermission: { permission: [Permission.TeamEdit, Permission.UserView] },
    },
    {
        ...configurationPageIdsInstance.subscriptions.root,
        RouteLink: configurationLinks.subscriptions.root,
        RoutePermission: { permission: [Permission.SubscriptionView] },
    },
    {
        ...configurationPageIdsInstance.subscriptions.create,
        RouteLink: configurationLinks.subscriptions.create(),
        RoutePermission: { permission: [Permission.SubscriptionCreate] },
    },
    {
        ...configurationPageIdsInstance.users.root,
        RouteLink: configurationLinks.users.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserView] },
    },
    {
        ...configurationPageIdsInstance.users.create,
        RouteLink: configurationLinks.users.create,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserEdit] },
    },
    {
        ...configurationPageIdsInstance.spaces.root,
        RouteLink: configurationLinks.spaces.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.SpaceView] },
    },
    {
        ...configurationPageIdsInstance.spaces.root,
        RouteLink: configurationLinks.spaces.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.SpaceView] },
    },
    /* Excluding as an edge case - our UI creates these via a popover.
    { ...configurationPageIdsInstance.spaces.create,
        RouteLink:  routeLink: configurationLinks.spaces.create,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.SpaceCreate] }\
    }
    */
    {
        ...configurationPageIdsInstance.teams.root,
        RouteLink: () => configurationLinks.teams.root(),
        RoutePermission: { permission: [Permission.TeamEdit, Permission.TeamView] },
    },
    {
        ...configurationPageIdsInstance.roles.root,
        RouteLink: configurationLinks.roles.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleView] },
    },
    {
        ...configurationPageIdsInstance.roles.create,
        RouteLink: configurationLinks.roles.create,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleEdit] },
    },
    {
        ...configurationPageIdsInstance.diagnostics.root,
        RouteLink: configurationLinks.diagnostics.root,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.root,
        RouteLink: configurationLinks.diagnostics.logs.root,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.autoDeploy,
        RouteLink: configurationLinks.diagnostics.logs.autoDeploy,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.scheduledDeploy,
        RouteLink: configurationLinks.diagnostics.logs.scheduledDeploy,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.machineCleanup,
        RouteLink: configurationLinks.diagnostics.logs.machineCleanup,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.subscription,
        RouteLink: configurationLinks.diagnostics.logs.subscription,
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.settings.root,
        RouteLink: configurationLinks.settings.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.extensions.root,
        RouteLink: configurationLinks.extensions.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.performance,
        RouteLink: configurationLinks.performance,
        RoutePermission: { permission: Permission.ConfigureServer },
    },
    {
        ...configurationPageIdsInstance.telemetry,
        RouteLink: configurationLinks.telemetry,
        RoutePermission: { permission: Permission.ConfigureServer },
    },
];
const taskPageIdsInstance = taskPageIds();
const taskLinks = taskRouteLinks(client.spaceId ?? "");
const taskPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...taskPageIdsInstance.tasks.root,
        RouteLink: taskLinks.tasks.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.TaskView], wildcard: true },
    },
    {
        ...taskPageIdsInstance.tasks.console,
        RouteLink: taskLinks.tasks.console,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.TaskCreate], wildcard: true },
    },
];
const infrastructurePagesIdsInstance = infrastructurePageIds();
const infrastructureLinks = infrastructureRouteLinks(client.spaceId ?? "");
const infrastructurePages: () => PageDefinitionWithRoutingInfo[] = () => {
    const results: PageDefinitionWithRoutingInfo[] = [
        {
            ...infrastructurePagesIdsInstance.root,
            RouteLink: infrastructureLinks.root,
            RoutePermission: {
                permission: [Permission.MachineView, Permission.EnvironmentView, Permission.WorkerView],
                wildcard: true,
            },
        },
        {
            ...infrastructurePagesIdsInstance.overview,
            RouteLink: infrastructureLinks.overview,
            RoutePermission: { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.environments.root,
            RouteLink: infrastructureLinks.environments.root,
            RoutePermission: { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true },
        },
        /* Excluding as an edge case - our UI creates these via a popover.
        {
            ...infrastructurePagesIdsInstance.environments.create,
            RouteLink: infrastructureLinks.environments.create,
            RoutePermission: { permission: Permission.EnvironmentCreate, wildcard: true },
        }
        */
        {
            ...infrastructurePagesIdsInstance.machines.root,
            RouteLink: infrastructureLinks.machines.root,
            RoutePermission: { permission: [Permission.MachineView], wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.machines.create,
            RouteLink: infrastructureLinks.machines.new(undefined),
            RoutePermission: {
                permission: Permission.MachineCreate,
                wildcard: true,
            },
        },
        {
            ...infrastructurePagesIdsInstance.proxies.root,
            RouteLink: infrastructureLinks.proxies.root,
            RoutePermission: { permission: Permission.ProxyView, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.proxies.create,
            RouteLink: infrastructureLinks.proxies.create,
            RoutePermission: { permission: Permission.ProxyCreate, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.machinePolicies.root,
            RouteLink: infrastructureLinks.machinePolicies.root,
            RoutePermission: { permission: Permission.MachinePolicyView, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.machinePolicies.create,
            RouteLink: infrastructureLinks.machinePolicies.create,
            RoutePermission: { permission: Permission.MachinePolicyCreate, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.accounts.root,
            RouteLink: infrastructureLinks.accounts.root,
            RoutePermission: { permission: Permission.AccountView, wildcard: true },
        },
        /*
        // Excluding as an edge case - our UI creates these via a popover.
        {
            ...infrastructurePagesIdsInstance.accounts.create,
            RouteLink: infrastructureLinks.accounts.create,
            RoutePermission: { permission: Permission.AccountCreate, wildcard: true }
        }
         */
        {
            ...infrastructurePagesIdsInstance.workerMachines.root,
            RouteLink: infrastructureLinks.workerMachines.root,
            RoutePermission: { permission: Permission.WorkerView, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.workerMachines.create,
            RouteLink: infrastructureLinks.workerMachines.new(undefined),
            RoutePermission: {
                permission: Permission.WorkerEdit,
                wildcard: true,
            },
        },
        {
            ...infrastructurePagesIdsInstance.workerPools.root,
            RouteLink: infrastructureLinks.workerPools.root,
            RoutePermission: { permission: Permission.WorkerView, wildcard: true },
        },
        /*
            // Excluding as an edge case - our UI creates these via a popover.
            ...infrastructurePagesIdsInstance.workerPools.create,
            RouteLink: infrastructureLinks.workerPools.create,
            RoutePermission: { permission: Permission.WorkerEdit, wildcard: true }
         */
    ];
    // Inject our account types dynamically.
    const accountTypeDetailsMap = RawAccountTypeDetailsMap;
    accountTypeDetailsMap.forEach((accountTypeDetails) => {
        if (accountTypeDetails.types.length === 0) {
            return null;
        }
        const accountCreateRoute = {
            pathname: routeLinks.infrastructure.accounts.create,
            search: convertQueryToQueryString({ accountType: accountTypeDetails.types[0] }),
        };
        results.push({
            ...pageId({
                area: "Infrastructure",
                id: `Infrastructure.AccountsNew.${accountTypeDetails.name}`,
                name: `Add New ${accountTypeDetails.name} ${accountTypeDetails.name.endsWith("Account") ? "" : "Account"}`,
            }),
            RouteLink: `${accountCreateRoute.pathname}${accountCreateRoute.search}`,
            RoutePermission: {
                permission: Permission.AccountCreate,
                wildcard: true,
            },
        });
    });
    return results;
};
const projectPageIdsInstance = projectPageIds();
const projectLinks = projectRouteLinks(client.spaceId ?? "");
const projectPages = (): PageDefinitionWithRoutingInfo[] => {
    return [
        {
            ...projectPageIdsInstance.projects.root,
            RouteLink: projectLinks.projects.root,
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
        {
            ...projectPageIdsInstance.importExport.root,
            RouteLink: projectLinks.importExport.root,
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
        {
            ...projectPageIdsInstance.importExport.import,
            RouteLink: projectLinks.export.root,
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
        {
            ...projectPageIdsInstance.importExport.export,
            RouteLink: projectLinks.import.root,
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
    ];
};
const tenantPageIdsInstance = tenantPageIds();
const tenantLinks = tenantRouteLinks(client.spaceId ?? "");
const tenantPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...tenantPageIdsInstance.tenants,
        RouteLink: tenantLinks.tenants,
        RoutePermission: { permission: Permission.TenantView, wildcard: true },
    },
];
const userProfilePageIdsInstance = userProfilePageIds();
const userProfileLinks = userProfileRouteLinks(client.spaceId ?? "", "");
const userProfilePages: PageDefinitionWithRoutingInfo[] = [
    {
        ...userProfilePageIdsInstance.me,
        RouteLink: userProfileLinks.me,
    },
    {
        ...userProfilePageIdsInstance.logins,
        RouteLink: userProfileLinks.logins,
    },
    {
        ...userProfilePageIdsInstance.details,
        RouteLink: userProfileLinks.details,
    },
    {
        ...userProfilePageIdsInstance.apiKeys,
        RouteLink: userProfileLinks.apiKeys,
    },
    {
        ...userProfilePageIdsInstance.activity,
        RouteLink: userProfileLinks.activity,
        RoutePermission: { permission: Permission.EventView, wildcard: true },
    },
];
export const pagesToIncludeInSearch = () => {
    return [...dashboardPages, ...libraryPages, ...configurationPages, ...taskPages, ...infrastructurePages(), ...projectPages(), ...tenantPages, ...userProfilePages];
};
