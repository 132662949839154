import { Tooltip } from "@octopusdeploy/design-system-components";
import type { ActivityElement, KubernetesObjectStatus, KubernetesStepStatus } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import PaperLayout from "~/components/PaperLayout/index";
import SimpleExpander from "~/components/SimpleExpander/index";
import { useOctopusTheme } from "~/components/Theme";
import DurationBetweenLabel from "~/components/TimeLabels/DurationBetweenLabel";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { Note } from "~/components/form/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
import SectionHeading from "~/primitiveComponents/dataDisplay/Section/SectionHeading";
import type { StepFilter } from "./KubernetesDeploymentStatus";
import styles from "./style.module.less";
interface KubernetesStepProps {
    status: KubernetesStepStatus | undefined;
    stepName: string;
    stepActivity: Pick<ActivityElement, "Status" | "Ended" | "Started">;
    filter: StepFilter;
}
type DataDictionary = {
    [key: string]: KubernetesObjectStatus[];
};
export function sortObjectStatusByObjectType(data: [
    string,
    KubernetesObjectStatus[]
][]) {
    const kubernetesObjectTypeOrder = "Pod,ReplicaSet,Deployment,StatefulSet,DaemonSet,Job,CronJob,Service,EndpointSlice,Ingress,ConfigMap,Secret,";
    data.sort(([a], [b]) => {
        const aIndex = kubernetesObjectTypeOrder.indexOf(`${a},`);
        const bIndex = kubernetesObjectTypeOrder.indexOf(`${b},`);
        if (aIndex == -1 && bIndex == -1) {
            // We fall back to order alphabetically when object type is not found
            return a.localeCompare(b);
        }
        if (aIndex == bIndex) {
            return 0;
        }
        if (aIndex == -1) {
            return 1;
        }
        if (bIndex == -1) {
            return -1;
        }
        if (aIndex > bIndex) {
            return 1;
        }
        return -1;
    });
}
function KubernetesStepStatusExpander(props: KubernetesStepProps) {
    function applyFilter(objects: KubernetesObjectStatus[], filter: StepFilter) {
        return objects.filter((o) => {
            let showTargetNameFilter = true;
            let showNamespaceFilter = true;
            let nameFilter = true;
            if (filter.targetName && filter.targetName !== o.ClusterName) {
                showTargetNameFilter = false;
            }
            if (filter.namespace && filter.namespace !== o.Namespace) {
                showNamespaceFilter = false;
            }
            if (filter.name && !o.Name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase())) {
                nameFilter = false;
            }
            return showTargetNameFilter && showNamespaceFilter && nameFilter;
        });
    }
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    function renderData(data: any) {
        if (Array.isArray(data)) {
            return data.join(", ");
        }
        return data.toString();
    }
    let data: [
        string,
        KubernetesObjectStatus[]
    ][] = [];
    const totalResults = props.status?.KubernetesObjects.length || 0;
    let totalFilteredResult = totalResults;
    if (props.status) {
        const filtererResults = applyFilter(props.status.KubernetesObjects, props.filter);
        totalFilteredResult = filtererResults.length;
        data = Object.entries(filtererResults.reduce<DataDictionary>((prev, current) => {
            if (prev[current.Kind] === undefined) {
                prev[current.Kind] = [];
            }
            prev[current.Kind].push(current);
            return prev;
        }, {}));
        sortObjectStatusByObjectType(data);
    }
    return (<PaperLayout className={styles.paperLayoutOverride}>
            <SimpleExpander key={props.stepName} accessibleName={props.stepName} title={<div className={styles.stepNameContainer}>
                        <Status status={props.stepActivity.Status || ""}/> <span className={styles.stepName}>{props.stepName}</span>
                    </div>} errorKey={props.stepName}>
                <div className={styles.expandoBodyContainer}>
                    {props.status && (<div className={styles.leftRightContainer}>
                            <div aria-label="Last Updated">
                                <Note>
                                    <LastUpdateMessage activityElement={props.stepActivity} lastObjectStatusUpdate={props.status.LastUpdated}/>
                                </Note>
                            </div>
                            {totalResults > totalFilteredResult && (<div aria-label="Filter Counter">
                                    <Note>
                                        {totalFilteredResult} of {totalResults} objects match your filters
                                    </Note>
                                </div>)}
                        </div>)}
                    <EmptyState status={props.stepActivity.Status} hasData={totalResults > 0}/>
                    {data.map(([kind, objects]) => (<div className={styles.kindContainer} key={kind}>
                            <SectionHeading title={<div className={styles.sectionHeadingTitle}>{kind}</div>}/>
                            <DataTable title={kind}>
                                <DataTableHeader>
                                    <DataTableRow>
                                        <DataTableHeaderColumn aria-label="Status Column" width={1}>
                                            &nbsp;
                                        </DataTableHeaderColumn>
                                        <DataTableHeaderColumn>Name</DataTableHeaderColumn>
                                        {Object.keys(objects[0].Data).map((k) => (<DataTableHeaderColumn key={k}>{k}</DataTableHeaderColumn>))}
                                        <DataTableHeaderColumn>Target Name</DataTableHeaderColumn>
                                        <DataTableHeaderColumn>Namespace</DataTableHeaderColumn>
                                    </DataTableRow>
                                </DataTableHeader>
                                <DataTableBody>
                                    {objects.map((obj) => (<DataTableRow key={`${obj.Name}-${obj.ClusterName}=${obj.Namespace}`}>
                                            <DataTableRowColumn>
                                                <ObjectStatus status={obj.Status} stepStatus={props.stepActivity.Status}/>
                                            </DataTableRowColumn>
                                            <DataTableRowColumn>{obj.Name}</DataTableRowColumn>
                                            {Object.values(obj.Data).map((v, i) => (<DataTableRowColumn key={i}>{renderData(v)}</DataTableRowColumn>))}
                                            <DataTableRowColumn>{obj.ClusterName}</DataTableRowColumn>
                                            <DataTableRowColumn>{obj.Namespace}</DataTableRowColumn>
                                        </DataTableRow>))}
                                </DataTableBody>
                            </DataTable>
                        </div>))}
                </div>
            </SimpleExpander>
        </PaperLayout>);
}
function LastUpdateMessage(props: {
    lastObjectStatusUpdate: string;
    activityElement: Pick<ActivityElement, "Status" | "Ended" | "Started">;
}) {
    if (props.activityElement.Status === ActivityStatus.Canceled) {
        return <>This task was canceled prior to starting</>;
    }
    if (props.activityElement.Ended) {
        return (<>
                This step started <TimeFromNowLabel time={props.activityElement.Started}/> and ran for <DurationBetweenLabel from={props.activityElement.Started} to={props.activityElement.Ended}/>
            </>);
    }
    return (<>
            Last Updated: <TimeFromNowLabel time={props.lastObjectStatusUpdate}/>
        </>);
}
function EmptyState(props: {
    status: ActivityStatus | undefined;
    hasData: boolean;
}) {
    let callout: JSX.Element | undefined = undefined;
    switch (props.status) {
        case ActivityStatus.Skipped:
            callout = (<Callout title="This step has been skipped" type={CalloutType.Information}>
                    Skipped steps won’t perform the Kubernetes Object Status Check.
                </Callout>);
            break;
        case ActivityStatus.Pending:
            if (!props.hasData)
                callout = (<Callout title="This step is currently queued" type={CalloutType.Information}>
                        You’ll see the status of your Kubernetes objects here when this step starts running.
                    </Callout>);
            break;
        case ActivityStatus.Failed:
        case ActivityStatus.Success:
        case ActivityStatus.SuccessWithWarning:
            if (!props.hasData)
                callout = (<Callout title="The Kubernetes Object Status Check is not enabled for this step" type={CalloutType.Information}>
                        <ExternalLink href="KOS">Learn more</ExternalLink> about how this feature can help you get better visibility into the status of your Kubernetes objects.
                    </Callout>);
            break;
        case ActivityStatus.Canceled:
            callout = (<Callout title="This step has been cancelled" type={CalloutType.Information}>
                    Cancelled steps won’t perform the Kubernetes Object Status Check.
                </Callout>);
            break;
    }
    if (callout !== undefined) {
        return <div className={styles.callout}>{callout}</div>;
    }
    return null;
}
function ObjectStatus(props: {
    status: string;
    stepStatus: string | undefined;
}) {
    const theme = useOctopusTheme();
    switch (props.stepStatus) {
        case ActivityStatus.Failed:
        case ActivityStatus.Success:
        case ActivityStatus.SuccessWithWarning:
        case ActivityStatus.Canceled:
        case "Successful":
            if (props.status === "InProgress" || props.status === ActivityStatus.Running) {
                return (<Tooltip content="Timed out while in progress">
                        <em aria-label="Timeout Status" className="fa-solid fa-clock" style={{ color: theme.alertText }}/>
                    </Tooltip>);
            }
    }
    return <Status status={props.status}/>;
}
function Status(props: {
    status: string;
}) {
    const theme = useOctopusTheme();
    switch (props.status) {
        case ActivityStatus.Skipped:
            return <em aria-label={`${ActivityStatus.Skipped} Status`} className="fa-solid fa-minus" style={{ color: theme.secondaryText }}/>;
        case ActivityStatus.Failed:
            return <em aria-label={`${ActivityStatus.Failed} Status`} className="fa-solid fa-circle-xmark" style={{ color: theme.dangerText }}/>;
        case ActivityStatus.Running:
        case "InProgress":
            return <em aria-label={`${ActivityStatus.Running} Status`} className="fa-solid fa-spinner fa-pulse" style={{ color: theme.primaryDark }}/>;
        case ActivityStatus.Pending:
            return <em aria-label={`${ActivityStatus.Pending} Status`} className="fa-solid fa-clock" style={{ color: theme.primaryLight }}/>;
        case ActivityStatus.Success:
        case ActivityStatus.SuccessWithWarning:
        case "Successful":
            return <em aria-label={`${ActivityStatus.Success} Status`} className="fa-solid fa-circle-check" style={{ color: theme.successText }}/>;
        case ActivityStatus.Canceled:
            return <em aria-label={`${ActivityStatus.Canceled} Status`} className="fa-solid fa-circle-xmark" style={{ color: theme.primaryText }}/>;
        default:
            return <ThirdPartyIcon iconType={ThirdPartyIconType.Clear} color={theme.primaryText} style={{ width: "16px", height: "16px" }}/>;
    }
}
export default KubernetesStepStatusExpander;
