import type { EnvironmentResource, LifecycleResource, ProjectGroupResource, ProjectSummaryResource, TagResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { compact, uniqBy } from "lodash";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
export enum FilterEntity {
    Project = "Project",
    Environment = "Environment",
    TagSet = "Tag Set",
    Lifecycle = "Lifecycle",
    ProjectGroup = "Project Group"
}
export enum FilterMultiplicity {
    Always,
    OncePerOption
}
export enum FilterOption {
    Is = "is",
    IsNot = "is not",
    In = "in",
    NotIn = "not in"
}
export enum FilterType {
    SingleSelect,
    MultiSelect
}
type SingleValue = string | undefined;
type MultiValue = string[] | undefined;
export interface SingleValueOption {
    option: FilterOption.Is | FilterOption.IsNot;
    initialValue?: SingleValue;
}
export interface MultiValueOption {
    option: FilterOption.In | FilterOption.NotIn;
    initialValue?: MultiValue;
}
type Option = SingleValueOption | MultiValueOption;
export interface Filter {
    entity: FilterEntity; // e.g. "Project", "Environment", "Project Group"
    options: Option[]; // e.g. "Is" or "IsNot"
    multiplicity: FilterMultiplicity; // i.e. "Always" (static) or "OncePerOption" (dynamic, only one allowed)
    availableItems: FilterResource[]; // The available values to select from
    name: string; // The display name of the filter. For entities like tag sets which should show the name of the set rather than "Tag Set". Will default to entity if not provided
}
export interface SingleSelectValue {
    type: FilterType.SingleSelect;
    value: SingleValue;
}
export interface MultiSelectValue {
    type: FilterType.MultiSelect;
    value: MultiValue;
}
export interface FilterValue {
    entity: FilterEntity;
    option: FilterOption;
    value: SingleSelectValue | MultiSelectValue;
    name: string;
}
export interface UnorderedFilterRow extends FilterValue {
    availableItems: FilterResource[];
    multiplicity: FilterMultiplicity;
    selected: boolean;
}
export interface FilterRow extends UnorderedFilterRow {
    order: number;
}
export type FilterResource = SelectItem & (ProjectSummaryResource | EnvironmentResource | TagResource | ProjectGroupResource | LifecycleResource);
const isSingleValueOption = (value: Option): value is SingleValueOption => value.option === FilterOption.Is || value.option === FilterOption.IsNot;
const isMultiValueOption = (value: Option): value is MultiValueOption => value.option === FilterOption.In || value.option === FilterOption.NotIn;
export const isSingleSelectValue = (value: SingleSelectValue | MultiSelectValue | undefined): value is SingleSelectValue => value?.type === FilterType.SingleSelect;
export const isMultiSelectValue = (value: SingleSelectValue | MultiSelectValue | undefined): value is MultiSelectValue => value?.type === FilterType.MultiSelect;
export const isTagResourceArray = (value: FilterResource[]): value is TagResource[] => (value.length > 0 ? "CanonicalTagName" in value[0] : true);
export const getSingleSelectValue = (filters: FilterValue[], entity: FilterEntity, option: FilterOption) => {
    const filter = filters.find((f) => f.entity === entity && f.option === option);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getMultiSelectValue = (filters: FilterValue[], name: string, option: FilterOption) => {
    const filter = filters.find((f) => f.name === name && f.option === option);
    return isMultiSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getIncludedProjectValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.Project && f.option === FilterOption.Is);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getExcludedProjectValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.Project && f.option === FilterOption.IsNot);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getIncludedEnvironmentValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.Environment && f.option === FilterOption.Is);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getExcludedEnvironmentValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.Environment && f.option === FilterOption.IsNot);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getIncludedLifecycleValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.Lifecycle && f.option === FilterOption.Is);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getExcludedLifecycleValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.Lifecycle && f.option === FilterOption.IsNot);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getIncludedProjectGroupValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.ProjectGroup && f.option === FilterOption.Is);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getExcludedProjectGroupValue = (filters: FilterValue[]) => {
    const filter = filters.find((f) => f.entity === FilterEntity.ProjectGroup && f.option === FilterOption.IsNot);
    return isSingleSelectValue(filter?.value) ? filter?.value.value : undefined;
};
export const getIncludedTagSetValues = (filters: FilterValue[]) => {
    return compact(filters.filter((f) => f.entity === FilterEntity.TagSet && f.option === FilterOption.In).flatMap((f) => (isMultiSelectValue(f.value) ? f.value.value : undefined)));
};
export const getExcludedTagSetValues = (filters: FilterValue[]) => {
    return compact(filters.filter((f) => f.entity === FilterEntity.TagSet && f.option === FilterOption.NotIn).flatMap((f) => (isMultiSelectValue(f.value) ? f.value.value : undefined)));
};
export const initializeFilterRows = (filters: Filter[]): FilterRow[] => {
    const filterRows = filters.flatMap((f): UnorderedFilterRow[] => f.options.map((option) => mapOptionToFilterRow(f, option)));
    const namesOfFiltersWithValues = filterRows.filter((filterWithValue) => filterWithValue.value.value !== undefined).map((filterWithValue) => filterWithValue.name);
    return filterRows.map((row, index): FilterRow => {
        if (!namesOfFiltersWithValues.includes(row.name) && row.multiplicity === FilterMultiplicity.Always && (row.option === FilterOption.Is || row.option === FilterOption.In)) {
            return { ...row, selected: true, order: index };
        }
        return { ...row, order: index };
    });
};
const mapOptionToFilterRow = (filter: Filter, option: Option): UnorderedFilterRow => {
    if (isSingleValueOption(option)) {
        return {
            name: filter.name,
            entity: filter.entity,
            option: option.option,
            availableItems: filter.availableItems,
            multiplicity: filter.multiplicity,
            value: { type: FilterType.SingleSelect, value: option.initialValue },
            selected: option.initialValue !== undefined,
        };
    }
    if (isMultiValueOption(option)) {
        return {
            name: filter.name,
            entity: filter.entity,
            option: option.option,
            availableItems: filter.availableItems,
            multiplicity: filter.multiplicity,
            value: { type: FilterType.MultiSelect, value: option.initialValue },
            selected: option.initialValue !== undefined,
        };
    }
    exhaustiveCheck(option, `The filter option provided is not a known value`);
};
export const getAvailableNames = (availableFilters: FilterRow[], filter?: FilterRow) => uniqBy(filter ? [filter, ...availableFilters] : availableFilters, (f) => f.name)
    .filter((f) => f.multiplicity !== FilterMultiplicity.Always)
    .map((f) => ({ value: f.name, text: f.name }));
export const getAvailableOptions = (availableFilters: FilterRow[], filter: FilterRow) => availableFilters
    .filter((f) => f.name === filter.name)
    .concat(filter)
    .map((f) => ({ value: f.option, text: f.option }));
export const getPlaceholder = (name: string) => {
    switch (name) {
        case "Project":
            return "Select a project";
        case "Environment":
            return "Select an environment";
        case "Lifecycle":
            return "Select a lifecycle";
        case "Project Group":
            return "Select a project group";
        default:
            return "Select a value";
    }
};
export function createSingleValueFilter(entity: FilterEntity, options: Option[], multiplicity: FilterMultiplicity, availableItems: FilterResource[], name?: string): Filter {
    return {
        entity,
        options,
        multiplicity,
        availableItems,
        name: name ?? entity,
    };
}
export function createProjectFilter(availableItems: ProjectSummaryResource[], initialIncludedValue?: string, initialExcludedValue?: string, multiplicity?: FilterMultiplicity): Filter {
    return createSingleValueFilter(FilterEntity.Project, [
        { option: FilterOption.Is, initialValue: initialIncludedValue },
        { option: FilterOption.IsNot, initialValue: initialExcludedValue },
    ], multiplicity ?? FilterMultiplicity.OncePerOption, availableItems);
}
export function createEnvironmentFilter(availableItems: EnvironmentResource[], initialIncludedValue?: string, initialExcludedValue?: string, multiplicity?: FilterMultiplicity): Filter {
    return createSingleValueFilter(FilterEntity.Environment, [
        { option: FilterOption.Is, initialValue: initialIncludedValue },
        { option: FilterOption.IsNot, initialValue: initialExcludedValue },
    ], multiplicity ?? FilterMultiplicity.OncePerOption, availableItems);
}
export function createLifecycleFilter(availableItems: LifecycleResource[], initialIncludedValue?: string, initialExcludedValue?: string, multiplicity?: FilterMultiplicity): Filter {
    return createSingleValueFilter(FilterEntity.Lifecycle, [
        { option: FilterOption.Is, initialValue: initialIncludedValue },
        { option: FilterOption.IsNot, initialValue: initialExcludedValue },
    ], multiplicity ?? FilterMultiplicity.OncePerOption, availableItems);
}
export function createProjectGroupFilter(availableItems: ProjectGroupResource[], initialIncludedValue?: string, initialExcludedValue?: string, multiplicity?: FilterMultiplicity): Filter {
    return createSingleValueFilter(FilterEntity.ProjectGroup, [
        { option: FilterOption.Is, initialValue: initialIncludedValue },
        { option: FilterOption.IsNot, initialValue: initialExcludedValue },
    ], multiplicity ?? FilterMultiplicity.OncePerOption, availableItems);
}
export function createTagSetFilters(availableItems: TagSetResource[], initialIncludedValue: string[], initialExcludedValue: string[], multiplicity?: FilterMultiplicity): Filter[] {
    return availableItems.map((ts) => {
        const tagIdsInTagSet = ts.Tags.map((t) => t.Id);
        const tagIdsInIncludeFilter = initialIncludedValue.filter((t) => tagIdsInTagSet.includes(t));
        const tagIdsInExcludeFilter = initialExcludedValue.filter((t) => tagIdsInTagSet.includes(t));
        return createTagSetFilter([
            { option: FilterOption.In, initialValue: tagIdsInIncludeFilter.length > 0 ? tagIdsInIncludeFilter : undefined },
            { option: FilterOption.NotIn, initialValue: tagIdsInExcludeFilter.length > 0 ? tagIdsInExcludeFilter : undefined },
        ], multiplicity ?? FilterMultiplicity.OncePerOption, ts.Tags, ts.Name);
    });
}
export function createTagSetFilter(options: Option[], multiplicity: FilterMultiplicity, availableItems: TagResource[], name: string): Filter {
    return {
        entity: FilterEntity.TagSet,
        options,
        multiplicity,
        availableItems,
        name,
    };
}
