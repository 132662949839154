/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import type { BreadcrumbItem } from "@octopusdeploy/design-system-components";
import { ActionButton, ActionButtonType, NavigationButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useParams } from "react-router";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import { client, repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import routeLinks from "../../../../routeLinks";
import styles from "./style.module.less";
interface RawLogProps {
    taskId: string;
    isPageHeaderVNextEnabled: boolean;
}
interface RawLogState extends DataBaseComponentState {
    task?: TaskResource<any>;
    raw?: string;
}
class RawLogInternal extends DataBaseComponent<RawLogProps, RawLogState> {
    constructor(props: RawLogProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        return this.refresh();
    }
    refresh() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.props.taskId);
            const raw = await repository.Tasks.getRaw(task);
            this.setState({ task, raw });
        });
    }
    render() {
        const task = this.state.task!;
        const actionList = task && (<ActionList actions={[
                <NavigationButton key="raw" href={task.Links["Raw"]} label="Download" external={true}/>,
                <ActionButton key="refresh" label="Refresh" type={ActionButtonType.Primary} disabled={this.state.busy} onClick={() => this.refresh()}/>,
            ]}/>);
        const breadcrumbsItems: BreadcrumbItem[] = [{ label: "Tasks", pageUrl: routeLinks.tasks.root }];
        if (task) {
            breadcrumbsItems.push({ label: task.Description, pageUrl: routeLinks.task(task && task.Id).root });
        }
        return (<main>
                {!this.props.isPageHeaderVNextEnabled && <AreaTitle link={routeLinks.tasks.rootForSpace(client.spaceId)} title="Tasks"/>}

                <ContextualHelpLayout>
                    <div className={this.props.isPageHeaderVNextEnabled ? containerStylesVNext : containerStyles}>
                        <PaperLayout title={"Raw Log"} titleLogo={task ? <TaskStatusIcon item={task}/> : undefined} breadcrumbTitle={task && task.Description} breadcrumbPath={routeLinks.task(task && task.Id).root} breadcrumbsItems={breadcrumbsItems} busy={this.state.busy} errors={this.errors} sectionControl={actionList} fullWidth={true}>
                            {task && <div className={styles.rawLogContent}>{this.state.raw}</div>}
                        </PaperLayout>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "RawLogInternal";
}
const containerStyles = css({
    margin: `${space[12]} ${space[16]} 0 ${space[16]}`,
});
const containerStylesVNext = css({
    margin: space[32],
});
export function RawLog() {
    const { taskId } = useParams<{
        taskId: string;
    }>();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return <RawLogInternal taskId={taskId} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
