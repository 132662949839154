/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CircularProgress } from "@octopusdeploy/design-system-components";
import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { client } from "~/clientInstance";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import type { SelectProps } from "../../../primitiveComponents/form/Select/Select";
import { withBoundField } from "../BoundField/BoundField";
import { SelectWithActions } from "../SelectWithActions/SelectWithActions";
interface SelectWithAddRefreshProps extends SelectProps {
    addUrl: string;
    disableAdd?: boolean;
    addButtonTooltipText?: string;
    onRequestRefresh(): Promise<void>;
}
interface SelectWithAddRefreshState {
    refreshing: boolean;
}
export default class SelectWithAddRefresh extends React.Component<SelectWithAddRefreshProps, SelectWithAddRefreshState> {
    constructor(props: SelectWithAddRefreshProps) {
        super(props);
        this.state = {
            refreshing: false,
        };
    }
    render() {
        const { addUrl, onRequestRefresh, ...selectProps } = this.props;
        return <SelectWithActions {...selectProps} actions={this.actions()}/>;
    }
    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });
        try {
            await this.props.onRequestRefresh();
        }
        finally {
            this.setState({ refreshing: false });
        }
    };
    private actions() {
        return [
            this.state.refreshing ? <CircularProgress size="small"/> : <IconButtonWithTooltip onClick={this.onRequestRefresh} toolTipContent="Refresh" icon="Refresh"/>,
            <IconButtonWithTooltip disabled={this.props.disableAdd} toolTipContent={this.props.addButtonTooltipText ? this.props.addButtonTooltipText : "Add"} onClick={this.openAddWindow} icon="Add"/>,
        ];
    }
    private openAddWindow = () => {
        window.open(resolveStringPathWithSpaceId(this.props.addUrl, client.spaceId!), "_blank");
    };
    static displayName = "SelectWithAddRefresh";
}
export const BoundSelectWithAddRefresh = withBoundField(SelectWithAddRefresh);
