import { css } from "@emotion/css";
import { CircularProgress } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/dist/src/resources/userOnboardingResource";
import React, { useState } from "react";
import { useAnalyticSampleProjectDispatch } from "~/analytics/Analytics";
import { generateGuid } from "~/areas/projects/components/Process/generation/index";
import { repository, session } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import routeLinks from "~/routeLinks";
const style = {
    disabledLink: css({
        pointerEvents: "none",
        cursor: "default",
        color: themeTokens.color.text.disabled,
    }),
    loadingText: css({
        color: themeTokens.color.text.primary,
        font: text.interface.body.default.medium,
    }),
    loadingCallout: css({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 10,
        paddingBottom: 10,
        gap: space[12],
        opacity: 0,
    }),
};
const calloutLoadingStyle = css(style.loadingCallout, {
    opacity: 1,
    transition: "opacity 300ms ease-in",
});
interface CreateSampleProjectCalloutInternalProps {
    userOnboarding: UserOnboardingResource | undefined;
    currentSpace: SpaceResource | undefined;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    onCreateSampleProject: (sampleProjectReference: string) => Promise<string | undefined>;
}
function CreateSampleProjectCalloutInternal(props: CreateSampleProjectCalloutInternalProps) {
    const { userOnboarding, currentSpace, busy, errors, onCreateSampleProject } = props;
    const navigate = useSpaceAwareNavigation();
    const sampleProjectDispatchAction = useAnalyticSampleProjectDispatch();
    const [loadingStyle, setLoadingStyle] = useState(style.loadingCallout);
    const clickSampleProject = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (busy) {
            return;
        }
        const sampleProjectReference = generateGuid();
        sampleProjectDispatchAction("Request Sample Project", {
            sampleProjectReference,
        });
        setTimeout(() => setLoadingStyle(calloutLoadingStyle), 10);
        const route = await onCreateSampleProject(sampleProjectReference);
        if (route) {
            navigate.redirect(route);
        }
    };
    const requiredFeaturesEnabled = isFeatureToggleEnabled("SampleProjectsFeatureToggle") && isFeatureToggleEnabled("SidebarImprovementsFeatureToggle");
    if (!requiredFeaturesEnabled || !currentSpace || !userOnboarding?.IsTrialInstance || !session.currentPermissions?.isSpaceManager(currentSpace)) {
        return <></>;
    }
    return (<Callout title="Sample project" type={CalloutType.NewFeature} hideTitle={!!busy}>
            {busy ? (<div className={loadingStyle}>
                    <CircularProgress size="large"/>
                    <div className={style.loadingText}>Loading sample project</div>
                </div>) : (<div>
                    <a href={busy ? undefined : "#"} role="link" onClick={clickSampleProject} aria-disabled={!!busy} className={busy ? style.disabledLink : undefined}>
                        Create a sample project
                    </a>{" "}
                    to learn about key Octopus features.
                </div>)}
        </Callout>);
}
interface CreateSampleProjectCalloutState {
    currentSpace?: SpaceResource;
    userOnboarding?: UserOnboardingResource;
}
class CreateSampleProjectCallout extends DataBaseComponent<{}, CreateSampleProjectCalloutState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId)
                return;
            const [response, space] = await Promise.all([repository.UserOnboarding.getForCurrentUser(), repository.Spaces.get(repository.spaceId)]);
            this.setState({ userOnboarding: response.UserOnboardingResource, currentSpace: space });
        });
    }
    async handleCreateSampleProject(sampleProjectReference: string): Promise<string | undefined> {
        let route: string | undefined = undefined;
        await this.doBusyTask(async () => {
            const sampleProject = await repository.Projects.createSampleProject("Sample Project", sampleProjectReference);
            route = routeLinks.forSpace(this.state.currentSpace?.Id).project(sampleProject.Project).overview;
        });
        return route;
    }
    render() {
        return (<CreateSampleProjectCalloutInternal busy={this.state.busy} userOnboarding={this.state.userOnboarding} currentSpace={this.state.currentSpace} onCreateSampleProject={(sampleProjectReference: string) => this.handleCreateSampleProject(sampleProjectReference)} errors={this.errors}/>);
    }
    static displayName = "CreateSampleProjectCallout";
}
export default CreateSampleProjectCallout;
