import { css, cx } from "@emotion/css";
import { Checkbox, ListItem } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubRepository } from "@octopusdeploy/octopus-server-client";
import React from "react";
import Chip from "~/components/Chips/Chip";
export interface GitHubAppRepositoryListItemProps {
    repository: GitHubRepository | string;
    selected: boolean;
    onChange: (newValue: boolean, repositoryId: string) => void;
    disableInteraction?: boolean;
}
export function GitHubAppRepositoryListItem({ repository, selected, onChange, disableInteraction }: GitHubAppRepositoryListItemProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            paddingLeft: space[16],
            paddingRight: space[16],
            gap: space[4],
            alignItems: "center",
        }),
        checkboxContainer: css({
            flexGrow: 1,
            alignSelf: "flex-start",
        }),
        metadataContainer: css({
            display: "flex",
            flexGrow: 0,
            flexDirection: "row-reverse",
            overflow: "hidden",
        }),
        repositoryLabel: css({
            marginTop: space[16],
            marginBottom: space[16],
            height: space[24],
            lineHeight: "1.5",
        }),
    };
    const isKnownRepository = typeof repository !== "string";
    const repositoryId = isKnownRepository ? repository.RepositoryId : repository;
    const repositoryName = isKnownRepository ? repository.RepositoryName : repository;
    return (<ListItem>
            <div className={styles.container}>
                <div className={styles.checkboxContainer}>
                    {disableInteraction ? <div className={styles.repositoryLabel}>{repositoryName}</div> : <Checkbox value={selected} label={repositoryName} accessibleName={repositoryName} onChange={(selected) => onChange(selected, repositoryId)}/>}
                </div>
                {isKnownRepository && (<div className={styles.metadataContainer}>
                        <Chip noTooltip>
                            <LockIcon unlocked={!repository.IsPrivate}/>
                            {repository.Visibility}
                        </Chip>
                        {repository.Language && <Chip noTooltip>{repository.Language}</Chip>}
                    </div>)}
            </div>
        </ListItem>);
}
interface LockIconProps {
    unlocked?: boolean;
}
export function LockIcon({ unlocked }: LockIconProps) {
    const iconInLabelStyles = css({
        marginRight: space[8],
    });
    const lockClass = unlocked ? "fa-lock-open" : "fa-lock";
    return <em className={cx("fa", lockClass, iconInLabelStyles)} aria-hidden={true}/>;
}
