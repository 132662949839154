import { Button } from "@octopusdeploy/design-system-components";
import * as React from "react";
type ResourceLinkProps = {
    icon: React.ReactNode;
    label: string;
    href: string;
    info?: boolean;
};
export function ResourceLink({ label, href, icon, info = false }: ResourceLinkProps) {
    return <Button leftIcon={icon} label={label} size="small" importance={info ? "tertiary" : "neutral"} onClick={() => handleClick(href)}/>;
}
function handleClick(href: string) {
    if (href.startsWith("mailto:")) {
        const email = href;
        const mailtoLink = `${email}`;
        window.location.href = mailtoLink;
    }
    else {
        window.open(href, "_blank", "noopener,noreferrer");
    }
}
