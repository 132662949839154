import { ActionButtonType, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { OctoLink } from "@octopusdeploy/utilities";
import React, { useEffect, useState } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import reportImageDark from "./assets/Upsell_Insights_Dark.svg";
import reportImage from "./assets/Upsell_Insights_Light.svg";
import styles from "./styles.module.less";
interface Props {
    showLicenseExpired: boolean;
}
export function ReportUpsell({ showLicenseExpired }: Props) {
    const dispatchAction = useAnalyticSimpleActionDispatch();
    const [requestSent, setRequestSent] = useState(false);
    const isDarkMode = useThemePaletteType() === "dark";
    const image = isDarkMode ? reportImageDark : reportImage;
    const onRequestTrial = async () => {
        await repository.InsightsReports.requestTrial();
        dispatchAction("Request for Insights trial submitted");
        setRequestSent(true);
        // Have to return here to close dialog
        return true;
    };
    const trackOpeningRequestDialog = () => dispatchAction("Request Insights trial dialog opened");
    useEffect(() => {
        dispatchAction("View Insights Reports Upsell page");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
            <AreaTitle link={routeLinks.insights.root} title="Insights"/>
            <main className={styles.main}>
                <div className={styles.outerContainer}>
                    <div className={styles.contentContainer}>
                        <div className={styles.content}>
                            <div className={styles.callouts}>
                                {requestSent && <RequestSentCallout />}
                                {showLicenseExpired && <LicenseExpiredCallout />}
                            </div>
                            <h1>Reduce uncertainty, save time</h1>
                            <p className={styles.paragraph}>
                                Access DORA metrics across your teams at a portfolio level to help quantify your performance across projects, environments or tenants. See what you need to do to improve next, increasing tempo and stability.
                            </p>
                            <p className={styles.paragraph}>Space-level DevOps Insights is a part of the Enterprise tier.</p>
                            <p className={styles.paragraph}>Request a trial of this feature from our Customer Success Team.</p>
                            <div className={styles.buttons}>
                                <OpenDialogButton label="Request trial" type={ActionButtonType.Primary} onClick={trackOpeningRequestDialog}>
                                    <RequestInsightsTrialDialog onRequestTrial={onRequestTrial}/>
                                </OpenDialogButton>
                                <div className={styles.navButtonWrap}>
                                    <NavigationButton href={OctoLink.Create("InsightsReports")} external label="Learn More" type={NavigationButtonType.Ternary}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.blankPageImage}>
                        <img className={styles.image} src={image} alt="Insights reports"/>
                    </div>
                </div>
            </main>
        </>);
}
function RequestSentCallout() {
    return (<Callout title="Your request for access has been sent" type={CalloutType.Success}>
            <p>The Customer Success Team will reach out to you with details of the trial.</p>
        </Callout>);
}
function LicenseExpiredCallout() {
    return (<Callout title="License expired" type={CalloutType.Information}>
            <p>This feature is limited to the Enterprise tier.</p>
        </Callout>);
}
interface RequestInsightsTrialDialogProps {
    onRequestTrial: () => Promise<boolean>;
}
function RequestInsightsTrialDialog({ onRequestTrial }: RequestInsightsTrialDialogProps) {
    return (<OkDialogLayout title="Request Trial" okButtonLabel="Continue" onOkClick={onRequestTrial}>
            <p>This will send a message to our Customer Success Team, who will contact you with the details of the trial.</p>
        </OkDialogLayout>);
}
