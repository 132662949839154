import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import type { GitDependencyReference } from "@octopusdeploy/octopus-server-client/dist/src/resources/gitDependencyReference";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import GitCredentialSelect from "~/areas/projects/components/ProjectSettings/VersionControl/GitCredentialSelect";
import { repository } from "~/clientInstance";
import PermissionCheck, { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import type { SummaryNode } from "~/components/form/index";
import { Note, required, Summary } from "~/components/form/index";
export interface GitSourceComponentProps {
    expandedByDefault: boolean;
    gitDependencyReference: GitDependencyReference | undefined;
    onGitDependencyChanged?(gitDependency: GitDependencyReference, initialise?: boolean): void;
    getFieldError(field: string): string;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    localNames?: string[];
}
function GitSourceComponent({ expandedByDefault, gitDependencyReference, onGitDependencyChanged, getFieldError, localNames, doBusyTask }: GitSourceComponentProps) {
    const [gitCredentials, setGitCredentials] = useState(new Array<GitCredentialResource>());
    const refreshGitCredentials = useCallback(async () => {
        if (hasPermission(Permission.GitCredentialView)) {
            await doBusyTask(async () => {
                const gitCredentials = await repository.GitCredentials.list({ take: Repository.takeAll });
                setGitCredentials(gitCredentials.Items);
            });
        }
        else {
            setGitCredentials([]);
        }
    }, [doBusyTask]);
    const [gitDependency, setGitDependency] = useState<GitDependencyReference>({
        Name: gitDependencyReference?.Name || "",
        DefaultBranch: gitDependencyReference?.DefaultBranch || "main",
        GitCredentialId: gitDependencyReference?.GitCredentialId || undefined,
        GitCredentialType: gitDependencyReference?.GitCredentialType || "Library",
        RepositoryUri: gitDependencyReference?.RepositoryUri || "",
        FilePathFilters: gitDependencyReference?.FilePathFilters || null,
        StepPackageInputsReferenceId: gitDependencyReference?.StepPackageInputsReferenceId,
    });
    useEffect(() => {
        (async () => await refreshGitCredentials())();
    }, [refreshGitCredentials]);
    interface GitDependencyProperty {
        property: keyof GitDependencyReference;
        value: string | undefined;
    }
    const setGitDependencyProperties = useCallback((changes: GitDependencyProperty[]) => {
        const reducedChanges = changes.reduce((acc, current) => {
            acc[current.property] = current.value;
            return acc;
        }, 
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {} as {
            [key in GitDependencyReference as string]: string | undefined;
        });
        setGitDependency((prevDep) => ({
            ...prevDep,
            ...reducedChanges,
        }));
        onGitDependencyChanged?.({
            ...gitDependency,
            ...reducedChanges,
        });
    }, [gitDependency, onGitDependencyChanged]);
    const setGitDependencyProperty = useCallback((property: keyof GitDependencyReference, value: string | undefined) => setGitDependencyProperties([{ property, value }]), [setGitDependencyProperties]);
    const setRepositoryUri = (val: string) => setGitDependencyProperty("RepositoryUri", val);
    const setCredentialType = (val: string) => {
        setGitDependencyProperties([
            { property: "GitCredentialType", value: val },
            { property: "GitCredentialId", value: undefined },
        ]);
    };
    const setCredentialId = (val: string) => setGitDependencyProperty("GitCredentialId", val);
    const setDefaultBranch = (val: string) => setGitDependencyProperty("DefaultBranch", val);
    const isUsingLibraryAuth = (): boolean => gitDependency.GitCredentialType === "Library";
    const authSummary = (): SummaryNode => {
        if (isUsingLibraryAuth()) {
            const id = gitDependency.GitCredentialId;
            const credential = gitCredentials.find((c) => c.Id === id);
            return Summary.summary(<>
                    <span>Using library credential</span>,
                    <span>
                        <strong>{credential ? ` ${credential.Name}` : ""}</strong>
                    </span>
                </>);
        }
        else {
            return Summary.summary(<span>
                    Authenticated as an <strong>Anonymous</strong> user
                </span>);
        }
    };
    const repoUrlSummary = (): SummaryNode => (gitDependency.RepositoryUri ? Summary.summary(gitDependency.RepositoryUri) : Summary.placeholder("Enter a Git repository URL"));
    const branchSummary = (): SummaryNode => gitDependency.DefaultBranch
        ? Summary.summary(<span>
                      Default branch is <strong>{gitDependency.DefaultBranch}</strong>
                  </span>)
        : Summary.placeholder("Configure default branch");
    return (<div>
            <ExpandableFormSection errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.Git.Repository" isExpandedByDefault={expandedByDefault} title="Authentication" summary={authSummary()} help="Choose a method to authenticate your Git repository.">
                <RadioButtonGroup value={gitDependency.GitCredentialType} onChange={(val) => setCredentialType(val)} error={getFieldError("GitCredentialType")} accessibleName="GitCredentialType">
                    <RadioButton value={"Library"} label="Library" accessibleName="GitCredentialTypeLibrary"/>
                    {isUsingLibraryAuth() && (<PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>You need the 'GitCredentialView' permission to change the Git credential.</Note>}>
                            <GitCredentialSelect items={gitCredentials} onChange={(Id) => setCredentialId(Id)} value={gitDependency.GitCredentialId ?? ""} onRequestRefresh={refreshGitCredentials} error={getFieldError("GitCredentialId")} helperText="Select Git credential" data-testid="gitCredentialSelect"/>
                            <Note> Use credential from the Git credential library </Note>
                        </PermissionCheck>)}
                    <RadioButton value={"Anonymous"} label="Anonymous" accessibleName="GitCredentialTypeAnonymous"/>
                </RadioButtonGroup>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Url" title="Repository URL" summary={repoUrlSummary()} help="Add the Git repository where you store your script and referenced files." isExpandedByDefault={expandedByDefault}>
                <VariableLookupText key="Url" localNames={localNames} value={gitDependency.RepositoryUri} onChange={(Url) => setRepositoryUri(Url)} label="Repository URL" error={getFieldError("RepositoryUri")} validate={required("Enter a Git repository URL.")} accessibleName={"URL for Git repository"}/>
                <Note>
                    The HTTPS URL to your git repo. E.g. <code>https://github.com/OctopusDeploy/OctopusClients.git</code>.<br />
                    Files referenced in your script should also be hosted in this Git repository.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={branchSummary()} help="Specify the default branch you want to use." isExpandedByDefault={expandedByDefault}>
                <VariableLookupText key="DefaultBranch" localNames={localNames} value={gitDependency.DefaultBranch} onChange={(DefaultBranch) => setDefaultBranch(DefaultBranch)} label="Default branch" error={getFieldError("DefaultBranch")} validate={required("Enter the default branch for your Git repository.")} accessibleName={"Name of the default branch on the Git repository"}/>
            </ExpandableFormSection>
        </div>);
}
export default GitSourceComponent;
