/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, NavigationButton } from "@octopusdeploy/design-system-components";
import type { ResourcesById, PackageNote, PackageNotesList, ReleaseResource, EnvironmentResource, ArtifactResource, ChannelResource, LifecycleResource, ResourceCollection, ProjectResource, TaskResource, IPhasedResource, GetReleaseDeploymentBff, GetReleaseDetailBffResponseProgression, GetReleaseProgressionPhaseBff, } from "@octopusdeploy/octopus-server-client";
import { PackageReferenceNamesMatch, ProcessType, DefectStatus, Permission, Repository } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics/src/AnalyticLinkLocationProvider";
import * as _ from "lodash";
import { compact } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, ProjectAnalyticView, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { DeployButton } from "~/areas/projects/components/Releases/DeployButton/DeployButton";
import { GitResourcesList } from "~/areas/projects/components/Releases/GitResourcesList";
import PreventProgression from "~/areas/projects/components/Releases/PreventProgression/PreventProgression";
import { DeploymentCreateGoal } from "~/areas/projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import UpdateVariables from "~/areas/projects/components/Releases/UpdateVariables/UpdateVariables";
import type { GitReferenceModel } from "~/areas/projects/components/Releases/gitResourceModel";
import { getNotesForPackages, splitPackagesIntoBoundOrUnboundFeeds } from "~/areas/projects/components/releaseAndRunbookHelpers";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import ArtifactLink from "~/areas/tasks/components/Task/Artifacts/ArtifactLink";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import ListEventsForRelease from "~/components/ListEventsForRelease";
import Markdown from "~/components/Markdown/index";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PagingList } from "~/components/PagingList/PagingList";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { FormSectionHeading, Note } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import type { LifecycleStatus } from "~/utils/MapProgressionToStatus/MapProgressionToStatus";
import { mapProgressionToStatus } from "~/utils/MapProgressionToStatus/MapProgressionToStatus";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import { LifecycleProgression } from "./LifecycleProgression";
import PackagesList from "./PackagesList/PackagesList";
import { ProcessSnapshotFormSection } from "./ProcessSnapshot/ProcessSnapshot";
import { VariableSnapshot } from "./VariableSnapshot/VariableSnapshot";
import type { PackageModel } from "./packageModel";
import { buildPartialReleaseNotes } from "./releaseNoteHelper";
import styles from "./style.module.less";
interface ReleaseState extends DataBaseComponentState {
    project: ProjectResource;
    release: ReleaseResource;
    progression: GetReleaseDetailBffResponseProgression;
    showUnblockReleaseDialog: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;
    packages: PackageModel[];
    gitResourceReferences: GitReferenceModel[];
    artifacts: ResourceCollection<ArtifactResource>;
    defects: Defect;
    channels: ChannelResource[];
    releaseChannel: ChannelResource;
    lifecycle: LifecycleResource;
    deployments: GetReleaseDeploymentBff[];
    deploymentTasks: Array<TaskResource<{
        DeploymentId: string;
    }>>;
    lifecycleStatus: LifecycleStatus;
    deploymentsByPhase: {
        [phase: string]: GetReleaseDeploymentBff[];
    };
    progressionByPhase: {
        [phase: string]: GetReleaseProgressionPhaseBff;
    };
    showFullReleaseNotes: boolean;
    showLifecycleProgression: boolean;
    isInitialLoad: boolean;
    totalNumOfEnvironments: number;
    totalNumOfPhases: number;
    hasPendingInteruptions: boolean;
    currentPageIndex: number;
    currentSkip: number;
    variableSnapshotRefreshKey: string;
    deleted: boolean;
    numberOfDeploymentsPerEnvironment: number;
}
interface Defect {
    releaseHasDefect: boolean;
    defectDescription: string;
}
class ArtifactsList extends PagingList<ArtifactResource> {
}
type ReleaseProps = RouteComponentProps<ProjectRouteParams & {
    releaseVersion: string;
}>;
interface ReleasePropsInternal extends ReleaseProps, WithProjectContextInjectedProps {
    dispatchAction: AnalyticActionDispatcher;
}
class ReleaseInternal extends DataBaseComponent<ReleasePropsInternal, ReleaseState> {
    private showHideLifecycleThreshhold: number = 20;
    private packageResolveMessage: string = "Package will be resolved during deployment";
    constructor(props: ReleasePropsInternal) {
        super(props);
        this.state = {
            project: null!,
            release: null!,
            progression: null!,
            showUnblockReleaseDialog: false,
            environmentsById: null!,
            packages: [],
            gitResourceReferences: [],
            artifacts: null!,
            defects: null!,
            channels: [],
            releaseChannel: null!,
            lifecycle: null!,
            deployments: [],
            deploymentTasks: [],
            lifecycleStatus: null!,
            deploymentsByPhase: null!,
            progressionByPhase: null!,
            showFullReleaseNotes: false,
            showLifecycleProgression: true,
            isInitialLoad: true,
            totalNumOfEnvironments: 0,
            totalNumOfPhases: 0,
            hasPendingInteruptions: false,
            currentPageIndex: 0,
            currentSkip: 0,
            variableSnapshotRefreshKey: DateFormatter.timestamp(),
            deleted: false,
            numberOfDeploymentsPerEnvironment: Repository.takeDefaultPageSize,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const release = await repository.Projects.getReleaseByVersion(project, this.props.match.params.releaseVersion);
            const environmentsById = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? await repository.Environments.allById() : null!;
            await this.init(project, release);
            await this.startRefreshLoop(() => this.refreshActiveComponents(project, release, environmentsById), 15000);
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const projectLinks = routeLinks.project(this.props.match.params.projectSlug);
        if (this.state.deleted) {
            return <InternalRedirect to={projectLinks.releases} push={true}/>;
        }
        const releaseLinks = projectLinks.release(this.props.match.params.releaseVersion);
        const overflowActions = [];
        if (this.state.project) {
            overflowActions.push(OverflowMenuItems.navItem("Edit", releaseLinks.edit, { permission: Permission.ReleaseEdit, project: this.state.project.Id, tenant: "*" }));
        }
        if (isAllowed({ permission: Permission.DefectReport, project: this.state.project && this.state.project.Id })) {
            if (this.state.defects && this.state.release) {
                if (this.state.defects.releaseHasDefect) {
                    overflowActions.push(OverflowMenuItems.item("Unblock", () => this.setState({ showUnblockReleaseDialog: true }), { permission: Permission.DefectResolve, project: this.state.project.Id }));
                }
                else {
                    if (this.state.release && this.state.defects) {
                        overflowActions.push(OverflowMenuItems.dialogItem("Prevent Progression", <PreventProgression defects={this.state.defects} release={this.state.release} onPreventProgressionClicked={(desc) => this.reportDefect(desc)}/>));
                    }
                }
            }
        }
        if (this.state.release) {
            overflowActions.push(OverflowMenuItems.dialogItem("Update Variables", <UpdateVariables processType={ProcessType.Deployment} onUpdateVariablesClicked={async () => {
                    const updatedRelease = await repository.Releases.snapshotVariables(this.state.release);
                    this.setState({ release: updatedRelease, variableSnapshotRefreshKey: DateFormatter.timestamp() });
                }}/>, {
                permission: Permission.ReleaseEdit,
                project: this.state.project && this.state.project.Id,
                wildcard: true,
            }));
            overflowActions.push(OverflowMenuItems.deleteItemDefault("release", this.handleDeleteConfirm, {
                permission: Permission.ReleaseDelete,
                project: this.state.project && this.state.project.Id,
                tenant: "*",
            }, "The release and any of its deployments will be permanently deleted and they will disappear from all dashboards."));
            overflowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.release.Id]), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const actions = [];
        if (this.state.project) {
            actions.push(<PermissionCheck permission={Permission.DeploymentCreate} project={this.state.project.Id} environment="*" tenant="*">
                    <NavigationButton label="Deploy To..." href={releaseLinks.deployments.create(DeploymentCreateGoal.To)} onClick={() => {
                    const ev: ActionEvent = {
                        action: Action.Deploy,
                        resource: "Deploy Release",
                    };
                    this.props.dispatchAction("Initiate Deployment", ev);
                }}/>
                </PermissionCheck>);
        }
        if (this.state.progression && this.state.environmentsById && this.state.project) {
            actions.push(<DeployButton releaseVersion={this.state.release.Version} projectSlug={this.state.project.Slug} projectId={this.state.project.Id} nextDeployments={this.state.progression.NextDeployments} environmentsById={this.state.environmentsById} tenantedDeploymentMode={this.state.project.TenantedDeploymentMode}/>);
        }
        if (this.state.release) {
            actions.push(<OverflowMenu menuItems={overflowActions}/>);
        }
        const sectionControl = <ActionList actions={actions}/>;
        return (<AnalyticLinkLocationProvider location="Content">
                <ProjectPageLayout title={this.releaseTitle()} breadcrumbTitle={"Releases"} breadcrumbPath={projectLinks.releases} breadcrumbsItems={[{ label: "Releases", pageUrl: projectLinks.releases }]} busy={this.state.busy} errors={this.errors} sectionControl={sectionControl} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                    {this.state.release && !this.state.deleted && <ProjectAnalyticView name="View a Release" resource="Release" projectId={this.state.project.Id}/>}
                    <div className={styles.releaseDetailsLayout}>
                        {this.state.release && (<div className={styles.releaseDetailsLayoutContent}>
                                <FormSectionHeading key="deployments" title="Progression"/>
                                <PermissionCheck permission={Permission.LifecycleView} alternate={<Section key="sectionLifecycleNoAccess" sectionHeader="">
                                            <Callout type={CalloutType.Information} title={"Permission required"}>
                                                The {Permission.LifecycleView} permission is required to view the deployments
                                            </Callout>
                                        </Section>}>
                                    <PermissionCheck permission={Permission.EnvironmentView} wildcard={true} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                                                The {Permission.EnvironmentView} permission is required to view where the lifecycle flows.
                                            </Callout>}>
                                        {this.state.release && this.state.progression && this.state.environmentsById && (<div className={styles.container}>
                                                {this.state.defects && this.state.defects.releaseHasDefect && (<div className={styles.container}>
                                                        <Callout title="Deployment to the next phase is blocked" type={CalloutType.Danger}>
                                                            <Markdown markup={this.state.defects.defectDescription}/>
                                                            <PermissionCheck permission={Permission.DefectResolve} project={this.state.release.ProjectId}>
                                                                <ActionButton type={ActionButtonType.Save} onClick={() => this.setState({ showUnblockReleaseDialog: true })} label="Unblock"/>
                                                            </PermissionCheck>
                                                        </Callout>
                                                        <PermissionCheck permission={Permission.DefectResolve} project={this.state.release.ProjectId}>
                                                            <ConfirmationDialog title="Unblock deployment" continueButtonLabel="Unblock" open={this.state.showUnblockReleaseDialog} onClose={() => this.setState({ showUnblockReleaseDialog: false })} onContinueClick={async () => this.resolveDefect()}>
                                                                <p>If it is now safe to proceed with deployments of this release, you can unblock it.</p>
                                                            </ConfirmationDialog>
                                                        </PermissionCheck>
                                                    </div>)}
                                                <LifecycleProgression project={this.state.project} release={this.state.release} channels={this.state.channels} releaseChannel={this.state.releaseChannel} deploymentTasks={this.state.deploymentTasks} progression={this.state.progression} lifecycle={this.state.lifecycle} lifecycleStatus={this.state.lifecycleStatus} environmentsById={this.state.environmentsById} deploymentsByPhase={this.state.deploymentsByPhase} progressionByPhase={this.state.progressionByPhase} showLifecycleProgression={this.state.showLifecycleProgression} onLifecycleProgressionToggled={() => this.setState({ showLifecycleProgression: !this.state.showLifecycleProgression })} totalNumOfEnvironments={this.state.totalNumOfEnvironments} totalNumOfPhases={this.state.totalNumOfPhases} isCollapsable={this.state.totalNumOfEnvironments > this.showHideLifecycleThreshhold} hasPendingInteruptions={this.state.hasPendingInteruptions} loadMoreDeployments={this.loadMoreDeployments} {...this.props}/>
                                            </div>)}
                                    </PermissionCheck>
                                </PermissionCheck>
                                {this.state.release.ReleaseNotes && this.getReleaseNoteSection()}
                                {this.state.release.VersionControlReference && <ProcessSnapshotFormSection release={this.state.release}/>}
                                <FormSectionHeading key="packages" title="Packages"/>
                                <Section key="sectionPackages" sectionHeader="">
                                    <PermissionCheck permission={Permission.FeedView} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                                                The {Permission.FeedView} permission is required to view packages
                                            </Callout>}>
                                        <PermissionCheck permission={Permission.DeploymentView} project={this.state.release.ProjectId} wildcard={true} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                                                    The {Permission.DeploymentView} permission is required to view packages
                                                </Callout>}>
                                            <div className={styles.releasePackagesLayout}>
                                                <PackagesList packages={this.state.packages} buildInformation={this.state.release.BuildInformation}/>
                                            </div>
                                        </PermissionCheck>
                                    </PermissionCheck>
                                </Section>
                                {this.state.gitResourceReferences && (<>
                                        <FormSectionHeading key="gitResources" title="Git Resources"/>
                                        <div className={styles.container}>
                                            <div className={styles.releasePackagesLayout}>
                                                <GitResourcesList gitReferences={this.state.gitResourceReferences}/>
                                            </div>
                                        </div>
                                    </>)}

                                {/*Include the VariableSnapshot outside the Section so that the table can extend to the edge of the paper element*/}
                                {this.state.release && (<PermissionCheck permission={Permission.VariableView} project={this.state.release.ProjectId} wildcard={true}>
                                        <VariableSnapshot projectId={this.state.release.ProjectId} snapshot={this.state.release} doBusyTask={this.doBusyTask} updateVariablesRefreshKey={this.state.variableSnapshotRefreshKey} onUpdate={this.reloadRelease}/>
                                    </PermissionCheck>)}
                                {this.state.artifacts && (<>
                                        <FormSectionHeading key="artifacts" title="Artifacts"/>
                                        <div className={styles.releaseArtifactsLayout}>
                                            <ArtifactsList initialData={this.state.artifacts} onRow={(artifact: ArtifactResource) => (<>
                                                        <ArtifactLink artifact={artifact} key="link"/>
                                                        <div key="time" className={styles.time}>
                                                            <TimeFromNowLabel time={artifact.Created}/>
                                                        </div>
                                                    </>)} showPagingInNumberedStyle={true} currentPageIndex={this.state.currentPageIndex} onPageSelected={this.handleArtifactsPageSelected} empty={<Note>
                                                        No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                                    </Note>}/>
                                        </div>
                                    </>)}
                                <FormSectionHeading key="deploymentHistory" title="Deployment history"/>
                                {/* Technically, the `ReleaseView` check should _also be wrapped_ by `EnvironmentView`,
                but we leak this data in the old portal via event history, so will leave it like this for consistency for now
            <PermissionCheck permission={Permission.EnvironmentView} project={this.state.release.ProjectId} wildcard={true} alternate={
                <Callout type={CalloutType.Information}>
                The {Permission.EnvironmentView} permission is required to view the deployment history, so you can see where it was deployed.
            </Callout>}> */}
                                <PermissionCheck permission={Permission.DeploymentView} project={this.state.release.ProjectId} wildcard={true} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                                            The {Permission.DeploymentView} permission is required to view the deployment history
                                        </Callout>}>
                                    <ListEventsForRelease release={this.state.release}/>
                                </PermissionCheck>
                            </div>)}
                    </div>
                </ProjectPageLayout>
            </AnalyticLinkLocationProvider>);
    }
    private loadMoreDeployments = async (phaseName: string, environmentId: string, takePerEnvironment: number) => {
        const currentTake = this.state.numberOfDeploymentsPerEnvironment;
        const numberOfDeploymentsPerEnvironment = takePerEnvironment < currentTake ? currentTake : takePerEnvironment;
        let numberOfDeploymentsLoaded = 0;
        await this.doBusyTask(async () => {
            const state = await this.refreshActiveComponents(this.state.project, this.state.release, this.state.environmentsById, numberOfDeploymentsPerEnvironment);
            numberOfDeploymentsLoaded = state.deploymentsByPhase[phaseName].filter((d) => d.EnvironmentId === environmentId).length;
            this.setState({ ...state, numberOfDeploymentsPerEnvironment });
        });
        return numberOfDeploymentsLoaded;
    };
    private async init(project: ProjectResource, release: ReleaseResource) {
        const { projectContextRepository } = this.props.projectContext.state;
        const [defects, channels, deploymentProcess] = await Promise.all([
            this.loadDefects(release),
            isAllowed({ permission: Permission.LifecycleView }) ? repository.Projects.getChannels(project) : null,
            isAllowed({ permission: Permission.DeploymentView, project: project.Id, wildcard: true }) ? projectContextRepository.DeploymentProcesses.getForRelease(release) : null,
        ]);
        const template = deploymentProcess && (await projectContextRepository.DeploymentProcesses.getTemplate(deploymentProcess, release.ChannelId, release.Id));
        this.setState({
            defects,
            channels: channels ? channels.Items : [],
        });
        const allPackages = template
            ? compact(template.Packages.map((packageTemplate) => {
                const selectionForStep = release.SelectedPackages.find((selected) => selected.ActionName === packageTemplate.ActionName && PackageReferenceNamesMatch(selected.PackageReferenceName!, packageTemplate.PackageReferenceName!));
                if (selectionForStep) {
                    return {
                        ActionName: packageTemplate.ActionName,
                        PackageId: packageTemplate.PackageId,
                        PackageReferenceName: packageTemplate.PackageReferenceName!,
                        ProjectName: packageTemplate.ProjectName,
                        FeedName: packageTemplate.FeedName,
                        FeedId: packageTemplate.FeedId,
                        Version: selectionForStep.Version,
                        Notes: {
                            Notes: "Loading...",
                            Succeeded: true,
                            FailureReason: null,
                            Published: null,
                        },
                    };
                }
            }))
            : [];
        this.setState(() => ({ packages: allPackages }));
        if (isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true })) {
            // `loadPackages` is not awaited to prevent the page from freezing while waiting for package notes to load.
            this.loadPackages(allPackages);
        }
        const gitReferenceModels: GitReferenceModel[] = template?.GitResources?.map((gr): GitReferenceModel => ({
            ActionName: gr.ActionName,
            RepositoryUri: gr.RepositoryUri,
            FilePathFilters: gr.FilePathFilters,
            GitResource: release.SelectedGitResources?.find((sgr) => sgr.ActionName === gr.ActionName)!.GitReferenceResource,
        })) ?? [];
        this.setState(() => ({ gitResourceReferences: gitReferenceModels }));
    }
    reloadRelease = async () => {
        const project = this.props.projectContext.state.model;
        const release = await repository.Projects.getReleaseByVersion(project, this.props.match.params.releaseVersion);
        this.setState({ release });
    };
    private usePermissionCheckOptimization() {
        return session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
    }
    private async loadTasks(deployments: GetReleaseDeploymentBff[]) {
        const ids = deployments
            .filter((deployment) => session.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({
            permission: Permission.TaskView,
            projectId: deployment.ProjectId,
            environmentId: deployment.EnvironmentId,
            tenantId: deployment.TenantId,
        }))
            .map((deployment) => deployment.TaskId);
        return this.usePermissionCheckOptimization() ? repository.Tasks.byIdsUnpaginated(ids) : repository.Tasks.byIds(ids);
    }
    private async getLifecycle(project: ProjectResource, release: ReleaseResource) {
        if (release.ChannelId) {
            const channel = await repository.Releases.getChannel(release);
            const lifecycleId = channel.LifecycleId ? channel.LifecycleId : project.LifecycleId;
            const lifecycleRaw = await repository.Lifecycles.get(lifecycleId);
            const lifecycle = await repository.Lifecycles.preview(lifecycleRaw);
            return [lifecycle, channel];
        }
        else {
            const lifecycleRaw = await repository.Lifecycles.get(project.LifecycleId);
            const lifecycle = await repository.Lifecycles.preview(lifecycleRaw);
            return [lifecycle, null];
        }
    }
    private async refreshActiveComponents(project: ProjectResource, release: ReleaseResource, environmentsById: ResourcesById<EnvironmentResource>, numberOfDeploymentsPerEnvironment?: number) {
        const [channelAndLifecycle, artifacts, releaseDetails] = await Promise.all([
            isAllowed({ permission: Permission.LifecycleView }) ? this.getLifecycle(project, release) : null!,
            this.loadArtifactsPromise(release, this.state.currentSkip)!,
            repository.Releases.getViewReleaseDetailsBff(release, numberOfDeploymentsPerEnvironment || this.state.numberOfDeploymentsPerEnvironment),
        ]);
        const progression = releaseDetails.ReleaseProgression;
        const hasPendingInteruptions = progression.Phases.some((p) => p.Deployments.some((d) => d.TaskHasPendingInterruptions));
        const deployments = releaseDetails && releaseDetails.Deployments ? releaseDetails.Deployments : null!;
        const tasksPromise = deployments && this.loadTasks(deployments);
        const deploymentsByPhase: {
            [name: string]: GetReleaseDeploymentBff[];
        } = {};
        const lifecycle = channelAndLifecycle && (channelAndLifecycle[0] as LifecycleResource);
        const releaseChannel = channelAndLifecycle && (channelAndLifecycle[1] as ChannelResource);
        if (deployments && lifecycle) {
            const phases: IPhasedResource[] = lifecycle.Phases.length > 0 ? lifecycle.Phases : progression.Phases;
            const explicitEnvironmentsByPhase: string[] = [];
            phases.map((phase: IPhasedResource) => {
                phase.AutomaticDeploymentTargets.map((envId) => explicitEnvironmentsByPhase.push(envId));
                phase.OptionalDeploymentTargets.map((envId) => explicitEnvironmentsByPhase.push(envId));
            });
            phases.forEach((phase) => {
                const deploymentsForPhase = deployments.filter((deployment) => {
                    if (phase.AutomaticDeploymentTargets.length > 0 || phase.OptionalDeploymentTargets.length > 0) {
                        return phase.AutomaticDeploymentTargets.indexOf(deployment.EnvironmentId) !== -1 || phase.OptionalDeploymentTargets.indexOf(deployment.EnvironmentId) !== -1;
                    }
                    else {
                        // Phase has no explicit environments selected, use all the remaining environments
                        const environmentsInPhase = _.differenceWith(Object.keys(environmentsById), explicitEnvironmentsByPhase, _.isEqual);
                        return environmentsInPhase.indexOf(deployment.EnvironmentId) !== -1;
                    }
                });
                deploymentsByPhase[phase.Name] = deploymentsForPhase;
            });
        }
        const lifecycleStatus = mapProgressionToStatus(progression);
        const progressionByPhase: {
            [phase: string]: GetReleaseProgressionPhaseBff;
        } = {};
        let totalNumOfEnvironments: number = 0;
        progression.Phases.forEach((phaseProgress) => {
            progressionByPhase[phaseProgress.Name] = phaseProgress;
            const envsForPhase = [...phaseProgress.OptionalDeploymentTargets, ...phaseProgress.AutomaticDeploymentTargets];
            totalNumOfEnvironments += envsForPhase.length;
        });
        const resultForState = {
            project,
            release,
            environmentsById,
            artifacts,
            progression,
            deployments,
            deploymentsByPhase,
            progressionByPhase,
            lifecycleStatus,
            lifecycle,
            releaseChannel,
            deploymentTasks: isAllowed({ permission: Permission.TaskView, project: project.Id, wildcard: true }) ? await tasksPromise : [],
            showLifecycleProgression: this.state.isInitialLoad ? totalNumOfEnvironments <= this.showHideLifecycleThreshhold || hasPendingInteruptions : this.state.showLifecycleProgression,
            isInitialLoad: false,
            totalNumOfEnvironments,
            totalNumOfPhases: progression.Phases.length,
            hasPendingInteruptions,
        };
        return resultForState;
    }
    private async loadDefects(release: ReleaseResource) {
        const defects: Defect = {
            releaseHasDefect: false,
            defectDescription: null!,
        };
        const defectList = await repository.Defects.all(release);
        let defect = null;
        const hasItems = defectList.Items.length > 0;
        if (hasItems) {
            const lastIndex = defectList.Items.length - 1;
            const potentialDefect = defectList.Items[lastIndex];
            if (potentialDefect.Status === DefectStatus.Unresolved) {
                defect = potentialDefect;
            }
        }
        defects.releaseHasDefect = defect !== null;
        if (defect) {
            defects.defectDescription = defect.Description;
        }
        return defects;
    }
    private async loadPackages(allPackages: PackageModel[]) {
        const boundUnbound = splitPackagesIntoBoundOrUnboundFeeds(allPackages);
        // Bound packages all get a standard release notes string
        this.setState((existingState) => {
            boundUnbound.bound.forEach((bound: PackageModel) => (bound.Notes.Notes = this.packageResolveMessage));
            return {
                packages: [..._.differenceWith(existingState.packages, boundUnbound.bound, this.packageNoteEquals), ...boundUnbound.bound],
            };
        });
        try {
            (await getNotesForPackages(boundUnbound.unBound)).forEach((notes: PackageNotesList) => this.setState((existingState) => {
                // for every package that was returned, update the existing package
                // with the returned notes.
                const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, notes.Packages)));
                return {
                    packages: updated,
                };
            }));
        }
        catch (err) {
            this.setState((existingState) => {
                // for every package that was requested, set the state to error.
                // Possible bug: The Success property looks like it should be referring to PackageNoteResult.Succeeded.
                const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, boundUnbound.unBound, { Notes: { Success: false, FailureMessage: err.ErrorMessage } })));
                return {
                    packages: updated,
                };
            });
        }
    }
    /**
     * Finding release notes in a bulk fashion from the server means:
     * 1. Requesting the package details (in a request with a bunch of other packages)
     * 2. Assigning the returned details back to the matching packages from the state
     * 3. Optionally setting the some additional field, typically when a batch request failed and all packages need to show an error
     * This function will attempt to find a matching package from the list of returned packages, and if so assign the values from 3
     * to it, and then return it. Otherwise it will return an empty object. The returned object is expected to be assigned to
     * the package in the state to result in an updated package object that can be displayed to the user.
     * @param {PackageNote} original The original package details to match against the package returned by the server
     * @param {PackageNote[]} packages The list of packages returned by the server
     * @param assign An object that is assigned to the matching package, if one was found. It is like an "overlay" on matching packages.
     * @returns {(PackageNote | undefined) | {}} An empty object if no match was found, and the returned package
     * with the assign object assigned to it.
     */
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    private findMatchingNotesPackage(original: PackageNote, packages: PackageNote[], assign: any = null) {
        const packageWithNotes = packages.find((pkgWithNotes) => this.packageNoteEquals(pkgWithNotes, original));
        if (packageWithNotes) {
            if (assign) {
                _.assign(packageWithNotes, assign);
            }
        }
        return packageWithNotes || {};
    }
    private packageNoteEquals(a: PackageNote, b: PackageNote) {
        return a.PackageId === b.PackageId && a.Version === b.Version && a.FeedId === b.FeedId;
    }
    private releaseTitle() {
        return this.state.release ? `Release ${this.state.release && this.state.release.Version}` : this.props.match.params.releaseVersion;
    }
    private buildReleaseNotes() {
        if (this.state.showFullReleaseNotes) {
            return <Markdown markup={this.state.release.ReleaseNotes}/>;
        }
        const [releaseNotes, isTruncated] = buildPartialReleaseNotes(this.state.release.ReleaseNotes, 10);
        return (<div>
                <Markdown markup={releaseNotes}/>
                {isTruncated && <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ showFullReleaseNotes: true })} label="show more"/>}
            </div>);
    }
    private async resolveDefect() {
        return this.doBusyTask(async () => {
            await repository.Defects.resolve(this.state.release);
            await this.init(this.state.project, this.state.release);
            this.setState(await this.refreshActiveComponents(this.state.project, this.state.release, this.state.environmentsById));
        });
    }
    private async reportDefect(desc: string) {
        return this.doBusyTask(async () => {
            await repository.Defects.report(this.state.release, desc);
            await this.init(this.state.project, this.state.release);
            this.setState(await this.refreshActiveComponents(this.state.project, this.state.release, this.state.environmentsById));
        });
    }
    private getReleaseNoteSection() {
        return [
            <FormSectionHeading key="releaseNoteHeading" title="Release notes"/>,
            <Section key="releaseSection" sectionHeader="">
                <div className={styles.releaseNoteLayout}>{this.buildReleaseNotes()}</div>
            </Section>,
        ];
    }
    private loadArtifactsPromise = (release: ReleaseResource, skip: number) => (isAllowed({ permission: Permission.ArtifactView, wildcard: true }) ? repository.Artifacts.list({ regarding: release.Id, skip, take: 10, order: "asc" }) : null);
    private handleArtifactsPageSelected = async (skip: number, p: number) => {
        this.setState({ currentPageIndex: p, currentSkip: skip });
        this.setState({ artifacts: await this.loadArtifactsPromise(this.state.release, skip)! });
    };
    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (this.state.release) {
            await repository.Releases.del(this.state.release);
            this.setState({ deleted: true });
            return true;
        }
        else {
            return false;
        }
    };
    static displayName = "ReleaseInternal";
}
export function Release(props: ReleaseProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    return <ReleaseInternal {...props} projectContext={projectContext} dispatchAction={dispatchAction}/>;
}
