import { Button } from "@octopusdeploy/design-system-components";
import type { ReactNode } from "react";
import * as React from "react";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { SampleProjectCompleteDialog } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectCompleteDialog";
import SampleProjectTourOverlay from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourOverlay";
import SampleProjectTourPopper from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourPopper";
import type { SampleProjectTourStep, SampleProjectStepId } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import useLocalStorage from "~/hooks/useLocalStorage";
import routeLinks from "~/routeLinks";
interface SampleProjectTourProps {
    children: ReactNode;
    projectSlug: string;
    projectId: string;
}
export type SampleProjectTourContextProps = {
    continueSampleProjectTour: () => void;
    nextStep: () => void;
    startTour: () => void;
    previousStep: () => void;
    focusTour: () => void;
    unfocusTour: () => void;
    pauseTour: () => void;
    resumeTour: () => void;
    registerStep: (stepId: SampleProjectStepId, ref: HTMLDivElement) => void;
    removeRegisteredStep: (stepId: SampleProjectStepId) => void;
    tourState: SampleProjectTourState;
    stepIndex: number;
    totalStepCount: number;
    hasStepRefs: boolean;
};
export const SampleProjectTourContext = createContext<SampleProjectTourContextProps | undefined>(undefined);
type SampleProjectTourState = "NotStarted" | "Focused" | "Transition" | "Unfocused" | "Suspended" | "Dismissed" | "EndDialog" | "Finished";
function SampleProjectTour(props: SampleProjectTourProps) {
    const navigation = useSpaceAwareNavigation();
    const location = useLocation();
    const sampleProjectTourCurrentStep = `sampleProjectTour.${props.projectSlug}.currentStep`;
    const [sampleProjectTourSavedCurrentStep, setSampleProjectTourSavedCurrentStep] = useLocalStorage(sampleProjectTourCurrentStep, 0);
    const [stepRefs, setStepRefs] = useState<Map<SampleProjectStepId, HTMLDivElement>>(new Map<SampleProjectStepId, HTMLDivElement>());
    const [hasStepRefs, setHasStepRefs] = useState<boolean>(false);
    const [tourState, setTourState] = useState<SampleProjectTourState>(sampleProjectTourSavedCurrentStep === 0 ? "NotStarted" : "Suspended");
    const [stepIndex, setStepIndex] = useState<number>(sampleProjectTourSavedCurrentStep);
    const [steps, setSteps] = useState<SampleProjectTourStep[]>([
        {
            title: "Get visibility on your software releases",
            content: "This dashboard shows your recent software releases. Track what version of your software is running where and quickly spot deployment failures.",
            tip: "Tip: Click a successful or failed release to view its task summary and log.",
            popperPlacement: "bottom-start",
            popperPlacementSmallScreen: "bottom-start",
            target: `DeploymentOverview`,
            scrollOffset: 40,
            route: routeLinks.project(props.projectSlug).overview,
        },
        {
            title: "Deploy the same way to all your environments",
            content: "Consistently and confidently deploy to all environments in your software's lifecycle with one repeatable deployment process.",
            popperPlacement: "bottom-start",
            popperPlacementSmallScreen: "bottom-start",
            target: "DeploymentProcessEnvironments",
            scrollOffset: 40,
            route: routeLinks.project(props.projectSlug).overview,
        },
        {
            title: "Define your deployment process",
            content: "Our extensive step library lets you define how you deploy your software across your infrastructure without writing and sifting through thousands of lines of code.",
            tip: "Tip: Try adding a step to browse our library of over 500 steps.",
            popperPlacement: "bottom-start",
            popperPlacementSmallScreen: "right-end",
            target: "DeploymentProcess",
            scrollOffset: 210,
            route: routeLinks.project(props.projectSlug).deployments.process.root,
        },
        {
            title: "Manage variables across your infrastructure",
            content: "Variables are a powerful tool that lets you securely store and manage sensitive keys and configuration values across your infrastructure.",
            tip: "Tip: Use variables to avoid manually updating hard-coded configuration settings as you deploy across environments.",
            popperPlacement: "top-end",
            popperPlacementSmallScreen: "bottom-start",
            target: "Variable",
            scrollOffset: 0,
            route: routeLinks.project(props.projectSlug).variables.root,
        },
        {
            title: "Automate routine and emergency operations tasks",
            content: "Runbooks use our powerful step library and turn your disaster recovery checklists into automated processes. You can schedule runbooks or run them on-demand.",
            tip: "Tip: Using variables, you can empower anyone on your team to use runbooks without giving them access to credentials.",
            popperPlacement: "bottom-start",
            popperPlacementSmallScreen: "bottom-start",
            target: "Runbooks",
            scrollOffset: 120,
            route: routeLinks.project(props.projectSlug).operations.runbooks,
        },
    ]);
    useEffect(() => {
        if (tourState === "Unfocused") {
            setTourState("Suspended");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    const startTour = () => {
        if (tourState !== "NotStarted")
            return;
        setTourState("Focused");
        setStepIndex(0);
        calculateScrollOffset();
    };
    const continueSampleProjectTour = () => {
        if (tourState !== "Transition")
            return;
        setTourState("Focused");
        setStepIndex(stepIndex);
        calculateScrollOffset();
    };
    const nextStep = () => {
        if (stepIndex < steps.length - 1) {
            const nextStepIndex = stepIndex + 1;
            setStepIndex(nextStepIndex);
            handleTransitionToStep(nextStepIndex);
            setSampleProjectTourSavedCurrentStep(nextStepIndex);
            const nextStepRefElement = stepRefs.get(steps[nextStepIndex].target);
            if (nextStepRefElement !== undefined) {
                calculateScrollOffset();
            }
        }
        else {
            setStepIndex(0);
            setTourState("EndDialog");
        }
    };
    const previousStep = () => {
        if (stepIndex > 0) {
            const nextStepIndex = stepIndex - 1;
            setStepIndex(nextStepIndex);
            handleTransitionToStep(nextStepIndex);
            setSampleProjectTourSavedCurrentStep(nextStepIndex);
        }
    };
    const handleTransitionToStep = (nextStepIndex: number) => {
        const nextStepRefElement = stepRefs.get(steps[nextStepIndex].target);
        // will be undefined if the element we need to highlight isn't on the current page, so we need to navigate to that page first
        if (nextStepRefElement === undefined) {
            setTourState("Transition");
            // Remove all the registered elements, as they will no longer be on the page. This will support navigating back.
            setStepRefs((stepRefs) => {
                stepRefs.clear();
                return stepRefs;
            });
            setHasStepRefs(false);
            navigation.navigate(steps[nextStepIndex].route);
        }
    };
    const calculateScrollOffset = () => {
        const scrollOffset = steps[stepIndex].scrollOffset ?? 0;
        if (scrollOffset > 0) {
            document.documentElement.scrollTop = scrollOffset;
            document.body.scrollTop = scrollOffset;
        }
    };
    const unfocusTour = () => {
        setTourState("Unfocused");
    };
    const focusTour = () => {
        setTourState("Focused");
    };
    const pauseTour = () => {
        setTourState("Suspended");
    };
    const resumeTour = () => {
        const currentElementRef = stepRefs.get(steps[stepIndex].target);
        if (currentElementRef === undefined) {
            handleTransitionToStep(stepIndex);
        }
        else {
            setTourState("Focused");
            calculateScrollOffset();
        }
    };
    const registerStep = (stepId: SampleProjectStepId, ref: HTMLDivElement) => {
        setStepRefs((refs) => {
            refs.set(stepId, ref);
            return refs;
        });
        setHasStepRefs(true);
    };
    const removeRegisteredStep = (stepId: SampleProjectStepId) => {
        setStepRefs((refs) => {
            refs.clear();
            return refs;
        });
        setHasStepRefs(false);
    };
    return (<SampleProjectTourContext.Provider value={{ nextStep, previousStep, hasStepRefs, startTour, registerStep, removeRegisteredStep, pauseTour, resumeTour, continueSampleProjectTour, tourState, stepIndex, totalStepCount: steps.length, unfocusTour, focusTour }}>
            <FeatureToggleVisibility toggle={"SampleProjectTourToggle"} disabledContent={props.children}>
                <Button label={"Start Sample Project Tour"} onClick={() => {
            setTourState("Focused");
            setStepIndex(0);
        }} importance={"primary"}/>

                <SampleProjectCompleteDialog projectId={props.projectId} open={tourState === "EndDialog"} onCloseDialog={() => {
            setTourState("Finished");
        }}/>
                {(tourState === "Focused" || tourState === "Unfocused") && (<>
                        <SampleProjectTourOverlay targetElement={stepRefs.get(steps[stepIndex].target)} onOverlayClicked={unfocusTour}/>
                        <SampleProjectTourPopper targetElement={stepRefs.get(steps[stepIndex].target)} step={steps[stepIndex]} nextStep={nextStep}/>
                    </>)}
                {props.children}
            </FeatureToggleVisibility>
        </SampleProjectTourContext.Provider>);
}
export default SampleProjectTour;
