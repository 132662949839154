import { CircularProgress } from "@octopusdeploy/design-system-components";
import type { GetInsightsMetricsForProjectRequest, ChannelResource, EnvironmentResource, ProjectResource, TenantResource, GetInsightsForProjectBffResponse, InsightsTrendKey } from "@octopusdeploy/octopus-server-client";
import { InsightsTenantFilter, Permission } from "@octopusdeploy/octopus-server-client";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import { keyBy } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router";
import URI from "urijs";
import type { AnalyticInsightsDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticInsightsDispatch } from "~/analytics/Analytics";
import { TrendIndicator } from "~/areas/insights/components/Reports/TrendIndicator/TrendIndicator";
import type { InsightsCadence } from "~/areas/insights/insightsCadence";
import { insightsCadenceLookup, insightsCadenceOptions } from "~/areas/insights/insightsCadence";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { ProjectStatus } from "~/areas/projects/components/ProjectStatus/ProjectStatus";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Select } from "~/components/form";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { InsightsNoEnvironmentsCallout } from "../InsightsNoEnvironmentsCallout";
import styles from "./ProjectInsightsDataLoader.module.less";
export interface ProjectInsightsFilter {
    channelId: string;
    environmentId: string;
    cadence: InsightsCadence;
    tenantId?: string;
    tenantFilter?: string;
}
export interface ProjectInsightsPageProps {
    project: ProjectResource;
    bffResponse: GetInsightsForProjectBffResponse;
    busy: Promise<void> | undefined;
    filter: ProjectInsightsFilter;
}
interface ProjectInsightsDataLoaderProps {
    title: string;
    children: (props: ProjectInsightsPageProps) => React.ReactNode;
    trendKey?: InsightsTrendKey;
}
interface ProjectInsightsDataLoaderState {
    bffResponse: GetInsightsForProjectBffResponse | null;
    channels: ChannelResource[];
    allEnvironments: EnvironmentResource[];
    channelEnvironments: EnvironmentResource[];
    projectTenants: TenantResource[];
    channelId: string;
    environmentId: string;
    tenantId: string;
    cadence: InsightsCadence;
}
interface ProjectInsightsDataLoaderInnerProps extends ProjectInsightsDataLoaderProps {
    project: ProjectResource;
    dispatchAction: AnalyticInsightsDispatcher;
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    page: PageDefinition;
}
interface QueryFilters {
    channelId: string | null;
    environmentId: string | null;
    tenantId: string | null;
    cadence: InsightsCadence | null;
}
class ProjectInsightsDataLoaderInner extends DataBaseComponent<ProjectInsightsDataLoaderInnerProps, ProjectInsightsDataLoaderState> {
    constructor(props: ProjectInsightsDataLoaderInnerProps) {
        super(props);
        this.state = {
            bffResponse: null,
            channels: [],
            allEnvironments: [],
            channelEnvironments: [],
            projectTenants: [],
            channelId: "",
            environmentId: "",
            tenantId: "all",
            cadence: this.getDefaultCadence(),
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const channelsRequest = repository.Channels.allFromProject(this.props.project);
            const allEnvironmentsRequest = repository.Environments.all();
            const tenantsEnabled = this.props.project.TenantedDeploymentMode !== "Untenanted";
            const projectTenantsRequest = tenantsEnabled && isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ projectId: this.props.project.Id }) : Promise.resolve([]);
            const channels = await channelsRequest;
            const defaultChannel = this.getDefaultChannel(channels);
            // If the channel doesn't have a lifecycle, it inherits from the project.
            const lifecycleId = defaultChannel.LifecycleId ?? this.props.project.LifecycleId;
            const allEnvironments = await allEnvironmentsRequest;
            const channelEnvironments = await this.filterEnvironmentsByChannelLifecycle(lifecycleId, allEnvironments);
            const defaultEnvironment = this.getDefaultEnvironment(channelEnvironments);
            const projectTenants = await projectTenantsRequest;
            const defaultTenantId = this.getDefaultTenantId(projectTenants);
            const request: GetInsightsMetricsForProjectRequest = {
                channelId: defaultChannel.Id,
                environmentId: defaultEnvironment?.Id ?? "",
                ...insightsCadenceLookup[this.state.cadence],
                ...this.getTenantArgs(defaultTenantId),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
            const bffResponse = defaultEnvironment ? await repository.Projects.getInsightsMetrics(this.props.project, request) : null;
            this.setState({ bffResponse, channels, allEnvironments, channelEnvironments, projectTenants, channelId: defaultChannel.Id, environmentId: defaultEnvironment?.Id ?? "", tenantId: defaultTenantId }, this.updateQueryString);
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    getDefaultChannel(channels: ChannelResource[]) {
        const { channelId: channelIdInQuery } = this.getFiltersFromQueryString();
        const channelFromQuery = channels.find((c) => c.Id === channelIdInQuery);
        if (channelFromQuery)
            return channelFromQuery;
        const defaultChannel = channels.find((c) => c.IsDefault) ?? channels[0];
        return defaultChannel;
    }
    getDefaultEnvironment(channelEnvironments: EnvironmentResource[]) {
        const { environmentId: environmentIdInQuery } = this.getFiltersFromQueryString();
        const environmentFromQuery = channelEnvironments.find((e) => e.Id === environmentIdInQuery);
        if (environmentFromQuery)
            return environmentFromQuery;
        return channelEnvironments.length > 0 ? channelEnvironments[channelEnvironments.length - 1] : null;
    }
    getDefaultTenantId(projectTenants: TenantResource[]) {
        const deploymentMode = this.props.project.TenantedDeploymentMode;
        const { tenantId: tenantIdInQuery } = this.getFiltersFromQueryString();
        // "all" is always a valid tenant selection, regardless of the projects tenant deployment mode
        if (tenantIdInQuery === "all")
            return "all";
        // Check that the project can show 'untenanted' deployments
        const canBeUntenanted = deploymentMode === "TenantedOrUntenanted" || deploymentMode === "Untenanted";
        if (tenantIdInQuery === "untenanted" && canBeUntenanted)
            return "untenanted";
        // Check if the project can be tenanted and if the tenant in the query string is applicable
        const canBeTenanted = deploymentMode === "TenantedOrUntenanted" || deploymentMode === "Tenanted";
        const tenantFromQuery = projectTenants.find((t) => t.Id === tenantIdInQuery);
        if (tenantFromQuery && canBeTenanted)
            return tenantFromQuery.Id;
        // The query string doesn't have an appropriate value, use default logic.
        // Show all tenanted and untenanted deployments if the project is "TenantedOrUntenanted".
        if (deploymentMode === "TenantedOrUntenanted")
            return "all";
        //If the project is "Tenanted" then default to 'all'
        return "all";
    }
    getDefaultCadence() {
        const defaultCadence = "lastQuarterWeekly";
        const { cadence } = this.getFiltersFromQueryString();
        if (!cadence)
            return defaultCadence;
        // Check if the cadence in the url is a valid value, otherwise use the default
        return insightsCadenceOptions.map((o) => o.value).includes(cadence) ? cadence : defaultCadence;
    }
    getFiltersFromQueryString(): QueryFilters {
        const uri = new URI().search(this.props.location.search).search(true);
        return {
            channelId: uri.channelId ?? null,
            environmentId: uri.environmentId ?? null,
            tenantId: uri.tenantId ?? null,
            cadence: uri.cadence ?? null,
        };
    }
    updateQueryString() {
        const { channelId, environmentId, tenantId, cadence } = this.state;
        const search = {
            channelId,
            environmentId,
            tenantId,
            cadence,
        };
        const newQs = new URI().search(search).search();
        if (this.props.location.search !== newQs) {
            this.props.history.replace({ ...this.props.history, search: newQs });
        }
    }
    async filterEnvironmentsByChannelLifecycle(lifecycleId: string, environments: EnvironmentResource[]) {
        // Get all the EnvironmentIds from each phase then map them to their resource
        const lifecyclePhases = (await repository.Lifecycles.get(lifecycleId)).Phases;
        const lifecycleEnvironmentIds = lifecyclePhases.reduce<string[]>((acc, curr) => {
            acc = [...acc, ...curr.AutomaticDeploymentTargets, ...curr.OptionalDeploymentTargets];
            return acc;
        }, []);
        if (lifecycleEnvironmentIds.length > 0) {
            // We want to keep the environments in the same order as they appear in lifecycle
            const keyedEnvironments = keyBy(environments, (e) => e.Id);
            const lifecycleEnvironments = lifecycleEnvironmentIds.reduce<EnvironmentResource[]>((acc, curr) => {
                const env = keyedEnvironments[curr];
                if (env)
                    acc.push(env); // only add if the user can see the environment
                return acc;
            }, []);
            return lifecycleEnvironments;
        }
        // Fallback to all environments
        const sortedEnvs = environments.sort((a, b) => a.SortOrder - b.SortOrder);
        return sortedEnvs;
    }
    async handleChannelChange(newChannelId: string) {
        this.props.dispatchAction("Select Channel", { action: Action.Select, inputField: "Channel", cadence: this.state.cadence });
        await this.doBusyTask(async () => {
            // The corresponding channel will always exist in state
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const channelLifecycleId = this.state.channels.find((c) => c.Id === newChannelId)!.LifecycleId ?? this.props.project.LifecycleId;
            const channelEnvironments = await this.filterEnvironmentsByChannelLifecycle(channelLifecycleId, this.state.allEnvironments);
            // Check if the environment is still valid, otherwise default to the last one in the lifecycle
            const environmentId = channelEnvironments.find((e) => e.Id === this.state.environmentId)?.Id ?? channelEnvironments[channelEnvironments.length - 1].Id;
            this.setState({ channelId: newChannelId, channelEnvironments, environmentId });
            await this.getInsightsMetrics(newChannelId, this.state.environmentId, this.state.cadence, this.state.tenantId);
        });
    }
    async handleEnvironmentChange(newEnvironmentId: string) {
        this.props.dispatchAction("Select Environment", { action: Action.Select, inputField: "Environment", cadence: this.state.cadence });
        const environmentTenants = this.getEnvironmentTenants(newEnvironmentId);
        let tenantId = this.state.tenantId;
        if (tenantId !== "all" && tenantId !== "untenanted") {
            // If the currently selected tenant isn't tied to the new environment - default back to untenanted and all tenants
            const isTenantStillApplicable = environmentTenants.some((t) => t.Id === tenantId);
            tenantId = isTenantStillApplicable ? tenantId : "all";
        }
        await this.doBusyTask(async () => {
            this.setState({ environmentId: newEnvironmentId, tenantId });
            await this.getInsightsMetrics(this.state.channelId, newEnvironmentId, this.state.cadence, tenantId);
        });
    }
    async handleTenantChange(newTenantId: string) {
        this.props.dispatchAction("Select Tenant", { action: Action.Select, inputField: "Tenant", cadence: this.state.cadence });
        await this.doBusyTask(async () => {
            this.setState({ tenantId: newTenantId });
            await this.getInsightsMetrics(this.state.channelId, this.state.environmentId, this.state.cadence, newTenantId);
        });
    }
    async handleCadenceChange(newCadence: InsightsCadence) {
        this.props.dispatchAction("Select Cadence", { action: Action.Select, inputField: "Cadence", cadence: newCadence });
        await this.doBusyTask(async () => {
            this.setState({ cadence: newCadence });
            await this.getInsightsMetrics(this.state.channelId, this.state.environmentId, newCadence, this.state.tenantId);
        });
    }
    async getInsightsMetrics(channelId: string, environmentId: string, cadence: InsightsCadence, tenantId: string) {
        const args: GetInsightsMetricsForProjectRequest = {
            channelId,
            environmentId,
            ...insightsCadenceLookup[cadence],
            ...this.getTenantArgs(tenantId),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const bffResponse = await repository.Projects.getInsightsMetrics(this.props.project, args);
        this.setState({ channelId, environmentId, tenantId, bffResponse }, this.updateQueryString);
    }
    private getTenantArgs(tenantId: string) {
        if (this.props.project.TenantedDeploymentMode === "Untenanted")
            return {};
        if (tenantId === "all")
            return { tenantFilter: InsightsTenantFilter.UntenantedAndAllTenants };
        if (tenantId === "untenanted")
            return { tenantFilter: InsightsTenantFilter.Untenanted };
        return {
            tenantId,
            tenantFilter: InsightsTenantFilter.SingleTenant,
        };
    }
    private getEnvironmentTenants(environmentId: string) {
        return this.state.projectTenants.filter((t) => t.ProjectEnvironments[this.props.project.Id].some((e) => e === environmentId));
    }
    private getTenantSelectOptions() {
        if (this.props.project.TenantedDeploymentMode === "Untenanted")
            return [];
        const environmentTenants = this.getEnvironmentTenants(this.state.environmentId);
        if (environmentTenants.length === 0)
            return [];
        const tenantOptions = environmentTenants.map((t) => ({ text: t.Name, value: t.Id }));
        return this.props.project.TenantedDeploymentMode === "TenantedOrUntenanted"
            ? [{ value: "all", text: "All tenants and untenanted" }, { value: "untenanted", text: "Untenanted" }, ...tenantOptions]
            : [{ value: "all", text: "All tenants" }, ...tenantOptions];
    }
    private buildFilter() {
        const { channelId, environmentId, tenantId, cadence } = this.state;
        return {
            channelId,
            environmentId,
            cadence,
            ...this.getTenantArgs(tenantId),
        };
    }
    render() {
        const { channelId, environmentId, tenantId, cadence, channels, channelEnvironments, allEnvironments, bffResponse } = this.state;
        const { title, project } = this.props;
        const channelOptions = channels.map((c) => ({ text: c.Name, value: c.Id }));
        const environmentOptions = channelEnvironments.map((e) => ({ text: e.Name, value: e.Id }));
        const tenantOptions = this.getTenantSelectOptions();
        const hasData = bffResponse && bffResponse.Series.length > 0;
        const pageContent = bffResponse && this.props.children({ bffResponse, busy: this.state.busy, project, filter: this.buildFilter() });
        const showInsightsBreadcrumb = this.props.page.Id !== pageIds.project().insights.overview.Id;
        return (<ProjectPageLayout title={title} breadcrumbTitle={showInsightsBreadcrumb ? `Insights` : undefined} breadcrumbPath={showInsightsBreadcrumb ? routeLinks.project(project).insights.overview : undefined} busy={this.state.busy} errors={this.errors} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>} sectionControl={bffResponse && this.props.trendKey && <TrendIndicator trend={bffResponse[this.props.trendKey].OverallTrend} trendKey={this.props.trendKey}/>} fullWidth={true}>
                {!this.state.busy && allEnvironments.length === 0 && !this.errors && <InsightsNoEnvironmentsCallout />}
                {allEnvironments.length > 0 && (<>
                        <section className={styles.filterSection}>
                            <div className={styles.scopeSelectors}>
                                {channels.length > 1 && (<div className={styles.selectWrap}>
                                        <Select label="Channel" value={channelId} items={channelOptions} onChange={(val) => {
                        if (val)
                            this.handleChannelChange(val);
                    }}/>
                                    </div>)}
                                <div className={styles.selectWrap}>
                                    <Select label="Environment" value={environmentId} items={environmentOptions} sortItems={false} onChange={(val) => {
                    if (val)
                        this.handleEnvironmentChange(val);
                }}/>
                                </div>
                                {tenantOptions.length > 0 && (<div className={styles.selectWrap}>
                                        <Select label="Tenant" allowFilter value={tenantId} items={tenantOptions} sortItems={false} onChange={(val) => {
                        if (val)
                            this.handleTenantChange(val);
                    }}/>
                                    </div>)}
                            </div>
                            <div className={styles.selectWrap}>
                                <Select label="Date range" value={cadence} items={insightsCadenceOptions.map((o) => o)} sortItems={false} onChange={(val) => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    if (val)
                        this.handleCadenceChange(val as InsightsCadence);
                }}/>
                            </div>
                        </section>
                        {hasData ? pageContent : <BlankPage busy={Boolean(this.state.busy)}/>}
                    </>)}
            </ProjectPageLayout>);
    }
    static displayName = "ProjectInsightsDataLoaderInner";
}
interface BlankPageProps {
    busy: boolean;
}
function BlankPage({ busy }: BlankPageProps) {
    if (busy)
        return (<div style={{ textAlign: "center" }}>
                <CircularProgress size="large"/>
            </div>);
    return <OnboardingPage title="Deploy releases to see Insights for this project" intro="There's no deployment data for chosen combination." learnMore={null}/>;
}
export function ProjectInsightsDataLoader(props: ProjectInsightsDataLoaderProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useAnalyticInsightsDispatch();
    const location = useLocation();
    const history = useHistory();
    const page = usePage();
    return <ProjectInsightsDataLoaderInner {...props} project={projectContext.state.model} dispatchAction={dispatchAction} location={location} history={history} page={page}/>;
}
