import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { LifecycleResource, PhaseResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { keyBy } from "lodash";
import * as React from "react";
import LifecycleMap from "~/areas/library/components/Lifecycle/LifecycleMap/LifecycleMap";
import ChangeLifecycleDialog from "~/areas/projects/components/Process/Common/ChangeLifecycleDialog";
import BaseComponent from "~/components/BaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "~/routeLinks";
import { ScriptModulesWithActions } from "../../ScriptModules";
import type { ProcessState } from "../types";
import styles from "./style.module.less";
interface SideBarProps {
    process: ProcessState;
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource | null;
    environments: EnvironmentResource[];
    onDataChanged: () => Promise<void>;
}
interface SideBarState {
    largeLifecyleToggle?: boolean;
}
export interface ScriptModule {
    Id: string;
    Name: string;
}
export default class SideBar extends BaseComponent<SideBarProps, SideBarState> {
    private lifecycleThreshold = 20;
    constructor(props: SideBarProps) {
        super(props);
        this.state = {
            largeLifecyleToggle: true,
        };
    }
    render() {
        if (!this.props.process) {
            logger.error("Failed to render SideBar, expecting process to exist.");
            return null;
        }
        const largeLifecycle = this.props.lifecyclePreview &&
            this.props.lifecyclePreview.Phases &&
            (this.props.lifecyclePreview.Phases.length > this.lifecycleThreshold || this.props.lifecyclePreview.Phases.some((p: PhaseResource) => p.OptionalDeploymentTargets.length > this.lifecycleThreshold));
        return (<div>
                <PermissionCheck permission={Permission.LifecycleView} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to view the Lifecycle
                        </Callout>}>
                    {this.props.lifecyclePreview && (<div>
                            <h4>Lifecycle</h4>
                            <div className={styles.row}>
                                <InternalLink to={routeLinks.library.lifecycle(this.props.lifecyclePreview.Id)}>
                                    <span className={styles.lifeCycleName}>{this.props.lifecyclePreview.Name}</span>
                                </InternalLink>
                                <OpenDialogButton label={"CHANGE"} permission={{
                    permission: Permission.ProjectEdit,
                    project: this.props.process.ProjectId,
                    wildcard: true,
                }}>
                                    <ChangeLifecycleDialog projectId={this.props.process.ProjectId} saveDone={() => this.props.onDataChanged()}/>
                                </OpenDialogButton>
                            </div>

                            {largeLifecycle && this.props.lifecyclePreview && this.props.lifecyclePreview.Phases && <Note>This lifecycle has many phases / environments</Note>}

                            {largeLifecycle && (<ActionButton onClick={() => this.setState({ largeLifecyleToggle: !this.state.largeLifecyleToggle })} label={this.state.largeLifecyleToggle ? "SHOW LIFECYCLE" : "HIDE LIFECYCLE"} type={ActionButtonType.Ternary}/>)}
                            {(!largeLifecycle || !this.state.largeLifecyleToggle) && <LifecycleMap environmentsById={keyBy(this.props.environments, (x) => x.Id)} lifecyclePreview={this.props.lifecyclePreview}/>}
                        </div>)}
                    <Note>
                        Lifecycles can be defined in the <InternalLink to={routeLinks.library.lifecycles}>Library</InternalLink>
                    </Note>
                </PermissionCheck>
                <hr />
                <PermissionCheck permission={Permission.LibraryVariableSetView} wildcard={true} alternate={<Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LibraryVariableSetView} permission is required to view any included script modules
                        </Callout>}>
                    {this.props.includedScriptModules && (<div>
                            <ScriptModulesWithActions projectId={this.props.process.ProjectId} includedScriptModules={this.props.includedScriptModules} onDataChanged={this.props.onDataChanged}/>
                        </div>)}
                </PermissionCheck>
            </div>);
    }
    static displayName = "SideBar";
}
