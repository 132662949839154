import type { AWSRunScriptProperties } from "@octopusdeploy/legacy-action-properties";
import type { PackageReferenceProperties } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { BundledToolsProperties } from "~/components/BundledTools/BundledToolsEditBase";
import { BundledToolsEditBase } from "~/components/BundledTools/BundledToolsEditBase";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { CalloutType, default as Callout } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Note from "../../../primitiveComponents/form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { SupportedLanguage } from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import { FormSectionHeading, Summary } from "../../form";
import ExpandableFormSection from "../../form/Sections/ExpandableFormSection";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { ScriptActionEdit } from "../script/scriptAction";
import AwsLoginComponent from "./awsLoginComponent";
type AWSScriptActionEditState = {};
class AWSRunScriptActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Log into AWS and run a script with the AWS CLI.</div>;
    }
    static displayName = "AWSRunScriptActionSummary";
}
function AwsBundledToolsForScriptsEdit(props: ActionEditProps<BundledToolsProperties, PackageReferenceProperties | ScriptPackageProperties>) {
    return (<BundledToolsEditBase actionProps={props} title="AWS Tools" notes={<Note>
                    This step allows you run scripts that can use the AWS CLI. Learn more about <ExternalLink href="AWSTools">AWS Tools</ExternalLink>.
                </Note>} help="Select whether to use the bundled AWS tools or using tooling pre-installed on the worker" useBundledToolsSummary="Use AWS tools bundled with Octopus" usePreInstalledToolsSummary="Use AWS tools pre-installed on the worker" useBundledToolsCallout={<Callout type={CalloutType.Warning} title={"Not recommended"}>
                    Using the AWS tools bundled with Octopus is <strong>not recommended</strong>.
                    <br />
                    <br />
                    For <strong>Windows</strong> workers, Octopus can supply AWS CLI 1.16.178 and AWS PowerShell modules 3.3.390.
                    <br />
                    <br />
                    For <strong>Linux</strong> workers the AWS CLI must be pre-installed.
                    <br />
                    <br />
                    Learn more about <ExternalLink href="AWSTools">AWS Tools</ExternalLink>.
                </Callout>}/>);
}
export class AWSRunScriptActionEdit extends BaseComponent<ActionEditProps<AWSRunScriptProperties, ScriptPackageProperties>, AWSScriptActionEditState> {
    constructor(props: ActionEditProps<AWSRunScriptProperties, ScriptPackageProperties>) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        if (!this.props.properties["Octopus.Action.Aws.AssumeRole"]) {
            this.props.setProperties({ ["Octopus.Action.Aws.AssumeRole"]: "False" }, true);
        }
        if (!this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]) {
            this.props.setProperties({ ["Octopus.Action.AwsAccount.UseInstanceRole"]: "False" }, true);
        }
    }
    render() {
        return (<div>
                <AwsBundledToolsForScriptsEdit {...this.props}/>

                <FormSectionHeading title="Amazon Web Services"/>

                <AwsLoginComponent projectId={this.props.projectId} gitRef={this.props.gitRef} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault}/>
                <ExpandableFormSection errorKey="Octopus.Action.Aws.Region" isExpandedByDefault={this.props.expandedByDefault} title="Region" summary={this.regionSummary()} help={"Specify the default region"}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Aws.Region"]} label="Region" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Aws.Region"]: val })} error={this.props.getFieldError("Octopus.Action.Aws.Region")}/>
                    <Note>
                        View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation for a current list of the available region codes.
                    </Note>
                </ExpandableFormSection>

                <ScriptActionEdit plugin={this.props.plugin} projectId={this.props.projectId} gitRef={this.props.gitRef} packages={this.props.packages} gitDependencies={this.props.gitDependencies} localNames={this.props.localNames} properties={this.props.properties} setProperties={this.props.setProperties} setPackages={this.props.setPackages} setGitDependencies={this.props.setGitDependencies} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} supportedLanguages={SupportedLanguage.PowerShellAndBash} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault} parameters={this.props.parameters}/>
            </div>);
    }
    private regionSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.Aws.Region"]) {
            return Summary.summary(<span>
                    Using <strong>{properties["Octopus.Action.Aws.Region"]}</strong> as the default region
                </span>);
        }
        return Summary.placeholder("Specify the default AWS region");
    }
    static displayName = "AWSRunScriptActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AwsRunScript",
    summary: (properties, targetRolesAsCSV) => <AWSRunScriptActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: { default: AWSRunScriptActionEdit },
    canHaveChildren: () => true,
    canBeChild: true,
    targetRoleOption: () => TargetRoles.Optional,
    hasPackages: () => false,
    features: {
        optional: ["Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
    getInitialProperties: () => {
        return {
            OctopusUseBundledTooling: "False",
        };
    },
});
