import { MachineModelHealthStatus, AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
interface AccountTypeDetails {
    types: AccountType[];
    name: string;
    namePlural: string;
    description: JSX.Element;
}
/**
 * A collection that defines the variable types available to the UI
 */
export const RawAccountTypeDetailsMap: AccountTypeDetails[] = [
    {
        types: [AccountType.AzureSubscription, AccountType.AzureServicePrincipal, AccountType.AzureOidc],
        name: "Azure Subscription",
        namePlural: "Azure Subscriptions",
        description: (<div>
                Use <em>Azure Subscriptions</em> to set subscription IDs, management certificates, and service principals to use when deploying to Microsoft Azure.
            </div>),
    },
    {
        types: [AccountType.AmazonWebServicesAccount],
        name: "AWS Account",
        namePlural: "AWS Accounts",
        description: (<div>
                Use <em>Amazon Web Service accounts</em> when deploying to Amazon Web services.
            </div>),
    },
    {
        types: [AccountType.GoogleCloudAccount],
        name: "Google Cloud Account",
        namePlural: "Google Cloud Accounts",
        description: (<div>
                Use <em>Google Cloud Accounts</em> when deploying to Google Cloud.
            </div>),
    },
    {
        types: [AccountType.SshKeyPair],
        name: "SSH Key Pair",
        namePlural: "SSH Key Pairs",
        description: (<div>
                <em>SSH Key Pairs</em> are used when authenticating to machines running Linux or another Unix-like operating system.
            </div>),
    },
    {
        types: [AccountType.UsernamePassword],
        name: "Username/Password",
        namePlural: "Usernames/Passwords",
        description: <div>Records a username and password pair.</div>,
    },
    {
        types: [AccountType.Token],
        name: "Token",
        namePlural: "Tokens",
        description: <div>An authorization token without username.</div>,
    },
];
/**
 * Ordered list of Health Statuses - this order determines page render order
 */
export const orderedHealthStatuses = [MachineModelHealthStatus.Healthy, MachineModelHealthStatus.HasWarnings, MachineModelHealthStatus.Unhealthy, MachineModelHealthStatus.Unavailable, MachineModelHealthStatus.Unknown];
