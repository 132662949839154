import { css, keyframes } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context/index";
interface SampleProjectTourBeaconProps {
    onClick: () => void;
}
function SampleProjectTourBeacon(props: SampleProjectTourBeaconProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const onClick = () => {
        dispatchAction("Sample Project Tour: Click Beacon", { resource: "Sample Project Tour Beacon", action: Action.Resume });
        props.onClick();
    };
    return (<button onClick={onClick} className={beaconStyles.container}>
            <span className={beaconStyles.beaconInner}/>
            <span className={beaconStyles.beaconOuter}/>
        </button>);
}
const outerRingKeyframes = keyframes({
    "0%": {
        transform: "scale(1)",
    },
    "50%": {
        transform: "scale(.9)",
    },
    "100%": {
        transform: "scale(1)",
    },
});
const beaconStyles = {
    container: css({
        width: space[32],
        height: space[32],
        position: "relative",
        cursor: "pointer",
        background: "none",
        border: "none",
    }),
    beaconInner: css({
        position: "absolute",
        width: "55%",
        height: "55%",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        display: "block",
        borderRadius: "50%",
        backgroundColor: themeTokens.color.icon.info,
    }),
    beaconOuter: css({
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0",
        top: "0",
        display: "block",
        borderRadius: "50%",
        border: `3px solid ${themeTokens.color.icon.info}`,
        transformOrigin: "center center",
        animation: `${outerRingKeyframes} 2.5s ease infinite`,
    }),
};
export default SampleProjectTourBeacon;
