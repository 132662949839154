import { css } from "@emotion/css";
import { Divider, List, ListItem, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import type { GitHubAppConnectionSummary, GitHubAppConnections } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import routeLinks from "~/routeLinks";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import GitConnectionOnboarding from "./GitConnectionOnboarding";
import GitHubAppAuthCheck from "./GitHubAppAuthCheck";
import GitHubAppInstallationDisplay from "./GitHubAppInstallationDisplay";
interface GitConnectionListInternalProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
function GitConnectionListInternal({ doBusyTask, busy, errors }: GitConnectionListInternalProps) {
    const [connections, setConnections] = useState<GitHubAppConnections | undefined>(undefined);
    const skip = 0; // Don't skip, just increase the take size.
    const take = Repository.takeAll; // No paging, just take all
    const styles = {
        authorizationButton: css({
            marginLeft: space[16],
            marginTop: space[16],
            marginBottom: space[16],
        }),
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        const connections = await repository.GitHubApp.getConnections(skip, take);
        setConnections(connections);
    }, []);
    const addButton = (<PermissionCheck permission={Permission.GitCredentialEdit}>
            <NavigationButton type={NavigationButtonType.Primary} label="New Connection" href={routeLinks.library.gitConnections.addGitHubConnection}/>
        </PermissionCheck>);
    return (<LibraryLayout>
            <PaperLayout title="Git Connections" sectionControl={addButton} busy={busy} errors={errors}>
                <GitHubAppAuthCheck doBusyTask={doBusyTask}/>
                {connections && (<>
                        {connections.Connections.length == 0 && <GitConnectionOnboarding />}
                        {connections.Connections.length > 0 && (<>
                                <List items={connections.Connections} rowKey={(i) => i.Id} renderRow={(props) => <GitHubAppConnectionListItem connection={props.item}/>}/>
                                <Divider />
                            </>)}
                    </>)}
            </PaperLayout>
        </LibraryLayout>);
}
class GitConnectionList extends DataBaseComponent<GitConnectionListInternalProps> {
    constructor(props: GitConnectionListInternalProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <GitConnectionListInternal doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "GitConnectionList";
}
interface GitHubAppConnectionListItemProps {
    connection: GitHubAppConnectionSummary;
}
function GitHubAppConnectionListItem({ connection }: GitHubAppConnectionListItemProps) {
    const link = routeLinks.library.gitConnections.getGitHubConnection(connection.Id);
    return (<ListItem>
            <InternalLink to={link}>{connection.Installation ? <GitHubAppInstallationDisplay installation={connection.Installation}/> : <GitHubAppConnectionMissingDetails connectionId={connection.Id}/>}</InternalLink>
        </ListItem>);
}
interface GitHubAppConnectionMissingDetailsProps {
    connectionId: string;
}
function GitHubAppConnectionMissingDetails({ connectionId }: GitHubAppConnectionMissingDetailsProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            padding: space[16],
            gap: space[16],
            alignItems: "center",
        }),
    };
    return (<div className={styles.container}>
            <h3>{connectionId}</h3>
        </div>);
}
export default GitConnectionList;
