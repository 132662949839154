import { AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import URI from "urijs";
import { DynamicEnvironmentLayout } from "~/areas/infrastructure/components/DynamicEnvironmentLayout/DynamicEnvironmentLayout";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import AccountEdit from "../AccountEdit/AccountEdit";
import AccountsLayout from "../AccountsLayout";
import { DeploymentTargetSettingsLayout } from "../DeploymentTargetSettings/DeploymentTargetSettings";
import { DynamicEnvironmentsLayout } from "../DynamicEnvironmentsLayout/DynamicEnvironmentsLayout";
import EnvironmentLayout from "../EnvironmentLayout/EnvironmentLayout";
import { EnvironmentsLayout } from "../EnvironmentsLayout/EnvironmentsLayout";
import InfrastructureDashboard from "../InfrastructureDashboard";
import InfrastructureLayout from "../InfrastructureLayout";
import { DeploymentTargetConnectionsLayout, WorkerMachineConnectionsLayout } from "../MachineConnection/MachineConnection";
import MachineDeployments from "../MachineDeployments/MachineDeployments";
import { DeploymentTargetDiscoveryLayout, WorkerMachineDiscoveryLayout } from "../MachineDiscovery/MachineDiscovery";
import MachineEvents from "../MachineEvents/MachineEvents";
import { MachineLayout, WorkerMachineLayout } from "../MachineLayout/MachineLayout";
import MachinePoliciesLayout from "../MachinePoliciesLayout/MachinePoliciesLayout";
import MachinePolicyLayout from "../MachinePolicyLayout/MachinePolicyLayout";
import MachineRunbookRuns from "../MachineRunbookRuns/MachineRunbookRuns";
import { MachineTargetNew } from "../MachineSettings/MachineTargetNew";
import DeploymentTargetsLayout from "../MachinesLayout/DeploymentTargetsLayout";
import WorkerMachinesLayout from "../MachinesLayout/WorkerMachinesLayout";
import ProxiesLayout from "../ProxiesLayout/ProxiesLayout";
import ProxyLayout from "../ProxyLayout/ProxyLayout";
import { WorkerMachineNew } from "../WorkerMachineSettings/WorkerMachineNew";
import { WorkerMachineSettingsLayout } from "../WorkerMachineSettings/WorkerMachineSettings";
import WorkerPoolEdit from "../WorkerPools/WorkerPoolLayout/WorkerPoolEdit";
import WorkerPoolsLayout from "../WorkerPools/WorkerPoolsLayout";
const machineDiscovery = renderWithLayout(InfrastructureLayout)(DeploymentTargetDiscoveryLayout);
const machineSettings = renderWithLayout(MachineLayout)(DeploymentTargetSettingsLayout);
const machineConnection = renderWithLayout(MachineLayout)(DeploymentTargetConnectionsLayout);
const machineDeployments = renderWithLayout(MachineLayout)(MachineDeployments);
const machineRunbookRuns = renderWithLayout(MachineLayout)(MachineRunbookRuns);
const machineEvents = renderWithLayout(MachineLayout)(MachineEvents);
const machineNewWithLayout = renderWithLayout(InfrastructureLayout)(MachineTargetNew);
const machineCreateWithLayout = renderWithLayout(InfrastructureLayout)(DeploymentTargetSettingsLayout);
const workerMachineDiscovery = renderWithLayout(InfrastructureLayout)(WorkerMachineDiscoveryLayout);
const workerMachineSettings = renderWithLayout(WorkerMachineLayout)(WorkerMachineSettingsLayout);
const workerMachineConnection = renderWithLayout(WorkerMachineLayout)(WorkerMachineConnectionsLayout);
const workerMachineEvents = renderWithLayout(WorkerMachineLayout)(MachineEvents);
const WorkerMachineNewWithLayout = renderWithLayout(InfrastructureLayout)(WorkerMachineNew);
const WorkerMachineCreateWithLayout = renderWithLayout(InfrastructureLayout)(WorkerMachineSettingsLayout);
const InfrastructureDashboardPage = withPage({ page: pageIds.infrastructure.overview })(InfrastructureDashboard);
const EnvironmentPage = withPage({ page: pageIds.infrastructure.environment })(EnvironmentLayout);
const ListEnvironmentsPage = withPage({ page: pageIds.infrastructure.environments.root })(EnvironmentsLayout);
const DynamicEnvironmentPage = withPage({ page: pageIds.infrastructure.dynamicEnvironment })(DynamicEnvironmentLayout);
const ListDynamicEnvironmentsPage = withPage({ page: pageIds.infrastructure.dynamicEnvironments.root })(DynamicEnvironmentsLayout);
const NewWorkerMachinePage = withPage({ page: pageIds.infrastructure.workerMachines.select })(WorkerMachineNewWithLayout);
const DiscoverWorkerMachinePage = withPage({ page: pageIds.infrastructure.workerMachines.discover })(workerMachineDiscovery);
const CreateWorkerMachinePage = withPage({ page: pageIds.infrastructure.workerMachines.create })(WorkerMachineCreateWithLayout);
const WorkerSettingsPage = withPage({ page: pageIds.infrastructure.workerMachine.settings })(workerMachineSettings);
const WorkerConnectionPage = withPage({ page: pageIds.infrastructure.workerMachine.connection })(workerMachineConnection);
const WorkerEventsPage = withPage({ page: pageIds.infrastructure.workerMachine.events })(workerMachineEvents);
const WorkersPage = withPage({ page: pageIds.infrastructure.workerMachines.root })(WorkerMachinesLayout);
const WorkerPoolPage = withPage({ page: pageIds.infrastructure.workerPool })(WorkerPoolEdit);
const ListWorkerPoolsPage = withPage({ page: pageIds.infrastructure.workerPools.root })(WorkerPoolsLayout);
const NewMachinePage = withPage({ page: pageIds.infrastructure.machines.select })(machineNewWithLayout);
const DiscoverMachinePage = withPage({ page: pageIds.infrastructure.machines.discover })(machineDiscovery);
const CreateMachinePage = withPage({ page: pageIds.infrastructure.machines.create })(machineCreateWithLayout);
const MachineSettingsPage = withPage({ page: pageIds.infrastructure.machine.settings })(machineSettings);
const MachineConnectionPage = withPage({ page: pageIds.infrastructure.machine.connection })(machineConnection);
const MachineDeploymentsPage = withPage({ page: pageIds.infrastructure.machine.deployments })(machineDeployments);
const MachineRunbookRunsPage = withPage({ page: pageIds.infrastructure.machine.runbookRuns })(machineRunbookRuns);
const MachineEventsPage = withPage({ page: pageIds.infrastructure.machine.events })(machineEvents);
const DeploymentTargetsPage = withPage({ page: pageIds.infrastructure.machines.root })(DeploymentTargetsLayout);
const CreateProxyPage = withPage({ page: pageIds.infrastructure.proxies.create })(ProxyLayout);
const ProxyPage = withPage({ page: pageIds.infrastructure.proxy })(ProxyLayout);
const ListProxiesPage = withPage({ page: pageIds.infrastructure.proxies.root })(ProxiesLayout);
const CreateMachinePolicyPage = withPage({ page: pageIds.infrastructure.machinePolicies.create })(MachinePolicyLayout);
const MachinePolicyPage = withPage({ page: pageIds.infrastructure.machinePolicy })(MachinePolicyLayout);
const ListMachinePoliciesPage = withPage({ page: pageIds.infrastructure.machinePolicies.root })(MachinePoliciesLayout);
const CreateAccountPage = withPage({ page: pageIds.infrastructure.accounts.create })(AccountEdit);
const AccountPage = withPage({ page: pageIds.infrastructure.account })(AccountEdit);
const ListAccountsPage = withPage({ page: pageIds.infrastructure.accounts.root })(AccountsLayout);
function InfrastructureRoutesInternal() {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    return (<Switch>
            <ReloadableRoute path={routeLinks.infrastructure.overview} component={InfrastructureDashboardPage}/>
            <ReloadableRoute path={routeLinks.infrastructure.environments.create} render={() => <EnvironmentPage newOrExistingEnvironment={{ createNewEnvironment: true }}/>}/>
            <ReloadableRoute path={routeLinks.infrastructure.environment(":environmentId")} render={(routeProps: RouteComponentProps<{
        environmentId: string;
    }>) => <EnvironmentPage newOrExistingEnvironment={{ createNewEnvironment: false, existingEnvironmentId: routeProps.match.params.environmentId }}/>}/>
            <ReloadableRoute path={routeLinks.infrastructure.environments.root} exact component={ListEnvironmentsPage}/>
            {dynamicEnvironmentsEnabled && <ReloadableRoute path={routeLinks.infrastructure.dynamicEnvironment(":environmentId").overview} exact component={DynamicEnvironmentPage}/>}
            {dynamicEnvironmentsEnabled && <ReloadableRoute path={routeLinks.infrastructure.dynamicEnvironments.root()} component={ListDynamicEnvironmentsPage}/>}
            <ReloadableRoute path={routeLinks.infrastructure.workerMachines.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.infrastructure.workerMachines.new(":workerPoolId?")} component={NewWorkerMachinePage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.workerMachines.discover(":key", ":workerPoolId?")} component={DiscoverWorkerMachinePage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.workerMachines.create()} component={CreateWorkerMachinePage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.workerMachine(":machineId").settings} component={WorkerSettingsPage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.workerMachine(":machineId").connection} component={WorkerConnectionPage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.workerMachine(":machineId").events} component={WorkerEventsPage}/>
                    <ReloadableRoute exact={true} path={routeLinks.infrastructure.workerMachine(":machineId").root} render={(props: RouteComponentProps<{
        machineId: string;
    }>) => <InternalRedirect to={routeLinks.infrastructure.workerMachine(props.match.params.machineId).settings}/>}/>
                    <ReloadableRoute exact={true} path={routeLinks.infrastructure.workerMachines.root} component={WorkersPage}/>
                    <RedirectAs404 />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.infrastructure.workerPool(":workerPoolId")} component={WorkerPoolPage}/>
            <ReloadableRoute path={routeLinks.infrastructure.workerPools.root} component={ListWorkerPoolsPage}/>
            <ReloadableRoute path={routeLinks.infrastructure.machines.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.infrastructure.machines.new(":environmentId?")} render={(routeProps: RouteComponentProps<{
        environmentId?: string;
    }>) => <NewMachinePage environmentId={routeProps.match.params.environmentId}/>}/>
                    <ReloadableRoute path={routeLinks.infrastructure.machines.discover(":key", ":environmentId?")} component={DiscoverMachinePage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.machines.discover(":key")} component={DiscoverMachinePage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.machines.create()} component={CreateMachinePage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.machine(":machineId").settings} component={MachineSettingsPage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.machine(":machineId").connection} component={MachineConnectionPage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.deploymentTarget(":machineId").deployments} component={MachineDeploymentsPage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.deploymentTarget(":machineId").runbookRuns} component={MachineRunbookRunsPage}/>
                    <ReloadableRoute path={routeLinks.infrastructure.machine(":machineId").events} component={MachineEventsPage}/>
                    <ReloadableRoute exact={true} path={routeLinks.infrastructure.machine(":machineId").root} render={(props: RouteComponentProps<{
        machineId: string;
    }>) => <InternalRedirect to={routeLinks.infrastructure.machine(props.match.params.machineId).settings}/>}/>
                    <ReloadableRoute exact={true} path={routeLinks.infrastructure.machines.root} component={DeploymentTargetsPage}/>
                    <RedirectAs404 />
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.infrastructure.proxies.create} render={(routeProps) => <CreateProxyPage create {...routeProps}/>}/>
            <ReloadableRoute path={routeLinks.infrastructure.proxy(":proxyId")} component={ProxyPage}/>
            <ReloadableRoute path={routeLinks.infrastructure.proxies.root} component={ListProxiesPage}/>
            <ReloadableRoute path={routeLinks.infrastructure.machinePolicies.create} render={(routeProps) => <CreateMachinePolicyPage create {...routeProps}/>}/>
            <ReloadableRoute path={routeLinks.infrastructure.machinePolicy(":machinePolicyId")} component={MachinePolicyPage}/>
            <ReloadableRoute path={routeLinks.infrastructure.machinePolicies.root} component={ListMachinePoliciesPage}/>
            <ReloadableRoute path={routeLinks.infrastructure.accounts.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.infrastructure.accounts.create} render={(routeProps: RouteComponentProps) => {
            const uri = URI(routeProps.location.search);
            const query = uri.search(true);
            const accountType: keyof typeof AccountType = query.accountType;
            return <CreateAccountPage createNew={true} accountType={AccountType[accountType]}/>;
        }}/>
                    <ReloadableRoute path={routeLinks.infrastructure.account(":accountId")} render={(routeProps: RouteComponentProps<{
            accountId: string;
        }>) => {
            return <AccountPage createNew={false} accountId={routeProps.match.params.accountId}/>;
        }}/>
                    <ReloadableRoute component={ListAccountsPage}/>
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute exact={true} path={routeLinks.infrastructure.root} render={() => <InternalRedirect to={routeLinks.infrastructure.overview}/>}/>
            <RedirectAs404 />
        </Switch>);
}
export class InfrastructureRoutes extends React.Component<RouteComponentProps> {
    render() {
        return <InfrastructureRoutesInternal />;
    }
    static displayName = "InfrastructureRoutes";
}
export default InfrastructureRoutes;
