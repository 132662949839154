import type { CommonInputProps, NoteExpression } from "@octopusdeploy/step-ui";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import { Video } from "~/components/ContextualHelp/Video";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import NoteForm from "~/primitiveComponents/form/Note/Note";
import ExternalLink from "../../../Navigation/ExternalLink";
interface NoteProps<StepInputs, TInputComponents> {
    note?: NoteExpression[];
}
export function noteToElement(note: NoteExpression[]) {
    return note.map((value, index) => {
        if (typeof value === "string") {
            const lines = [];
            let lineIndex = 0;
            for (const line of value.split("\n")) {
                if (lines.length > 0) {
                    lines.push(<br key={`${lineIndex}br`}/>);
                }
                lines.push(<React.Fragment key={lineIndex}>{line}</React.Fragment>);
                lineIndex++;
            }
            return <React.Fragment key={index}>{lines}</React.Fragment>;
        }
        switch (value.type) {
            case "link":
                return (<ExternalLink href={value.href} key={index}>
                        {value.title}
                    </ExternalLink>);
            case "bold":
                return <strong key={index}>{value.text}</strong>;
            case "italic":
                return <em key={index}>{value.text}</em>;
            case "code":
                return <code key={index}>{value.text}</code>;
            case "video":
                return <Video src={value.src}/>;
            case "video-link":
                return <ExternalVideoLink href={value.href}>{value.title}</ExternalVideoLink>;
            default:
                exhaustiveCheck(value, "Missing implementation. Not all component for Note have been handled.");
        }
    });
}
export function Note<StepInputs, TInputComponents extends CommonInputProps>(props: NoteProps<StepInputs, TInputComponents>) {
    if (!props.note) {
        return null;
    }
    return <NoteForm>{noteToElement(props.note)}</NoteForm>;
}
