import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { generatePath } from "react-router";
import { Switch } from "react-router-dom";
import ProjectTenants from "~/areas/projects/components/ProjectTenants/ProjectTenants";
import type { ProjectRouteParams, ProjectsRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import DeploymentSettingsRoute from "../DeploymentProcessSettings/Routing/DeploymentSettingsRoute";
import DeploymentsRoute from "../DeploymentsRoute";
import ExportProjects from "../ImportExport/ExportProjects";
import { ExportTaskDetails } from "../ImportExport/ExportTaskDetails";
import ImportExportTasks from "../ImportExport/ImportExportTasks";
import ImportProjects from "../ImportExport/ImportProjects";
import { ImportTaskDetails } from "../ImportExport/ImportTaskDetails";
import OperationsRoute from "../OperationsRoute";
import ProcessRoute from "../Process/Routing/ProcessRoute";
import ProjectInsightsRoutes from "../ProjectInsights/ProjectInsightsRoutes/ProjectInsightsRoutes";
import ProjectSettingsRoute from "../ProjectSettings/ProjectSettingsRoute";
import ProjectTasks from "../ProjectTasks/ProjectTasks";
import { Projects } from "../Projects";
import { EnhancedRunbooksRoute as RunbooksRoute } from "../Runbooks/RunbooksRoute";
import { TriggersRoute } from "../Triggers";
import { AllVariablesRoute } from "../Variables/AllVariables/AllVariablesRoute";
import { ProjectVariablesRoute } from "../Variables/ProjectVariables/ProjectVariablesRoute";
import { VariablePreviewRoute } from "../Variables/VariablePreview/VariablePreviewRoute";
import { ProjectToOverviewRedirect } from "./ProjectToOverviewRedirect";
const ProjectTasksPage = withPage({ page: pageIds.project().tasks })(ProjectTasks);
const ProjectTenantsPage = withPage({ page: pageIds.project().tenants })(ProjectTenants);
const ProjectsPage = withPage({ page: pageIds.projects.root })(Projects);
const ImportExportPage = withPage({ page: pageIds.importExport.root })(ImportExportTasks);
const ExportPage = withPage({ page: pageIds.importExport.export })(ExportProjects);
const ExportTaskDetailsPage = withPage({ page: pageIds.importExport.exportTask })(ExportTaskDetails);
const ImportPage = withPage({ page: pageIds.importExport.import })(ImportProjects);
const ImportTaskDetailsPage = withPage({ page: pageIds.importExport.importTask })(ImportTaskDetails);
type ProjectsRouteProps = RouteComponentProps<{
    spaceId: string;
}>;
export function ProjectsRoutes() {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    return (<Switch>
            <ReloadableRoute path={routeLinks.v3projectRoutes.newProject}>
                <InternalRedirect to={routeLinks.projects.root}/>
            </ReloadableRoute>
            {!isRoutingVNextEnabled && <ReloadableRoute path={routeLinks.projects.root} render={(props: RouteComponentProps<ProjectsRouteParams>) => <ProjectsPage spaceId={props.match.params.spaceId}/>}/>}
            <RedirectAs404 />
        </Switch>);
}
type ProjectsImportExportTaskRouteProps = RouteComponentProps<{
    spaceId: string;
    taskId: string;
}>;
export function ProjectsImportExportRoutes() {
    return (<Switch>
            <ReloadableRoute path={routeLinks.importExport.root} render={(p: ProjectsRouteProps) => <ImportExportPage spaceId={p.match.params.spaceId}/>}/>
            <ReloadableRoute path={routeLinks.exportTask(":taskId").root} render={(p: ProjectsImportExportTaskRouteProps) => <ExportTaskDetailsPage spaceId={p.match.params.spaceId} taskId={p.match.params.taskId}/>}/>
            <ReloadableRoute path={routeLinks.importTask(":taskId").root} render={(p: ProjectsImportExportTaskRouteProps) => <ImportTaskDetailsPage spaceId={p.match.params.spaceId} taskId={p.match.params.taskId}/>}/>
            <ReloadableRoute path={routeLinks.export.root} render={(p: ProjectsRouteProps) => <ExportPage spaceId={p.match.params.spaceId}/>}/>
            <ReloadableRoute path={routeLinks.import.root} render={(p: ProjectsRouteProps) => <ImportPage spaceId={p.match.params.spaceId}/>}/>
            <RedirectAs404 />
        </Switch>);
}
export function ProjectBranchRoutes() {
    const branchRouteLinks = routeLinks.projectBranchRoutes();
    return (<Switch>
            <RunbooksRoute path={branchRouteLinks.runbooks}/>
            <RedirectAs404 />
        </Switch>);
}
export function ProjectBranchVariablesRoutes() {
    const branchRouteLinks = routeLinks.projectBranchRoutes();
    return (<Switch>
            <AllVariablesRoute path={branchRouteLinks.variables.all}/>
            <VariablePreviewRoute path={branchRouteLinks.variables.preview}/>
            <ProjectVariablesRoute path={branchRouteLinks.variables.root}/>
            <RedirectAs404 />
        </Switch>);
}
export function ProjectBranchDeploymentRoutes() {
    const branchRouteLinks = routeLinks.projectBranchRoutes();
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    return (<Switch>
            <ProcessRoute path={branchRouteLinks.deploymentProcess.root} processType={ProcessType.Deployment}/>
            {!isRoutingVNextEnabled && <DeploymentSettingsRoute path={branchRouteLinks.deploymentSettings}/>}
        </Switch>);
}
export function ProjectNonBranchRoutes() {
    const projectRouteLinks = routeLinks.projectRoutes();
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    return (<Switch>
            {!isRoutingVNextEnabled && <ReloadableRoute path={projectRouteLinks.tasks} component={ProjectTasksPage}/>}
            <ReloadableRoute path={projectRouteLinks.tenants} component={ProjectTenantsPage}/>
            <ReloadableRoute path={projectRouteLinks.settings.root} component={ProjectSettingsRoute}/>
            <ReloadableRoute path={projectRouteLinks.triggers} component={TriggersRoute}/>
            <ReloadableRoute path={projectRouteLinks.deployments.root} component={DeploymentsRoute}/>
            <OperationsRoute path={projectRouteLinks.operations.root}/>
            <ReloadableRoute path={projectRouteLinks.insights.root} component={ProjectInsightsRoutes}/>
            <ReloadableRoute path={projectRouteLinks.stepTemplates} render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.process.stepTemplates}/>}/>
            <ReloadableRoute exact={true} path={projectRouteLinks.overview} render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.root}/>}/>
            <ReloadableRoute exact={true} path={projectRouteLinks.root} render={(props: RouteComponentProps<ProjectRouteParams>) => <ProjectToOverviewRedirect />}/>
            <ReloadableRoute path={projectRouteLinks.childStepTemplates(":parentStepId").root} render={(props: RouteComponentProps<ProjectRouteParams & {
            parentStepId: string;
        }>) => (<InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.process.childStepTemplates(props.match.params.parentStepId).root}/>)}/>
            <ReloadableRoute path={`${projectRouteLinks.channels}*`} render={(props: RouteComponentProps) => {
            //Rewrite any old release based routes from project/releases to project/deployments/releases
            const rewrite = props.match.path.replace(projectRouteLinks.channels, projectRouteLinks.deployments.channels);
            const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
            return <InternalRedirect to={destination}/>;
        }}/>
            <ReloadableRoute path={`${projectRouteLinks.releases}*`} render={(props: RouteComponentProps) => {
            //Rewrite any old release based routes from project/releases to project/deployments/releases
            const rewrite = props.match.path.replace(projectRouteLinks.releases, projectRouteLinks.deployments.releases);
            const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
            return <InternalRedirect to={destination}/>;
        }}/>
            <ReloadableRoute path={`${projectRouteLinks.deployments.process.root}*`} render={(props: RouteComponentProps) => {
            //Rewrite any old process links to the new route
            const rewrite = props.match.path.replace(projectRouteLinks.deployments.process.root, projectRouteLinks.deployments.process.root);
            const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
            return <InternalRedirect to={destination}/>;
        }}/>
            <RedirectAs404 />
        </Switch>);
}
