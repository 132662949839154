import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import RedirectToProcessEditorViaSlug from "~/components/RedirectToProcessEditorViaSlug/RedirectToProcessEditorViaSlug";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import routeLinks from "~/routeLinks";
import type { ProjectRouteParams } from "../../ProjectsRoutes/ProjectRouteParams";
import { ProcessListLayoutPage, ProcessStepsPage } from "../Pages";
type ProcessRouteProps = {
    path: string;
    processType: ProcessType;
};
function ProcessRoute({ path, processType }: ProcessRouteProps) {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    return (<Switch>
            <Route path={`${path}/steps/:stepSlug`} exact={true} render={(routeProps) => <RedirectToProcessEditorViaSlug processType={processType} projectSlug={routeProps.match.params.projectSlug} stepSlug={routeProps.match.params.stepSlug}/>}/>
            {!isRoutingVNextEnabled && <Route path={`${path}/steps`} exact={true} render={(routeProps) => <ProcessStepsPage processType={processType} {...routeProps}/>}/>}
            {!isRoutingVNextEnabled && <Route path={`${path}`} exact={true} render={() => <ProcessListLayoutPage processType={processType}/>}/>}
            <OldProcessRedirectRoute path={path} processType={processType}/>
            <RedirectAs404 />
        </Switch>);
}
class OldProcessRedirectRoute extends React.Component<ProcessRouteProps> {
    render() {
        // If anyone has bookmarked an older step route, we redirect them back to the list. We want to K.I.S.S. and avoid mapping the old route parameters to the new query-string parameters (that felt like overkill).
        const renderRedirectToProcessList = (props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.process.root}/>;
        return (<Switch>
                <ReloadableRoute path={`${this.props.path}/step/new/:actionType/:template?`} render={renderRedirectToProcessList}/>
                <ReloadableRoute path={`${this.props.path}/step/:stepId/new/:actionType/:template?`} render={renderRedirectToProcessList}/>
                <ReloadableRoute path={`${this.props.path}/step/:stepId/:reloadKey?`} render={renderRedirectToProcessList}/>
                <ReloadableRoute path={`${this.props.path}/steptemplates`} render={renderRedirectToProcessList}/>
                <ReloadableRoute path={`${this.props.path}/childsteptemplates/:parentStepId`} render={renderRedirectToProcessList}/>
            </Switch>);
    }
    static displayName = "OldProcessRedirectRoute";
}
export function ProjectDeploymentProcessRoutes() {
    return <ProcessRoute path={routeLinks.projectRoutes().deployments.process.root} processType={ProcessType.Deployment}/>;
}
export default ProcessRoute;
