import type { SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { useMenuState, SimpleMenu, ActionButtonType, ActionButton } from "@octopusdeploy/design-system-components";
import type { AccountResource, AccountType, EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { sortBy, uniq } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import MarkdownDescription from "~/components/MarkdownDescription";
import { SimplePagingList } from "~/components/PagingList/SimplePagingList";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { convertQueryToQueryString } from "~/components/QueryStringFilters/QueryStringFilters";
import { FormSectionHeading } from "~/components/form";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { environmentChipListIncludingMissing, tenantChipListIncludingMissing } from "../../../../components/Chips/index";
import routeLinks from "../../../../routeLinks";
import { RawAccountTypeDetailsMap } from "../../InfrastructureDetails";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import Onboarding from "./Onboarding";
import styles from "./style.module.less";
class AccountList extends SimplePagingList<AccountResource> {
}
interface AccountsParams {
    initialData: InitialData;
}
interface InitialData {
    accounts: AccountResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
}
const Title = "Accounts";
const AccountsLayoutFormPage = FormPage<InitialData>();
const AccountsLayoutPage: React.FC = () => {
    return (<AccountsLayoutFormPage title={Title} load={async () => {
            const accounts = await repository.Accounts.all();
            const envIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.EnvironmentIds), []));
            const tenantIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.TenantIds), []));
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ ids: tenantIds }) : Promise.resolve([]);
            return {
                accounts,
                environments: await repository.Environments.all({ ids: envIds }),
                tenants: await tenantsPromise,
            };
        }} renderWhenLoaded={(initialData) => <AccountsLayoutInternal initialData={initialData}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
};
AccountsLayoutPage.displayName = "AccountsLayoutPage"
class AccountsLayoutInternal extends DataBaseComponent<AccountsParams> {
    constructor(props: AccountsParams) {
        super(props);
    }
    renderEnvironments = (account: AccountResource) => {
        if (account.EnvironmentIds.length === 0) {
            return null;
        }
        return <div>{environmentChipListIncludingMissing(this.props.initialData.environments, account.EnvironmentIds)}</div>;
    };
    renderAccountTypes(types: AccountType[], name: string, description: JSX.Element) {
        let accounts = this.props.initialData.accounts.filter((account) => types.indexOf(account.AccountType) !== -1);
        accounts = sortBy(accounts, (account) => account.Name);
        if (!accounts || accounts.length === 0) {
            return null;
        }
        const title = (<div className={styles.typeHeader}>
                <div className={styles.typeHeaderTitleContainer}>{name}</div>
            </div>);
        return (<React.Fragment>
                <FormSectionHeading title={title}/>
                <div className={styles.typeBody}>
                    <AccountList items={accounts} onRow={this.renderRow} onRowRedirectUrl={(account: AccountResource) => routeLinks.infrastructure.account(account.Id)}/>
                </div>
            </React.Fragment>);
    }
    renderRow = (account: AccountResource) => {
        return (<div key={account.Id} className={styles.account}>
                <ListTitle>{account.Name}</ListTitle>
                {environmentChipListIncludingMissing(this.props.initialData.environments, account.EnvironmentIds)}
                {tenantChipListIncludingMissing(this.props.initialData.tenants, account.TenantIds)}
                <MarkdownDescription markup={account.Description}/>
            </div>);
    };
    renderContent = () => {
        const accountTypeDetailsMap = RawAccountTypeDetailsMap;
        return <div>{accountTypeDetailsMap.map((account) => this.renderAccountTypes(account.types, account.namePlural, account.description))}</div>;
    };
    render() {
        const addAccountButton = <AddAccountButton />;
        return (<InfrastructureLayout>
                <PaperLayout title="Accounts" sectionControl={addAccountButton}>
                    {this.props.initialData.accounts.length === 0 && <Onboarding />}
                    {this.props.initialData.accounts.length > 0 && <React.Fragment>{this.renderContent()}</React.Fragment>}
                </PaperLayout>
            </InfrastructureLayout>);
    }
    static displayName = "AccountsLayoutInternal";
}
function AddAccountButton() {
    const [openMenu, menuState, buttonArriaAttributes] = useMenuState();
    const addAccountItems: SimpleMenuItem[] = RawAccountTypeDetailsMap.map((accountTypeDetails) => ({
        type: "internal-link",
        label: accountTypeDetails.name,
        path: `${routeLinks.infrastructure.accounts.create}${convertQueryToQueryString({ accountType: accountTypeDetails.types[0] })}`,
    }));
    return (<PermissionCheck permission={Permission.AccountCreate} wildcard={true}>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition="right" label="Add Account" onClick={openMenu} menuButtonAttributes={buttonArriaAttributes}/>
            <SimpleMenu menuState={menuState} items={addAccountItems} accessibleName={"Add account"} compact={true}/>
        </PermissionCheck>);
}
export default AccountsLayoutPage;
