/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MultiTenancyStatusResource, ResourceCollection, TenantResource, IconMetadataResource, IconSvgResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { connect } from "react-redux";
import type { match as Match } from "react-router";
import { useParams, useRouteMatch } from "react-router";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import IconAndLogoEditLayout, { LogoTypeSelection } from "~/areas/infrastructure/components/IconAndLogoEditLayout/IconAndLogoEditLayout";
import DeleteTenantDialog from "~/areas/tenants/TenantSettings/DeleteTenantDialog";
import { repository } from "~/clientInstance";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PagingList } from "~/components/PagingList/PagingList";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import type { IconEditorSettings } from "~/components/form/IconEditor/IconEditor";
import IconEditor, { IconEditorDefaultColor } from "~/components/form/IconEditor/IconEditor";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import FormPaperLayout from "../../../components/FormPaperLayout/FormPaperLayout";
import Logo from "../../../components/Logo/Logo";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import { ExpandableFormSection, FormSectionHeading, MarkdownEditor, Summary } from "../../../components/form";
import type { SummaryNode } from "../../../components/form";
import LogoEditor from "../../../components/form/LogoEditor/LogoEditor";
import type { LogoEditorSettings } from "../../../components/form/LogoEditor/LogoEditor";
import { required } from "../../../components/form/Validators";
import Text from "../../../primitiveComponents/form/Text/Text";
import routeLinks from "../../../routeLinks";
import { configurationActions } from "../../configuration/reducers/configurationArea";
import { CloneSpecificTenantDialog } from "../Tenants/AddOrCloneTenant";
import { tenantsActions } from "../tenantsArea";
interface TenantModel {
    name: string;
    slug?: string;
    description: string | null;
    logo: LogoEditorSettings;
    icon: IconEditorSettings;
    tenantTags: string[];
    clonedFromTenantId: string | null;
}
interface GlobalDispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
    onTenantSaved: (tenant: TenantResource) => void;
}
interface TenantSettingsState extends FormBaseComponentState<TenantModel> {
    tenant: TenantResource;
    deleted: boolean;
    redirectTo?: string;
    logoTypeSelection: LogoTypeSelection;
}
const AccessDenied = "AccessDenied";
const NotFound = "NotFound";
const NotCloned = "NotCloned";
type AvailableCloneFromTenantDetails = {
    clonedFromTenant: TenantResource;
};
type PossiblyClonedFromTenantDetails = typeof NotCloned | CloneFromTenantDetails;
type CloneFromTenantDetails = typeof NotFound | typeof AccessDenied | AvailableCloneFromTenantDetails;
class ClonedTenantsList extends PagingList<TenantResource> {
}
interface RouteParams {
    tenantId: string;
}
interface InitialData {
    tenant: TenantResource;
    clonedFromTenantDetails: PossiblyClonedFromTenantDetails;
    clonedTenantsCollection: ResourceCollection<TenantResource>;
    iconSvgResources: IconSvgResource[];
    iconMetadata: IconMetadataResource;
}
const TenantSettingsFormPage = FormPage<InitialData>();
const title = "Settings";
const TenantSettingsPage: React.FC = () => {
    const { tenantId } = useParams<RouteParams>();
    const match = useRouteMatch();
    const trackAnalytics = useAnalyticActionDispatch();
    async function getCloneDetails(tenant: TenantResource): Promise<PossiblyClonedFromTenantDetails> {
        if (!tenant.ClonedFromTenantId) {
            return NotCloned;
        }
        const canAccessClone = isAllowed({
            permission: Permission.TenantView,
            tenant: tenant.ClonedFromTenantId,
            wildcard: true,
        });
        if (!canAccessClone) {
            return AccessDenied;
        }
        try {
            const clonedFromTenant = await repository.Tenants.get(tenant.ClonedFromTenantId);
            return { clonedFromTenant };
        }
        catch (error) {
            // if it's any other error let it go, if we failed to load due to bad data, just move on with 'NotFound'
            if (error.StatusCode === 404) {
                return NotFound;
            }
            else {
                throw error;
            }
        }
    }
    return (<TenantSettingsFormPage title={title} load={async () => {
            const [tenant, clonedTenantsCollection, iconSvgResources, iconMetadata] = await Promise.all([
                repository.Tenants.get(tenantId),
                repository.Tenants.list({ clonedFromTenantId: tenantId }),
                repository.Icons.getIcons(),
                repository.Icons.getIconMetadata(),
            ]);
            const clonedFromTenantDetails = await getCloneDetails(tenant);
            return {
                tenant,
                clonedTenantsCollection,
                clonedFromTenantDetails,
                iconSvgResources,
                iconMetadata,
            };
        }} renderWhenLoaded={(initialData) => {
            return <TenantSettings initialData={initialData} match={match || undefined} trackAction={trackAnalytics}/>;
        }}/>);
};
TenantSettingsPage.displayName = "TenantSettingsPage"
type TenantSettingsInternalProps = {
    initialData: InitialData;
    trackAction: AnalyticActionDispatcher;
} & GlobalDispatchProps & {
    match?: Match<any>;
};
class TenantSettingsInternal extends FormBaseComponent<TenantSettingsInternalProps, TenantSettingsState, TenantModel> {
    constructor(props: TenantSettingsInternalProps) {
        super(props);
        this.state = {
            tenant: this.props.initialData.tenant,
            model: this.buildModel(props.initialData.tenant),
            cleanModel: this.buildModel(props.initialData.tenant),
            deleted: false,
            logoTypeSelection: LogoTypeSelection.NotSet,
        };
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const CloneDialog = () => (<PermissionCheck permission={Permission.TenantCreate}>
                <CloneSpecificTenantDialog cloneId={this.state.tenant.Id} cloneTenantName={this.state.tenant.Name} tenantCreated={this.onTenantCreated}/>
            </PermissionCheck>);
        const overFlowActions = [
            OverflowMenuItems.dialogItem("Clone", <CloneDialog />, { permission: Permission.TenantCreate }),
            OverflowMenuItems.dialogItem("Delete", <DeleteTenantDialog tenant={this.state.tenant} handleDeleteConfirm={this.handleDeleteConfirm}/>, { permission: Permission.TenantDelete }),
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForTenant(this.state.tenant.Id), { permission: Permission.EventView, wildcard: true }),
        ];
        if (this.state.deleted) {
            return <InternalRedirect to={routeLinks.tenants}/>;
        }
        return (<FormPaperLayout title="Settings" busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: Permission.TenantEdit, tenant: "*" }} onSaveClick={this.handleSaveClick} overFlowActions={overFlowActions} saveText="Tenant details updated">
                <TransitionAnimation>
                    <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an icon or upload a custom image.">
                        <IconAndLogoEditLayout iconEditor={<IconEditor icons={this.props.initialData.iconSvgResources} iconMetadata={this.props.initialData.iconMetadata} selectedIconId={this.state.model.icon?.iconId} selectedIconColor={this.state.model.icon?.iconColor} onIconIdChange={(iconId) => {
                    this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                    this.setModelState({ icon: { iconId: iconId, iconColor: this.state.model?.icon?.iconColor ?? IconEditorDefaultColor }, logo: { file: undefined, reset: false } });
                }} onIconColorChange={(iconColor) => {
                    this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                    this.setModelState({ icon: { iconId: this.state.model?.icon?.iconId ?? "", iconColor } });
                }}/>} logoEditor={<LogoEditor value={this.state.model.logo} onChange={(logo) => {
                    this.setState({ logoTypeSelection: LogoTypeSelection.CustomImage });
                    this.setModelState({ logo, icon: { iconId: "", iconColor: IconEditorDefaultColor } });
                }}/>} onTabChange={(logoType) => this.setState({ logoTypeSelection: logoType })}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your tenant")} help="Enter a name for your tenant.">
                        <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Tenant name" validate={required("Please enter a tenant name")} autoFocus={true}/>
                        <SlugEditor value={this.state.model.slug ?? ""} name={this.state.model.name} originalSlug={this.state.cleanModel?.slug ?? ""} onChange={(slug) => this.setModelState({ slug })} label={"Tenant slug"} validate={required("Please enter a tenant slug")} error={this.getFieldError("slug")}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection errorKey="description" title="Description" summary={this.state.model.description ? Summary.summary(this.state.model.description) : Summary.placeholder("No description provided")} help="Enter a description for your tenant.">
                        <MarkdownEditor value={this.state.model.description ?? undefined} label="Tenant description" onChange={(description) => this.setModelState({ description })}/>
                    </ExpandableFormSection>

                    {this.renderCloningSection()}
                </TransitionAnimation>
            </FormPaperLayout>);
    }
    onTenantCreated = (tenant: TenantResource) => {
        this.props.trackAction("Clone a Tenant", { resource: "Tenant", action: Action.Add });
        this.setState({ redirectTo: routeLinks.tenant(tenant.Id).root });
    };
    renderCloneDetails(cloneDetails: CloneFromTenantDetails) {
        const clonedFromId = this.state.tenant.ClonedFromTenantId;
        if (cloneDetails === NotFound) {
            return <div>This tenant was originally cloned from a tenant ({clonedFromId}) that cannot be found.</div>;
        }
        else if (cloneDetails === AccessDenied) {
            return <div>This tenant was originally cloned from a tenant that you do not have {Permission.TenantView} for.</div>;
        }
        else {
            return (<div>
                    This tenant was originally cloned from <InternalLink to={routeLinks.tenant(cloneDetails.clonedFromTenant).root}>{cloneDetails.clonedFromTenant.Name}</InternalLink>.
                </div>);
        }
    }
    handleSaveClick = async () => {
        const model = this.state.model;
        const tenant: TenantResource = {
            ...this.state.tenant,
            Name: model.name,
            Slug: model.slug,
            Description: model.description,
            TenantTags: model.tenantTags,
            ClonedFromTenantId: model.clonedFromTenantId,
        };
        await this.doBusyTask(async () => {
            if (this.state.logoTypeSelection === LogoTypeSelection.Icon) {
                await repository.Logos.saveIcon(tenant, model.icon?.iconId, model.icon?.iconColor);
            }
            else if (this.state.logoTypeSelection === LogoTypeSelection.CustomImage) {
                await repository.Logos.saveLogo(tenant, model.logo.file, model.logo.reset);
            }
            const result = await repository.Tenants.save(tenant);
            this.props.onTenantSaved(result);
            this.setState(() => {
                return {
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    tenant: result,
                };
            });
        });
    };
    buildModel(tenant: TenantResource): TenantModel {
        const model: TenantModel = {
            name: tenant.Name,
            slug: tenant.Slug,
            tenantTags: tenant.TenantTags,
            logo: { file: undefined, reset: false },
            icon: {
                iconId: tenant.Icon?.Id ?? "",
                iconColor: tenant.Icon?.Color ?? IconEditorDefaultColor,
            },
            clonedFromTenantId: tenant.ClonedFromTenantId,
            description: tenant.Description,
        };
        return model;
    }
    logoSummary(): SummaryNode {
        if (this.state.model.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model.logo.file) {
            return Summary.summary(this.state.model.logo.file.name);
        }
        return Summary.summary(<Logo url={this.state.tenant.Links.Logo} size="2.5em"/>);
    }
    private renderClonedFrom() {
        const clonedDetails = this.props.initialData.clonedFromTenantDetails;
        if (clonedDetails === NotCloned) {
            return null;
        }
        const clonedFromElement = this.renderCloneDetails(clonedDetails);
        return <ExpandableFormSection errorKey="ClonedFrom" title="Cloned From" summary={Summary.summary(clonedFromElement)} help={clonedFromElement}/>;
    }
    private renderClonedTenants() {
        if (this.props.initialData.clonedTenantsCollection.TotalResults <= 0) {
            return null;
        }
        return (<ExpandableFormSection errorKey="ClonedTenants" title="Cloned Tenants" summary={Summary.summary("This tenant was cloned to create other tenants.")} help="This tenant was cloned to create the following tenants.">
                <Section>
                    <ClonedTenantsList initialData={this.props.initialData.clonedTenantsCollection} onRow={(tenant: TenantResource) => {
                return <ListTitle>{tenant.Name}</ListTitle>;
            }} onRowRedirectUrl={(tenant: TenantResource) => routeLinks.tenant(tenant).root} filterSearchEnabled={false} autoFocusOnFilterSearch={false} apiSearchParams={["partialName"]} match={this.props.match} showPagingInNumberedStyle={true}/>
                </Section>
            </ExpandableFormSection>);
    }
    private renderCloningSection() {
        const clonedFrom = this.renderClonedFrom();
        const clonedTenants = this.renderClonedTenants();
        const shouldRender = clonedFrom !== null || clonedTenants !== null;
        return (shouldRender && (<React.Fragment>
                    <FormSectionHeading title="Cloning History"/>
                    {clonedFrom}
                    {clonedTenants}
                </React.Fragment>));
    }
    private handleDeleteConfirm = async () => {
        const result = await repository.Tenants.del(this.state.tenant);
        const status = await repository.Tenants.status();
        this.props.onSpaceMultiTenancyStatusFetched(status);
        this.setState(() => {
            return {
                model: undefined,
                cleanModel: undefined,
                deleted: true,
            };
        });
        return true;
    };
    static displayName = "TenantSettingsInternal";
}
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps => bindActionCreators({
    onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched,
    onTenantSaved: tenantsActions.tenantSaved,
}, dispatch);
const TenantSettings = connect(null, mapGlobalActionDispatchersToProps)(TenantSettingsInternal);
export default TenantSettingsPage;
