import { Divider } from "@octopusdeploy/design-system-components";
import { type GitHubAppInstallations } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "~/routeLinks";
import LibraryLayout from "../LibraryLayout";
import GitHubAppInstallationList from "./GitHubAppInstallationList";
type AddGitHubConnectionInternalProps = {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
};
function AddGitHubConnectionInternal({ doBusyTask, busy, errors }: AddGitHubConnectionInternalProps) {
    const [installations, setInstallations] = useState<GitHubAppInstallations | undefined>(undefined);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const installations = await repository.GitHubApp.getInstallations();
        setInstallations(installations);
    }, []);
    return (<LibraryLayout>
            <PaperLayout errors={errors} title={"Add GitHub Connection"} breadcrumbTitle={"Git Connections"} breadcrumbPath={routeLinks.library.gitConnections.root} busy={busy}>
                {installations && (<div data-testid="installations">
                        <Divider />
                        <GitHubAppInstallationList installations={installations}/>
                        <Divider />
                    </div>)}
            </PaperLayout>
        </LibraryLayout>);
}
type AddGitHubConnectionProps = {};
export class AddGitHubConnection extends DataBaseComponent<AddGitHubConnectionProps> {
    constructor(props: AddGitHubConnectionProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <AddGitHubConnectionInternal doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "AddGitHubConnection";
}
export default AddGitHubConnection;
