/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource, ChannelResource, DeploymentProcessResource, DeploymentActionPackageResource } from "@octopusdeploy/octopus-server-client";
import { displayName } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import EditAutoReleaseCreation from "~/areas/projects/components/Triggers/EditAutoReleaseCreation";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import BaseComponent from "~/components/BaseComponent";
import OpenDeleteDialogButton from "~/components/Button/OpenDeleteDialogButton";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import FeatureNotSupportedForConfigAsCode from "~/components/FeatureNotSupported/FeatureNotSupportedForConfigAsCode";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../../../routeLinks";
import styles from "./style.module.less";
interface SideBarProps {
    project: ProjectResource;
    actionPackages: DeploymentActionPackageResource[];
    builtInPackageRepositoryInUse: boolean;
    showAutomaticReleaseCreation: boolean;
    channels: ChannelResource[];
    deploymentProcess: DeploymentProcessResource;
    onProjectUpdated(project: ProjectResource): void;
}
export default class SideBar extends BaseComponent<SideBarProps> {
    constructor(props: SideBarProps) {
        super(props);
    }
    render() {
        return (<div className={styles.sidebar}>
                <h4>Automatic Release Creation</h4>
                <FeatureNotSupportedForConfigAsCode>{this.build()}</FeatureNotSupportedForConfigAsCode>
            </div>);
    }
    private build(): React.ReactNode {
        const learnMore = (<p>
                Learn about <ExternalLink href="AutoReleaseCreation">Automatic Release Creation</ExternalLink>.
            </p>);
        const setupButton = (<OpenDialogButton label={this.props.project.AutoCreateRelease ? "Change" : "Setup"}>
                <EditAutoReleaseCreation project={this.props.project} actionPackages={this.props.actionPackages} channels={this.props.channels} deploymentProcess={this.props.deploymentProcess} onProjectSaved={(p) => this.onProjectSaved(p)}/>
            </OpenDialogButton>);
        const disableButton = (<OpenDeleteDialogButton label="Disable" type={ActionButtonType.Secondary} dialogTitle="Disable Automatic Release Creation" dialogButtonLabel="Disable" dialogButtonBusyLabel="Disabling..." disabled={false} onDeleteClick={() => this.disableAutomaticReleaseCreation()}>
                <p>Are you sure you want to disable Automatic Release Creation?</p>
            </OpenDeleteDialogButton>);
        if (!this.props.builtInPackageRepositoryInUse) {
            return (<>
                    <p>
                        There are no steps using the built-in package repository. At least one step must use a package from the <InternalLink to={routeLinks.library.builtInRepository.root}>built-in package repository</InternalLink> for Automatic
                        Release Creation to work.
                    </p>
                    {learnMore}
                </>);
        }
        const project = this.props.project;
        if (!project.AutoCreateRelease) {
            return (<>
                    <p>Create a release when a package is pushed to the built-in package repository.</p>
                    {learnMore}
                    {setupButton}
                </>);
        }
        if (!project.ReleaseCreationStrategy || !project.ReleaseCreationStrategy.ReleaseCreationPackage) {
            return (<>
                    <p>Create a release when a package is pushed to the built-in package repository.</p>
                    {learnMore}
                    {setupButton}
                    {disableButton}
                </>);
        }
        const step = this.props.actionPackages.find((a) => {
            // With our TinyType implementation, server will convert the release creation package
            // reference from "" to null, and breaks this lookup (the package list keeps them as ""
            // as this is a meaningful value in the server code). Convert back to empty string so
            // we can do the lookup.
            const nullAsEmptyPackageReferenceIndex = a.PackageReference ?? "";
            const nullAsEmptyReleaseCreationPackageReference = this.props.project.ReleaseCreationStrategy.ReleaseCreationPackage.PackageReference ?? "";
            return a.DeploymentAction === this.props.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction && nullAsEmptyPackageReferenceIndex === nullAsEmptyReleaseCreationPackageReference;
        });
        const stepName = step ? displayName(step) : "Not found";
        const channel = this.props.channels.find((c) => c.Id === this.props.project.ReleaseCreationStrategy.ChannelId);
        const channelName = channel ? channel.Name : "Not found";
        return (<>
                <p>Create a release when a package is pushed to the built-in package repository for:</p>
                <p>
                    Package Step: <strong>{stepName}</strong>
                </p>
                <p>
                    Channel: <strong>{channelName}</strong>
                </p>
                <div>
                    <ActionList actions={[setupButton, disableButton]} alignStart={true}/>
                </div>
            </>);
    }
    private async disableAutomaticReleaseCreation() {
        const project: ProjectResource = {
            ...this.props.project,
            AutoCreateRelease: false,
            ReleaseCreationStrategy: {
                ReleaseCreationPackage: null!,
                ChannelId: null!,
            },
        };
        const result = await repository.Projects.save(project);
        this.onProjectSaved(project);
        return true;
    }
    private onProjectSaved(project: ProjectResource) {
        this.props.onProjectUpdated(project);
    }
    static displayName = "SideBar";
}
