import React from "react";
import { Switch, type RouteComponentProps } from "react-router";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import AddGitHubConnection from "./AddGitHubConnection";
import AddGitHubConnectionSelectRepositories from "./AddGitHubConnectionSelectRepositories";
import GitConnectionList from "./GitConnectionList";
import GitHubConnection from "./GitHubConnection";
const GitHubAppConnectionPage = withPage({ page: pageIds.library.gitConnections.getGitHub })(GitHubConnection);
const AddGitHubAppConnectionPage = withPage({ page: pageIds.library.gitConnections.addGitHub })(AddGitHubConnection);
const AddGitHubAppConnectionSelectRepositoriesPage = withPage({ page: pageIds.library.gitConnections.addGitHubSelectRepositories })(AddGitHubConnectionSelectRepositories);
const GitConnectionListPage = withPage({ page: pageIds.library.gitConnections.root })(GitConnectionList);
export class GitConnectionRoutes extends React.Component<RouteComponentProps> {
    render() {
        const libraryRouteLinks = routeLinks.library;
        return (<Switch>
                <ReloadableRoute path={libraryRouteLinks.gitConnections.getGitHubConnection(":connectionId")} component={GitHubAppConnectionPage}/>
                <ReloadableRoute path={libraryRouteLinks.gitConnections.addGitHubConnectionSelectRepositories(":installationId")} component={AddGitHubAppConnectionSelectRepositoriesPage}/>
                <ReloadableRoute path={libraryRouteLinks.gitConnections.addGitHubConnection} component={AddGitHubAppConnectionPage}/>
                <ReloadableRoute component={GitConnectionListPage}/>
            </Switch>);
    }
    static displayName = "GitConnectionRoutes";
}
export default GitConnectionRoutes;
