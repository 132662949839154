import { css } from "@emotion/css";
import { LinearProgress } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import type { ReactNode } from "react";
import type { BusyState } from "../BusyFromPromise/BusyFromPromise";
import BusyFromPromise from "../BusyFromPromise/BusyFromPromise";
import type { Errors } from "../DataBaseComponent";
import ErrorPanel from "../ErrorPanel";
import KeyboardHandler, { Key } from "../KeyboardHandler";
export interface OnboardingDialogLayoutProps {
    title: ReactNode;
    children: ReactNode;
    actions: ReactNode;
    errors?: Errors | null;
    busy?: BusyState | null;
    onCloseDialog?: () => void;
}
export function OnboardingDialogLayout({ title, children, actions, errors, busy, onCloseDialog }: OnboardingDialogLayoutProps) {
    const handleEscKeyPressed = () => {
        if (!onCloseDialog)
            return false;
        onCloseDialog();
        return true;
    };
    const keyboardRegistrations = [{ key: Key.Esc, onKeyPressed: handleEscKeyPressed }];
    return (<KeyboardHandler registrations={keyboardRegistrations} className={styles.layout}>
            <BusyFromPromise promise={busy}>
                {(busy: boolean) => (<div className={styles.progress}>
                        <LinearProgress variant={"indeterminate"} show={busy}/>
                    </div>)}
            </BusyFromPromise>
            <div className={styles.content}>
                {title}
                {errors && <ErrorPanel message={errors.message} errors={errors.errors} scrollToPanel={false}/>}
                {children}
                <div className={styles.actionsContainer}>
                    <div className={styles.actions}>{actions}</div>
                </div>
            </div>
        </KeyboardHandler>);
}
const styles = {
    layout: css({
        display: "flex",
        flexDirection: "column",
    }),
    progress: css({ position: "absolute", width: "100%", height: 4 }),
    content: css({
        display: "flex",
        flexDirection: "column",
        gap: space[16],
        margin: space[32],
        height: "100%",
    }),
    actionsContainer: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        flexGrow: 1,
    }),
    actions: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: space[8],
    }),
};
