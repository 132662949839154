/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { DynamicWorkerPoolResource, WorkerPoolResource, FeedResource, WorkerToolsLatestImages } from "@octopusdeploy/octopus-server-client";
import { Permission, FeedType, WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { isRunOnBuiltInWorker, isRunOnServerOrWorkerPool } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import type { RunOn, RunOnServerOrWorkerPool } from "~/areas/projects/components/Process/types";
import { client } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { noFeedsWarning, possibleFeeds } from "~/components/PackageSelector/PackageSelector";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { getDefaultFeedId } from "~/components/Selectors/FeedSelector";
import { Note, Text } from "~/components/form";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import SelectWithAddRefresh from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import callAll from "~/utils/callAll";
import routeLinks from "../../routeLinks";
import isBound from "../form/BoundField/isBound";
export enum StepExecutionOption {
    RunDirectlyOnWorker = "RunDirectlyOnWorker",
    RunInsideContainerOnWorker = "RunInsideContainerOnWorker"
}
const BoundDebounceText = withBoundField(DebounceValue(Text));
interface ExecutionContainerImageSelectorProps {
    workerPools: WorkerPoolResource[];
    runOn: RunOnServerOrWorkerPool;
    feeds: FeedResource[];
    autoFocus?: boolean;
    feedError?: string;
    feedSelectLabel?: string;
    imageNameError?: string;
    workerPoolId: string | undefined;
    refreshFeeds(): Promise<void>;
    resetContainer(runOn: RunOnServerOrWorkerPool): void;
    onImageNameChange(value: string): void;
    onFeedChange(id: string | undefined): void;
    onStepExecutionOptionChange?(option: StepExecutionOption): void;
    containerImageRecommendation?: JSX.Element[];
    doBusyTask: DoBusyTask;
}
const ExecutionContainerImageSelector: React.FC<ExecutionContainerImageSelectorProps> = (props) => {
    const { feeds: providedFeeds, autoFocus = false, feedError, feedSelectLabel = "Container Registry", refreshFeeds, onFeedChange, onImageNameChange, onStepExecutionOptionChange, resetContainer, runOn, imageNameError, workerPoolId, workerPools, } = props;
    const feedType = [FeedType.Docker, FeedType.AwsElasticContainerRegistry, FeedType.AzureContainerRegistry, FeedType.GoogleContainerRegistry];
    const feeds = possibleFeeds(providedFeeds, feedType);
    //TODO: SM - this will eventually become container.Feed, but for now we are keeping this.
    const defaultFeedKey = runOn.container.FeedId ?? getDefaultFeedId(feeds);
    const defaultImageName = runOn.container.Image ?? "";
    const [feedKey, setFeedKey] = React.useState(defaultFeedKey);
    const [imageName, setImageName] = React.useState(defaultImageName);
    const [stepExecutionOption, setStepExecutionOption] = React.useState<StepExecutionOption>(runOn.runningInContainer ? StepExecutionOption.RunInsideContainerOnWorker : StepExecutionOption.RunDirectlyOnWorker);
    const [latestWindows2019Image, setLatestWindows2019Image] = React.useState("");
    const [latestWindows2022Image, setLatestWindows2022Image] = React.useState("");
    const [latestUbuntu2204Image, setLatestUbuntu2204Image] = React.useState("");
    const isWindows2016CloudWorkerPool = React.useMemo(() => {
        return isWindows2016CloudWorker(workerPoolId, workerPools);
    }, [workerPoolId, workerPools]);
    const runInsideContainer = stepExecutionOption === StepExecutionOption.RunInsideContainerOnWorker;
    const noFeeds = feeds == null || feeds.length === 0;
    useDoBusyTaskEffect(props.doBusyTask, async () => {
        if (runInsideContainer) {
            await findLatestWorkerToolsImageTag(setLatestWindows2019Image, setLatestWindows2022Image, setLatestUbuntu2204Image);
        }
    }, [runInsideContainer]);
    if (!runOn.container.FeedId && defaultFeedKey && runInsideContainer) {
        onFeedChange(defaultFeedKey);
    }
    if (!runOn.container.Image && imageName && runInsideContainer) {
        onImageNameChange(imageName);
    }
    const runningInContainer = Boolean(isRunningInContainer(runOn) || (feedKey && imageName));
    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });
    const feedEditPermissionGranted = isAllowed({ permission: Permission.FeedEdit, wildcard: true });
    const runningOnBuiltInWorker = isRunOnBuiltInWorker(runOn);
    return (<>
            {actionContainersEarlyAccessCallout()}

            <RadioButtonGroup value={stepExecutionOption} onChange={(option) => {
            setStepExecutionOption(option);
            if (onStepExecutionOptionChange) {
                onStepExecutionOptionChange(option);
            }
        }}>
                <RadioButton value={StepExecutionOption.RunDirectlyOnWorker} label={`Runs directly on ${runningOnBuiltInWorker ? "Octopus Server" : "a worker"}`} isDefault={true}/>
                <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} will need the required dependencies pre-installed.</Note>

                <RadioButton value={StepExecutionOption.RunInsideContainerOnWorker} label={`Runs inside a container, on ${runningOnBuiltInWorker ? "the Octopus Server" : "a worker"}`}/>
                <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} will need Docker installed</Note>

                {!feedViewPermissionGranted ? feedViewPermissionMissingCallout(runningInContainer) : ""}

                {runInsideContainer && isWindows2016CloudWorkerPool ? dockerNotInstalledOnWindows2016WorkersWarning() : ""}

                {runInsideContainer && noFeeds && feedViewPermissionGranted ? (noFeedsWarning(feedType, refreshFeeds)) : runInsideContainer && feedViewPermissionGranted ? (<div>
                        <SelectWithAddRefresh value={feedKey} onChange={callAll(setFeedKey, onFeedChange)} items={feeds.map((f) => ({ value: f.Id, text: f.Name }))} error={feedError} autoFocus={autoFocus} label={feedSelectLabel} addUrl={`#${routeLinks.library.feeds}`} onRequestRefresh={refreshFeeds} {...(!feedEditPermissionGranted
            ? {
                disableAdd: true,
                addButtonTooltipText: "FeedEdit permissions required to add or edit a new feed",
            }
            : {})}/>
                        <BoundDebounceText variableLookup={{
                localNames: null!,
            }} debounceDelay={500} placeholder="Enter the image, including the tag" value={imageName} onChange={callAll(setImageName, onImageNameChange)} error={imageNameError}/>
                        {props.containerImageRecommendation ? <Note>{props.containerImageRecommendation}</Note> : actionContainersHelp(latestWindows2019Image, latestWindows2022Image, latestUbuntu2204Image, setImageName, onImageNameChange)}
                    </div>) : ("")}
            </RadioButtonGroup>
        </>);
};
ExecutionContainerImageSelector.displayName = "ExecutionContainerImageSelector"
const feedViewPermissionMissingCallout = (isRunningInContainerCheck: boolean) => {
    return (<Callout type={CalloutType.Warning} title={`FeedView Permission required`}>
            <div>You will need FeedView permission to {isRunningInContainerCheck ? "edit selected container and/or feed" : "enable running inside a container"}</div>
            <ExternalLink href="spaces-and-permissions">System and Space Permissions</ExternalLink>{" "}
        </Callout>);
};
const actionContainersEarlyAccessCallout = () => {
    return (<Note>
            If you have any feedback on execution containers, <ExternalLink href="ActionContainersFeedbackForm">please let us know</ExternalLink>.
        </Note>);
};
const actionContainersHelp = (latestWindows2019Image: string, latestWindows2022Image: string, latestUbuntu2204Image: string, setImageName: (image: string) => void, onImageNameChange: (value: string) => void) => {
    return (<div>
            <Note>The image should include the tag.</Note>

            <Note>
                Learn more about <ExternalLink href="ExecutionContainersWhichImage">which image you can use</ExternalLink>.
            </Note>

            <Note>
                The <ExternalLink href="WorkerToolsDockerHub">octopusdeploy/worker-tools</ExternalLink> images contain many tools commonly used in deployments.
                <ul>
                    {latestUbuntu2204Image && (<li>
                            <a href="#" onClick={(e) => setImageOnClick(e, latestUbuntu2204Image, setImageName, onImageNameChange)}>
                                Use latest Ubuntu 22.04 based image
                            </a>
                            : <code>{latestUbuntu2204Image}</code>
                        </li>)}
                    {latestWindows2022Image && (<li>
                            <a href="#" onClick={(e) => setImageOnClick(e, latestWindows2022Image, setImageName, onImageNameChange)}>
                                Use latest Windows 2022 based image
                            </a>
                            : <code>{latestWindows2022Image}</code>
                        </li>)}
                    {latestWindows2019Image && (<li>
                            <a href="#" onClick={(e) => setImageOnClick(e, latestWindows2019Image, setImageName, onImageNameChange)}>
                                Use latest Windows 2019 based image
                            </a>
                            : <code>{latestWindows2019Image}</code>
                        </li>)}
                </ul>
            </Note>
        </div>);
};
const dockerNotInstalledOnWindows2016WorkersWarning = () => {
    return (<Callout title="Docker not available" type={CalloutType.Warning}>
            Docker is not installed on Windows 2016 hosted workers. Attempting to use execution containers on this worker pool will fail. Please select a different <ExternalLink href="DynamicWorkerPools">worker pool</ExternalLink>.
        </Callout>);
};
const findLatestWorkerToolsImageTag = async (setLatestWindows2019Image: (image: string) => void, setLatestWindows2022Image: (image: string) => void, setLatestUbuntu2204Image: (image: string) => void) => {
    const latestImages = (await client.get(client.getLink("WorkerToolsLatestImages"))) as WorkerToolsLatestImages;
    const latestWindows2019Image = latestImages["windows.ltsc2019"];
    const latestWindows2022Image = latestImages["windows.ltsc2022"];
    const latestUbuntu2204Image = latestImages["ubuntu.22.04"];
    if (!!latestWindows2019Image) {
        setLatestWindows2019Image(latestWindows2019Image);
    }
    if (!!latestWindows2022Image) {
        setLatestWindows2022Image(latestWindows2022Image);
    }
    if (!!latestUbuntu2204Image) {
        setLatestUbuntu2204Image(latestUbuntu2204Image);
    }
};
function isDynamicWorkerPool(resource: WorkerPoolResource): resource is DynamicWorkerPoolResource {
    return resource.WorkerPoolType === WorkerPoolType.Dynamic;
}
const isWindows2016CloudWorker = (workerPoolId: string | undefined, workerPools: WorkerPoolResource[]) => {
    if (!workerPoolId || isBound(workerPoolId))
        return false;
    const workerPool = workerPools.find((workerPool) => workerPool.Id === workerPoolId);
    return !!workerPool && isDynamicWorkerPool(workerPool) && workerPool.WorkerType === "Windows2016";
};
export const isRunningInContainer = (runOn: RunOn): boolean => {
    if (!isRunOnServerOrWorkerPool(runOn)) {
        return false;
    }
    if (!runOn.runningInContainer) {
        return false;
    }
    if (runOn.container.FeedId === null || runOn.container.Image === null) {
        return false;
    }
    return true;
};
const setImageOnClick = (e: React.MouseEvent<Element, MouseEvent>, image: string, setImage: (image: string) => void, onImageNameChange: (value: string) => void) => {
    e.preventDefault();
    setImage(image);
    onImageNameChange(image);
};
export default ExecutionContainerImageSelector;
