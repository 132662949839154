import { Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { StyledMaterialChip } from "./StyledMaterialChip";
import type { CommonChipProps } from "./types";
interface ChipProps extends CommonChipProps {
    onClick?: (event: object) => void;
    backgroundColorOnHover?: string;
}
export const Chip: React.FC<ChipProps> = (props) => {
    const label = props.noTooltip ? props.children : <Tooltip content={<span>{props.description || props.children}</span>}>{props.children}</Tooltip>;
    return (<StyledMaterialChip backgroundColor={props.backgroundColor} labelColor={props.labelColor} noMargin={props.noMargin} markAsRemoved={props.markAsRemoved} borderColor={props.borderColor} backgroundColorOnHover={props.backgroundColorOnHover} fullWidth={props.fullWidth} icon={props.icon} label={label} onClick={props.onClick} tabIndex={props.tabIndex} variant={props.variant} accessibilityRole={props.accessibilityRole}/>);
};
Chip.displayName = "Chip"
export default Chip;
