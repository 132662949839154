import { ProcessType } from "@octopusdeploy/octopus-server-client";
import type { LegacyRouteResolver, PageRouteDefinition, ParsedQueryParams, UnknownPageRouteDefinition } from "@octopusdeploy/portal-routes";
import { projectCommonVariableTemplatesRoute, projectVariablesLibraryRoute, projectVariableTemplatesRoute, projectBranchVariablesRoute, projectBranchVariablesPreviewRoute, projectVariablesPreviewRoute, projectVariablesRoute, projectAllVariablesRoute, projectBranchAllVariablesRoute, projectBranchDeploymentProcessStepsRoute, projectDeploymentProcessStepsRoute, projectDeploymentChannelsCreateRoute, projectDeploymentChannelsEditRoute, projectDeploymentChannelsRoute, projectDeploymentOverviewRoute, projectBranchDeploymentSettingsRoute, projectDeploymentSettingsRoute, projectDeploymentProcessRoute, projectBranchDeploymentProcessRoute, gitRoute, licenseRoute, projectsRoute, projectTasksRoute, allRoutes, } from "@octopusdeploy/portal-routes";
import Projects from "app/areas/projects/components/Projects/Projects";
import * as React from "react";
import GitPage from "~/areas/configuration/components/Git/Git";
import { License as LicensePage } from "~/areas/configuration/components/License/License";
import { ChannelsWithMatch } from "~/areas/projects/components/Channels/Channels";
import { Edit as EditChannel } from "~/areas/projects/components/Channels/Edit";
import DeploymentProcessSettings from "~/areas/projects/components/DeploymentProcessSettings/DeploymentProcessSettings";
import DeploymentsOverview from "~/areas/projects/components/DeploymentsOverview";
import { ProcessListLayout, ProcessSteps } from "~/areas/projects/components/Process/Pages";
import ProjectTasks from "~/areas/projects/components/ProjectTasks/ProjectTasks";
import AllVariables from "~/areas/projects/components/Variables/AllVariables";
import CommonVariableTemplates from "~/areas/projects/components/Variables/CommonVariableTemplates";
import LibraryVariableSets from "~/areas/projects/components/Variables/LibraryVariableSets/LibraryVariableSets";
import ProjectVariableTemplates from "~/areas/projects/components/Variables/ProjectVariableTemplates";
import ProjectVariables from "~/areas/projects/components/Variables/ProjectVariables/ProjectVariables";
import VariablePreview from "~/areas/projects/components/Variables/VariablePreview/VariablePreview";
import routeLinks from "~/routeLinks";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { PageGroupRegistration, PageGroupRegistrationDefinition, PagesConstraint, RouteSegmentsConstraint } from "~/routing/pageRegistrations/createPageMap";
import { createPageMap } from "~/routing/pageRegistrations/createPageMap";
import { createConfigurationPageRegistration } from "./ConfigurationPageRegistration";
import { createProjectPageRegistration } from "./ProjectPageRegistration";
const systemPages = createPageMap(allRoutes.system, {
    pages: [],
    partialRoute: (r) => r,
    childGroups: {
        configuration: {
            partialRoute: (r) => r,
            childGroups: {},
            pages: {
                licensePage: (r) => createConfigurationPageRegistration(licenseRoute, () => <LicensePage />, { pageArea: "Configuration", pageId: "Configuration.License", pageName: "License" }, () => routeLinks.configuration.license),
                gitPage: (r) => createConfigurationPageRegistration(gitRoute, (PageLayout) => <GitPage PageLayout={PageLayout}/>, { pageArea: "Configuration", pageId: "Configuration.Git", pageName: "Git" }, () => routeLinks.configuration.git),
            },
        },
    },
});
export const allPages = {
    system: systemPages,
};
//These are experimental routes and won't have any impact on existing routes as we define them today.
//The better portal experience team is experimenting with adding an abstraction layer for our routing.
const configurationPages = [
    createConfigurationPageRegistration(licenseRoute, () => <LicensePage />, { pageArea: "Configuration", pageId: "Configuration.License", pageName: "License" }, () => routeLinks.configuration.license),
    createConfigurationPageRegistration(gitRoute, (layout) => <GitPage PageLayout={layout}/>, { pageArea: "Configuration", pageId: "Configuration.Git", pageName: "Git" }, () => routeLinks.configuration.git),
];
const projectPages = [
    createProjectPageRegistration(projectsRoute, ({ spaceId }) => <Projects spaceId={spaceId}/>, { pageArea: "Project", pageName: "Projects", pageId: "Projects" }, ({ spaceId }, queryParams) => {
        if (queryParams?.newProjectGroupId) {
            return `${routeLinks.forSpace(spaceId).projects.root}?newProjectGroupId=${queryParams.newProjectGroupId}`;
        }
        if (queryParams?.projectGroupId) {
            return routeLinks.forSpace(spaceId).projects.filteredByGroup(queryParams.projectGroupId);
        }
        return routeLinks.forSpace(spaceId).projects.root;
    }),
    createProjectPageRegistration(projectBranchDeploymentProcessRoute, () => <ProcessListLayout processType={ProcessType.Deployment}/>, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deploymentProcess.root),
    createProjectPageRegistration(projectBranchDeploymentProcessStepsRoute, () => <ProcessSteps processType={ProcessType.Deployment}/>, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, branchName, projectSlug }) => `${routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deploymentProcess.root}/steps`),
    createProjectPageRegistration(projectBranchDeploymentSettingsRoute, () => <DeploymentProcessSettings />, { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deploymentSettings),
    createProjectPageRegistration(projectBranchVariablesRoute, () => <ProjectVariables />, { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).variables.root),
    createProjectPageRegistration(projectBranchAllVariablesRoute, () => <AllVariables />, { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).variables.all),
    createProjectPageRegistration(projectBranchVariablesPreviewRoute, () => <VariablePreview />, { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).variables.preview),
    createProjectPageRegistration(projectDeploymentOverviewRoute, () => <DeploymentsOverview />, { pageArea: "Project", pageId: "Project.Overview", pageName: "Overview" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.root),
    createProjectPageRegistration(projectDeploymentProcessRoute, () => <ProcessListLayout processType={ProcessType.Deployment}/>, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.process.root),
    createProjectPageRegistration(projectDeploymentProcessStepsRoute, () => <ProcessSteps processType={ProcessType.Deployment}/>, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, projectSlug }) => `${routeLinks.forSpace(spaceId).project(projectSlug).deployments.process.root}/steps`),
    createProjectPageRegistration(projectDeploymentSettingsRoute, () => <DeploymentProcessSettings />, { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.settings),
    createProjectPageRegistration(projectDeploymentChannelsRoute, () => <ChannelsWithMatch />, { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.channels),
    createProjectPageRegistration(projectDeploymentChannelsCreateRoute, () => <EditChannel create={true}/>, { pageArea: "Project", pageId: "Project.ChannelNew", pageName: "Add New Channels" }, ({ spaceId, projectSlug }) => `${routeLinks.forSpace(spaceId).project(projectSlug).deployments.channels}/create`),
    createProjectPageRegistration(projectDeploymentChannelsEditRoute, ({ channelId }) => <EditChannel create={false} channelId={channelId}/>, { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" }, ({ spaceId, projectSlug, channelId }) => `${routeLinks.forSpace(spaceId).project(projectSlug).deployments.channels}/edit/${channelId}`),
    createProjectPageRegistration(projectVariablesRoute, () => <ProjectVariables />, { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.root),
    createProjectPageRegistration(projectAllVariablesRoute, () => <AllVariables />, { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.all),
    createProjectPageRegistration(projectVariablesPreviewRoute, () => <VariablePreview />, { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.preview),
    createProjectPageRegistration(projectVariablesLibraryRoute, ({ projectSlug }) => <LibraryVariableSets projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.VariableLibrary", pageName: "Variables Library" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.library),
    createProjectPageRegistration(projectVariableTemplatesRoute, ({ projectSlug }) => <ProjectVariableTemplates projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.VariableTemplates", pageName: "Variables Templates" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.projectTemplates),
    createProjectPageRegistration(projectCommonVariableTemplatesRoute, ({ projectSlug }) => <CommonVariableTemplates projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.VariableCommonTemplates", pageName: "Common Variables Templates" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.commonTemplates),
    createProjectPageRegistration(projectTasksRoute, () => <ProjectTasks />, { pageArea: "Project", pageId: "Project.Tasks", pageName: "Tasks" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).tasks),
];
export const allPageRegistrations = {
    configurationPages,
    projectPages,
};
export function getPagesFromPageMap<RouteParams, ChildRouteSegments extends RouteSegmentsConstraint, ChildPages extends PagesConstraint, PageRegistrationDefinitions extends PageGroupRegistrationDefinition<RouteParams, ChildRouteSegments, ChildPages>>(pageMap: PageGroupRegistration<PageRegistrationDefinitions>): UnknownPageRegistration[] {
    const childGroupPages = Object.values(pageMap.childGroups).flatMap((childGroup) => getPagesFromPageMap(childGroup));
    const entries: UnknownPageRegistration[] = Object.values(pageMap.pages);
    return [...entries, ...childGroupPages];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistration = PageRegistration<any, any>;
export const legacyRouteResolver: LegacyRouteResolver = {
    resolve<RouteParams, QueryParams>(route: PageRouteDefinition<RouteParams, QueryParams>, routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>): string {
        const pages = [...getPagesFromPageMap(allPages.system), ...projectPages];
        const pageRegistration = pages.find((r) => {
            const unknownRoute: UnknownPageRouteDefinition = r.route;
            return unknownRoute === route;
        });
        if (!pageRegistration) {
            throw new Error(`No route registered for ${route.getDebugString()}`);
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const registeredRouteWithCorrectTypeArguments: PageRegistration<RouteParams, QueryParams> = pageRegistration as unknown as PageRegistration<RouteParams, QueryParams>;
        return registeredRouteWithCorrectTypeArguments.legacyRouteLink(routeParams, queryParams);
    },
};
