/* eslint-disable no-eq-null */
import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { TenantMissingVariableResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import type { Errors } from "../../../components/DataBaseComponent";
import DataLoader from "../../../components/DataLoader";
import ErrorPanel from "../../../components/ErrorPanel";
import NavigationSidebarLayout, { Navigation } from "../../../components/NavigationSidebarLayout";
import routeLinks from "../../../routeLinks";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import { tenantsActions } from "../tenantsArea";
import type { TenantState } from "../tenantsArea";
interface RouteProps {
    tenantId: string;
}
interface TenantLayoutInternalProps {
    tenant: TenantState;
}
const TenantLoader = DataLoader<{}>();
const TenantLayoutLoader: React.FC<RouteComponentProps<RouteProps>> = (props) => {
    const tenantState = useSelector<GlobalState, TenantState | null>((s) => s.tenantsArea.currentTenant);
    const dispatch = useDispatch();
    return (<TenantLoader load={async () => {
            if (tenantState?.id !== props.match.params.tenantId) {
                const tenant = await repository.Tenants.get(props.match.params.tenantId);
                onTenantFetched(tenant);
                const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
                onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
            }
            return {};
        }} operationName="TenantLayout" renderWhenLoaded={(_) => {
            if (tenantState == null || tenantState.id !== props.match.params.tenantId) {
                throw new Error("Tenant loaded and stored in redux does not exist, or does not match the current route");
            }
            return <TenantLayoutInternal tenant={tenantState}>{props.children}</TenantLayoutInternal>;
        }} renderAlternate={({ busy, errors }) => {
            return <TenantLoadingLayout busy={busy} errors={errors}/>;
        }}/>);
    function onTenantFetched(tenant: TenantResource) {
        dispatch(tenantsActions.tenantFetched(tenant));
    }
    function onTenantVariablesFetched(tenantMissingVariables?: TenantMissingVariableResource) {
        dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
    }
};
TenantLayoutLoader.displayName = "TenantLayoutLoader"
interface TenantLoadingLayoutProps {
    errors: Errors | undefined;
    busy: boolean;
}
const TenantLoadingLayout: React.FC<TenantLoadingLayoutProps> = ({ busy, errors }) => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const errorPanel = errors ? <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/> : undefined;
    const busyIndicator = <LinearProgress variant={"indeterminate"} show={busy}/>;
    if (isPageHeaderVNextEnabled) {
        return (<main>
                <PageHeaderPrimary title="Tenants"/>
                {busyIndicator}
                {errorPanel}
            </main>);
    }
    return (<main>
            <AreaTitle link={routeLinks.tenants} title="Tenants" busyIndicator={busyIndicator}/>
            {errorPanel}
        </main>);
};
TenantLoadingLayout.displayName = "TenantLoadingLayout"
const TenantLayoutInternal: React.FC<TenantLayoutInternalProps> = ({ tenant, children }) => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const variableLink = tenant.hasMissingVariables ? (<span>
            Variables <MissingVariablesIcon show={true}/>
        </span>) : ("Variables");
    const tenantLinks = routeLinks.tenant(tenant.id);
    const sidebarLinks = compact([
        Navigation.navItem("Overview", tenantLinks.overview),
        Navigation.navItem(variableLink, tenantLinks.variables().pathname),
        Navigation.navItem("Tasks", tenantLinks.tasks, undefined, {
            permission: Permission.TaskView,
            tenant: tenant.id,
        }),
        Navigation.navItem("Settings", tenantLinks.settings),
    ]);
    if (isPageHeaderVNextEnabled) {
        return (<main>
                <NavigationSideBarLayoutVNext navItems={sidebarLinks} content={children} description={tenant.description ?? undefined} header={<PageHeaderPrimary title={tenant.name} logo={{ href: tenant.logoUrl, accessibleName: `${tenant.name} logo}` }} navUpLink={{ label: "Tenants", linkHref: routeLinks.tenants }}/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={routeLinks.tenants} title="Tenants"/>
            <NavigationSidebarLayout logoUrl={tenant.logoUrl} name={tenant.name} navLinks={sidebarLinks} content={children} description={tenant.description ?? undefined}/>
        </main>);
};
TenantLayoutInternal.displayName = "TenantLayoutInternal"
const TenantLayout = withRouter(TenantLayoutLoader);
export default TenantLayout;
