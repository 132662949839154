/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { ClickAwayListener } from "@material-ui/core";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { OctopusError, ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import cn from "classnames";
import "codemirror/lib/codemirror.css";
import fuzzysort from "fuzzysort";
import * as React from "react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { CustomDialogActions, CustomFlexDialogContent } from "~/components/DialogLayout/Custom/index";
import type { IconButtonWithTooltipProps } from "~/components/IconButtonWithTooltip/index";
import { SupportedLanguage } from "~/components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { Note } from "~/components/form";
import InputLabel from "~/components/form/InputLabel/InputLabel";
import InsertVariableButton from "~/components/form/InsertVariableButton/InsertVariableButton";
import type { VariableLookupProps } from "~/components/form/VariableLookup/VariableLookup";
import { InsertVariableIcon } from "~/primitiveComponents/dataDisplay/Icon/index";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import CopyToClipboardButton from "../CopyToClipboardButton";
import type FormFieldProps from "../form/FormFieldProps";
import { CodeEditorSelect } from "./CodeEditorSelect";
import { ArrowDownLeftUpRightIcon } from "./Icons/ArrowDownLeftUpRightIcon";
import { ArrowUpRightDownLeftIcon } from "./Icons/ArrowUpRightDownLeftIcon";
import styles from "./styles.module.less";
const CodeMirror = require("@skidding/react-codemirror");
require("codemirror/mode/powershell/powershell");
require("codemirror/mode/javascript/javascript");
require("codemirror/mode/clike/clike");
require("codemirror/mode/mllike/mllike");
require("codemirror/mode/shell/shell");
require("codemirror/mode/xml/xml");
require("codemirror/mode/htmlmixed/htmlmixed");
require("codemirror/mode/css/css");
require("codemirror/mode/properties/properties");
require("codemirror/mode/coffeescript/coffeescript");
require("codemirror/mode/markdown/markdown");
require("codemirror/mode/dockerfile/dockerfile");
require("codemirror/mode/yaml/yaml");
require("codemirror/mode/python/python");
require("codemirror/lib/codemirror");
require("codemirror/addon/display/fullscreen");
require("codemirror/addon/display/placeholder");
require("codemirror/addon/fold/foldgutter");
require("codemirror/addon/fold/foldcode");
require("codemirror/addon/fold/brace-fold.js");
require("codemirror/addon/fold/xml-fold.js");
require("codemirror/addon/fold/indent-fold.js");
require("codemirror/addon/fold/markdown-fold.js");
require("codemirror/addon/fold/comment-fold.js");
require("codemirror/addon/hint/show-hint.css");
require("codemirror/addon/hint/show-hint.js");
export type CodeEditorLanguage = ScriptingLanguage[keyof ScriptingLanguage] | Language[keyof Language] | TextFormat[keyof TextFormat];
interface CodeEditorProps extends VariableLookupProps, FormFieldProps<string> {
    containerClassName?: string;
    onToggleFullScreen?: () => void;
    language: CodeEditorLanguage;
    allowFullScreen?: boolean;
    readOnly?: boolean;
    label?: string | JSX.Element;
    autoComplete?: Array<{
        display: string;
        code: string;
    }>;
    autoFocus?: boolean;
    autoExpand?: boolean;
    showToolbar?: boolean;
    scriptingLanguageSelectorOptions?: ScriptingLanguageSelectorOptions;
    showCopyButton?: boolean;
    showInsertVariableButton?: boolean;
    onEscPressed?(): void;
    children?(props: CommonToolbarButtonProps): React.ReactElement<any>;
    validate?(value: string): Promise<OctopusError> | OctopusError | Error | null;
}
export enum Language {
    HTML = "HTML",
    CSS = "CSS",
    Markdown = "Markdown",
    DockerFile = "DockerFile",
    INI = "INI",
    CoffeeScript = "CoffeeScript"
}
export enum TextFormat {
    JSON = "JSON",
    PlainText = "PlainText",
    XML = "XML",
    YAML = "YAML"
}
const helloWorldInLanguage = {
    [ScriptingLanguage.CSharp]: "Console.WriteLine(\"Hello World!\");",
    [ScriptingLanguage.FSharp]: "printfn \"Hello World!\"",
    [ScriptingLanguage.PowerShell]: "Write-Host \"Hello World!\"",
    [ScriptingLanguage.Python]: "print(\"Hello World!\")",
    [ScriptingLanguage.Bash]: "echo \"Hello World!\"",
};
const autoCompleteNote = () => {
    return (<Note className={styles.autocompleteNote}>
            Insert variables with <code>control</code> + <code>i</code>.&nbsp; Fuzzy search supported.
            <PopoverHelp trigger="click" placement={"top-end"}>
                <div style={{ textAlign: "left" }} className={styles.fuzzySearchTooltip}>
                    You can type things like <code>machineid</code> followed by <code>control</code> + <code>i</code> to quickly narrow down to <code>Octopus.Machine.Id</code>.
                    <br />
                    Or try <code>ospn</code> followed by <code>control</code> + <code>i</code> to insert <code>Octopus.Space.Name</code>.
                    <br />
                    You can also narrow the selection by typing in while the selection list is opened.
                </div>
            </PopoverHelp>
        </Note>);
};
export function languageToMode(language: CodeEditorLanguage) {
    switch (language) {
        case ScriptingLanguage.Bash:
            return "shell";
        case ScriptingLanguage.CSharp:
            return "text/x-csharp";
        case ScriptingLanguage.FSharp:
            return "text/x-fsharp";
        case ScriptingLanguage.Python:
            return "text/x-python";
        case TextFormat.JSON:
            return "application/json";
        case TextFormat.PlainText:
            return "null";
        case ScriptingLanguage.PowerShell:
            return "powershell";
        case TextFormat.XML:
            return "text/html";
        case TextFormat.YAML:
            return "text/x-yaml";
        case Language.CoffeeScript:
            return "application/vnd.coffeescript";
        case Language.CSS:
            return "text/css";
        case Language.DockerFile:
            return "text/x-dockerfile";
        case Language.HTML:
            return "text/html";
        case Language.INI:
            return "text/x-ini";
        case Language.Markdown:
            return "text/x-markdown";
        default:
            return "null";
    }
}
export interface CodeEditorElement {
    blur(): void;
}
export const CodeEditor = forwardRef<CodeEditorElement, CodeEditorProps>(({ containerClassName: containerClassNameProp, language, allowFullScreen, readOnly = false, label, autoComplete = [], autoFocus, onEscPressed, value, validate, onChange, children, autoExpand = false, syntax, localNames, showToolbar = true, showCopyButton = false, showInsertVariableButton = false, scriptingLanguageSelectorOptions, }, ref) => {
    const [containerClassName, setContainerClassName] = useState(styles.codeEditorContainer);
    const [focused, setFocused] = useState(false);
    const [protectFocus, setProtectFocus] = useState(false);
    const [isInFullScreen, setIsInFullScreen] = useState(false);
    const [errors, setErrors] = React.useState<OctopusError | Error | null>(null);
    const [sourceCode, setSourceCode] = React.useState<string>(value);
    let codeMirrorInstance: any = {};
    let cursorPosition: {
        line: number;
        ch: number;
    } | null = null;
    useImperativeHandle(ref, () => ({
        blur: () => {
            blur();
        },
    }));
    useEffect(() => {
        requestAnimationFrame(() => {
            //Code mirror has some issues with refreshing things, so queue things to focus and refresh as soon as we are done mounting
            //this fixes issues where the editor isn't rendered, focused or line numbers don't align.
            if (autoFocus) {
                focus();
            }
            codeMirrorInstance ? codeMirrorInstance.getCodeMirror().refresh() : noOp();
        });
        return () => {
            if (isInFullScreen) {
                toggleFullScreen();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const data = autoComplete;
    const options: {
        mode: string;
        lineNumbers: boolean;
        lineWrapping: boolean;
        extraKeys: {
            [id: string]: string | ((editor: any) => void);
        };
        readOnly: boolean | undefined;
        gutters: string[];
        foldOptions: {
            widget: string;
        };
        closeCharacters: RegExp;
        foldGutter: boolean;
        hintOptions: {
            async: boolean;
            hint: (editor: any, callback: any) => void;
        };
        placeholder: string;
    } = {
        mode: languageToMode(language),
        lineNumbers: true,
        lineWrapping: true,
        extraKeys: { "Ctrl-I": "autocomplete" },
        readOnly: readOnly,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        foldOptions: {
            widget: "...",
        },
        closeCharacters: /[\s;:,]/,
        foldGutter: true,
        placeholder: helloWorldInLanguage[language as ScriptingLanguage],
        hintOptions: {
            hint: (editor: any) => {
                const cur = editor.getCursor();
                const textBeforeCursor: string = editor.getLine(cur.line).substr(0, cur.ch);
                const wordStartIndex = textBeforeCursor.replace(/[^a-zA-Z0-9_#]/g, " ").lastIndexOf(" ") + 1;
                const wordBeforeCursor = textBeforeCursor.substr(wordStartIndex, cur.ch);
                const filter = wordBeforeCursor;
                if (filter) {
                    const results = fuzzysort.go(filter, data, {
                        limit: 100,
                        threshold: -10000,
                        key: "display",
                    });
                    return {
                        list: results.map((result) => ({
                            displayText: result.obj.display,
                            text: result.obj.code,
                            matches: fuzzysort.indexes(result).map((n) => n.valueOf()),
                            render: (elt: any, _: any, item: {
                                displayText: string;
                                text: string;
                                matches: number[];
                            }) => {
                                const hOpen = `<strong class="${styles.hintHighlight}">`;
                                const hClose = "</strong>";
                                let highlighted = "";
                                let matchesIndex = 0;
                                let opened = false;
                                const target = item.displayText;
                                const targetLen = target.length;
                                const matchesBest = item.matches;
                                for (let i = 0; i < targetLen; ++i) {
                                    const char = target[i];
                                    if (matchesBest[matchesIndex] === i) {
                                        ++matchesIndex;
                                        if (!opened) {
                                            opened = true;
                                            highlighted += hOpen;
                                        }
                                        if (matchesIndex === matchesBest.length) {
                                            highlighted += char + hClose + target.substr(i + 1);
                                            break;
                                        }
                                    }
                                    else {
                                        if (opened) {
                                            opened = false;
                                            highlighted += hClose;
                                        }
                                    }
                                    highlighted += char;
                                }
                                elt.innerHTML = highlighted;
                            },
                        })),
                        from: { line: cur.line, ch: wordStartIndex },
                        to: { line: cur.line, ch: cur.ch },
                    };
                }
                else {
                    return {
                        list: data.map((result) => ({
                            displayText: result.display,
                            text: result.code,
                        })) ?? [],
                        from: { line: cur.line, ch: wordStartIndex },
                        to: { line: cur.line, ch: cur.ch },
                    };
                }
            },
            async: false,
        },
    };
    if (allowFullScreen) {
        options.extraKeys["Esc"] = (cm: any) => {
            if (isInFullScreen) {
                toggleFullScreen();
            }
        };
    }
    // This one override the full screen rule as user wants to handle it explicitly
    if (onEscPressed) {
        options.extraKeys["Esc"] = () => {
            if (onEscPressed) {
                onEscPressed();
            }
        };
    }
    const val = value ? value : "";
    function focus() {
        if (codeMirrorInstance) {
            codeMirrorInstance.focus();
            if (cursorPosition) {
                codeMirrorInstance.getCodeMirror().setCursor(cursorPosition);
            }
        }
    }
    function blur() {
        if (codeMirrorInstance) {
            codeMirrorInstance.getCodeMirror().getInputField().blur();
        }
    }
    function insertAtCursor(value: string) {
        codeMirrorInstance.getCodeMirror().replaceSelection(value);
        cursorPosition = codeMirrorInstance.getCodeMirror().getCursor();
    }
    const onFocusChange = (focused: boolean) => {
        if (focused)
            setFocused(true);
        if (!focused && codeMirrorInstance) {
            cursorPosition = codeMirrorInstance.getCodeMirror().getCursor();
        }
    };
    const handleClickAway = () => {
        if (focused) {
            // eslint-disable-next-line no-empty
            if (codeMirrorInstance && codeMirrorInstance.getCodeMirror().hasFocus()) {
            }
            else if (!protectFocus) {
                setFocused(false);
            }
        }
    };
    const handleChange = (value: string) => {
        if (onChange) {
            onChange(value);
        }
    };
    const save = async () => {
        if (validate !== undefined) {
            const validationErrors = await validate(sourceCode);
            if (validationErrors) {
                setErrors(validationErrors);
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
    const toggleFullScreen = () => {
        setIsInFullScreen(!isInFullScreen);
    };
    const fullScreenDialog = () => {
        return (<CustomDialog open={isInFullScreen} close={toggleFullScreen} render={(customDialogRenderProps) => (<CustomSaveDialogLayout {...customDialogRenderProps} renderTitle={() => <></>} {...(errors && OctopusError.isOctopusError(errors) && errors.Errors ? { errors: { errors: errors.Errors.map((x: string) => x.toString()) ?? [], message: errors.ErrorMessage, fieldErrors: {}, details: {} } } : {})} onSaveClick={() => save()} renderActions={(renderProps) => (<CustomDialogActions className={styles.customDialogActions} actions={<ActionButton label="Close" className={styles.closeButton} onClick={async () => ((await renderProps.onSaveClick()) ? renderProps.close() : {})} type={ActionButtonType.Secondary}/>} additionalActions={<>
                                            {autoComplete?.length > 0 && (<div style={{ flexGrow: 0 }} className={styles.autocompleteNoteContainer}>
                                                    {autoCompleteNote()}
                                                </div>)}
                                        </>}/>)} renderContent={() => <CustomFlexDialogContent>{mainBody({ isInADialog: true })}</CustomFlexDialogContent>}/>)}/>);
    };
    const insert = (value: string) => {
        insertAtCursor(value);
        focus();
    };
    const mainBody = ({ isInADialog = false }) => {
        const childProps = {
            // Your IDE is lying, these ARE used!
            isInFullScreen,
            onToggleFullScreen: () => toggleFullScreen(),
            insert,
            onToolbarClick: () => {
                if (codeMirrorInstance && codeMirrorInstance.getCodeMirror().hasFocus())
                    setFocused(true);
            },
            onToolbarButtonClick: () => setFocused(true),
        };
        return (<React.Fragment>
                    {label && <InputLabel label={label}/>}
                    {showToolbar && (<CodeEditorToolbar {...childProps} scriptingLanguageSelector={scriptingLanguageSelectorOptions &&
                    ((Array.isArray(scriptingLanguageSelectorOptions.supportedLanguages) && scriptingLanguageSelectorOptions.supportedLanguages.length > 1) ||
                        scriptingLanguageSelectorOptions.supportedLanguages === SupportedLanguage.All) ? (<CodeEditorScriptingLanguageSelector scriptingLanguage={syntax ? (syntax as ScriptingLanguage) : (language as ScriptingLanguage | Language | TextFormat)} supportedLanguages={scriptingLanguageSelectorOptions.supportedLanguages} onScriptingLanguageChanged={scriptingLanguageSelectorOptions.onScriptingLanguageChanged} onClose={() => setProtectFocus(false)} onOpen={() => setProtectFocus(true)} {...childProps}/>) : undefined}>
                            {showCopyButton && <CodeEditorCopyToClipboardButton value={value} {...childProps}/>}
                            {showInsertVariableButton && <CodeEditorInsertVariableButton syntax={syntax as ScriptingLanguage} localNames={localNames} {...childProps}/>}
                            {allowFullScreen && <CodeEditorToggleFullScreenButton {...childProps}/>}
                        </CodeEditorToolbar>)}
                    <div className={cn(containerClassName, containerClassNameProp, { [styles.containerInADialog]: isInADialog, [styles.focused]: focused && autoExpand, [styles.noToolbar]: !showToolbar })}>
                        <CodeMirror ref={(codeEditorRef: any) => {
                if (!isInADialog || isInFullScreen)
                    codeMirrorInstance = codeEditorRef;
            }} className={cn(`${styles.codeMirror}`, { readonly: readOnly })} preserveScrollPosition={true} value={val} onFocusChange={(state: boolean) => {
                if (!isInADialog) {
                    onFocusChange(state);
                }
            }} onChange={handleChange} options={options}/>
                    </div>
                </React.Fragment>);
    };
    return (<>
                {isInFullScreen ? (fullScreenDialog()) : (<ClickAwayListener onClickAway={handleClickAway}>
                        <div className={styles.container}>
                            {mainBody({ isInADialog: false })}{" "}
                            {autoComplete.length > 0 && (<div className={styles.autocompleteNoteContainer} style={{ marginTop: "8px" }}>
                                    {autoCompleteNote()}
                                </div>)}
                        </div>
                    </ClickAwayListener>)}
            </>);
});
interface CommonToolbarButtonProps {
    onToggleFullScreen?: (value: boolean) => void;
    isInFullScreen?: boolean;
    insert?: (value: string) => void;
    onToolbarClick?: () => void;
    onToolbarButtonClick?: () => void;
}
interface ToolbarProps {
    children: React.ReactNode;
    scriptingLanguageSelector?: React.ReactElement<typeof CodeEditorScriptingLanguageSelector>;
}
const CodeEditorToolbar = (props: ToolbarProps & CommonToolbarButtonProps) => {
    return (<div className={cn({ [styles.toolbar]: true, [styles.containerInADialog]: props.isInFullScreen })} onClick={props.onToolbarClick}>
            {(props.isInFullScreen || props.scriptingLanguageSelector) && (<div className={styles.titleSection}>
                    {props.isInFullScreen && <div>Edit Source Code</div>}
                    {props.scriptingLanguageSelector && <div>{props.scriptingLanguageSelector}</div>}
                </div>)}
            <div className={styles.toolbarButtonsContainer}>{props.children}</div>
        </div>);
};
const CodeEditorCopyToClipboardButton = (props: {
    value: string;
} & CommonToolbarButtonProps) => {
    return (<CopyToClipboardButton value={props.value} showHoverTooltip={false}>
            <div className={styles.toolbarButton}>
                <ActionButton className={styles.toolbarIconButton} label="Copy to clipboard" icon={<em className={cn("fa", `fa-clone`, styles.fontAwesomeIcon)} aria-hidden="true"/>} iconPosition="left" aria-label={`Copy to clipboard`} type={ActionButtonType.Ternary}/>
            </div>
        </CopyToClipboardButton>);
};
type ScriptingLanguageSelectorOptions = {
    onScriptingLanguageChanged: (scriptingLanguage: ScriptingLanguage | Language | TextFormat) => void;
    supportedLanguages: (ScriptingLanguage | Language | TextFormat)[] | SupportedLanguage;
};
type ScriptingLanguageSelectorProps = CommonToolbarButtonProps & ScriptingLanguageSelectorOptions & {
    scriptingLanguage: ScriptingLanguage | Language | TextFormat;
    onClose?: () => void;
    onOpen?: () => void;
};
const CodeEditorScriptingLanguageSelector = (props: ScriptingLanguageSelectorProps) => {
    const getSupportedLanguages = (enumValue: SupportedLanguage): ScriptingLanguage[] => {
        return enumValue === SupportedLanguage.All
            ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash, ScriptingLanguage.CSharp, ScriptingLanguage.FSharp, ScriptingLanguage.Python]
            : enumValue === SupportedLanguage.PowerShellAndBash
                ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash]
                : [ScriptingLanguage.PowerShell];
    };
    const supportedLanguages = Array.isArray(props.supportedLanguages) ? props.supportedLanguages : getSupportedLanguages(props.supportedLanguages);
    return (<CodeEditorSelect supportedLanguages={supportedLanguages} scriptingLanguage={props.scriptingLanguage} onChange={(value) => {
            props.onScriptingLanguageChanged?.(value);
            props.onToolbarButtonClick?.();
        }} onFocus={() => props.onToolbarButtonClick?.()} onClose={props.onClose} onOpen={props.onOpen}></CodeEditorSelect>);
};
const CodeEditorToggleFullScreenButton = (props: CommonToolbarButtonProps) => {
    if (props.isInFullScreen) {
        return <></>;
    }
    return (<div className={styles.toolbarButton} onClick={() => props.onToggleFullScreen?.(!props.isInFullScreen)}>
            <ActionButton className={styles.toolbarIconButton} label={`${props.isInFullScreen ? "Exit" : "Enter"} full screen`} icon={props.isInFullScreen ? <ArrowDownLeftUpRightIcon className={styles.fontAwesomeIcon}/> : <ArrowUpRightDownLeftIcon className={styles.fontAwesomeIcon}/>} iconPosition="left" aria-label={`${props.isInFullScreen ? "Exit" : "Enter"} full screen`} type={ActionButtonType.Ternary}/>
        </div>);
};
const InsertVariableButtonInternal = ({ onClick }: IconButtonWithTooltipProps) => {
    return (<div className={styles.toolbarButton} onClick={onClick}>
            <ActionButton className={styles.toolbarIconButton} label="Insert variable" icon={<InsertVariableIcon className={styles.fontAwesomeIcon}/>} iconPosition="left" aria-label={`Insert variable`} type={ActionButtonType.Ternary}/>
        </div>);
};
interface InsertVariableButtonProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
}
const CodeEditorInsertVariableButton = (props: InsertVariableButtonProps & CommonToolbarButtonProps) => {
    return (<InsertVariableButton syntax={props.syntax} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }} localNames={props.localNames} onSelected={(v) => props.insert?.(v)} button={(buttonProps) => (<InsertVariableButtonInternal {...buttonProps} onClick={(e) => {
                props.onToolbarButtonClick?.();
                buttonProps.onClick?.(e);
            }}/>)} prompt={autoCompleteNote()}/>);
};
