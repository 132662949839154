import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { InsightsMetricsSplit } from "@octopusdeploy/octopus-server-client";
import type { TagSetResource, GetInsightsForReportBffResponse, InsightsTrendKey, InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React from "react";
import { useHistory, useLocation } from "react-router";
import type { AnalyticInsightsDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticInsightsDispatch } from "~/analytics/Analytics";
import type { InsightsCadence } from "~/areas/insights/insightsCadence";
import { insightsCadenceLookup, insightsCadenceOptions } from "~/areas/insights/insightsCadence";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import PaperLayout from "~/components/PaperLayout";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { Select } from "~/components/form";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { TrendIndicator } from "../TrendIndicator/TrendIndicator";
import type { InsightsReportFilter, InsightsReportQueryFilter } from "./reportMetricFilter";
import { insightsReportFilterToQuery, insightsReportQueryToFilter, getCurrentOrDefaultInsightsReportFilter } from "./reportMetricFilter";
import styles from "./styles.module.less";
function getSplitOptions(report: InsightsReportResource, tagSets: TagSetResource[]) {
    const options: DropdownMenuOption[] = [
        {
            value: InsightsMetricsSplit.None,
            text: "None",
        },
        {
            value: InsightsMetricsSplit.Project,
            text: "Project",
        },
        {
            value: InsightsMetricsSplit.ProjectGroup,
            text: "Project Group",
        },
        {
            value: InsightsMetricsSplit.Environment,
            text: "Environment",
        },
        {
            value: InsightsMetricsSplit.EnvironmentGroup,
            text: "Environment group",
        },
    ];
    if (report.TenantIds.length > 0 || report.TenantTags.length > 0) {
        options.push({
            value: InsightsMetricsSplit.Tenant,
            text: "Tenant",
        });
        tagSets.forEach((tagSet) => options.push({
            value: tagSet.Id,
            text: `Tag Set - ${tagSet.Name}`,
        }));
    }
    return options;
}
export interface ReportMetricsPageProps {
    report: InsightsReportResource;
    bffResponse: GetInsightsForReportBffResponse;
    split: InsightsMetricsSplit;
    cadence: InsightsCadence;
    busy: Promise<void> | undefined;
}
interface ReportMetricsLayoutProps {
    title: string;
    report: InsightsReportResource;
    children: (props: ReportMetricsPageProps) => React.ReactNode;
    trendKey?: InsightsTrendKey;
}
interface ReportMetricsDataLoaderProps extends ReportMetricsLayoutProps {
    report: InsightsReportResource;
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    dispatchAction: AnalyticInsightsDispatcher;
}
interface ReportMetricDataLoaderState {
    bffResponse: GetInsightsForReportBffResponse | null;
    tagSets: TagSetResource[];
    filter: InsightsReportFilter;
    queryFilter?: InsightsReportFilter;
}
interface ReportMetricLayoutProps {
    title: string;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    children: ReactNode;
    onSplitChange: (split: InsightsMetricsSplit | string) => void;
    onCadenceChange: (cadence: InsightsCadence) => void;
    report: InsightsReportResource;
    bffResponse: GetInsightsForReportBffResponse | null;
    trendKey?: InsightsTrendKey;
    split: InsightsMetricsSplit;
    cadence: InsightsCadence;
    tagSets: TagSetResource[];
    tenantTagSetId?: string;
}
const InsightsReportsQueryStringFilters = QueryStringFilters.For<InsightsReportFilter, InsightsReportQueryFilter>();
class ReportMetricsDataLoader extends DataBaseComponent<ReportMetricsDataLoaderProps, ReportMetricDataLoaderState> {
    constructor(props: ReportMetricsDataLoaderProps) {
        super(props);
        this.state = {
            bffResponse: null,
            tagSets: [],
            filter: getCurrentOrDefaultInsightsReportFilter(this.props.location.search),
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tagSetRequest = repository.TagSets.all();
            const cadenceDefinition = insightsCadenceLookup[this.state.filter.cadence];
            const bffResponseRequest = repository.InsightsReports.bff(this.props.report, this.state.filter.split, cadenceDefinition.timeRange, cadenceDefinition.granularity, this.state.filter.tenantTagSetId);
            this.setState({ bffResponse: await bffResponseRequest, tagSets: await tagSetRequest });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async onSplitChange(split: string) {
        const validatedSplitArgs = this.getValidatedSplitArgs(split);
        this.props.dispatchAction("Select Split", { action: Action.Select, inputField: "Split", split: validatedSplitArgs.split });
        this.setFilterState({ split: validatedSplitArgs.split, tenantTagSetId: validatedSplitArgs.tenantTagSetId });
        await this.getReportMetrics(validatedSplitArgs.split, this.state.filter.cadence, validatedSplitArgs.tenantTagSetId);
    }
    getValidatedSplitArgs(selectedOption: string) {
        // The selected option was a valid split
        if (Object.values(InsightsMetricsSplit).some((s) => s === selectedOption))
            return {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                split: selectedOption as InsightsMetricsSplit,
                tenantTagSetId: undefined,
            };
        // The selected option was a tagset
        if (this.state.tagSets.some((tagSet) => tagSet.Id === selectedOption))
            return {
                split: InsightsMetricsSplit.TenantTagSet,
                tenantTagSetId: selectedOption,
            };
        // Default case
        return {
            split: InsightsMetricsSplit.None,
            tenantTagSetId: undefined,
        };
    }
    async onCadenceChange(cadence: InsightsCadence) {
        this.props.dispatchAction("Select Cadence", { action: Action.Select, inputField: "Cadence", cadence });
        this.setFilterState({ cadence });
        await this.getReportMetrics(this.state.filter.split, cadence, this.state.filter.tenantTagSetId);
    }
    async getReportMetrics(split: InsightsMetricsSplit, cadence: InsightsCadence, tenantTagSetId?: string) {
        const { report } = this.props;
        const cadenceDefinition = insightsCadenceLookup[cadence];
        await this.doBusyTask(async () => {
            const bffResponse = await repository.InsightsReports.bff(report, split, cadenceDefinition.timeRange, cadenceDefinition.granularity, tenantTagSetId);
            this.setState({ bffResponse });
        }, { timeOperationOptions: timeOperationOptions.forRefresh() });
    }
    private setFilterState<K extends keyof InsightsReportFilter>(state: Pick<InsightsReportFilter, K>) {
        this.setState((prev) => ({
            filter: { ...prev.filter, ...state },
        }));
    }
    render() {
        const { filter, busy, bffResponse, tagSets } = this.state;
        const { title, report, trendKey } = this.props;
        return (<>
                <InsightsReportsQueryStringFilters filter={this.state.filter} getQuery={insightsReportFilterToQuery} getFilter={insightsReportQueryToFilter} onFilterChange={(filter) => this.setState({ filter })}/>
                <ReportMetricsLayoutInner title={title} busy={busy} errors={this.errors} split={filter.split} cadence={filter.cadence} onSplitChange={(s) => this.onSplitChange(s)} onCadenceChange={(c) => this.onCadenceChange(c)} report={report} bffResponse={bffResponse} trendKey={trendKey} tagSets={tagSets} tenantTagSetId={filter.tenantTagSetId}>
                    {bffResponse !== null && this.props.children({ report, bffResponse, cadence: filter.cadence, busy, split: filter.split })}
                </ReportMetricsLayoutInner>
            </>);
    }
    static displayName = "ReportMetricsDataLoader";
}
const checkMinimumSettings = (report: InsightsReportResource) => (report.ProjectIds.length > 0 || report.ProjectGroupIds.length > 0 || report.ChannelIds.length > 0) && report.EnvironmentGroups.some((g) => g.Environments.length > 0);
function ReportMetricsLayoutInner({ title, split, cadence, busy, errors, children, onCadenceChange, onSplitChange, report, bffResponse, tagSets, tenantTagSetId, trendKey }: ReportMetricLayoutProps) {
    const splitOptions = getSplitOptions(report, tagSets);
    const splitValue = split === InsightsMetricsSplit.TenantTagSet ? tenantTagSetId : split;
    const hasMinimumSettings = checkMinimumSettings(report);
    const hasData = bffResponse && bffResponse.Series.length !== 0;
    const getSectionControl = () => {
        if (!hasMinimumSettings)
            return <GoToSettingsButton report={report} type={ActionButtonType.Primary}/>;
        if (bffResponse !== null && trendKey)
            return <TrendIndicator trend={bffResponse[trendKey].OverallTrend} trendKey={trendKey}/>;
    };
    return (<PaperLayout title={title} busy={busy} errors={errors} disableStickyHeader={!errors} sectionControl={getSectionControl()}>
            {hasMinimumSettings && (<>
                    <section className={styles.filterSection}>
                        <div className={styles.selectWrap}>
                            <Select label="Split by" value={splitValue} items={splitOptions} sortItems={false} onChange={(val) => {
                if (val)
                    onSplitChange(val);
            }}/>
                        </div>
                        <div className={styles.selectWrap}>
                            <Select label="Date range" value={cadence} items={insightsCadenceOptions.map((o) => o)} sortItems={false} onChange={(val) => {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                if (val)
                    onCadenceChange(val as InsightsCadence);
            }}/>
                        </div>
                    </section>
                </>)}
            {bffResponse && !hasData ? <BlankPage report={report}/> : children}
        </PaperLayout>);
}
interface GoToSettingsButtonProps {
    report: InsightsReportResource;
    type: ActionButtonType;
}
function GoToSettingsButton({ report, type }: GoToSettingsButtonProps) {
    const history = useHistory();
    const reportLinks = routeLinks.insights.report(report.Id);
    const onSetupReport = () => {
        history.push(resolveStringPathWithSpaceId(reportLinks.settings, report.SpaceId));
    };
    return <ActionButton label="Set up report" onClick={onSetupReport} type={type}/>;
}
interface BlankPageProps {
    report: InsightsReportResource;
}
function BlankPage({ report }: BlankPageProps) {
    const hasMinimumSettings = checkMinimumSettings(report);
    if (!hasMinimumSettings)
        return (<OnboardingPage title="Set up this report to see data insights" intro="Set up this report by adding projects and environments." learnMore={null} actionButtons={<GoToSettingsButton report={report} type={ActionButtonType.Secondary}/>}/>);
    return <OnboardingPage title="Deploy releases to see Insights for this report" intro="There's no deployment data for the projects and environments configured for this report." learnMore={null}/>;
}
export function ReportMetricsLayout({ title, report, trendKey, children }: ReportMetricsLayoutProps) {
    const location = useLocation();
    const history = useHistory();
    const dispatchAction = useAnalyticInsightsDispatch();
    return (<ReportMetricsDataLoader title={title} location={location} history={history} report={report} dispatchAction={dispatchAction} trendKey={trendKey}>
            {(props) => children(props)}
        </ReportMetricsDataLoader>);
}
