/* eslint-disable @typescript-eslint/init-declarations */
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { DetailedServerLogs } from "~/areas/configuration/components/Diagnostics/DetailedServerLogs";
import { Diagnostics } from "~/areas/configuration/components/Diagnostics/Diagnostics";
import { AutoDeployLogs } from "~/areas/configuration/components/Diagnostics/Logs/AutoDeployLogs";
import { ScheduledDeployLogs } from "~/areas/configuration/components/Diagnostics/Logs/ScheduledDeployLogs";
import { SubscriptionLogs } from "~/areas/configuration/components/Diagnostics/Logs/SubscriptionLogs";
import Git from "~/areas/configuration/components/Git/Git";
import ConfigureLetsEncrypt from "~/areas/configuration/components/LetsEncrypt/ConfigureLetsEncrypt";
import LetsEncrypt from "~/areas/configuration/components/LetsEncrypt/LetsEncrypt";
import { License } from "~/areas/configuration/components/License/License";
import { Maintenance } from "~/areas/configuration/components/Maintenance/Maintenance";
import { Nodes } from "~/areas/configuration/components/Nodes/Nodes";
import { ServerConfigurationSettings } from "~/areas/configuration/components/Nodes/ServerConfigurationSettings";
import { Performance } from "~/areas/configuration/components/Performance/Performance";
import { SettingsEdit } from "~/areas/configuration/components/Settings/SettingsEdit";
import { Settings } from "~/areas/configuration/components/Settings/SettingsLayout";
import type { TestPermissionsProps } from "~/areas/configuration/components/TestPermissions/TestPermissions";
import { TestPermissions } from "~/areas/configuration/components/TestPermissions/TestPermissions";
import { Thumbprint } from "~/areas/configuration/components/Thumbprint/Thumbprint";
import { client } from "~/clientInstance";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { withPageFromProps } from "~/components/Page/WithPageFromProps";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { firstAuthorized } from "~/components/PermissionCheck/PermissionCheck";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import AuditArchiveLayout from "../AuditLayout/AuditArchiveLayout";
import AuditLayout from "../AuditLayout/AuditLayout";
import Backup from "../Backup/Backup";
import { Extensions } from "../Extensions/Extensions";
import FeaturesLayout from "../FeaturesLayout/FeaturesLayout";
import RoleEdit from "../Roles/RoleEdit";
import { Roles } from "../Roles/Roles";
import SmtpLayout from "../Smtp/Smtp";
import SpaceEdit from "../Spaces/SpaceEdit";
import Spaces from "../Spaces/Spaces";
import SubscriptionLayout from "../SubscriptionLayout/SubscriptionLayout";
import SubscriptionsLayout from "../SubscriptionsLayout/SubscriptionsLayout";
import { RedirectFromScopedRole } from "../Teams/RedirectFromScopedRole";
import TeamEdit from "../Teams/TeamEdit";
import type { TeamEditProps } from "../Teams/TeamEdit";
import Teams from "../Teams/Teams";
import TelemetryLayout from "../Telemetry/TelemetryLayout";
import { UserEdit, UserCreate } from "../Users/UserEdit";
import UserInvite from "../Users/UserInvite";
import Users from "../Users/Users";
export const AuditArchivePage = withPage({ page: pageIds.configuration.auditArchive })(AuditArchiveLayout);
export const AuditPage = withPage({ page: pageIds.configuration.auditBaseRoute })(AuditLayout);
export const BackupPage = withPage({ page: pageIds.configuration.backup })(Backup);
export const AutoDeployLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.autoDeploy })(AutoDeployLogs);
export const ScheduledDeployLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.scheduledDeploy })(ScheduledDeployLogs);
export const SubscriptionLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.subscription })(SubscriptionLogs);
export const DetailedServerLogsPage = withPage({ page: pageIds.configuration.diagnostics.logs.root })(DetailedServerLogs);
export const DiagnosticsPage = withPage({ page: pageIds.configuration.diagnostics.root })(Diagnostics);
export const FeaturesPage = withPage({ page: pageIds.configuration.features })(FeaturesLayout);
export const GitPage = withPage({ page: pageIds.configuration.git })(Git);
export const LicensePage = withPage({ page: pageIds.configuration.license })(License);
export const ConfigureLetsEncryptPage = withPage({ page: pageIds.configuration.letsEncrypt.configure })(ConfigureLetsEncrypt);
export const LetsEncryptPage = withPage({ page: pageIds.configuration.letsEncrypt.root })(LetsEncrypt);
export const MaintenancePage = withPage({ page: pageIds.configuration.maintenance })(Maintenance);
export const PerformancePage = withPage({ page: pageIds.configuration.performance })(Performance);
export const ServerConfigurationSettingsPage = withPage({ page: pageIds.configuration.nodes.serverSettings })(ServerConfigurationSettings);
export const NodesPage = withPage({ page: pageIds.configuration.nodes.root })(Nodes);
export const SmtpPage = withPage({ page: pageIds.configuration.smtp })(SmtpLayout);
export const CreateSubscriptionPage = withPage({ page: pageIds.configuration.subscriptions.create })(SubscriptionLayout);
export const SubscriptionPage = withPage({ page: pageIds.configuration.subscription })(SubscriptionLayout);
export const SubscriptionsPage = withPage({ page: pageIds.configuration.subscriptions.root })(SubscriptionsLayout);
export const TestPermissionPage = withPage({ page: pageIds.configuration.testPermission })(TestPermissions);
export const TestPermissionsPage = withPage({ page: pageIds.configuration.testPermissions })(TestPermissions);
export const ThumbprintPage = withPage({ page: pageIds.configuration.thumbprint })(Thumbprint);
export const RoleCreatePage = withPage({ page: pageIds.configuration.roles.create })(RoleEdit);
export const RoleEditPage = withPage({ page: pageIds.configuration.role })(RoleEdit);
export const RolesPage = withPage({ page: pageIds.configuration.roles.root })(Roles);
export const SpaceCreatePage = withPage({ page: pageIds.configuration.spaces.create })(SpaceEdit);
export const SpaceEditPage = withPage({ page: pageIds.configuration.space })(SpaceEdit);
export const SpacesPage = withPage({ page: pageIds.configuration.spaces.root })(Spaces);
export const TeamsPage = withPage({ page: pageIds.configuration.teams.root })(Teams);
export const SettingPage = withPageFromProps(SettingsEdit, (props) => ({ page: pageIds.configuration.setting(props.match.params.settingId).root }));
export const SettingsPage = withPage({ page: pageIds.configuration.settings.root })(Settings);
export const ExtensionsPage = withPage({ page: pageIds.configuration.extensions.root })(Extensions);
export const UserCreatePage = withPage({ page: pageIds.configuration.users.create })(UserCreate);
export const UserEditPage = withPage({ page: pageIds.configuration.user })(UserEdit);
export const UsersPage = withPage({ page: pageIds.configuration.users.root })(Users);
export const UserInvitePage = withPage({ page: pageIds.configuration.userInvites })(UserInvite);
export const EditTeamPage = withPage({ page: pageIds.configuration.team })(TeamEdit);
export const TelemetryPage = withPage({ page: pageIds.configuration.telemetry })(TelemetryLayout);
const ConfigurationRoutes: React.StatelessComponent = (props) => {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    let defaultRoute;
    switch (firstAuthorized([Permission.AdministerSystem, Permission.EventView, Permission.TeamView])) {
        case Permission.AdministerSystem:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.features}/>;
            break;
        case Permission.EventView:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.audit(client.spaceId)}/>;
            break;
        case Permission.TeamView:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.teams.root()}/>;
            break;
        default:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.spaces.root}/>;
    }
    const legacyConfigurationRoutes = isRoutingVNextEnabled
        ? []
        : [<ReloadableRoute key="git" path={routeLinks.configuration.git} render={() => <GitPage PageLayout={PaperLayout}/>}/>, <ReloadableRoute key="license" path={routeLinks.configuration.license} component={LicensePage}/>];
    return (<Switch>
            <ReloadableRoute path={routeLinks.configuration.auditArchive} component={AuditArchivePage}/>
            <ReloadableRoute path={routeLinks.configuration.auditBaseRoute} component={AuditPage}/>
            <ReloadableRoute path={routeLinks.configuration.backup} component={BackupPage}/>
            <ReloadableRoute path={routeLinks.configuration.v3route.certificates}>
                <InternalRedirect to={routeLinks.configuration.thumbprint}/>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.v3route.usersInvite}>
                <InternalRedirect to={routeLinks.configuration.userInvites}/>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.v3route.testPermissions}>
                <InternalRedirect to={routeLinks.configuration.testPermissions}/>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.autoDeploy} component={AutoDeployLogsPage}/>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.scheduledDeploy} component={ScheduledDeployLogsPage}/>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.subscription} component={SubscriptionLogsPage}/>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.root} component={DetailedServerLogsPage}/>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.logs.machineCleanup}>
                <InternalRedirect to={routeLinks.configuration.machineEventsForUser("users-system", "Deleted")}/>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.diagnostics.root} component={DiagnosticsPage}/>
            <ReloadableRoute path={routeLinks.configuration.extensions.root} component={ExtensionsPage}/>
            <ReloadableRoute path={routeLinks.configuration.features} component={FeaturesPage}/>
            <ReloadableRoute path={routeLinks.configuration.letsEncrypt.configure} component={ConfigureLetsEncryptPage}/>
            <ReloadableRoute path={routeLinks.configuration.letsEncrypt.root} component={LetsEncryptPage}/>
            <ReloadableRoute path={routeLinks.configuration.maintenance} component={MaintenancePage}/>
            <ReloadableRoute path={routeLinks.configuration.performance} component={PerformancePage}/>
            <ReloadableRoute path={routeLinks.configuration.nodes.serverSettings} component={ServerConfigurationSettingsPage}/>
            <ReloadableRoute path={routeLinks.configuration.nodes.root} component={NodesPage}/>
            <ReloadableRoute path={routeLinks.configuration.smtp} component={SmtpPage}/>
            <ReloadableRoute path={routeLinks.configuration.subscriptions.create()} render={(routeProps) => <CreateSubscriptionPage create {...routeProps}/>}/>
            <ReloadableRoute path={routeLinks.configuration.subscription(":subscriptionId")} component={SubscriptionPage}/>
            <ReloadableRoute path={routeLinks.configuration.subscriptions.root} component={SubscriptionsPage}/>
            <ReloadableRoute path={routeLinks.configuration.testPermissions}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.testPermission(":userId")} render={(routeProps: RouteComponentProps<TestPermissionsProps>) => <TestPermissionPage userId={routeProps.match.params.userId}/>}/>
                    <ReloadableRoute component={TestPermissionsPage}/>
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.thumbprint} component={ThumbprintPage}/>
            <ReloadableRoute path={routeLinks.configuration.roles.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.roles.create} component={RoleCreatePage}/>
                    <ReloadableRoute path={routeLinks.configuration.role(":roleId")} component={RoleEditPage}/>
                    <ReloadableRoute component={RolesPage}/>
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.spaces.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.spaces.create} component={SpaceCreatePage}/>
                    <ReloadableRoute path={routeLinks.configuration.space(":currentSpaceId")} component={SpaceEditPage}/>
                    <ReloadableRoute component={SpacesPage}/>
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.teams.baseRoute}>
                <Switch>
                    <ReloadableRoute exact={true} path={routeLinks.configuration.teams.redirect(":scopedRoleId")} component={RedirectFromScopedRole}/>
                    <ReloadableRoute path={routeLinks.configuration.team(":teamId")} render={(routeProps: RouteComponentProps<TeamEditProps>) => <EditTeamPage teamId={routeProps.match.params.teamId} {...routeProps}/>}/>
                    <ReloadableRoute component={TeamsPage}/>
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.settings.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.setting(":settingId")} component={SettingPage}/>
                    <ReloadableRoute component={SettingsPage}/>
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.users.root}>
                <Switch>
                    <ReloadableRoute path={routeLinks.configuration.users.create} render={() => <UserCreatePage />}/>
                    <ReloadableRoute path={routeLinks.configuration.user(":userId")} render={(routeProps: RouteComponentProps<{
        userId: string;
    }>) => <UserEditPage userId={routeProps.match.params.userId}/>}/>
                    <ReloadableRoute component={UsersPage}/>
                </Switch>
            </ReloadableRoute>
            <ReloadableRoute path={routeLinks.configuration.telemetry} component={TelemetryPage}/>
            <ReloadableRoute path={routeLinks.configuration.userInvites} component={UserInvitePage}/>
            {legacyConfigurationRoutes}
            <ReloadableRoute exact={true} path={routeLinks.configuration.root}>
                {defaultRoute}
            </ReloadableRoute>
            <RedirectAs404 />
        </Switch>);
};
ConfigurationRoutes.displayName = "ConfigurationRoutes"
export default ConfigurationRoutes;
