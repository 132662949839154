import { ActionButton } from "@octopusdeploy/design-system-components";
import type { InputPathToArray, InputPathToObject, InputPathToValue, ObjectInputPathsAndPathToObject } from "@octopusdeploy/step-inputs";
import type { InputObjectAccessor, ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import { createInputArrayAccessor, createInputValueAccessor, createObjectInputPaths, createObjectValueAccessor, getArrayFromArrayInputPath, getPathToArrayInput, getPathToInput, isBoundValue, isInputPathToValue, } from "@octopusdeploy/step-runtime-inputs";
import type { FormContent, ListComponent, StepInputComponent, NoteExpression } from "@octopusdeploy/step-ui";
import React, { useCallback, useContext, useEffect, useState } from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { ExpandableContainer } from "~/components/Expandable";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { createRenderedComponentAndSummaryForStepFactory, createSummaryFactory } from "~/components/StepPackageEditor/CreateRenderedComponentAndSummaryForStepFactory";
import { EditStepPackageInputs } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { getSchemaForInputArray } from "~/components/StepPackageEditor/Inputs/schemaTraversal";
import type { SetGitDependencies, StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { Drawer } from "~/primitiveComponents/dataDisplay/Drawer/Drawer";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import DialogOpener from "../../../../Dialog/DialogOpener";
import OkDialogLayout from "../../../../DialogLayout/OkDialogLayout";
import { Note } from "../../Note/Note";
import { mapInitialInputs } from "../../mapInitialInputs";
import styles from "./style.module.less";
const NestedDrawerContext = React.createContext(false);
export function getListSummary<StepInputs>(content: ListComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputArrayAccessor = createInputArrayAccessor<StepInputs, unknown>(content.input);
    const numberOfItems = inputArrayAccessor.getInputValue(inputs).length;
    if (numberOfItems === 0) {
        return "empty";
    }
    // todo-step-ui Add a set of summary chips of the individual items in the list
    if (numberOfItems === 1) {
        return { isDefaultValue: false, value: `${numberOfItems} item` };
    }
    return { isDefaultValue: false, value: `${numberOfItems} items` };
}
interface ListProps<StepInputs> {
    configuredStepUIProps: ListComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    setGitDependencies: SetGitDependencies;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    inputDependencies: StepInputDependencies;
    getFieldError: (name: string | PathToInput) => string;
    note?: NoteExpression[];
    doBusyTask: DoBusyTask;
}
type Item = ObjectRuntimeInputs<unknown>;
class UnknownRemoveItemList extends RemoveItemsList<Item> {
}
function inputPathsEqual<T, U>(aInputPath: InputPathToValue<T>, bInputPath: InputPathToValue<U>) {
    const a = getPathToInput(aInputPath);
    const b = getPathToInput(bInputPath);
    if (a.length !== b.length)
        return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
}
const SUPPORTED_SUMMARY_TYPES: string[] = ["text", "number"];
const SUMMARY_FIELDS_LIMIT = 3;
function getListItemFieldsSummary<ArrayItem, StepInputs>(listComponent: ListComponent, getSummary: (component: StepInputComponent) => InputSummary, inputItem: ObjectInputPathsAndPathToObject<ArrayItem>): ListItemFieldSummary[] {
    const { editItemForm, itemSummary } = listComponent;
    const { title: itemSummaryTitle, fields: itemSummaryFields } = itemSummary(inputItem);
    const formFields = editItemForm(inputItem).reduce(flattenSections, []);
    const summaryComponents = itemSummaryFields != undefined ? findComponentsForInputs(formFields, itemSummaryFields) : allComponentsExceptTitle(formFields, itemSummaryTitle);
    return summaryComponents
        .filter((component) => SUPPORTED_SUMMARY_TYPES.includes(component.type))
        .reduce<ListItemFieldSummary[]>((summaries, component) => [...summaries, toListItemSummaryProp(component)], [])
        .slice(0, SUMMARY_FIELDS_LIMIT);
    function toListItemSummaryProp(component: StepInputComponent): ListItemFieldSummary {
        const summary = getSummary(component);
        const value = summary !== "empty" ? summary.value : summary;
        return { label: component.label, value };
    }
}
function flattenSections(acc: StepInputComponent[], item: FormContent<StepInputComponent>): StepInputComponent[] {
    if (item.type == "section") {
        return [...acc, ...item.content];
    }
    if (item.type == "section group") {
        return [...acc, ...item.content.reduce(flattenSections, [])];
    }
    return [...acc, item];
}
function findComponentsForInputs(formFields: StepInputComponent[], itemSummaryFields: InputPathToValue<string | number | undefined>[]) {
    return itemSummaryFields.map((chosen) => formFields.find((field) => isInputPathToValue(field.input) && inputPathsEqual(field.input, chosen))).filter(<T>(value: T | undefined): value is T => value != undefined);
}
function allComponentsExceptTitle(formFields: StepInputComponent[], titleInputPath: InputPathToValue<string>) {
    return formFields.filter((component) => !(isInputPathToValue(component.input) && inputPathsEqual(component.input, titleInputPath)));
}
type ListItemSummaryProps = {
    fields: ListItemFieldSummary[];
};
type ListItemFieldSummary = {
    label: string;
    value: string;
};
function ListItemSummary(props: ListItemSummaryProps) {
    return (<>
            <div className={styles.itemSummary}>
                <div className={styles.itemSummaryFields}>
                    {props.fields.map(({ label, value }) => (<span key={label} className={styles.itemSummaryField}>
                            <span className={styles.itemSummaryFieldTitle}>{label}:</span>
                            {value}
                        </span>))}
                </div>
                {props.fields.length > 0 && <div className={styles.itemSummaryMore}>+ more&hellip;</div>}
            </div>
        </>);
}
export function List<StepInputs>(props: ListProps<StepInputs>) {
    const [itemCurrentlyBeingEdited, setItemCurrentlyBeingEdited] = useState<{
        indexOfItem: number;
        editedItem: ObjectRuntimeInputs<unknown>;
        accessor: InputObjectAccessor<StepInputs, unknown>;
    } | null>(null);
    const inputArrayAccessor = createInputArrayAccessor<StepInputs, unknown>(props.configuredStepUIProps.input);
    const items = inputArrayAccessor.getInputValue(props.inputs);
    const isInsideDrawer = useContext(NestedDrawerContext);
    const stepInputSchema = props.getInputSchema(props.inputs);
    // Automatically open the drawer when a new item is added.
    const getAccessorForCurrentItemHandler = React.useCallback(getAccessorForCurrentItem, [props.configuredStepUIProps.input]);
    const currentNumberOfItems = React.useRef(items.length);
    useEffect(() => {
        if (itemCurrentlyBeingEdited === null && currentNumberOfItems.current < items.length) {
            setItemCurrentlyBeingEdited({
                editedItem: { ...items[items.length - 1] },
                indexOfItem: items.length - 1,
                accessor: getAccessorForCurrentItemHandler(items.length - 1),
            });
        }
        currentNumberOfItems.current = items.length;
    }, [items, getAccessorForCurrentItemHandler, itemCurrentlyBeingEdited, currentNumberOfItems]);
    function addNewItem() {
        // TODO: in this case we are pushing an initial input type into the runtime input type array
        // This could cause issues with trying to retrieve runtime schema from those inputs (although likely wont as the types sufficiently overlap)
        // We should investigate creating a shared base between these types
        const newItem = { ...props.configuredStepUIProps.newItem };
        const newInputs = inputArrayAccessor.changeInputValue(props.inputs, [...items, newItem]);
        const currentRuntimeSchema = getSchemaForInputArray(props.configuredStepUIProps.input, props.getInputSchema(newInputs));
        const newInputSchema = currentRuntimeSchema.itemTypes[currentRuntimeSchema.itemTypes.length - 1];
        // Only objects are supported as the input type for arrays when using the List component
        // so the value for isRequired here doesn't matter as the object will be parsed and
        // the requiredness of each property will be evaluated individually
        const mappedInputs = mapInitialInputs(newInputSchema, false, [...getPathToArrayInput(props.configuredStepUIProps.input), currentRuntimeSchema.itemTypes.length - 1], newInputs);
        props.setInputs(mappedInputs);
    }
    function removeItem(item: Item) {
        const newInputs = inputArrayAccessor.changeInputValue(props.inputs, items.filter((i) => i !== item));
        props.setInputs(newInputs);
    }
    function getAccessorForCurrentItem(indexOfItemBeingEdited: number): InputObjectAccessor<StepInputs, unknown> {
        // We constrain ourselves to having lists of objects only, hence this cast
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const pathToItemInArray: InputPathToObject<unknown> = getArrayFromArrayInputPath(props.configuredStepUIProps.input)[indexOfItemBeingEdited] as unknown as InputPathToObject<unknown>;
        return createObjectValueAccessor<StepInputs, unknown>(pathToItemInArray);
    }
    function saveChangesToItem() {
        if (itemCurrentlyBeingEdited === null) {
            throw new Error("Can't save changes to an item because there is no item currently stored on state");
        }
        const { editedItem, accessor } = itemCurrentlyBeingEdited;
        const newInputs = accessor.changeInputValue(props.inputs, editedItem);
        props.setInputs(newInputs);
        setItemCurrentlyBeingEdited(null);
        return true;
    }
    const listContent = itemCurrentlyBeingEdited && (<ListContent itemBeingEdited={itemCurrentlyBeingEdited.editedItem} itemIndex={itemCurrentlyBeingEdited.indexOfItem} formContent={props.configuredStepUIProps.editItemForm} inputDependencies={props.inputDependencies} getInputSchema={props.getInputSchema} onEditedItemChanged={(updatedItem) => setItemCurrentlyBeingEdited({ ...itemCurrentlyBeingEdited, editedItem: updatedItem })} inputPath={props.configuredStepUIProps.input} inputObjectAccessor={itemCurrentlyBeingEdited.accessor} parentInputs={props.inputs} getFieldError={props.getFieldError} doBusyTask={props.doBusyTask} setGitDependencies={props.setGitDependencies}/>);
    // Prevent more than one layer of Drawers - if a ContextualHelpLayout is an ancestor, we render the contents in a Dialog instead.
    return (<>
            {isInsideDrawer ? (<DialogOpener open={itemCurrentlyBeingEdited !== null} onClose={() => setItemCurrentlyBeingEdited(null)} wideDialog>
                    <OkDialogLayout onOkClick={saveChangesToItem} title={props.configuredStepUIProps.itemLabel} okButtonAccessibleName={`Confirm ${props.configuredStepUIProps.itemLabel}`}>
                        {listContent}
                    </OkDialogLayout>
                </DialogOpener>) : (<Drawer open={itemCurrentlyBeingEdited !== null} onClose={() => setItemCurrentlyBeingEdited(null)} onOkClick={saveChangesToItem} actionName={props.configuredStepUIProps.itemLabel}>
                    <NestedDrawerContext.Provider value={true}>{listContent}</NestedDrawerContext.Provider>
                </Drawer>)}
            <Note note={props.note}/>
            <UnknownRemoveItemList data={items} listActions={[<ActionButton key="add" label="Add" accessibleName={`Add ${props.configuredStepUIProps.itemLabel}`} onClick={addNewItem}/>]} onRow={(item, itemIndex) => {
            const schemaForArrayItem = getSchemaOfItemInArray(props.configuredStepUIProps.input, stepInputSchema, itemIndex);
            const inputPathsForArrayItem = createObjectInputPaths(schemaForArrayItem);
            const getSummary = createSummaryFactory(item, () => schemaForArrayItem, props.inputDependencies);
            const itemFieldsSummary = getListItemFieldsSummary(props.configuredStepUIProps, getSummary, inputPathsForArrayItem);
            const itemSummary = getItemSummary(item, itemIndex, props.configuredStepUIProps.input, stepInputSchema, props.configuredStepUIProps.itemSummary);
            return (<>
                            <ListTitle>{itemSummary === "" ? "Not Provided" : itemSummary}</ListTitle>
                            <ListItemSummary fields={itemFieldsSummary}></ListItemSummary>
                        </>);
        }} onRowTouch={(item) => {
            const indexOfItem = items.indexOf(item);
            setItemCurrentlyBeingEdited({
                editedItem: { ...item },
                indexOfItem: indexOfItem,
                accessor: getAccessorForCurrentItem(indexOfItem),
            });
        }} onRemoveRow={(item) => removeItem(item)} removeButtonAccessibleName={(item, itemIndex) => {
            const itemSummary = getItemSummary(item, itemIndex, props.configuredStepUIProps.input, stepInputSchema, props.configuredStepUIProps.itemSummary);
            return `Remove ${itemSummary}`;
        }} clearButtonAlignment="top"/>
        </>);
}
interface ListContentProps<StepInputs, ArrayItem> {
    inputPath: InputPathToArray<unknown>;
    itemBeingEdited: ObjectRuntimeInputs<ArrayItem>;
    itemIndex: number;
    onEditedItemChanged: (newItem: ObjectRuntimeInputs<ArrayItem>) => void;
    formContent: (arrayItemInputs: ObjectInputPathsAndPathToObject<ArrayItem>) => FormContent<StepInputComponent>[];
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    inputDependencies: StepInputDependencies;
    getFieldError: (name: string | PathToInput) => string;
    inputObjectAccessor: InputObjectAccessor<StepInputs, ArrayItem>;
    parentInputs: ObjectRuntimeInputs<StepInputs>;
    doBusyTask: DoBusyTask;
    setGitDependencies: SetGitDependencies;
}
function ListContent<StepInputs, ArrayItem>(props: ListContentProps<StepInputs, ArrayItem>) {
    const { getFieldError, inputPath, getInputSchema, parentInputs, itemIndex, inputObjectAccessor } = props;
    const getSchemaOfArrayItem = useCallback<(inputs: ObjectRuntimeInputs<ArrayItem>) => PlainObjectTypeDefinition>((updatedItemBeingEdited) => {
        const modifiedInputs = inputObjectAccessor.changeInputValue(parentInputs, updatedItemBeingEdited);
        const parentInputSchema = getInputSchema(modifiedInputs);
        return getSchemaOfItemInArray(inputPath, parentInputSchema, itemIndex);
    }, [inputObjectAccessor, parentInputs, getInputSchema, inputPath, itemIndex]);
    const schemaForArrayItem = getSchemaOfArrayItem(props.itemBeingEdited);
    const inputPathsForArrayItem = createObjectInputPaths<ArrayItem>(schemaForArrayItem);
    const formContent = props.formContent(inputPathsForArrayItem);
    const getFieldErrorForArrayItem: (path: string | PathToInput) => string = React.useCallback((path) => {
        if (typeof path === "string") {
            return getFieldError(path);
        }
        const arrayPath = getPathToArrayInput(inputPath);
        return getFieldError([...arrayPath, ...path]);
    }, [getFieldError, inputPath]);
    const getRenderedComponentAndSummary = createRenderedComponentAndSummaryForStepFactory(props.itemBeingEdited, props.inputDependencies, props.onEditedItemChanged, getSchemaOfArrayItem, getFieldErrorForArrayItem, props.doBusyTask, props.setGitDependencies);
    // By putting an expandable container here, the state of the expanders is removed when the Expandable container unmounts (i.e. when the drawer is removed)
    // This means that the next time the drawer is shown, it uses fresh state for the expanders. Without the ExpandableContainer, this doesn't happen and it re-uses the old state
    return (<ExpandableContainer containerKey={"step ui list drawer"}>
            <EditStepPackageInputs<unknown, StepInputComponent> formContent={formContent} isExpandedByDefault={true} getRenderedComponentAndSummary={getRenderedComponentAndSummary}/>
        </ExpandableContainer>);
}
function getSchemaOfItemInArray<StepInputs, ArrayItem>(inputPath: InputPathToArray<ArrayItem>, schema: PlainObjectTypeDefinition, itemIndex: number): PlainObjectTypeDefinition {
    const schemaForArray = getSchemaForInputArray(inputPath, schema);
    const schemaForArrayItem = schemaForArray.itemTypes[itemIndex];
    if (schemaForArrayItem === undefined) {
        throw new Error(`Couldn't find schema for array item at index ${itemIndex}`);
    }
    switch (schemaForArrayItem.type) {
        case "package":
        case "sensitive":
        case "account":
        case "git-source":
        case "string":
        case "primitive":
        case "container-image":
            throw new Error("Primitive types can't be the inner type of an array for List components.");
        case "array":
            throw new Error("Arrays can't be the inner type of an array for List components");
    }
    return schemaForArrayItem;
}
function getItemSummary<StepInputs, ArrayItem>(item: ObjectRuntimeInputs<ArrayItem>, itemIndex: number, inputPath: InputPathToArray<ArrayItem>, inputSchema: PlainObjectTypeDefinition, itemSummary: ListComponent["itemSummary"]): string {
    const schemaForArrayItem = getSchemaOfItemInArray(inputPath, inputSchema, itemIndex);
    const inputPathsForArrayItem = createObjectInputPaths<ArrayItem>(schemaForArrayItem);
    const pathToSummaryInput = itemSummary(inputPathsForArrayItem);
    const summaryValueAccessor = createInputValueAccessor<ArrayItem, string>(pathToSummaryInput.title);
    const summaryValue = summaryValueAccessor.getInputValue(item);
    return isBoundValue(summaryValue) ? summaryValue.expression : summaryValue;
}
