/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { RunbookSnapshotResource, Repository } from "@octopusdeploy/octopus-server-client";
import { projectsRoute } from "@octopusdeploy/portal-routes";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import PaperLayout from "~/components/PaperLayout/index";
import { repository } from "../../clientInstance";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
interface ProjectRunbookChildRedirectState extends DataBaseComponentState {
    redirectTo: string;
}
interface ProjectRunbookChildRedirectProps {
    spaceId: string;
    getRunbookSnapshot: (repository: Repository) => Promise<RunbookSnapshotResource>;
    getRedirectPath: (projectId: string, runbookId: string) => string;
}
class ProjectRunbookChildRedirect extends DataBaseComponent<ProjectRunbookChildRedirectProps, ProjectRunbookChildRedirectState> {
    constructor(props: ProjectRunbookChildRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const runbookSnapshot = await this.props.getRunbookSnapshot(repository);
            const redirectTo = this.props.getRedirectPath(runbookSnapshot.ProjectId, runbookSnapshot.RunbookId);
            this.setState({
                redirectTo,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main>
                    <AreaTitle link={projectsRoute.generateUrl({ spaceId: this.props.spaceId })} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={{ pathname: redirectTo }}/>;
    }
    static displayName = "ProjectRunbookChildRedirect";
}
export const RunbookSnapshotRedirect: React.SFC<{
    spaceId: string;
    runbookSnapshotId: string;
}> = (props) => {
    return (<ProjectRunbookChildRedirect spaceId={props.spaceId} getRunbookSnapshot={(r) => r.RunbookSnapshots.get(props.runbookSnapshotId)} getRedirectPath={(projectId: string, runbookId: string) => routeLinks.project(projectId).operations.runbook(runbookId).runbookSnapshot(props.runbookSnapshotId).root}/>);
};
RunbookSnapshotRedirect.displayName = "RunbookSnapshotRedirect"
