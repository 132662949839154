import type { GitHubAppConnectionResource, GitHubRepository } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { FormSectionHeading, UnstructuredFormSection } from "~/components/form";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import LibraryLayout from "../LibraryLayout";
import GitHubAppRepositoryList, { GitHubAppUnknownRepositoryList } from "./GitHubAppRepositoryList";
type GitHubConnectionInternalProps = {
    connectionId: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
};
function GitHubConnectionInternal({ connectionId, doBusyTask, busy, errors }: GitHubConnectionInternalProps) {
    const [editing, setEditing] = useState<boolean>(false);
    const [connection, setConnection] = useState<GitHubAppConnectionResource | undefined>(undefined);
    const [otherRepositories, setOtherRepositories] = useState<GitHubRepository[] | undefined>(undefined);
    const [selectedRepositories, setSelectedRepositories] = useState<string[]>([]);
    const [cleanSelectedRepositories, setCleanSelectedRepositories] = useState<string[]>([]);
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        const connection = await repository.GitHubApp.getConnection(connectionId);
        setConnection(connection);
        const initialSelectedRepositories = connection.Repositories.map((r) => r.RepositoryId);
        initialSelectedRepositories.push(...connection.UnknownRepositoryIds);
        initialSelectedRepositories.sort();
        setSelectedRepositories(initialSelectedRepositories);
        setCleanSelectedRepositories(initialSelectedRepositories);
    }, [connectionId]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (editing) {
            if (connection) {
                const repositories = await repository.GitHubApp.getRepositories(connection.Installation.InstallationId);
                // This will return the full list of repositories on the installation, we only want to show
                // repositories at the bottom of the list if they're not already selected.
                const additionalRepositories = repositories.Repositories.filter((r) => !cleanSelectedRepositories.includes(r.RepositoryId));
                setOtherRepositories(additionalRepositories);
            }
            // If we don't have a connection there is nothing to do yet. This will be called again once
            // we have a connection
        }
        else {
            setOtherRepositories(undefined);
        }
    }, [editing, connection?.Installation.InstallationId]);
    function setRepositorySelected(selected: boolean, repositoryId: string) {
        if (selected) {
            setSelectedRepositories((prev) => [...prev, repositoryId].sort());
        }
        else {
            setSelectedRepositories((prev) => prev.filter((id) => id !== repositoryId).sort());
        }
    }
    async function save() {
        await doBusyTask(async () => {
            await repository.GitHubApp.modifyConnection(connectionId, { RepositoryIds: selectedRepositories });
            setEditing(false);
            await refresh();
        });
    }
    function toggleEditing() {
        setEditing(!editing);
        setSelectedRepositories(cleanSelectedRepositories);
    }
    const button: PageAction = {
        type: "button",
        buttonType: "secondary",
        hasPermissions: isAllowed({ permission: Permission.GitCredentialEdit }),
        label: editing ? "Cancel" : "Edit",
        onClick: toggleEditing,
    };
    return (<LibraryLayout>
            <FormPaperLayout model={{ selected: selectedRepositories }} cleanModel={{ selected: cleanSelectedRepositories }} pageActions={[button]} onSaveClick={save} errors={errors} title={connection ? connection.Installation.AccountLogin : StringHelper.ellipsis} breadcrumbTitle={"Git Connections"} breadcrumbPath={routeLinks.library.gitConnections.root} busy={busy} savePermission={{ permission: Permission.GitCredentialEdit }}>
                {connection && (<>
                        <FormSectionHeading title="Connected Repositories"/>
                        <UnstructuredFormSection stretchContent>
                            <GitHubAppRepositoryList hideConfigureInstallation disableInteraction={!editing} repositories={connection.Repositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
                        </UnstructuredFormSection>
                        {connection.UnknownRepositoryIds.length > 0 && (<>
                                <FormSectionHeading title={"Missing Repositories"}/>
                                <UnstructuredFormSection stretchContent>
                                    <GitHubAppUnknownRepositoryList disableInteraction={!editing} repositories={connection.UnknownRepositoryIds} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
                                </UnstructuredFormSection>
                            </>)}
                        {editing && (<>
                                <FormSectionHeading title="Add Repositories"/>
                                <UnstructuredFormSection stretchContent>
                                    {otherRepositories && <GitHubAppRepositoryList repositories={otherRepositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>}
                                </UnstructuredFormSection>
                            </>)}
                    </>)}
            </FormPaperLayout>
        </LibraryLayout>);
}
export class GitHubConnection extends DataBaseComponent<RouteComponentProps<{
    connectionId: string;
}>> {
    constructor(props: RouteComponentProps<{
        connectionId: string;
    }>) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <GitHubConnectionInternal connectionId={this.props.match.params.connectionId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "GitHubConnection";
}
export default GitHubConnection;
