/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import { Permission, DashboardRenderMode } from "@octopusdeploy/octopus-server-client";
import type { PerformanceConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import { ExpandableFormSection, Summary } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
type PerformanceState = OptionalFormBaseComponentState<PerformanceConfigurationResource>;
export class Performance extends FormBaseComponent<null, PerformanceState, PerformanceConfigurationResource> {
    constructor(props: null) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const configuration = await repository.PerformanceConfiguration.get();
            this.setState({
                model: configuration,
                cleanModel: cloneDeep(configuration),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const body = this.state.model && (<div>
                <ExpandableFormSection errorKey="DefaultDashboardRenderMode" title="Project Overview Rendering" focusOnExpandAll summary={this.state.model.DefaultDashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? Summary.summary("Full project overview rendering") : Summary.summary("Fast project overview rendering")} help="Choose the default project overview rendering mode. Full rendering will render all rows, fast rendering will render only visible rows.">
                    <RadioButtonGroup value={this.state.model.DefaultDashboardRenderMode} onChange={(DefaultDashboardRenderMode) => this.setModelState({ DefaultDashboardRenderMode })}>
                        <RadioButton value={DashboardRenderMode.VirtualizeColumns} label="Full render" isDefault={true}/>
                        <RadioButton value={DashboardRenderMode.VirtualizeRowsAndColumns} label="Fast render"/>
                    </RadioButtonGroup>
                </ExpandableFormSection>
            </div>);
        return (<FormPaperLayout title="Performance" busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: [Permission.ConfigureServer] }} onSaveClick={() => this.handleSaveClick()}>
                {body}
            </FormPaperLayout>);
    }
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const result = await repository.PerformanceConfiguration.modify(this.state.model!);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });
        });
    };
    static displayName = "Performance";
}
export default Performance;
