import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import Card from "~/components/Card";
import { DialogTrigger } from "~/components/Dialog/DialogTrigger";
import styles from "./EndpointCard.module.less";
export interface EndpointCardDispatchProps {
    onNavigate?: () => void;
}
export interface EndpointCardDetailProps {
    logo: React.ReactElement<{}>;
    registrationName: string;
    description: React.ReactNode;
    buttonLabel?: string;
}
export type EndpointCardProps = EndpointCardDispatchProps & EndpointCardDetailProps;
export const EndpointCard: React.SFC<EndpointCardProps> = (props) => (<Card logo={<div className={styles.logo}>{props.logo}</div>} className={styles.card} header={props.registrationName} content={<div className={styles.description}>{props.description}</div>} includeContentFade={false} /* Don't set true here, or you see the contentFade onHover */ onHoverView={<div className={styles.onHoverActions} onClick={props.onNavigate}>
                <div className={styles.onHoverActionsBackdrop}/>
                <ActionButton label={props.buttonLabel ? props.buttonLabel : "Add"} accessibleName={`Add ${props.registrationName}`} type={ActionButtonType.Primary} onClick={props.onNavigate}/>
            </div>}/>);
EndpointCard.displayName = "EndpointCard"
interface InformationalEndpointCardRenderProps {
    open: boolean;
    closeDialog(): void;
    openDialog(): void;
}
export type InformationalEndpointCardProps = EndpointCardDetailProps & {
    renderDialog: (props: InformationalEndpointCardRenderProps) => React.ReactElement<{}>;
};
export const InformationalEndpointCard: React.SFC<InformationalEndpointCardProps> = ({ buttonLabel = "View Instructions", renderDialog, ...rest }) => (<DialogTrigger render={(renderProps) => (<React.Fragment>
                <EndpointCard {...rest} onNavigate={renderProps.openDialog} buttonLabel={buttonLabel}/>
                {renderDialog(renderProps)}
            </React.Fragment>)}/>);
InformationalEndpointCard.displayName = "InformationalEndpointCard"
export type SimpleInformationalEndpointCardProps = EndpointCardDetailProps & {
    renderView: () => React.ReactElement<{}>;
};
export default EndpointCard;
