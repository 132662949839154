/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DrawnOctopusIllustration } from "@octopusdeploy/design-system-components";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { MapStateToProps } from "react-redux";
import { connect } from "react-redux";
import Select from "../../primitiveComponents/form/Select/Select";
import routeLinks from "../../routeLinks";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import { InternalRedirect } from "../Navigation/InternalRedirect";
import type { SpaceNotFoundContext } from "../StandardLayout/SpaceLoader";
import styles from "./style.module.less";
interface SpaceNotFoundProps {
    spaceNotFoundContext: SpaceNotFoundContext;
}
interface GlobalConnectedProps {
    spaces?: SpaceResource[];
}
type Props = SpaceNotFoundProps & GlobalConnectedProps;
interface SpaceNotFoundState {
    selectedSpaceId?: string;
}
class SpaceNotFoundInternal extends React.Component<Props, SpaceNotFoundState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    render() {
        const spaceItems = (this.props.spaces || []).map((space) => ({
            value: space.Id,
            text: space.Name,
        }));
        const title = `Unable to locate Space (${this.props.spaceNotFoundContext.missingSpaceId})...`;
        return (<main>
                <EmptyPage title={title} image={<DrawnOctopusIllustration />} description={"Our badly drawn octopus suggests trying another space."} action={<>
                            {this.state.selectedSpaceId && <InternalRedirect to={routeLinks.forSpace(this.state.selectedSpaceId).root}/>}
                            {!this.state.selectedSpaceId && spaceItems.length > 0 && (<div className={styles.selectContainer}>
                                    <Select items={spaceItems} value={this.state.selectedSpaceId} onChange={(selectedSpaceId) => this.setState({ selectedSpaceId })} label="Select a space"/>
                                </div>)}
                            <div>Alternatively, get in contact with your Octopus Deploy Administrator.</div>
                        </>}/>
            </main>);
    }
    static displayName = "SpaceNotFoundInternal";
}
const mapGlobalStateToProps: MapStateToProps<GlobalConnectedProps, SpaceNotFoundProps, GlobalState> = (state) => {
    return {
        spaces: state.configurationArea.spaces ? state.configurationArea.spaces.usersAccessibleSpaces : null!,
    };
};
export const SpaceNotFound = connect(mapGlobalStateToProps)(SpaceNotFoundInternal);
