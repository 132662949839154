/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { BreadcrumbItem } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { ActivityElement, ArtifactResource, ChannelResource, GitRefResource, InterruptionResource, ISnapshotResource, KubernetesTaskResourceStatusResource, ReleaseResource, ResourceCollection, RunbookResource, RunbookSnapshotResource, TaskDetailsResource, TaskResource, } from "@octopusdeploy/octopus-server-client";
import { isReleaseResource, isRunbookSnapshotResource, Permission, ProjectContextRepository, Repository, TaskName, TaskState as TaskStateEnum, toGitBranchWhenUnknown } from "@octopusdeploy/octopus-server-client";
import { memoize } from "lodash";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import URI from "urijs";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { ContainersFeedbackCallout } from "~/areas/ContainersFeedbackCallout";
import { DeploymentCreateGoal } from "~/areas/projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import { PublishSnapshotDialogLayout } from "~/areas/projects/components/Runbooks/PublishSnapshotDialogLayout/PublishSnapshotDialogLayout";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import type { WithOptionalProjectContextInjectedProps } from "~/areas/projects/context/index";
import { useOptionalProjectContext } from "~/areas/projects/context/index";
import { AdHocScriptTaskSummary } from "~/areas/tasks/components/Task/AdHocScriptTaskSummary";
import KubernetesDeploymentStatus from "~/areas/tasks/components/Task/K8sStatus/KubernetesDeploymentStatus";
import retrieveStepsWithKubernetesActions from "~/areas/tasks/components/Task/K8sStatus/retrieveStepsWithKubernetesActions";
import TaskLog from "~/areas/tasks/components/Task/TaskLog/TaskLog";
import { client, repository, session } from "~/clientInstance";
import { ChannelChip, RunbookSnapshotPublishedChip } from "~/components/Chips";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import type { OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import { UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ModifyTaskStateDialog from "./ModifyTaskStateDialog";
import TaskSummary from "./TaskSummary/TaskSummary";
export enum BuiltInTask {
    Deploy = "Deploy",
    RunbookRun = "RunbookRun",
    ExportProjects = "ExportProjects",
    ImportProjects = "ImportProjects"
}
interface TaskState extends DataBaseComponentState {
    task?: TaskResource<any>;
    kubernetesOctopusStatus: KubernetesTaskResourceStatusResource;
    stepsWithKubernetesActions: string[];
    taskDetails?: TaskDetailsResource;
    artifacts?: ArtifactResource[];
    interruptions?: ResourceCollection<InterruptionResource>;
    activityElements?: UniqueActivityElement[];
    verbose: boolean;
    tail: boolean;
    cancelPending: boolean;
    moveToTopPending: boolean;
    redirectTo?: string;
    breadcrumbTitle?: string;
    breadcrumbPath?: string;
    breadcrumbOverflow?: OverflowMenuNavLink[];
    breadcrumbsItems?: BreadcrumbItem[];
    hasLoadedOnce?: boolean;
    snapshot: ISnapshotResource;
    channel: ChannelResource;
    channelCount: number;
    changesMarkdown: string;
    runbook?: RunbookResource;
    taskDeploymentActionTypes: string[];
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
}
interface TaskComponentProps {
    task?: TaskResource<any>;
    taskId: string;
    projectId?: string;
    tenantId?: string;
    environmentId?: string;
    additionalSidebar?: React.ReactNode;
    getPrimaryPageAction?(Task: TaskResource<any>): PrimaryPageAction | undefined;
    getAdditionalPageActions?(Task: TaskResource<any>): PageAction[];
    additionalRefresh?(Task: TaskResource<any>): Promise<void>;
    delayRender(): boolean;
    statusSection?: React.ReactNode;
    defaultBreadcrumbItems?: BreadcrumbItem[];
    showBreadcrumbBackIcon?: boolean;
}
type TaskProps = TaskComponentProps & RouteComponentProps<any> & WithOptionalProjectContextInjectedProps;
type InternalTaskProps = TaskProps & {
    dispatchAction: AnalyticActionDispatcher;
};
const statesThatCanBeModified = [TaskStateEnum.Success, TaskStateEnum.Failed, TaskStateEnum.Canceled];
class InternalTask extends DataBaseComponent<InternalTaskProps, TaskState> {
    private timeLastRefreshTookInSeconds: number = 0;
    /**
     * Use memoization to remove unnecessary network calls as the UI is updated.
     * This allows us to regenerate the state by excluding or including machines,
     * and not call back to the server for deployment process info that will not
     * have changed.
     */
    repositoryDeploymentProcessesGet = memoize((release: ReleaseResource) => this.props.projectContext!.state.projectContextRepository.DeploymentProcesses.getForRelease(release));
    repositoryRunbookProcessGet = memoize((id: string) => repository.RunbookProcess.get(id));
    constructor(props: InternalTaskProps) {
        super(props);
        this.state = {
            verbose: false,
            tail: true,
            cancelPending: false,
            moveToTopPending: false,
            snapshot: null!,
            channel: null!,
            channelCount: 0,
            changesMarkdown: null!,
            taskDeploymentActionTypes: [],
            kubernetesOctopusStatus: { KubernetesStepsStatus: [] },
            stepsWithKubernetesActions: [],
            stepsCorrelationIds: undefined,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({
                task: this.props.task ? this.props.task : await repository.Tasks.get(this.props.taskId),
            }, () => {
                this.doBusyTask(async () => {
                    this.doRefresh = await this.startRefreshLoop(() => this.refresh(this.state.verbose, this.state.tail), this.getRefreshInterval, false, timeOperationOptions.forRefresh());
                });
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async refresh(verbose: boolean, tail: boolean) {
        const startTime = moment();
        this.setState({ verbose, tail });
        const taskDetailArgs = { verbose, tail: tail ? 20 : null };
        const taskDetailsPromise = repository.Tasks.details(this.state.task!, taskDetailArgs);
        const interruptionsPromise = isAllowed({
            permission: Permission.InterruptionViewSubmitResponsible,
            project: this.props.projectId,
            environment: this.props.environmentId,
            tenant: this.props.tenantId,
        })
            ? repository.Interruptions.list({ regarding: this.props.taskId })
            : Promise.resolve([] as any as ResourceCollection<InterruptionResource>);
        const taskDetails = await taskDetailsPromise;
        const activityElements = taskDetails.ActivityLogs.map((e: ActivityElement, n: number) => this.setIdPrefix(e, n));
        // Only supply breadcrumbs if this is a deployment task (as it will be redirected to the project area where it needs a breadcrumb).
        let snapshot: ReleaseResource | RunbookSnapshotResource | undefined;
        let channelCount = 0;
        let channel: ChannelResource | undefined;
        let breadcrumbTitle: string | undefined;
        let breadcrumbPath: string | undefined;
        let breadcrumbOverflow: OverflowMenuNavLink[] | undefined;
        let breadcrumbsItems: BreadcrumbItem[] | undefined;
        let kubernetesStatusUpdatePromise: Promise<KubernetesTaskResourceStatusResource> = Promise.resolve({ KubernetesStepsStatus: [] });
        let stepsWithKubernetesActions: string[] = [];
        const isDeploymentTask = !!(taskDetails.Task.Name === BuiltInTask.Deploy && taskDetails.Task.Arguments.DeploymentId === this.props.match.params.deploymentId);
        let changesMarkdown = null;
        // No point retrieving kubernetes status updates more than once for a completed task
        if (this.state.taskDetails?.Task.IsCompleted) {
            kubernetesStatusUpdatePromise = Promise.resolve(this.state.kubernetesOctopusStatus);
        }
        else {
            kubernetesStatusUpdatePromise = this.state.task!.SpaceId ? repository.Tasks.retrieveKubernetesStatusUpdate(this.state.task!) : Promise.resolve({ KubernetesStepsStatus: [] });
        }
        if (isDeploymentTask) {
            const deployment = await repository.Deployments.get(this.props.match.params.deploymentId);
            changesMarkdown = deployment.ChangesMarkdown;
            snapshot = deployment ? await repository.Releases.get(deployment.ReleaseId) : null!;
            if (isReleaseResource(snapshot)) {
                const project = deployment ? await repository.Projects.get(deployment.ProjectId) : null;
                if (project) {
                    try {
                        if (session.featureToggles?.includes("LinkProcessEditorFromDeploymentLogsFeatureToggle") && taskDetails.Task.IsCompleted) {
                            let gitRef: GitRefResource | undefined;
                            const snapshotGitRef = toGitBranchWhenUnknown(snapshot.VersionControlReference?.GitRef);
                            if (project.IsVersionControlled === true && !!snapshotGitRef) {
                                gitRef = await repository.Projects.getGitRef(project, snapshotGitRef);
                                this.setState({ gitRef: gitRef });
                            }
                            const projectContextRepository = new ProjectContextRepository(client, project, gitRef);
                            const deploymentProcess = await projectContextRepository.DeploymentProcesses.get();
                            // Filter step correlation ids by those present in the deployment process
                            const correlationIds: {
                                [key: string]: string;
                            } = {};
                            deployment.ExecutionPlanLogContext.Steps.forEach((context) => {
                                if (deploymentProcess.Steps.some((step) => step.Slug === context.Slug)) {
                                    correlationIds[context.CorrelationId] = context.Slug;
                                }
                            });
                            if (correlationIds) {
                                this.setState({ stepsCorrelationIds: correlationIds });
                            }
                        }
                    }
                    catch (e) {
                        logger.warn("Failed to get deployment process for project", e);
                    }
                    const projectChannels = await repository.Projects.getChannels(project);
                    channelCount = projectChannels.TotalResults;
                    channel = snapshot && projectChannels ? await repository.Releases.getChannel(snapshot) : null!;
                }
                else {
                    channelCount = 0;
                    channel = null!;
                }
                breadcrumbTitle = snapshot ? `Release ${snapshot.Version}` : null!;
                breadcrumbPath = snapshot ? routeLinks.project(project!.Slug).release(snapshot).root : null!;
                breadcrumbsItems = [
                    { label: "Releases", pageUrl: routeLinks.project(project!.Slug).releases },
                    { label: snapshot.Version, pageUrl: breadcrumbPath },
                ];
                const process = await this.repositoryDeploymentProcessesGet(snapshot);
                stepsWithKubernetesActions = retrieveStepsWithKubernetesActions(process.Steps);
            }
            const actionTypesBffResponse = await repository.Deployments.getDeploymentProcessActionTypes(deployment.DeploymentProcessId);
            this.setState({ taskDeploymentActionTypes: actionTypesBffResponse.ActionTypes });
        }
        const isRunbookTask = !!(taskDetails.Task.Name === BuiltInTask.RunbookRun && taskDetails.Task.Arguments.RunbookRunId === this.props.match.params.runbookRunId);
        let runbook: RunbookResource | undefined;
        if (isRunbookTask) {
            const runbookRun = await repository.RunbookRuns.get(this.props.match.params.runbookRunId);
            runbook = await repository.Runbooks.get(runbookRun.RunbookId);
            snapshot = runbookRun ? await repository.RunbookSnapshots.get(runbookRun.RunbookSnapshotId) : null!;
            if (isRunbookSnapshotResource(snapshot)) {
                const process = await this.repositoryRunbookProcessGet(snapshot.FrozenRunbookProcessId);
                stepsWithKubernetesActions = retrieveStepsWithKubernetesActions(process.Steps);
                const project = runbookRun ? await repository.Projects.get(runbookRun.ProjectId) : null;
                breadcrumbTitle = runbook ? `${runbook.Name}` : null!;
                breadcrumbPath = snapshot ? routeLinks.project(project!.Slug).operations.runbook(runbook.Id).runslist : null!;
                breadcrumbsItems = [
                    { label: "Runbooks", pageUrl: routeLinks.project(project!.Slug).operations.runbooks },
                    { label: breadcrumbTitle, pageUrl: breadcrumbPath },
                ];
                const runbookRoot = routeLinks.project(runbookRun.ProjectId).operations.runbook(runbookRun.RunbookId);
                breadcrumbOverflow = [
                    OverflowMenuItems.navItem("Overview", runbookRoot.overview),
                    OverflowMenuItems.navItem("Runs", runbookRoot.runslist),
                    OverflowMenuItems.navItem("Process", runbookRoot.runbookProcess.runbookProcess(runbook.RunbookProcessId).root),
                    OverflowMenuItems.navItem("Settings", runbookRoot.settings),
                ];
            }
        }
        const artifacts = this.loadArtifactsPromise();
        const result = {
            taskDetails,
            activityElements,
            artifacts: await artifacts,
            interruptions: await interruptionsPromise,
            task: taskDetails.Task,
            breadcrumbTitle: breadcrumbTitle!,
            breadcrumbPath: breadcrumbPath!,
            breadcrumbOverflow,
            breadcrumbsItems,
            hasLoadedOnce: true,
            snapshot: snapshot!,
            channel: channel!,
            channelCount,
            changesMarkdown: changesMarkdown!,
            runbook: runbook!,
            kubernetesOctopusStatus: await kubernetesStatusUpdatePromise,
            stepsWithKubernetesActions,
        };
        if (this.props.additionalRefresh) {
            await this.props.additionalRefresh(taskDetails.Task);
        }
        this.timeLastRefreshTookInSeconds = moment().diff(startTime, "seconds");
        return result;
    }
    getRefreshInterval = (hidden: boolean) => {
        if (!this.state.task) {
            return 2000;
        }
        if (this.timeLastRefreshTookInSeconds >= 5) {
            // Refresh time is terrible, back right off
            return hidden ? 120000 : 20000;
        }
        const refreshIsFast = this.timeLastRefreshTookInSeconds < 2;
        const completedRecently = this.state.task.CompletedTime && moment().diff(moment(this.state.task.CompletedTime), "seconds") < 15;
        if (completedRecently && refreshIsFast) {
            // Make sure we get the final logs that get written after the task officially completes
            return hidden ? 10000 : 2000;
        }
        if (this.state.task.IsCompleted) {
            // Keep refreshing after completion in case auto-deploy kicks in or someone else changes that task state
            return hidden ? 60000 : 20000;
        }
        const isQueuedOrStartedRecently = !this.state.task.StartTime || moment().diff(moment(this.state.task.StartTime), "seconds") < 15;
        if (isQueuedOrStartedRecently && refreshIsFast) {
            // Refresh often so the user can see it start and see it finish quickly if it's a short deployment
            return hidden ? 5000 : 1000;
        }
        return hidden ? 30000 : 5000;
    };
    // This is a bit hacky since auto-deploys that kick off from same deployment will have the same task prefix
    setIdPrefix(element: ActivityElement, n: number): UniqueActivityElement {
        return {
            ...element,
            uniqueId: n + "/" + element.Id,
            Children: element.Children ? element.Children.map((c) => this.setIdPrefix(c, n)) : null!,
        };
    }
    setVerbose = (value: boolean) => {
        this.setState({ verbose: value }, async () => this.doRefresh());
    };
    setTail = (value: boolean) => {
        this.setState({ tail: value }, async () => this.doRefresh());
    };
    isAlreadyPublished = () => {
        const runbook = this.state.runbook;
        const snapshot = this.state.snapshot;
        if (!runbook || !snapshot) {
            return false;
        }
        return runbook.PublishedRunbookSnapshotId === snapshot.Id;
    };
    getPublishRunbookSnapshotPageAction = (): PageAction | null => {
        const task = this.state.task;
        if (!task || !task.IsCompleted) {
            return null;
        }
        const runbook = this.state.runbook;
        const snapshot = this.state.snapshot;
        if (!runbook || !snapshot) {
            return null;
        }
        const hasRunbookEditPermissions = isAllowed({ permission: Permission.RunbookEdit, project: this.props.projectId, wildcard: true });
        if (!hasRunbookEditPermissions) {
            return null;
        }
        if (this.isAlreadyPublished()) {
            return null;
        }
        return {
            type: "dialog",
            label: "Publish",
            extraContext: "Publishing makes the runbook available to consumers and triggers.",
            renderDialog: ({ closeDialog, isOpen }) => (<Dialog open={isOpen} onRequestClose={closeDialog}>
                    <PublishSnapshotDialogLayout close={closeDialog} onPublishSnapshotDialogClicked={async () => {
                    await this.doBusyTask(async () => {
                        runbook.PublishedRunbookSnapshotId = snapshot.Id;
                        await repository.Runbooks.modify(runbook);
                        const path = routeLinks.project(runbook.ProjectId).operations.runbook(runbook.Id).runbookSnapshot(snapshot.Id).root;
                        this.setState({ redirectTo: path });
                    });
                }}/>
                </Dialog>),
            buttonType: "secondary",
        };
    };
    renderEditStateButton = () => {
        const task = this.state.task;
        if (!task!.IsCompleted || statesThatCanBeModified.indexOf(task!.State) === -1) {
            return null;
        }
        return OverflowMenuItems.dialogItem("Edit state", <ModifyTaskStateDialog availableStates={statesThatCanBeModified} currentTaskState={task!.State} onStateChanged={this.changeTaskState}/>, {
            permission: Permission.TaskEdit,
            project: this.props.projectId,
            environment: this.props.environmentId,
            tenant: "*",
        });
    };
    renderTryAgainButton = () => {
        const task = this.state.task!;
        if (!task.IsCompleted) {
            return null;
        }
        if (task.Name === TaskName.AdHocScript) {
            const path = task.Arguments.ActionTemplateId ? routeLinks.library.stepTemplate(task.Arguments.ActionTemplateId).run : routeLinks.tasks.console;
            const pathWithSearch = new URI({ path, query: `retry=${task.Id}` }).href();
            return OverflowMenuItems.navItem("Modify and re-run", pathWithSearch);
        }
        else if (task.Name === TaskName.BulkDeployment) {
            return OverflowMenuItems.item(task.FinishedSuccessfully ? "Re-run" : "Try again", this.rerun, { permission: Permission.DeploymentCreate, project: "*", environment: "*", tenant: "*" });
        }
        else if (task.CanRerun) {
            return OverflowMenuItems.item(task.FinishedSuccessfully ? "Re-run" : "Try again", this.rerun, { permission: Permission.TaskCreate, project: this.props.projectId, environment: this.props.environmentId, tenant: "*" });
        }
        else {
            return this.renderRedeployButton();
        }
    };
    renderRedeployButton = () => {
        const isRelease = isReleaseResource(this.state.snapshot);
        const deployToLabel = isRelease ? "Re-deploy..." : "Re-run...";
        const executePermission = isRelease ? Permission.DeploymentCreate : Permission.RunbookRunCreate;
        const routePath = isRelease
            ? routeLinks.project(this.state.snapshot.ProjectId).release(this.state.snapshot.Id).deployments.retry(this.state.taskDetails!.Task.Arguments.DeploymentId)
            : routeLinks.project(this.state.snapshot.ProjectId).operations.runbook(this.state.runbook!.Id).runbookSnapshot(this.state.snapshot.Id).runbookRuns.retry(this.state.taskDetails!.Task.Arguments.RunbookRunId);
        return OverflowMenuItems.navItem(deployToLabel, routePath, { permission: executePermission, project: this.state.snapshot.ProjectId, wildcard: true });
    };
    renderDeployToButton = () => {
        const canRenderDeployTo = this.state.snapshot && this.state.taskDetails && !this.state.taskDetails.Task.HasPendingInterruptions && this.state.taskDetails.Task.State !== TaskStateEnum.Executing;
        if (!canRenderDeployTo) {
            return null;
        }
        const isRelease = isReleaseResource(this.state.snapshot);
        const deployToLabel = isRelease ? "Deploy to..." : "Run on...";
        const executePermission = isRelease ? Permission.DeploymentCreate : Permission.RunbookRunCreate;
        const routePath = isRelease
            ? routeLinks.project(this.state.snapshot.ProjectId).release(this.state.snapshot.Id).deployments.create(DeploymentCreateGoal.To, "")
            : routeLinks.project(this.state.snapshot.ProjectId).operations.runbook(this.state.runbook!.Id).runbookSnapshot(this.state.snapshot.Id).runbookRuns.create(DeploymentCreateGoal.To, "");
        return OverflowMenuItems.navItem(deployToLabel, routePath, { permission: executePermission, project: this.state.snapshot.ProjectId, wildcard: true });
    };
    renderDeleteExecutionButton = () => {
        const canRenderDeleteDeploymentButton = !!(this.state.snapshot && this.state.taskDetails && this.state.taskDetails.Task.Name === BuiltInTask.Deploy && this.state.taskDetails.Task.Arguments.DeploymentId);
        const canRenderDeleteRunbookRunButton = !!(this.state.snapshot && this.state.taskDetails && this.state.taskDetails.Task.Name === BuiltInTask.RunbookRun && this.state.taskDetails.Task.Arguments.RunbookRunId);
        if (!canRenderDeleteDeploymentButton && !canRenderDeleteRunbookRunButton) {
            return null;
        }
        const isRelease = isReleaseResource(this.state.snapshot);
        const deletePermission = isRelease ? { permission: Permission.DeploymentDelete } : { permission: Permission.RunbookEdit, project: this.props.projectId, wildcard: true };
        return OverflowMenuItems.deleteItem(`Delete ${isRelease ? "deployment" : "run"}...`, `Are you sure you want to delete this ${isRelease ? "deployment" : "run"}?`, this.handleDeleteConfirm, <div>
                <p>Deleting this {isRelease ? "deployment" : "run"} is permanent. There is no going back.</p>
                <p>Do you wish to continue?</p>
            </div>, deletePermission);
    };
    handleDeleteConfirm = async () => {
        if (isReleaseResource(this.state.snapshot)) {
            const deployment = await repository.Deployments.get(this.state.taskDetails!.Task.Arguments.DeploymentId);
            await repository.Deployments.del(deployment);
            this.setState({ redirectTo: routeLinks.release(this.state.snapshot.Id) });
        }
        else if (isRunbookSnapshotResource(this.state.snapshot)) {
            const runbookRun = await repository.RunbookRuns.get(this.state.taskDetails!.Task.Arguments.RunbookRunId);
            await repository.RunbookRuns.del(runbookRun);
            this.setState({ redirectTo: routeLinks.runbookSnapshot(this.state.snapshot.Id) });
        }
        return true;
    };
    changeTaskState = async (newTaskState: string, reason: string) => {
        await this.doBusyTask(async () => {
            this.setState({ task: await repository.Tasks.changeState(this.state.task!, newTaskState, reason) });
        });
    };
    rerun = async () => {
        await this.doBusyTask(async () => {
            const newTask = await repository.Tasks.rerun(this.state.task!);
            this.setState({ redirectTo: routeLinks.task(newTask).root });
        });
    };
    performMoveToTop = async () => {
        await this.doBusyTask(async () => {
            try {
                this.props.dispatchAction("Move Task to Top of Queue", { resource: "Task", action: Action.Update });
                this.setState({ moveToTopPending: true });
                await repository.Tasks.prioritize(this.state.task!);
                await this.doRefresh();
            }
            finally {
                this.setState({ moveToTopPending: false });
            }
        });
    };
    getMoveToTopPageAction = (): null | PageAction => {
        if (!isFeatureToggleEnabled("ServerTaskPrioritizationFeatureToggle")) {
            return null;
        }
        const task = this.state.task!;
        if (task.State !== TaskStateEnum.Queued) {
            return null;
        }
        return {
            type: "button",
            buttonType: "secondary",
            label: "Move To Top",
            disabled: this.state.moveToTopPending,
            busyLabel: "Updating...",
            onClick: this.performMoveToTop,
            hasPermissions: isAllowed({ permission: Permission.TaskEdit, project: this.props.projectId, environment: this.props.environmentId, tenant: "*" }),
        };
    };
    performCancel = async () => {
        await this.doBusyTask(async () => {
            try {
                this.props.dispatchAction("Cancel task", { resource: "Task", action: Action.Cancel });
                this.setState({ cancelPending: true });
                await repository.Tasks.cancel(this.state.task!);
                await this.doRefresh();
            }
            finally {
                this.setState({ cancelPending: false });
            }
        });
    };
    getCancelPageAction = (): null | PageAction => {
        const task = this.state.task!;
        if (task.IsCompleted) {
            return null;
        }
        const isCancelling = this.state.cancelPending || task.State === TaskStateEnum.Cancelling;
        return {
            type: "button",
            buttonType: "destructive",
            label: "Cancel",
            disabled: isCancelling,
            busyLabel: "Cancelling...",
            onClick: this.performCancel,
            hasPermissions: isAllowed({ permission: Permission.TaskCancel, project: this.props.projectId, environment: this.props.environmentId, tenant: "*" }),
        };
    };
    render() {
        const redirectTo = this.state.redirectTo;
        if (redirectTo) {
            return <InternalRedirect to={{ pathname: redirectTo }} push={false}/>;
        }
        const initialLogs = new URI(this.props.location.search).search(true).tasklineid;
        const details = this.state.taskDetails;
        const task = this.state.task!;
        const canRender = task && details && !this.props.delayRender();
        const overflowMenuItems = this.getOverflowMenu(canRender!);
        let pageActions: PageAction[] = [];
        if (canRender) {
            const additionalActions = this.props.getAdditionalPageActions ? this.props.getAdditionalPageActions(task) : [];
            const publishSnapshotButton = this.getPublishRunbookSnapshotPageAction();
            if (publishSnapshotButton) {
                additionalActions.push(publishSnapshotButton);
            }
            pageActions = [this.getMoveToTopPageAction(), this.getCancelPageAction(), ...additionalActions].filter((x): x is PageAction => !!x);
        }
        const interruptionsCount = canRender && !task.IsCompleted && this.state.interruptions && this.state.interruptions.Items ? this.state.interruptions.Items.filter((i) => i.IsPending).length : 0;
        const summaryWarning = interruptionsCount > 0 ? `This task has interruption${interruptionsCount > 1 ? "s" : ""} preventing it from continuing` : null;
        return (<PaperLayout title={task ? task.Description : "Task"} titleLogo={task ? <TaskStatusIcon item={task!}/> : undefined} titleChip={this.isAlreadyPublished() ? <RunbookSnapshotPublishedChip noMargin={true}/> : undefined} breadcrumbTitle={this.state.breadcrumbTitle} breadcrumbPath={this.state.breadcrumbPath} breadcrumbOverflow={this.state.breadcrumbOverflow} breadcrumbChip={this.state.channelCount > 1 ? <ChannelChip channelName={this.state.channel.Name} fullWidth={true} to={routeLinks.project(this.state.channel.ProjectId).channel(this.state.channel.Id)}/> : undefined} breadcrumbsItems={this.state.breadcrumbsItems ?? this.props.defaultBreadcrumbItems} showBreadcrumbBackIcon={this.props.showBreadcrumbBackIcon} busy={this.state.busy} enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce} errors={this.errors} primaryAction={canRender ? this.props.getPrimaryPageAction?.(task) : undefined} pageActions={pageActions} overflowActions={overflowMenuItems} fullWidth={true} statusSection={this.props.statusSection}>
                {canRender && (<>
                        <ContainersFeedbackCallout actionTypes={this.state.taskDeploymentActionTypes}></ContainersFeedbackCallout>
                        <UrlNavigationTabsContainer defaultValue="taskSummary">
                            <TabItem label="Task Summary" value="taskSummary" warning={summaryWarning!}>
                                <TaskSummary task={task} projectId={this.props.projectId} environmentId={this.props.environmentId} tenantId={this.props.tenantId} artifacts={this.state.artifacts} interruptions={this.state.interruptions} activityElements={this.state.activityElements} stepsCorrelationIds={this.state.stepsCorrelationIds} additionalSidebar={this.props.additionalSidebar} taskDetails={this.state.taskDetails} doRefresh={this.doRefresh} changesMarkdown={this.state.changesMarkdown} snapshot={this.state.snapshot} gitRef={this.state.gitRef}/>
                            </TabItem>
                            <TabItem label="Task Log" value="taskLog">
                                <TaskLog details={details!} verbose={this.state.verbose} activityElements={this.state.activityElements!} stepsCorrelationIds={this.state.stepsCorrelationIds} tail={this.state.tail} initialExpandedId={initialLogs} showAdditional={() => this.setTail(false)} setVerbose={this.setVerbose} setTail={this.setTail} gitRef={this.state.gitRef}/>
                            </TabItem>
                            {this.state.stepsWithKubernetesActions.length > 0 && (<TabItem label="Kubernetes Object Status" value="kubernetesDeploymentStatus">
                                    <KubernetesDeploymentStatus status={this.state.kubernetesOctopusStatus} activityLogs={this.state.taskDetails?.ActivityLogs || []} stepsWithKubernetesActions={this.state.stepsWithKubernetesActions} projectId={this.props.projectId}/>
                                </TabItem>)}
                            {task!.Name === TaskName.AdHocScript && (<TabItem label={task!.Arguments.ActionTemplateId ? "Template Parameters" : "Script body"} value="adHocScriptSummary">
                                    <AdHocScriptTaskSummary task={task!}/>
                                </TabItem>)}
                        </UrlNavigationTabsContainer>
                    </>)}
            </PaperLayout>);
    }
    private doRefresh: Refresh = () => Promise.resolve();
    private getOverflowMenu = (canRender: boolean) => {
        return (canRender ? [this.renderEditStateButton()!, this.renderTryAgainButton()!, this.renderDeployToButton()!, this.renderDeleteExecutionButton()!] : []).filter((x) => x);
    };
    private loadArtifactsPromise = () => {
        return isAllowed({
            permission: Permission.ArtifactView,
            project: this.props.projectId,
            environment: this.props.environmentId,
            tenant: this.props.tenantId,
        })
            ? repository.Artifacts.list({ regarding: this.props.taskId, take: Repository.takeAll, order: "asc" }).then((r) => r.Items)
            : Promise.resolve([] as ArtifactResource[]);
    };
    static displayName = "InternalTask";
}
function Task(props: TaskComponentProps & RouteComponentProps) {
    const projectContext = useOptionalProjectContext();
    const dispatchAction = useAnalyticActionDispatch();
    return <InternalTask {...props} projectContext={projectContext} dispatchAction={dispatchAction}/>;
}
export default withRouter(Task);
