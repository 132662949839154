import React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { ExpandableFormSection, Note, Summary, Text } from "~/components/form";
type HelpSidebarSupportLinkProps = {
    link?: string;
    label?: string;
    linkError?: string;
    labelError?: string;
    onChangeLink: (link: string) => void;
    onChangeLinkLabel: (label: string) => void;
};
export function HelpSidebarSupportLink(props: HelpSidebarSupportLinkProps) {
    const isSidebarImprovementsFeatureEnabled = isFeatureToggleEnabled("SidebarImprovementsFeatureToggle");
    if (isSidebarImprovementsFeatureEnabled) {
        return ImprovedHelpSidebarSupportLink(props);
    }
    const { link, linkError, onChangeLink } = props;
    return (<ExpandableFormSection key="HelpSidebarSupportLink" errorKey="HelpSidebarSupportLink" title="Customize the Help Sidebar" summary={link ? Summary.summary(link) : Summary.default("Octopus support")} help="Customize the Help Sidebar support link in the Octopus web-portal UI.">
            <Text value={link || ""} onChange={onChangeLink} label="Support Link" error={linkError} autoFocus={true}/>
            <Note style={{ marginTop: "1rem" }}>
                The sidebar includes a link to the Octopus support website by default. This feature allows you to customize the link destination. For example, in cases where you'd prefer users contact your own support system. If the sidebar feature
                is disabled, this support link is located in the top profile menu.
            </Note>
        </ExpandableFormSection>);
}
function ImprovedHelpSidebarSupportLink({ link, label, linkError, labelError, onChangeLink, onChangeLinkLabel }: HelpSidebarSupportLinkProps) {
    return (<ExpandableFormSection key="HelpSidebarSupportLink|HelpSidebarSupportLinkLabel" errorKey="HelpSidebarSupportLink|HelpSidebarSupportLinkLabel" title="Customize the Help Sidebar" summary={link ? Summary.summary(link) : Summary.default("Octopus support")} help="Customize the Help sidebar support link in the Octopus web portal UI.">
            <Note>
                The sidebar includes links to Octopus support and resources. This feature lets you add a custom link, so users can contact your internal support system. If you disable the sidebar feature, you can find the support link in the top
                profile menu.
            </Note>
            <Text value={link || ""} onChange={onChangeLink} label="Support Link" helperText="Must follow one of the following valid URI schemes: http:, https:, mailto:, tel:" error={linkError} autoFocus={!!linkError}/>
            <Text value={label || ""} onChange={onChangeLinkLabel} label="Support Link Label" helperText="Must be 2 to 38 characters. If left blank, this will default to 'Your organization's internal support'" error={labelError} autoFocus={!!labelError}/>
        </ExpandableFormSection>);
}
