import { type AccountResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import FormPaperLayout from "~/components/FormPaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";
import InfrastructureLayout from "../InfrastructureLayout";
import type { AccountEditModel, AccountEditPropsInternal, AccountEditState } from "./AccountEditBase";
import AccountEditInternal from "./AccountEditBase";
abstract class AccountEditBasePaper<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends AccountEditInternal<TAccountResource, AccountEditState<TAccountResource, TModel>, TModel> {
    constructor(props: AccountEditPropsInternal<TAccountResource>) {
        super(props);
        this.state = this.initialState();
    }
    render() {
        const accountData = this.state.accountData;
        const isNewAccount = accountData === undefined;
        const savePermission = this.savePermission();
        const title = accountData?.account.Name ?? "Create Account";
        return (<InfrastructureLayout {...this.props}>
                <FormPaperLayout title={title} breadcrumbTitle={"Accounts"} breadcrumbPath={routeLinks.infrastructure.accounts.root} saveText="Account details changed" busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={savePermission} onSaveClick={() => this.handleSaveClick(false)} pageActions={isAllowed(savePermission) ? this.getPageActions() : undefined} expandAllOnMount={isNewAccount} overFlowActions={this.getOverFlowActions(accountData?.account)}>
                    {this.innerRender()}
                </FormPaperLayout>
            </InfrastructureLayout>);
    }
}
export default AccountEditBasePaper;
