/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { client } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import routeLinks from "../../../routeLinks";
import styles from "./style.module.less";
export default class TaskLayout extends BaseComponent<any, never> {
    render() {
        return (<main>
                <AreaTitle link={routeLinks.tasks.rootForSpace(client.spaceId)} title="Tasks"/>
                <ContextualHelpLayout>
                    <div className={styles.container}>{this.props.children}</div>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "TaskLayout";
}
