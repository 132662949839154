/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useParams } from "react-router";
import { repository, client } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import routeLinks from "~/routeLinks";
import Task, { BuiltInTask } from "./Task";
interface TaskScreenProps {
    taskId: string;
    isPageHeaderVNextEnabled: boolean;
}
interface TaskScreenState extends DataBaseComponentState {
    task?: TaskResource<any>;
    redirectTo?: string;
}
class TaskScreenInternal extends DataBaseComponent<TaskScreenProps, TaskScreenState> {
    constructor(props: TaskScreenProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.props.taskId, { spaces: ["all"] });
            if (task.SpaceId && task.SpaceId != client.spaceId) {
                this.setState({ redirectTo: routeLinks.forSpace(task.SpaceId).task(task).root });
                return;
            }
            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                this.setState({ redirectTo: routeLinks.deployment(task.Arguments.DeploymentId).root, task });
                return;
            }
            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                this.setState({ redirectTo: routeLinks.runbookRun(task.Arguments.RunbookRunId).root, task });
                return;
            }
            if (task.Name === BuiltInTask.ExportProjects) {
                this.setState({ redirectTo: routeLinks.exportTask(task.Id).root, task });
                return;
            }
            if (task.Name === BuiltInTask.ImportProjects) {
                this.setState({ redirectTo: routeLinks.importTask(task.Id).root, task });
                return;
            }
            this.setState({ task });
        });
    }
    render() {
        const { redirectTo, task } = this.state;
        if (redirectTo) {
            return <InternalRedirect to={{ pathname: redirectTo }} push={false}/>;
        }
        return (<main>
                {!this.props.isPageHeaderVNextEnabled && <AreaTitle link={routeLinks.tasks.rootForSpace(client.spaceId)} title="Tasks"/>}
                <ContextualHelpLayout>
                    <div className={this.props.isPageHeaderVNextEnabled ? containerStylesVNext : containerStyles}>
                        <Task task={task} taskId={this.props.taskId} delayRender={() => task == null} defaultBreadcrumbItems={[{ label: "Tasks", pageUrl: routeLinks.tasks.root }]} showBreadcrumbBackIcon={true}/>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "TaskScreenInternal";
}
const containerStyles = css({
    margin: `${space[12]} ${space[16]} 0`,
});
const containerStylesVNext = css({
    margin: space[32],
});
export function TaskScreen() {
    const { taskId } = useParams<{
        taskId: string;
    }>();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return <TaskScreenInternal taskId={taskId} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
