import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { throttle } from "lodash";
import React, { useRef, useCallback, useEffect, useState, useLayoutEffect } from "react";
import ActionList from "~/components/ActionList";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { Note, UnstructuredFormSection } from "~/components/form";
import WindowHelper from "~/utils/WindowHelper/index";
import ScopedUserRoleGroupExpander from "../ScopedUserRoleGroupExpander/ScopedUserRoleGroupExpander";
import type { ScopedUserRoleModel } from "../TeamEdit";
import styles from "./style.module.less";
interface ScopedUserRoleExpandersListProps {
    helpElement: JSX.Element;
    listActions: JSX.Element[];
    scopedUserRoles: ScopedUserRoleModel[];
    spaces: SpaceResource[];
    onRow(item: ScopedUserRoleModel, index: number): React.ReactNode;
    onRowOverflowMenuItems(spaceId: string | null): Array<MenuItem | MenuItem[]>;
}
export function ScopedUserRolesExpandersList({ helpElement, listActions, scopedUserRoles, spaces, onRow, onRowOverflowMenuItems }: ScopedUserRoleExpandersListProps) {
    const { topActionsMenuRef, bottomActionsMenuRef, bottomActionsVisibility } = useBottomActionsVisibility();
    return (<>
            <UnstructuredFormSection>
                <div className={styles.headerBar}>
                    <Note>{helpElement}</Note>
                    <div ref={topActionsMenuRef}>
                        <ActionList actions={listActions}/>
                    </div>
                </div>
            </UnstructuredFormSection>
            <div>
                <SystemItem scopedUserRoles={scopedUserRoles} onRow={onRow} onRowOverflowMenuItems={onRowOverflowMenuItems}/>
                {spaces
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .map((item) => (<SpaceItem key={item.Id} scopedUserRoles={scopedUserRoles} space={item} onRow={onRow} onRowOverflowMenuItems={onRowOverflowMenuItems}/>))}
            </div>
            <UnstructuredFormSection>
                {/* We include a bottom section that is ALWAYS visible and toggle visibility of the actions for this component.
                Do not change to hide/show or things get janky. */}
                <div ref={bottomActionsMenuRef} style={{ visibility: bottomActionsVisibility }}>
                    <ActionList actions={listActions}/>
                </div>
            </UnstructuredFormSection>
        </>);
}
type ButtonVisibility = "hidden" | "visible";
function useBottomActionsVisibility() {
    const topActionsMenuRef = useRef<HTMLDivElement>(null);
    const bottomActionsMenuRef = useRef<HTMLDivElement>(null);
    const [bottomActionsVisibility, setBottomActionsVisibility] = useState<ButtonVisibility>("visible");
    const updateBottomActionsVisibility = useCallback(() => {
        if (topActionsMenuRef.current !== null && bottomActionsMenuRef.current !== null) {
            let result: ButtonVisibility = "visible";
            if (WindowHelper.isElementInViewport(topActionsMenuRef.current) && WindowHelper.isElementCenterTouchable(topActionsMenuRef.current)) {
                result = "hidden";
            }
            else if (!WindowHelper.isElementInViewport(bottomActionsMenuRef.current)) {
                // If neither are visible, it might be that we're in a tab that isn't showing
                // so hide the bottom one so we don't show both when we come back.
                result = "hidden";
            }
            setBottomActionsVisibility(result);
        }
    }, []);
    useEffect(() => {
        const callback = throttle(updateBottomActionsVisibility, 100);
        window.addEventListener("resize", callback);
        return () => window.removeEventListener("resize", callback);
    }, [updateBottomActionsVisibility]);
    useEffect(() => {
        const callback = throttle(updateBottomActionsVisibility, 50);
        window.addEventListener("scroll", callback, { passive: true, capture: false });
        return () => window.removeEventListener("scroll", callback);
    }, [updateBottomActionsVisibility]);
    useLayoutEffect(() => {
        updateBottomActionsVisibility();
    }, [updateBottomActionsVisibility]);
    return {
        topActionsMenuRef,
        bottomActionsMenuRef,
        bottomActionsVisibility,
    };
}
interface SystemItemProps {
    scopedUserRoles: ScopedUserRoleModel[];
    onRow(item: ScopedUserRoleModel, index: number): React.ReactNode;
    onRowOverflowMenuItems(spaceId: string | null): Array<MenuItem | MenuItem[]>;
}
function SystemItem({ scopedUserRoles, onRow, onRowOverflowMenuItems }: SystemItemProps) {
    const systemScopedUserRoles = scopedUserRoles.filter((x) => !x.SpaceId);
    if (!systemScopedUserRoles.length) {
        return null;
    }
    return (<ScopedUserRoleGroupExpander key={"system"} errorKey={"system"} title={"System"} overflowMenuItems={onRowOverflowMenuItems(null)}>
            {systemScopedUserRoles.map((x, i) => onRow(x, i))}
        </ScopedUserRoleGroupExpander>);
}
interface SpaceItemProps extends SystemItemProps {
    space: SpaceResource;
}
function SpaceItem({ scopedUserRoles, space, onRow, onRowOverflowMenuItems }: SpaceItemProps) {
    const scopedUserRolesForSpace = scopedUserRoles.filter((x) => x.SpaceId === space.Id);
    if (scopedUserRolesForSpace.length === 0) {
        return null;
    }
    return (<ScopedUserRoleGroupExpander errorKey={space.Id} title={space.Name === "Default" ? "Default Space" : space.Name} overflowMenuItems={onRowOverflowMenuItems(space.Id)}>
            {scopedUserRolesForSpace.map((x, i) => onRow(x, i))}
        </ScopedUserRoleGroupExpander>);
}
