import "./DockerFeed";
import "./GitHubFeed";
import "./MavenFeed";
import "./NuGetFeed";
import "./HelmFeed";
import "./OciRegistryFeed";
import "./AwsElasticContainerRegistry";
import "./AzureContainerRegistry";
import "./GoogleContainerRegistry";
import "./S3Feed";
import feedTypeRegistry from "./feedTypeRegistry";
export default feedTypeRegistry;
