import { stringQueryParam, useQueryStringParam } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { ControlledTabsContainer } from "./ControlledTabsContainer";
import type { RenderTabCallback } from "./useTabConversion";
export interface UrlNavigationTabsContainerProps {
    defaultValue: string;
    urlParameterName?: string;
    renderTab?: RenderTabCallback;
}
export const UrlNavigationTabsContainer: React.FC<UrlNavigationTabsContainerProps> = ({ defaultValue, urlParameterName = "activeTab", renderTab, children }) => {
    const urlParameterNameParameter = stringQueryParam(urlParameterName);
    const [value, setValue] = useQueryStringParam(urlParameterNameParameter);
    return (<ControlledTabsContainer renderTab={renderTab} value={value ?? defaultValue} onChange={setValue}>
            {children}
        </ControlledTabsContainer>);
};
UrlNavigationTabsContainer.displayName = "UrlNavigationTabsContainer"
export default UrlNavigationTabsContainer;
