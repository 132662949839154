/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { projectsRoute } from "@octopusdeploy/portal-routes";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import { repository } from "../../clientInstance";
import InternalRedirect from "../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../routeLinks";
interface DeploymentToProjectTaskRedirectProps {
    spaceId: string;
    deploymentId: string;
}
interface DeploymentToProjectTaskRedirectState extends DataBaseComponentState {
    redirectTo: string;
}
export default class DeploymentToProjectTaskRedirect extends DataBaseComponent<DeploymentToProjectTaskRedirectProps, DeploymentToProjectTaskRedirectState> {
    constructor(props: DeploymentToProjectTaskRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const deploymentId = this.props.deploymentId;
            const deployment = await repository.Deployments.get(deploymentId);
            const release = await repository.Releases.get(deployment.ReleaseId);
            const path = routeLinks.project(release.ProjectId).release(release).deployments.specific(deployment);
            this.setState({
                redirectTo: path,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main>
                    <AreaTitle link={projectsRoute.generateUrl({ spaceId: this.props.spaceId })} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors} title={`Deployment`}/>
                </main>);
        }
        return <InternalRedirect push={false} to={{ pathname: redirectTo }}/>;
    }
    static displayName = "DeploymentToProjectTaskRedirect";
}
