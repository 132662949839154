import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import * as React from "react";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import SampleProjectTourBeacon from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourBeacon";
import { DismissPrompt } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourResume";
import type { SampleProjectTourStep } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { useRequiredContext } from "~/hooks/index";
import SimplePopper from "~/primitiveComponents/dataDisplay/Popper/SimplePopper";
export type PopperPlacement = "bottom-end" | "bottom-start" | "top-start" | "top-end" | "left-start" | "left-end" | "right-start" | "right-end";
interface SampleProjectTourPopoverProps {
    targetElement: HTMLDivElement | undefined;
    step: SampleProjectTourStep;
    nextStep: () => void;
}
function SampleProjectTourPopper(props: SampleProjectTourPopoverProps) {
    const sampleProjectTourContext = useRequiredContext(SampleProjectTourContext);
    const isDarkMode = useThemePaletteType() === "dark";
    const styles = {
        paper: css({
            //TODO: Sort out the z-index of this, the header, the sticky header and the sidebar in a subsequent PR
            zIndex: "9999",
            backgroundColor: isDarkMode ? themeTokens.color.background.secondary.default : themeTokens.color.background.primary.default,
            boxShadow: themeTokens.shadow.medium,
            borderRadius: "8px",
            padding: space[24],
            fontSize: "14px",
            maxWidth: "400px",
            ":after": {
                content: "\"\"",
                position: "absolute",
                width: "0",
                height: "0",
                boxSizing: "border-box",
                border: `10px solid ${isDarkMode ? themeTokens.color.background.secondary.default : themeTokens.color.background.primary.default}`,
                borderColor: `${isDarkMode ? themeTokens.color.background.secondary.default : themeTokens.color.background.primary.default} ${isDarkMode ? themeTokens.color.background.secondary.default : themeTokens.color.background.primary.default} transparent transparent`,
                background: isDarkMode ? themeTokens.color.background.secondary.default : themeTokens.color.background.primary.default,
                transformOrigin: "0 0",
                boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
            },
        }),
        placement: {
            "bottom-start": css({
                ":after": {
                    top: "0px",
                    left: "0px",
                    marginLeft: "63px",
                    transform: "rotate(135deg)",
                },
            }),
            "bottom-end": css({
                ":after": {
                    top: "0px",
                    left: "100%",
                    marginLeft: "-33px",
                    transform: "rotate(135deg)",
                },
            }),
            "top-start": css({
                ":after": {
                    bottom: "-20px",
                    left: "0",
                    marginLeft: "33px",
                    transform: "rotate(-45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "top-end": css({
                ":after": {
                    bottom: "-20px",
                    left: "100%",
                    marginLeft: "-60px",
                    transform: "rotate(-45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "left-start": css({
                ":after": {
                    top: "3px",
                    right: "-20px",
                    marginTop: "60px",
                    transform: "rotate(-135deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "left-end": css({
                ":after": {
                    bottom: "13px",
                    right: "-20px",
                    transform: "rotate(-135deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "right-start": css({
                ":after": {
                    top: "0px",
                    left: "2px",
                    marginTop: "30px",
                    transform: "rotate(45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
            "right-end": css({
                ":after": {
                    bottom: "43px",
                    left: "2px",
                    transform: "rotate(45deg)",
                    boxShadow: "-4px 4px 4px 0rem rgba(0, 0, 0, 0.04)",
                },
            }),
        },
        container: css({
            maxWidth: "400px",
            backgroundColor: "#111A23",
            padding: space[16],
        }),
        title: css({
            font: text.interface.body.bold.base,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }),
        content: css({
            font: text.interface.body.default.medium,
        }),
        tipContainer: css({
            borderRadius: "4px",
            border: `1px solid ${themeTokens.color.border.primary}`,
            padding: space[12],
            marginBottom: space[12],
            font: text.interface.body.default.medium,
            backgroundColor: isDarkMode ? themeTokens.color.background.primary.default : themeTokens.color.background.secondary.default,
        }),
        bottomContainer: css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }),
    };
    const nextButtonLabel = sampleProjectTourContext.stepIndex + 1 < sampleProjectTourContext.totalStepCount ? "Next" : "Finish";
    const onBeaconClicked = () => {
        sampleProjectTourContext.focusTour();
    };
    return sampleProjectTourContext.tourState === "Unfocused" ? (<SimplePopper placement={props.step.popperPlacement} open={true} anchorEl={props.targetElement} className={css({ zIndex: 9999 })} modifiers={{
            flip: {
                enabled: false,
            },
        }}>
            <SampleProjectTourBeacon onClick={() => onBeaconClicked()}/>
        </SimplePopper>) : (<SimplePopper placement={props.step.popperPlacement} open={true} anchorEl={props.targetElement} className={cn(styles.paper, styles.placement[props.step.popperPlacement])} modifiers={{
            flip: {
                enabled: false,
            },
            offset: { enabled: true, offset: "0, 32" },
        }}>
            <div className={styles.title}>
                <div>{props.step.title}</div>
                <DismissPrompt onDismissClicked={sampleProjectTourContext.pauseTour}/>
            </div>
            <p className={styles.content}>{props.step.content}</p>
            {props.step.tip && <div className={styles.tipContainer}>{props.step.tip}</div>}
            <div className={styles.bottomContainer}>
                <div>
                    {sampleProjectTourContext.stepIndex + 1} of {sampleProjectTourContext.totalStepCount}
                </div>
                <div className={css({
            display: "flex",
            flexDirection: "row",
            gap: space[8],
            justifyContent: "center",
        })}>
                    {sampleProjectTourContext.stepIndex > 0 && <Button label={"Back"} importance={"secondary"} onClick={sampleProjectTourContext.previousStep}></Button>}
                    <Button label={nextButtonLabel} importance={"primary"} onClick={props.nextStep}></Button>
                </div>
            </div>
        </SimplePopper>);
}
export default SampleProjectTourPopper;
