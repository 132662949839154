import React, { useContext, useEffect } from "react";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
interface SampleProjectTourStartTourOnMountProps {
    children?: React.ReactNode;
}
function SampleProjectTourStartTourOnMount(props: SampleProjectTourStartTourOnMountProps) {
    const sampleProjectContext = useContext(SampleProjectTourContext);
    useEffect(() => {
        setTimeout(() => {
            sampleProjectContext?.startTour();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>{props.children}</>;
}
export default SampleProjectTourStartTourOnMount;
