import { Divider } from "@octopusdeploy/design-system-components";
import { Permission, type GitHubAppInstallation, type GitHubAppRepositories } from "@octopusdeploy/octopus-server-client";
import type { CreateGitHubAppConnectionCommand } from "@octopusdeploy/octopus-server-client/src/repositories/gitHubAppRepository";
import React, { useRef, useState } from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import InternalLink from "~/components/Navigation/InternalLink";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import routeLinks from "~/routeLinks";
import LibraryLayout from "../LibraryLayout";
import GitHubAppInstallationDisplay from "./GitHubAppInstallationDisplay";
import GitHubAppRepositoryList from "./GitHubAppRepositoryList";
type AddGitHubConnectionSelectRepositoriesInternalProps = {
    installationId: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
};
function AddGitHubConnectionSelectRepositoriesInternal({ installationId, doBusyTask, busy, errors }: AddGitHubConnectionSelectRepositoriesInternalProps) {
    const installationUrl = useRef(repository.GitHubApp.getInstallationUrl(window.location.href));
    const [installation, setInstallation] = useState<GitHubAppInstallation | undefined>(undefined);
    const [repositories, setRepositories] = useState<GitHubAppRepositories | undefined>(undefined);
    const [selectedRepositories, setSelectedRepositories] = useState<string[]>([]);
    const navigation = useSpaceAwareNavigation();
    useDoBusyTaskEffect(doBusyTask, async () => {
        await Promise.all([getInstallations(), getRepositories()]);
    }, [installationId]);
    async function getInstallations() {
        const installations = await repository.GitHubApp.getInstallations();
        const installation = installations.Installations.filter((i) => i.InstallationId.match(installationId))[0];
        setInstallation(installation);
    }
    async function getRepositories() {
        const repositories = await repository.GitHubApp.getRepositories(installationId);
        setRepositories(repositories);
    }
    useDoBusyTaskEffect(doBusyTask, async () => {
        const repositories = await repository.GitHubApp.getRepositories(installationId);
        setRepositories(repositories);
    }, [installationId]);
    function setRepositorySelected(selected: boolean, repositoryId: string) {
        if (selected) {
            setSelectedRepositories((prev) => [...prev, repositoryId]);
        }
        else {
            setSelectedRepositories((prev) => prev.filter((id) => id !== repositoryId));
        }
    }
    async function save() {
        await doBusyTask(async () => {
            const command: CreateGitHubAppConnectionCommand = {
                InstallationId: installationId,
                RepositoryIds: selectedRepositories,
            };
            const response = await repository.GitHubApp.addConnection(command);
            navigation.navigate(routeLinks.library.gitConnections.getGitHubConnection(response.ConnectionId));
        });
    }
    return (<LibraryLayout>
            <FormPaperLayout model={{}} onSaveClick={save} errors={errors} title={"Add GitHub Connection"} breadcrumbTitle={"Git Connections"} breadcrumbPath={routeLinks.library.gitConnections.root} busy={busy} savePermission={{ permission: Permission.GitCredentialEdit }} disableDirtyFormChecking={true} forceDisableFormSaveButton={selectedRepositories.length === 0}>
                <div data-testid="repositories">
                    {installation && <GitHubAppInstallationDisplay installation={installation} buttons={<InternalLink to={routeLinks.library.gitConnections.addGitHubConnection}>Back</InternalLink>}/>}
                    <Divider />
                    {repositories && <GitHubAppRepositoryList repositories={repositories.Repositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>}
                </div>
            </FormPaperLayout>
        </LibraryLayout>);
}
export class AddGitHubConnectionSelectRepositories extends DataBaseComponent<RouteComponentProps<{
    installationId: string;
}>> {
    constructor(props: RouteComponentProps<{
        installationId: string;
    }>) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <AddGitHubConnectionSelectRepositoriesInternal installationId={this.props.match.params.installationId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "AddGitHubConnectionSelectRepositories";
}
export default AddGitHubConnectionSelectRepositories;
