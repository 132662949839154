import moment from "moment/moment";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import useLocalStorage from "~/hooks/useLocalStorage";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
export const CodeEditorFeedback = () => {
    const DISMISS_DAYS = 7;
    const [dateDismissed, setDateDismissed] = useLocalStorage<string | undefined>("Octopus.Callout.CodeEditorFeedback.DateDismissed", undefined);
    const onCloseCallout = () => {
        setDateDismissed(moment().toDate().toString());
    };
    // We dismiss this callout for 7 days
    if (dateDismissed && moment(dateDismissed).add(DISMISS_DAYS, "days").isAfter(moment())) {
        return null;
    }
    return (<Callout title="We've updated our code editor" type={CalloutType.NewFeature} canClose={true} onClose={onCloseCallout}>
            Learn about the code editor changes in this <ExternalLink href="CodeEditorBlogPost">blog post</ExternalLink> and share your feedback.
        </Callout>);
};
