/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { session, repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import Gravatar from "~/components/Gravatar/Gravatar";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import routeLinks from "../../../routeLinks";
interface UserProfileLayoutState {
    user: UserResource;
}
interface UserProfileLayoutInternalProps {
    isPageHeaderVNextEnabled: boolean;
}
class UserProfileLayoutInternal extends BaseComponent<UserProfileLayoutInternalProps, UserProfileLayoutState> {
    constructor(props: UserProfileLayoutInternalProps) {
        super(props);
        this.state = {
            user: null!,
        };
    }
    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }
    async componentDidMount() {
        const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;
        this.setState({ user: user! });
    }
    render() {
        const navLinks = compact([
            Navigation.navItem("My Profile", routeLinks.currentUser.details),
            Navigation.navItem("My Logins", routeLinks.currentUser.logins),
            Navigation.navItem("My API Keys", routeLinks.currentUser.apiKeys),
            Navigation.navItem("My Recent Activity", routeLinks.currentUser.activity, null!, { permission: Permission.EventView, wildcard: true }),
        ]);
        if (this.props.isPageHeaderVNextEnabled) {
            return (<main>
                    <NavigationSideBarLayoutVNext navItems={navLinks} content={this.props.children} header={<PageHeaderPrimary title={"My Profile"}/>}/>
                </main>);
        }
        return (<main>
                <AreaTitle link={routeLinks.currentUser.me} title={"My Profile"}/>
                <NavigationSidebarLayout image={this.state.user ? <Gravatar user={this.state.user} size={200}/> : <div />} navLinks={navLinks} content={this.props.children}/>
            </main>);
    }
    static displayName = "UserProfileLayoutInternal";
}
export function UserProfileLayout({ children }: PropsWithChildren<{}>) {
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return <UserProfileLayoutInternal isPageHeaderVNextEnabled={isPageHeaderVNextEnabledOutsideOfProjects}>{children}</UserProfileLayoutInternal>;
}
