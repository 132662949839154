import { AccountType } from "@octopusdeploy/octopus-server-client";
import type { SensitiveValue, TokenAccountResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ExpandableFormSection, Summary, FormSectionHeading } from "~/components/form";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import type { AccountEditModel, AccountEditPropsInternal, AccountEditRenderTypeProps } from "./AccountEditBase";
import AccountEditBaseDrawer from "./AccountEditBaseDrawer";
import AccountEditBasePaper from "./AccountEditBasePaper";
interface TokenModel extends AccountEditModel {
    token: SensitiveValue;
}
export default function TokenAccountEdit(props: AccountEditPropsInternal<TokenAccountResource> & AccountEditRenderTypeProps) {
    switch (props.type) {
        case "paper":
            return <TokenAccountEditPaper {...props}/>;
        case "drawer":
            return <TokenAccountEditDrawer {...props}/>;
    }
}
class TokenAccountEditPaper extends AccountEditBasePaper<TokenAccountResource, TokenModel> {
    getPartialModel(account?: TokenAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (token) => this.setModelState({ token }));
    }
}
class TokenAccountEditDrawer extends AccountEditBaseDrawer<TokenAccountResource, TokenModel> {
    getPartialModel(account?: TokenAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (token) => this.setModelState({ token }));
    }
}
function getPartialModel(account?: TokenAccountResource): Partial<TokenModel> {
    if (!account) {
        return {
            token: {
                HasValue: false,
            },
        };
    }
    return {
        token: account.Token,
    };
}
function getPartialResource(modelState: TokenModel): Partial<TokenAccountResource> & {
    AccountType: AccountType;
} {
    return {
        AccountType: AccountType.Token,
        Token: modelState.token,
    };
}
function tokenSummary(modelState: TokenModel) {
    return modelState.token && modelState.token.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No token yet provided");
}
function customExpandableFormSections(modelState: TokenModel, onTokenChange: (token: SensitiveValue) => void): JSX.Element[] {
    const baseElements: JSX.Element[] = [];
    baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="token" errorKey="token" title="Token" summary={tokenSummary(modelState)} help="The password to use to when authenticating against the remote host.">
            <Sensitive value={modelState.token} onChange={onTokenChange} label="Token"/>
        </ExpandableFormSection>);
    return baseElements;
}
