/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Checkbox } from "@octopusdeploy/design-system-components";
import type { SmtpConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import type { Action } from "redux";
import SendTestEmail from "~/areas/configuration/components/Smtp/SendEmail";
import { repository } from "~/clientInstance";
import Dialog from "~/components/Dialog/Dialog";
import { actions as dialogActions, selectors as dialogSelectors } from "~/components/Dialog/store";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import Markdown from "~/components/Markdown";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Text, ExpandableFormSection, Summary, Sensitive, required } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ParseHelper from "~/utils/ParseHelper";
import styles from "./style.module.less";
interface SmtpProps extends RouteComponentProps {
    create?: boolean;
}
interface SmtpState extends OptionalFormBaseComponentState<SmtpConfigurationResource> {
    showPasswordChange: boolean;
    testEmailAddress?: string;
}
interface GlobalDispatchProps {
    openTestEmailDialog(): void;
}
interface StateProps {
    testEmailDialogOpen: boolean;
}
type Props = SmtpProps & StateProps & GlobalDispatchProps;
class SmtpInternal extends FormBaseComponent<Props, SmtpState, SmtpConfigurationResource> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showPasswordChange: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const smtpConfiguration = await repository.SmtpConfiguration.get();
            this.setState({
                model: smtpConfiguration,
                cleanModel: cloneDeep(smtpConfiguration),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const title = "SMTP Configuration";
        const saveText = "SMTP details updated";
        const pageActions: PageAction[] = [
            {
                type: "button",
                buttonType: "secondary",
                label: "Save and Test",
                busyLabel: "Saving...",
                disabled: this.state.busy,
                onClick: () => this.handleSaveClick(true),
                hasPermissions: isAllowed({ permission: Permission.ConfigureServer }),
            },
        ];
        const body = this.state.model && (<div className={styles.fullWidth}>
                <ExpandableFormSection errorKey="SmtpHost" title="SMTP Host" focusOnExpandAll summary={this.state.model.SmtpHost ? Summary.summary(this.state.model.SmtpHost) : Summary.placeholder("No DNS hostname specified")} help="Enter the DNS hostname for your SMTP server.">
                    <Text value={this.state.model.SmtpHost} onChange={(SmtpHost) => this.setModelState({ SmtpHost })} label="SMTP host" autoFocus={true}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="SmtpPort" title="SMTP Port" summary={this.state.model.SmtpPort ? Summary.summary(this.state.model.SmtpPort) : Summary.placeholder("No port specified")} help="Enter the TCP port for your SMTP server.">
                    <Text value={this.state.model.SmtpPort === null ? "" : this.state.model.SmtpPort!.toString()} onChange={(x) => this.setModelState({ SmtpPort: ParseHelper.safeParseInt(x, null!) })} label="SMTP port" validate={required("Please enter an SMTP port")}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Timeout" title="Timeout" summary={this.state.model.Timeout === null ? Summary.default("12 seconds") : Summary.summary(`${this.state.model.Timeout! / 1000} seconds`)} help="Enter timeout for SMTP operations. Value is in miliseconds.">
                    <Text value={this.state.model.Timeout === null ? "" : this.state.model.Timeout!.toString()} onChange={(x) => this.setModelState({ Timeout: ParseHelper.safeParseInt(x, null!) })} label="Timeout" placeholder="Default value is 12000 miliseconds (12 seconds)"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="EnableSsl" title="Use SSL/TLS" summary={this.state.model.EnableSsl ? Summary.summary("Yes") : Summary.default("No")} help={<Markdown markup="&#xA;This option controls whether or not Octopus enforces using an SSL/TLS-wrapped connection.&#xA;If this is not selected, SSL/TLS will still be used if your email server supports the `STARTTLS` extension, but it is not mandatory"/>}>
                    <Checkbox value={this.state.model.EnableSsl} onChange={(EnableSsl) => this.setModelState({ EnableSsl })} label="Use SSL/TLS" className={styles.rememberMe}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="SendEmailFrom" title="From Address" summary={this.state.model.SendEmailFrom ? Summary.summary(this.state.model.SendEmailFrom) : Summary.placeholder("No from address specified")} help="All emails will be sent 'From' this address.">
                    <Text value={this.state.model.SendEmailFrom} onChange={(SendEmailFrom) => this.setModelState({ SendEmailFrom })} label="From address"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Credentials" title="Credentials" summary={this.state.model.SmtpLogin ? Summary.summary(`Credentials have been entered; username is ${this.state.model.SmtpLogin}`) : Summary.placeholder("Add authentication details if your SMTP server requires authentication")} help="Leave blank if your SMTP server does not require authentication.">
                    <Text value={this.state.model.SmtpLogin!} onChange={(SmtpLogin) => this.setModelState({ SmtpLogin })} label="SMTP login"/>
                    <br />
                    <Sensitive value={this.state.model.SmtpPassword} onChange={(SmtpPassword) => this.setModelState({ SmtpPassword })} label="SMTP password"/>
                </ExpandableFormSection>
            </div>);
        return (<FormPaperLayout title={title} busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: [Permission.AdministerSystem, Permission.ConfigureServer] }} onSaveClick={() => this.handleSaveClick(false)} saveText={saveText} pageActions={pageActions} expandAllOnMount={this.props.create}>
                <Dialog open={this.props.testEmailDialogOpen || false}>
                    <SendTestEmail />
                </Dialog>
                {body}
            </FormPaperLayout>);
    }
    private handleSaveClick = async (testSend: boolean) => {
        await this.doBusyTask(async () => {
            const result = await repository.SmtpConfiguration.modify(this.state.model!);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });
            if (testSend) {
                this.props.openTestEmailDialog();
            }
        });
    };
    static displayName = "SmtpInternal";
}
const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        testEmailDialogOpen: dialogSelectors.createDialogOpenSelector("testEmail")(state),
    };
};
const mapGlobalActionDispatchersToProps = (dispatch: React.Dispatch<Action>) => {
    return {
        openTestEmailDialog: () => dispatch(dialogActions.open("testEmail")),
    };
};
const Smtp = connect<StateProps, GlobalDispatchProps, SmtpProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(SmtpInternal);
export default Smtp;
