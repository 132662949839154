import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission, ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { useProjectContext } from "~/areas/projects/context/index";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import { LastPublishedChip } from "../../Runbooks/LastPublishedChip";
import RunbooksFormPaperLayout from "../../Runbooks/Layouts/RunbooksFormPaperLayout";
import PublishButton, { usePublishedRunbookSnapshotSetup } from "../../Runbooks/PublishButton";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import { useRunbookContext, useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { ContextAddStepButton } from "../../Steps";
import { useProcessContext } from "../Contexts/ProcessContext";
import { ProjectContextFormPaperLayout } from "./ProjectContextFormPaperLayout";
type ProcessContextFormPaperLayoutPropsInternal = {
    doBusyTask: DoBusyTask;
    onCreateBranch?: (newBranchName: string) => Promise<void>;
};
type ProcessContextFormPaperLayoutProps = FormPaperLayoutProps & ProcessContextFormPaperLayoutPropsInternal & RouteComponentProps<{
    projectSlug: string;
}>;
const ProcessContextFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps> = ({ children, ...rest }) => {
    const processContext = useProcessContext();
    const type = processContext.selectors.getProcessType();
    const hasSteps = processContext.selectors.hasSteps();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    if (type === ProcessType.Runbook) {
        return (<RunbooksProcessEditorFormPaperLayout title={runbookName} breadcrumbTitle="Runbooks" hasSteps={hasSteps} project={project} disableAnimations={true} {...rest}>
                {children}
            </RunbooksProcessEditorFormPaperLayout>);
    }
    else {
        return (<DeploymentsProcessEditorFormPaperLayout title={"Process Editor"} breadcrumbTitle="Process" project={project} disableAnimations={true} {...rest}>
                {children}
            </DeploymentsProcessEditorFormPaperLayout>);
    }
};
ProcessContextFormPaperLayout.displayName = "ProcessContextFormPaperLayout"
export const EnhancedProcessContextFormPaperLayout = withRouter(ProcessContextFormPaperLayout);
const DeploymentsProcessEditorFormPaperLayout: React.FC<FormPaperLayoutProps & {
    project: ProjectResource;
    onCreateBranch?: (newBranchName: string) => Promise<void>;
}> = ({ project, ...props }) => {
    const projectLinks = routeLinks.project(project.Slug);
    const { title = "Process Editor", breadcrumbPath = projectLinks.deployments.process.root, breadcrumbTitle = "Process", fullWidth = true, pageActions = [], ...rest } = props;
    return (<ProjectContextFormPaperLayout title={title} pageActions={[...pageActions, { type: "custom", content: <ContextAddStepButton />, key: "Add Step", hasPermissions: isAllowed({ permission: Permission.ProcessEdit, project: project.Id, wildcard: true }) }]} breadcrumbPath={breadcrumbPath} breadcrumbTitle={breadcrumbTitle} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: projectLinks.deployments.process.root }]} fullWidth={fullWidth} {...rest}>
            {props.children}
        </ProjectContextFormPaperLayout>);
};
DeploymentsProcessEditorFormPaperLayout.displayName = "DeploymentsProcessEditorFormPaperLayout"
const RunbooksProcessEditorFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps & {
    project: ProjectResource;
    hasSteps: boolean;
    doBusyTask: DoBusyTask;
}> = ({ project, hasSteps, doBusyTask, pageActions: consumerPageActions = [], ...props }) => {
    const runbooksContext = useRunbookContext();
    const runbook = runbooksContext.state.runbook;
    if (!runbook) {
        throw Error("runbook value should exist");
    }
    const publishedRunbookAndTemplate = usePublishedRunbookSnapshotSetup(doBusyTask, runbook);
    const publishedRunbookSnapshot = publishedRunbookAndTemplate?.publishedRunbookSnapshot;
    const runbookRunTemplate = publishedRunbookAndTemplate?.runbookRunTemplate;
    const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
    const pageActions: PageAction[] = [
        ...consumerPageActions,
        {
            type: "custom",
            content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
            key: "Publish",
            hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
        },
        { type: "custom", content: <ContextualRunNowButton isDisabled={!hasSteps}/>, key: "Run Now", hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }) },
        { type: "custom", content: <ContextAddStepButton />, key: "Add Step", hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }) },
    ];
    return (<RunbooksFormPaperLayout title={runbooksContext.state.runbook?.Name} titleChip={titleChip} breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(project.Slug).operations.runbooks} breadcrumbsItems={[{ label: "Runbooks", pageUrl: routeLinks.project(project.Slug).operations.runbooks }]} pageActions={pageActions} {...props}>
            {props.children}
        </RunbooksFormPaperLayout>);
};
RunbooksProcessEditorFormPaperLayout.displayName = "RunbooksProcessEditorFormPaperLayout"
