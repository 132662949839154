import { css } from "@emotion/css";
import React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import Logo from "~/components/Logo/Logo";
import InternalLink from "~/components/Navigation/InternalLink/index";
import routeLinks from "~/routeLinks";
interface TenantsDataTableTenantNameCellWithLinkProps {
    tenantId: string;
    tenantName: string;
    tenantLogoLink: string;
}
export function TenantsDataTableNameCellWithLink({ tenantId, tenantName, tenantLogoLink }: TenantsDataTableTenantNameCellWithLinkProps) {
    const dispatchAction = useAnalyticActionDispatch();
    function handleClick() {
        dispatchAction("View Tenant", { resource: "Tenant", action: Action.View });
    }
    return (<InternalLink to={routeLinks.tenant(tenantId).root} onClick={handleClick}>
            <div className={style}>
                <div className={logoStyle}>
                    <Logo url={tenantLogoLink}/>
                </div>
                <div>{tenantName}</div>
            </div>
        </InternalLink>);
}
interface TenantsDataTableTenantNameCellProps {
    tenantName: string;
    tenantLogoLink: string;
    overflow?: boolean;
}
export function TenantsDataTableNameCell({ tenantName, tenantLogoLink, overflow }: TenantsDataTableTenantNameCellProps) {
    return (<div className={style}>
            <div className={logoStyle}>
                <Logo url={tenantLogoLink} overflow={overflow}/>
            </div>
            <div>{tenantName}</div>
        </div>);
}
const centeredFlex = css({
    display: "flex",
    alignItems: "center",
});
const logoScale = css({
    scale: "0.7",
});
const style = css(centeredFlex);
const logoStyle = css(logoScale);
