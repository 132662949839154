import { FeedType } from "@octopusdeploy/octopus-server-client";
import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { BoundSelectWithAddRefresh } from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import routeLinks from "~/routeLinks";
interface FeedSelectorProps {
    localNames: string[] | undefined;
    feeds: FeedResource[];
    feedId: string | undefined;
    refreshFeeds: () => Promise<void>;
    onChange: (selectedFeed: FeedResource | string) => void;
    feedSelectLabel?: string;
    autoFocus?: boolean;
    feedIdError?: string;
}
export const getDefaultFeedId = (feeds: FeedResource[]): string | undefined => {
    if (feeds.length === 0) {
        return undefined;
    }
    const buildtIn = feeds.find((f) => f.FeedType === FeedType.BuiltIn);
    if (buildtIn) {
        return buildtIn.Id;
    }
    return feeds[0].Id;
};
const getSelectedFeed = (feedId: string | undefined, feeds: FeedResource[] | null) => {
    if (feeds === null || feeds.length === 0) {
        return null;
    }
    if (feedId) {
        const feed = feeds.find((f) => f.Id === feedId);
        if (feed) {
            return feed;
        }
    }
    return null;
};
export const FeedSelector = (props: FeedSelectorProps) => {
    const defaultFeedId = getDefaultFeedId(props.feeds);
    let selectedFeedId = props.feedId;
    if (selectedFeedId === null) {
        selectedFeedId = defaultFeedId;
    }
    const feedSelectLabel = props.feedSelectLabel || "Package feed";
    const handleOnChange = (feedId: string | undefined) => {
        const feed = props.feeds.find((f) => f.Id === feedId);
        if (feed) {
            props.onChange(feed);
        }
        else if (feedId !== undefined) {
            props.onChange(feedId);
        }
    };
    return (<BoundSelectWithAddRefresh variableLookup={{
            localNames: props.localNames,
        }} resetValue={defaultFeedId} value={selectedFeedId} onChange={handleOnChange} items={props.feeds.map((f) => ({ value: f.Id, text: f.Name }))} error={props.feedIdError} autoFocus={props.autoFocus} label={feedSelectLabel} addUrl={`#${routeLinks.library.feeds}`} onRequestRefresh={props.refreshFeeds}/>);
};
