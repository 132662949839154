/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionButton, Divider, Checkbox } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import { ControlType, PropertyApplicabilityMode } from "@octopusdeploy/octopus-server-client";
import type { DataContext, TypeMetadata, PropertyMetadata, PropertyApplicability } from "@octopusdeploy/octopus-server-client";
import _ from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import CopyToClipboard from "~/components/CopyToClipboardButton/CopyToClipboardButton";
import Markdown from "~/components/Markdown";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { BoundSensitive } from "~/components/form/Sensitive/Sensitive";
import { Drawer } from "~/primitiveComponents/dataDisplay/Drawer/Drawer";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import type { Options } from "../../primitiveComponents/form/Select/Options";
import { BoundSelect, default as Select } from "../../primitiveComponents/form/Select/Select";
import type { BoundFieldProps } from "../Actions/pluginRegistry";
import Section from "../Section";
import type { SummaryNodeProps } from "../form";
import { ExpandableFormSection, FormSectionHeading, Sensitive, Summary } from "../form";
import { VariableLookupText } from "../form/VariableLookupText";
import DynamicConnectivityCheck from "./DynamicConnectivityCheck";
import MetadataTypeHelpers from "./MetadataTypeHelpers";
import styles from "./style.module.less";
class DynamicFormRemoveItemsList extends RemoveItemsList<DataContext> {
}
export interface DynamicFormProps {
    description?: string;
    types?: TypeMetadata[];
    values?: DataContext;
    onChange?: (context: DataContext) => void;
    isBindable?: boolean;
    getBoundFieldProps?: () => BoundFieldProps;
    customControlTypes?: DynamicFormCustomControlType[];
}
export class DynamicFormCustomControlType {
    name: string = undefined!;
    summaryNodeProps: (value: any) => React.ComponentType<SummaryNodeProps> = undefined!;
    readonlyValue: (value: any) => string = undefined!;
    getInputControl: (property: PropertyMetadata, dataContext: DataContext, value: any) => JSX.Element = undefined!;
}
interface EitherProps {
    flag: any;
    renderLeft: () => React.ReactElement<any>;
    renderRight: () => React.ReactElement<any>;
}
const Either: React.FunctionComponent<EitherProps> = ({ flag, renderLeft, renderRight }) => (!!flag === true ? renderRight() : renderLeft());
Either.displayName = "Either"
interface DrawInfo {
    isOpen: boolean;
    data: DataContext;
    isUpdate: boolean;
    itemIndex: number;
}
interface DrawerState {
    [key: string]: DrawInfo;
}
interface DynamicFormState {
    drawers: DrawerState;
}
const DynamicForm: React.FunctionComponent<DynamicFormProps> = (props) => {
    const [state, updateState] = React.useState<DynamicFormState>({
        drawers: {},
    });
    const noValueMessage: string = "No value provided";
    const getControlType = (property: PropertyMetadata): ControlType | undefined => {
        if (property.DisplayInfo.ListApi || property.DisplayInfo.Options) {
            return ControlType.Select;
        }
        switch (property.Type) {
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
                return ControlType.SingleLineText;
            case "bool":
            case "bool?":
            case "boolean":
                return ControlType.Checkbox;
            case "string[]":
            case "raw_map":
            case "raw_list":
                return ControlType.MultiLineText;
            case "SensitiveValue":
                return ControlType.Sensitive;
        }
        if (props.customControlTypes && props.customControlTypes.find((c) => c.name === property.Type)) {
            return ControlType.Custom;
        }
    };
    const getBooleanDisplayValue = (value: any): string => {
        return value === undefined || value === null || value === false ? "No" : "Yes";
    };
    const getSelectDisplayValue = (property: PropertyMetadata, value: any): string => {
        if (value === null || value === "") {
            return noValueMessage;
        }
        if (property.DisplayInfo.Options) {
            const objectKeys = Object.getOwnPropertyNames(property.DisplayInfo.Options.Values);
            const options = objectKeys.map((key) => ({ value: key.toString(), text: property.DisplayInfo.Options.Values[key].toString() }));
            const selectedOption = options.find((x) => x.value === value);
            return selectedOption ? selectedOption.text : value;
        }
        return value;
    };
    const createSummary = (property: PropertyMetadata, value: any) => {
        switch (property.Type) {
            case "bool":
            case "boolean":
            case "bool?":
                return Summary.summary(getBooleanDisplayValue(value));
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
            case "raw_map":
            case "raw_list":
                return Summary.summary(getSelectDisplayValue(property, value));
            case "string[]":
                return Summary.summary(value && value.join ? value.join(", ") : value);
            case "SensitiveValue":
                return !value || value.HasValue === false ? Summary.placeholder(noValueMessage) : Summary.summary("*****");
        }
        if (property.Type.endsWith("[]") && Array.isArray(value)) {
            return Summary.summary(`${value.length === 0 ? "No" : value.length} ${pluralize(property.Name.toLowerCase(), value.length)}`);
        }
        if (props.customControlTypes) {
            const customControlType = props.customControlTypes.find((c) => c.name === property.Type);
            if (customControlType) {
                return customControlType.summaryNodeProps(value);
            }
        }
    };
    const getReadonlyValue = (property: PropertyMetadata, value: any) => {
        switch (property.Type) {
            case "bool":
            case "boolean":
            case "bool?":
                return getBooleanDisplayValue(value);
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
            case "string[]":
            case "raw_map":
            case "raw_list":
                return value === null || value === "" ? noValueMessage : value.toString();
            case "SensitiveValue":
                return !value || value.HasValue === false ? noValueMessage : "*****";
        }
        if (props.customControlTypes) {
            const customControlType = props.customControlTypes.find((c) => c.name === property.Type);
            if (customControlType) {
                return customControlType.readonlyValue(value);
            }
        }
    };
    const getSelectOptions = (property: PropertyMetadata): Options => {
        if (property.DisplayInfo.Options && property.DisplayInfo.Options.Values) {
            const objectKeys = Object.getOwnPropertyNames(property.DisplayInfo.Options.Values);
            const options = objectKeys.map((key) => ({ value: key.toString(), text: property.DisplayInfo.Options.Values[key].toString() }));
            return options;
        }
        else if (property.DisplayInfo.ListApi) {
            return []; // TODO: load from api
        }
        else {
            return [];
        }
    };
    const getInputControl = (property: PropertyMetadata, dataContext: DataContext, getBoundFieldProps?: () => BoundFieldProps) => {
        let value = dataContext[property.Name];
        if (value && property.Type === "string[]") {
            // We might have a string value that was changed to an array, so don't
            // assume join is a valid method.
            if (value.join) {
                value = value.join("\n");
            }
        }
        if (property.DisplayInfo.ReadOnly) {
            const displayValue = getReadonlyValue(property, value);
            return <span>{displayValue}</span>;
        }
        const inputType = getControlType(property);
        const formProps = {
            label: property.DisplayInfo.Label,
            value: value !== undefined ? value : "",
            onChange: (newValue: any) => onChange(property, dataContext, newValue),
        };
        const boundFieldProps: BoundFieldProps = getBoundFieldProps ? getBoundFieldProps() : {};
        switch (inputType) {
            case ControlType.SingleLineText:
                return <Either flag={props.isBindable} renderLeft={() => <DebounceText id={property.Name} {...formProps}/>} renderRight={() => <VariableLookupText id={property.Name} {...formProps} {...boundFieldProps}/>}/>;
            case ControlType.MultiLineText:
                return (<Either flag={props.isBindable} renderLeft={() => <DebounceText id={property.Name} {...formProps} multiline={true}/>} renderRight={() => <VariableLookupText id={property.Name} {...formProps} multiline={true} {...boundFieldProps}/>}/>);
            case ControlType.Select:
                return (<Either flag={props.isBindable} renderLeft={() => <Select items={getSelectOptions(property)} allowClear={false} {...formProps}/>} renderRight={() => <BoundSelect items={getSelectOptions(property)} allowClear={false} resetValue={""} {...formProps} {...boundFieldProps}/>}/>);
            case ControlType.Checkbox: {
                return <Either flag={props.isBindable} renderLeft={() => <Checkbox {...formProps}/>} renderRight={() => <BoundStringCheckbox resetValue={""} {...formProps} {...boundFieldProps}/>}/>;
            }
            case ControlType.Sensitive:
                return <Either flag={props.isBindable} renderLeft={() => <Sensitive {...formProps}/>} renderRight={() => <BoundSensitive resetValue={""} {...formProps} {...boundFieldProps}/>}/>;
            case ControlType.Custom:
                if (props.customControlTypes) {
                    const customControlType = props.customControlTypes.find((c) => c.name === property.Type);
                    if (customControlType) {
                        return customControlType.getInputControl(property, dataContext, value);
                    }
                }
        }
        return <DebounceText id={property.Name} {...formProps}/>;
    };
    const isApplicable = (applicability: PropertyApplicability, dataContext: DataContext) => {
        if (applicability) {
            switch (applicability.Mode) {
                case PropertyApplicabilityMode.ApplicableIfHasAnyValue:
                    if (dataContext[applicability.DependsOnPropertyName]) {
                        return true;
                    }
                    break;
                case PropertyApplicabilityMode.ApplicableIfHasNoValue:
                    if (!dataContext[applicability.DependsOnPropertyName]) {
                        return true;
                    }
                    break;
                case PropertyApplicabilityMode.ApplicableIfSpecificValue:
                    if (dataContext[applicability.DependsOnPropertyName] === applicability.DependsOnPropertyValue) {
                        return true;
                    }
                    break;
                case PropertyApplicabilityMode.ApplicableIfNotSpecificValue:
                    if (dataContext[applicability.DependsOnPropertyName] !== applicability.DependsOnPropertyValue) {
                        return true;
                    }
                    break;
            }
            return false;
        }
        return true;
    };
    const renderItem = (parameter: DataContext, fieldsToShow: string[]) => {
        const dataToShow = fieldsToShow.map((field, index) => <div key={index}>{parameter[field]}</div>);
        return dataToShow;
    };
    const openDraw = (drawId: string) => {
        updateState({
            ...state,
            drawers: {
                [drawId]: {
                    ...state.drawers[drawId],
                    isOpen: true,
                },
            },
        });
    };
    const closeDraw = (drawId: string) => {
        updateState({
            ...state,
            drawers: {
                [drawId]: {
                    ...state.drawers[drawId],
                    isOpen: false,
                },
            },
        });
    };
    const removeItemFromArray = (item: DataContext, dataContext: DataContext) => {
        if (Array.isArray(dataContext)) {
            _.remove(dataContext, (data) => _.isEqual(item, data));
        }
        if (props.onChange) {
            props.onChange(props.values!);
        }
    };
    const onDrawOk = (drawId: string, dataContext: DataContext) => {
        if (Array.isArray(dataContext)) {
            if (state.drawers[drawId].isUpdate) {
                state.drawers[drawId].isUpdate = false;
                dataContext[state.drawers[drawId].itemIndex] = state.drawers[drawId].data;
            }
            else {
                dataContext.push(state.drawers[drawId].data);
            }
        }
        onDrawClose(drawId);
        if (props.onChange) {
            props.onChange(props.values!);
        }
    };
    const onDrawOpen = (drawId: string, data?: DataContext, dataContext?: DataContext) => {
        if (data && dataContext) {
            if (Array.isArray(dataContext)) {
                state.drawers[drawId].data = _.cloneDeep(data);
                state.drawers[drawId].isUpdate = true;
                state.drawers[drawId].itemIndex = dataContext.findIndex((val) => _.isEqual(val, data));
            }
        }
        openDraw(drawId);
    };
    const onDrawClose = (drawId: string) => {
        state.drawers[drawId].data = {};
        state.drawers[drawId].isUpdate = false;
        closeDraw(drawId);
    };
    const renderArraySection = (property: PropertyMetadata, dataContext: DataContext): React.ReactNode => {
        if (!Array.isArray(dataContext)) {
            throw new Error("DataContext was expected to be an array.");
        }
        if (state.drawers[property.Name] === undefined) {
            state.drawers[property.Name] = {
                data: {},
                isOpen: false,
                isUpdate: false,
                itemIndex: -1,
            };
        }
        const description = property.DisplayInfo && property.DisplayInfo.Description ? (<span className={styles.markdownDescriptionContainer}>
                    <Markdown markup={property.DisplayInfo.Description}/>
                </span>) : (`Provide a value for ${property.DisplayInfo.Label}`);
        const compositeTypeToFind = property.Type.substring(0, property.Type.length - 2);
        const compositeType = props.types!.filter((t) => t.Name === compositeTypeToFind)[0];
        const fieldsToShow = compositeType.Properties.filter((prop) => prop.DisplayInfo.Required).map((prop) => prop.Name);
        return (<ExpandableFormSection errorKey={property.Name} title={pluralize(property.DisplayInfo.Label, 2)} help={description} key={property.Name} isExpandedByDefault={true} summary={createSummary(property, dataContext)}>
                <DynamicFormRemoveItemsList empty={""} onRow={(data) => renderItem(data, fieldsToShow)} data={dataContext} listActions={[<ActionButton label={`Add ${pluralize(property.DisplayInfo.Label, 1)}`} onClick={() => onDrawOpen(property.Name)}/>]} onRemoveRow={(item) => removeItemFromArray(item, dataContext)} onRowTouch={(item) => onDrawOpen(property.Name, item, dataContext)}/>
                <Drawer actionName={`${state.drawers[property.Name].isUpdate ? "Edit" : "Add"} ${pluralize(property.DisplayInfo.Label, 1)}`} open={state.drawers[property.Name].isOpen} onClose={() => onDrawClose(property.Name)} onOkClick={() => onDrawOk(property.Name, dataContext)}>
                    <Divider />
                    <div>{renderSection(compositeType, state.drawers[property.Name].data, property.DisplayInfo.Label, property.Name, !state.drawers[property.Name].isUpdate, false)}</div>
                </Drawer>
            </ExpandableFormSection>);
    };
    const renderProperty = (property: PropertyMetadata, dataContext: DataContext, parentPropertyName: string, isExpandedByDefault = false, retrieveValuesFromParent = true): React.ReactNode => {
        if (!isApplicable(property.DisplayInfo.PropertyApplicability!, dataContext)) {
            return;
        }
        if (MetadataTypeHelpers.isCompositeType(property) && (!props.customControlTypes || !props.customControlTypes.find((c) => c.name === property.Type))) {
            const compositeType = props.types!.filter((t) => t.Name === property.Type)[0];
            if (!dataContext[property.Name]) {
                dataContext[property.Name] = {};
            }
            return renderSection(compositeType, dataContext[property.Name], property.DisplayInfo.Label, property.Name, isExpandedByDefault, retrieveValuesFromParent);
        }
        if (MetadataTypeHelpers.isArrayType(property)) {
            return renderArraySection(property, dataContext[property.Name]);
        }
        const controlType = getControlType(property);
        const selectOptions = getSelectOptions(property);
        const description = property.DisplayInfo && property.DisplayInfo.Description ? (<span className={styles.markdownDescriptionContainer}>
                    <Markdown markup={property.DisplayInfo.Description}/>
                </span>) : (`Provide a value for ${property.DisplayInfo.Label}`);
        const extendedDescription = property.DisplayInfo && property.DisplayInfo.ExtendedDescription ? (<span className={styles.markdownDescriptionContainer}>
                    <Markdown markup={property.DisplayInfo.ExtendedDescription}/>
                </span>) : null;
        const controlToRender = getInputControl(property, dataContext, props.getBoundFieldProps);
        return (<ExpandableFormSection errorKey={parentPropertyName ? `${parentPropertyName}.${property.Name}` : property.Name} title={property.DisplayInfo.Label} help={description} key={parentPropertyName ? `${parentPropertyName}.${property.Name}` : property.Name} isExpandedByDefault={isExpandedByDefault} summary={createSummary(property, dataContext[property.Name])}>
                {controlToRender}
                {property.DisplayInfo.ShowCopyToClipboard && <CopyToClipboard value={dataContext[property.Name]}/>}
                {renderConnectivityCheckButton(property, dataContext, retrieveValuesFromParent)}
                <Note>{extendedDescription}</Note>
            </ExpandableFormSection>);
    };
    // Sorting properties by simple types first, then composite types
    const sortPropertiesByCompositeType = (left: PropertyMetadata, right: PropertyMetadata) => {
        const leftIsCompositeType = MetadataTypeHelpers.isCompositeType(left);
        const rightIsCompositeType = MetadataTypeHelpers.isCompositeType(right);
        return leftIsCompositeType === rightIsCompositeType ? 0 : leftIsCompositeType ? 1 : -1;
    };
    const renderConnectivityCheckButton = (property: PropertyMetadata, dataContext: DataContext, retrieveValuesFromParent: boolean) => {
        if (property.DisplayInfo.ConnectivityCheck && property.DisplayInfo.ConnectivityCheck.Url) {
            const values: {
                [key: string]: any;
            } = {};
            if (property.DisplayInfo.ConnectivityCheck.DependsOnPropertyNames && property.DisplayInfo.ConnectivityCheck.DependsOnPropertyNames.length > 0) {
                property.DisplayInfo.ConnectivityCheck.DependsOnPropertyNames.forEach((propName) => {
                    let value = dataContext[propName];
                    if (value === undefined && retrieveValuesFromParent && props.values && props.values[propName] !== undefined) {
                        value = props.values[propName];
                    }
                    if (typeof value === "object" && value !== null && Object.keys(value).indexOf("HasValue") !== -1) {
                        values[propName] = dataContext[propName].NewValue;
                    }
                    else {
                        values[propName] = value;
                    }
                });
            }
            return <DynamicConnectivityCheck title={property.DisplayInfo.ConnectivityCheck.Title} url={property.DisplayInfo.ConnectivityCheck.Url} values={values}/>;
        }
    };
    const renderSection = (compositeType: TypeMetadata, dataContext: DataContext, sectionName: string, parentPropertyName: string, isExpandedByDefault = false, retrieveValuesFromParent = true) => {
        const propMetadata = compositeType?.Properties?.sort(sortPropertiesByCompositeType);
        // Move "Is Enabled" toggle so that it is always ontop of the section
        const indexOfIsEnabled = propMetadata.findIndex((prop) => prop.Name === "IsEnabled");
        const isEnabledProp = indexOfIsEnabled > -1 ? propMetadata[indexOfIsEnabled] : null;
        if (isEnabledProp) {
            propMetadata.splice(indexOfIsEnabled, 1);
            propMetadata.unshift(isEnabledProp);
        }
        const types = propMetadata.map((t) => renderProperty(t, dataContext, parentPropertyName, isExpandedByDefault, retrieveValuesFromParent));
        const sectionHeading = sectionName && <FormSectionHeading title={sectionName} key={sectionName}/>;
        return (<div key={sectionName}>
                {sectionHeading}
                <div>{types}</div>
            </div>);
    };
    const onChange = (property: PropertyMetadata, dataContext: DataContext, value: any) => {
        let boundValue = value;
        if (property.Type === "string[]") {
            boundValue = value.split("\n");
        }
        // mutate state and trigger UI refresh
        // it would be really nice to replace this
        dataContext[property.Name] = boundValue;
        if (props.onChange) {
            props.onChange(props.values!);
        }
    };
    if (props && props.types && props.types.length > 0) {
        return (<div>
                {props.description && (<Section className={styles.markdownNote}>
                        <Markdown markup={props.description}/>
                    </Section>)}
                {renderSection(props.types[0], props.values!, null!, null!)}
            </div>);
    }
    else {
        logger.error("No types provided to DynamicForm");
    }
    return null;
};
DynamicForm.displayName = "DynamicForm"
export default DynamicForm;
