import { Tooltip } from "@octopusdeploy/design-system-components";
import type { ProjectResource, ProjectChangeFailureRateDeployment } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { TrendTable } from "~/areas/insights/components/Reports/TrendTables/TrendTable/TrendTable";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
interface Props {
    title: string;
    data: ProjectChangeFailureRateDeployment[];
    project: ProjectResource;
}
export function ProjectFailureRateTrendTable({ title, data, project }: Props) {
    const isTenanted = project.TenantedDeploymentMode != TenantedDeploymentMode.Untenanted;
    return (<TrendTable title={title} isEmpty={data.length === 0} note={<span>
                    The most recent <ExternalLink href="InsightsDeploymentFailureRate">failed deployments</ExternalLink> for the current filter. The first 10 in descending order by completed time are shown.
                </span>} headerContent={<DataTableRow>
                    {isTenanted && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    <DataTableHeaderColumn>State</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Guided failure</DataTableHeaderColumn>
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.Id}>
                            {isTenanted && <DataTableRowColumn>{r.TenantName}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(project.Slug).release(r.ReleaseVersion).root}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <Tooltip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.project(project.Slug).release(r.ReleaseVersion).deployments.specific(r.Id)}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </Tooltip>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.State}</DataTableRowColumn>
                            <DataTableRowColumn>{r.HadGuidedFailure ? "True" : "False"}</DataTableRowColumn>
                        </DataTableRow>))}
                </>}/>);
}
