import type { CloudConnectionType, GitRefType } from "@octopusdeploy/octopus-server-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import { useAnalyticLinkLocation, useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { getPageFields, useDispatchLinkClicked, usePage } from "@octopusdeploy/portal-routes";
import React, { useCallback, useEffect } from "react";
import type { AuditStreamType } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamType";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import { isErrors } from "~/components/DataBaseComponent/Errors";
import { formatUrl } from "~/components/Navigation/ExternalLink/ExternalLink";
import type { SampleProjectFields, TenantsWizardFields, ActionedFields, ActionFields, CloudConnectionsFields, DataTableExpansionFields, DeprecationNotificationFields, FilterFields, InsightsFields, LogoFields, OnboardingFields, OtherActionFields as SimpleActionFields, PagingFields, StatusFields, StepEditorFields, StepFields, ViewFields, ViewHelpFields, ConnectTenantsFields, } from "./AnalyticSession";
export interface ViewEvent {
    resource: string;
}
export interface ViewProps extends ViewEvent {
    // Override the name by specifying manually
    name?: string;
}
export interface ProjectViewProps extends ViewProps {
    projectId: string;
}
export type ResourceEvent = ViewEvent;
export enum Action {
    Add = "Add",
    Apply = "Apply",
    Autofill = "Autofill",
    Cancel = "Cancel",
    Clear = "Clear",
    Commit = "Commit",
    Configure = "Configure",
    Deploy = "Deploy",
    Dismiss = "Dismiss",
    Download = "Download",
    Edit = "Edit",
    Save = "Save",
    Select = "Select",
    ServerResponse = "Server Response",
    Test = "Test",
    Update = "Update",
    View = "View",
    Toggle = "Toggle",
    Pause = "Pause",
    Delete = "Delete",
    Resume = "Resume",
    RequestTrial = "Request Trial"
}
export interface ActionData {
    target?: string;
    stepTemplate?: string;
    source?: string;
}
export interface ActionEvent extends ResourceEvent {
    action: Action;
    actionMeta?: string;
    data?: ActionData;
    isCaCenabled?: "True" | "False" | undefined; // The type is based on usage, but it should be a boolean
    isDefaultBranch?: boolean;
    commitMessage?: boolean | undefined;
    status?: ActionStatus;
    gitRefType?: GitRefType;
    isProtectedBranch?: boolean | undefined;
    commitBranch?: "Same branch" | "New branch" | undefined;
    plan?: Plan;
    siemProvider?: AuditStreamType;
}
export enum ActionStatus {
    Success = "Success",
    SuccessWithWarning = "Success with Warning",
    Failed = "Failed"
}
export enum Plan {
    Enterprise = "Enterprise",
    NonEnterprise = "Non-Enterprise"
}
interface TrackedActionEvent extends ActionEvent {
    error: string | undefined;
    statusCode: number | undefined;
    status: ActionStatus;
    duration: number;
}
export interface LinkEvent {
    linkLabel: string;
    documentUrl: string | undefined;
}
export interface StepEvent extends ResourceEvent {
    action: Action;
    stepCategory: string;
    stepTemplate: string | undefined;
}
export interface StepEditorEvent extends ResourceEvent {
    action: Action;
    stepTemplate: string | undefined;
    value?: string;
}
export enum OnboardingType {
    step = "Onboarding Step"
}
export interface OnboardingEvent {
    linkLabel: string;
    onboardingType: OnboardingType;
}
export interface SampleProjectEvent {
    sampleProjectReference: string;
}
export interface InsightsEvent {
    action: Action;
    inputField?: "Environment" | "Channel" | "Tenant" | "Cadence" | "Split";
    cadence?: string;
    split?: string;
}
export enum LogoType {
    icon = "Icon",
    customImage = "Custom Image"
}
export interface LogoEvent extends ActionEvent, ResourceEvent {
    logoType: LogoType;
}
export interface CloudConnectionsEvent extends ActionEvent {
    cloudProvider: CloudConnectionType;
}
export interface PagingEvent extends ActionEvent {
    itemsPerPage: number;
}
export interface FilterEvent extends ActionEvent {
    numFilterRules: number;
    numUniqueOperators: number;
}
export interface DataTableExpansionEvent extends ActionEvent {
    category: string;
}
export interface ConnectTenantsEvent extends ActionEvent {
    numTenants: number;
}
export interface ViewHelpEvent {
    context: string;
}
export enum NotificationState {
    Raised = "Raised",
    Dismissed = "Dismissed"
}
export interface DeprecationNotificationEvent {
    state: NotificationState;
    deprecation: string;
}
export interface TenantWizardEvent extends ResourceEvent {
    action: Action;
    currentStepName: string;
}
export type AnalyticsDispatchEvent<TEvent> = (name: string, event: TEvent) => void;
export type AnalyticErrorCallback = (error: string | OctopusError | Errors) => void;
export type AnalyticTrackedActionDispatcher = <T>(name: string, event: ActionEvent, inner: (cb: AnalyticErrorCallback) => Promise<T>) => Promise<T>;
export type AnalyticActionDispatcher = AnalyticsDispatchEvent<ActionEvent>;
export type AnalyticLinkDispatcher = AnalyticsDispatchEvent<LinkEvent>;
export type AnalyticStepDispatcher = AnalyticsDispatchEvent<StepEvent>;
export type AnalyticStepEditorDispatcher = AnalyticsDispatchEvent<StepEditorEvent>;
export type AnalyticViewHelpDispatcher = AnalyticsDispatchEvent<ViewHelpEvent>;
export type AnalyticOnboardingDispatcher = AnalyticsDispatchEvent<OnboardingEvent>;
export type AnalyticSampleProjectDispatched = AnalyticsDispatchEvent<SampleProjectEvent>;
export type AnalyticViewDispatcher = AnalyticsDispatchEvent<ViewEvent>;
export type AnalyticSimpleDispatcher = (name: string) => void;
export type AnalyticLogoDispatcher = AnalyticsDispatchEvent<LogoEvent>;
export type AnalyticPagingDispatcher = <T>(name: string, event: PagingEvent, inner: () => Promise<T>) => Promise<T>;
export type AnalyticFilterDispatcher = <T>(name: string, event: FilterEvent, inner: () => Promise<T>) => Promise<T>;
export type AnalyticDataTableExpansionDispatcher = AnalyticsDispatchEvent<DataTableExpansionEvent>;
export type AnalyticConnectTenantsDispatcher = AnalyticsDispatchEvent<ConnectTenantsEvent>;
export type AnalyticInsightsDispatcher = AnalyticsDispatchEvent<InsightsEvent>;
export type AnalyticCloudConnectionsDispatcher = AnalyticsDispatchEvent<CloudConnectionsEvent>;
export type AnalyticDeprecationNotificationDispatcher = AnalyticsDispatchEvent<DeprecationNotificationEvent>;
export type AnalyticTenantWizardDispatcher = AnalyticsDispatchEvent<TenantWizardEvent>;
function getStatusText(code: number | undefined) {
    if (code === undefined) {
        return undefined;
    }
    switch (code) {
        case 400:
            return "Bad Request";
        case 401:
            return "Unauthorised";
        case 403:
            return "Forbidden";
        case 404:
            return "Not Found";
        case 409:
            return "Conflict";
        case 500:
            return "Server Error";
        case 503:
            return "Service Unavailable";
    }
    return "Error";
}
function toErrorCode(code: number | undefined) {
    return code === undefined ? undefined : `${code} - ${getStatusText(code)}`;
}
function getDataFields(data?: ActionData) {
    return {
        ...getPropIfDefined("Target Type", data?.target),
        ...getPropIfDefined("Step Template", data?.stepTemplate),
        ...getPropIfDefined("Source", data?.source),
    };
}
export function getErrorFields(statusCode: number | undefined, error: string | undefined) {
    return {
        ...getPropIfDefined("Error Code", toErrorCode(statusCode)),
        ...getPropIfDefined("Error Messages", error),
    };
}
function useAnalyticTrackedActionDispatchInternal(projectId?: string | undefined): AnalyticTrackedActionDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    function sendTrackedActionEvent(name: string, event: TrackedActionEvent) {
        if (!page) {
            return;
        }
        const fields: ActionFields = {
            "Action Type": event.action,
            "Action Meta": event.actionMeta,
            "Duration (ms)": event.duration.toFixed(2),
            ...getPageFields(page),
            "Resource Type": event.resource,
            Status: event.status,
            ...getDataFields(event.data),
            "Is CaC Enabled": event.isCaCenabled,
            "Project Id": projectId === undefined ? undefined : session.anonymize(projectId),
            ...getPropIfDefined("Error Code", toErrorCode(event.statusCode)),
            ...getPropIfDefined("Error Messages", event.error),
        };
        if (typeof event.commitMessage === "boolean") {
            fields["Commit Message"] = event.commitMessage ? "True" : "False";
        }
        if (typeof event.isDefaultBranch === "boolean") {
            fields["Is Default Branch"] = event.isDefaultBranch ? "True" : "False";
        }
        if (typeof event.isProtectedBranch === "boolean") {
            fields["Is Protected Branch"] = event.isProtectedBranch ? "True" : "False";
        }
        if (typeof event.commitBranch === "string") {
            fields["Commit Branch"] = event.commitBranch;
        }
        if (typeof event.siemProvider === "string") {
            fields["SIEM Provider"] = event.siemProvider;
        }
        const status: StatusFields = {
            "Action Type": event.action,
            ...getPropIfDefined("Error Code", toErrorCode(event.statusCode)),
            ...getPropIfDefined("Error Messages", event.error),
            Status: event.status,
            "Resource Type": event.resource,
        };
        session.track(name, fields);
        session.track(event.status, status);
    }
    async function dispatch<T>(name: string, event: ActionEvent, inner: (cb: AnalyticErrorCallback) => Promise<T>): Promise<T> {
        const start = performance.now();
        let errorList: Array<string> = [];
        let statusCode: number | undefined = undefined;
        let status: ActionStatus = ActionStatus.Success;
        const addErrors = (errors?: Array<string>, error?: string) => {
            if (errors && errors.length > 0) {
                errorList = [...errorList, ...errors];
            }
            else if (error) {
                errorList = [...errorList, error];
            }
        };
        const onError = (error: string | OctopusError | Errors) => {
            status = ActionStatus.Failed;
            if (error instanceof OctopusError) {
                statusCode = statusCode ?? error.StatusCode;
                addErrors(error.Errors, error.ErrorMessage);
            }
            else if (isErrors(error)) {
                statusCode = statusCode ?? error.statusCode;
                addErrors(error.errors, error.message);
            }
            else {
                addErrors(undefined, error);
            }
        };
        try {
            return await inner(onError);
        }
        catch (e) {
            if (e instanceof OctopusError) {
                onError(e);
            }
            else if (e instanceof Error) {
                onError(e.message);
            }
            throw e;
        }
        finally {
            const error = errorList && errorList.length > 0 ? JSON.stringify(errorList) : undefined;
            sendTrackedActionEvent(name, { ...event, error, status, statusCode, duration: performance.now() - start });
        }
    }
    return dispatch;
}
export function useAnalyticTrackedActionDispatch(): AnalyticTrackedActionDispatcher {
    return useAnalyticTrackedActionDispatchInternal();
}
export function useProjectScopedAnalyticTrackedActionDispatch(projectId: string | undefined): AnalyticTrackedActionDispatcher {
    return useAnalyticTrackedActionDispatchInternal(projectId);
}
function useAnalyticActionDispatchInternal(projectId?: string | undefined): AnalyticActionDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: ActionEvent) => {
        if (!page) {
            return;
        }
        const fields: ActionedFields = {
            "Action Type": event.action,
            ...getPageFields(page),
            "Resource Type": event.resource,
            ...getDataFields(event.data),
            Status: event.status,
            "Project Id": projectId === undefined ? undefined : session.anonymize(projectId),
        };
        if (typeof event.isCaCenabled === "string") {
            fields["Is CaC Enabled"] = event.isCaCenabled;
        }
        if (typeof event.commitMessage === "boolean") {
            fields["Commit Message"] = event.commitMessage ? "True" : "False";
        }
        if (typeof event.isDefaultBranch === "boolean") {
            fields["Is Default Branch"] = event.isDefaultBranch ? "True" : "False";
        }
        if (typeof event.gitRefType === "string") {
            fields["Git Ref Type"] = event.gitRefType;
        }
        if (typeof event.plan === "string") {
            fields["Plan"] = event.plan;
        }
        session.track(name, fields);
    };
}
export function useAnalyticActionDispatch(): AnalyticActionDispatcher {
    return useAnalyticActionDispatchInternal();
}
export function useProjectScopedAnalyticActionDispatch(projectId: string | undefined): AnalyticActionDispatcher {
    return useAnalyticActionDispatchInternal(projectId);
}
export function useAnalyticExternalLinkDispatch(): (linkLabel: string, href: string, name: string) => void {
    const dispatchLink = useDispatchLinkClicked();
    return useCallback((linkLabel: string, href: string, name: string) => {
        const ev: LinkEvent = {
            linkLabel,
            documentUrl: formatUrl(href),
        };
        dispatchLink(name, ev);
    }, [dispatchLink]);
}
export function nameForDocument(href: string) {
    return `Visit Documentation`;
}
export function nameForView(resource: string) {
    return `View ${resource}`;
}
export function useAnalyticStepDispatch(projectId?: string | undefined): AnalyticStepDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: StepEvent) => {
        if (!page) {
            return;
        }
        const fields: StepFields = {
            "Action Type": event.action,
            ...getPageFields(page),
            "Resource Type": event.resource,
            "Step Category": event.stepCategory,
            ...getPropIfDefined("Step Template", event.stepTemplate),
            "Project Id": projectId === undefined ? undefined : session.anonymize(projectId),
        };
        session.track(name, fields);
    };
}
export function useAnalyticsStepEditorDispatch(stepId: string, projectId?: string | undefined): AnalyticStepEditorDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: StepEditorEvent) => {
        if (!page) {
            return;
        }
        const fields: StepEditorFields = {
            "Action Type": event.action,
            ...getPageFields(page),
            "Resource Type": event.resource,
            ...getPropIfDefined("Step Template", event.stepTemplate),
            "Project Id": projectId === undefined ? undefined : session.anonymize(projectId),
            "Step Id": session.anonymize(stepId),
            ...getPropIfDefined("Value", event.value),
        };
        session.track(name, fields);
    };
}
export function useAnalyticInsightsDispatch(): AnalyticInsightsDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: InsightsEvent) => {
        if (!page) {
            return;
        }
        const fields: InsightsFields = {
            "Action Type": event.action,
            ...getPageFields(page),
            ...getPropIfDefined("Insights Input Field", event.inputField),
            ...getPropIfDefined("Cadence", event.cadence),
        };
        session.track(name, fields);
    };
}
export function useAnalyticsViewHelpDispatch(): AnalyticViewHelpDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: ViewHelpEvent) => {
        if (!page) {
            return;
        }
        const fields: ViewHelpFields = {
            Context: event.context,
            ...getPageFields(page),
        };
        session.track(name, fields);
    };
}
export function useAnalyticOnboardingDispatch(): AnalyticOnboardingDispatcher {
    const session = useAnalyticSession();
    const linkLocation = useAnalyticLinkLocation();
    const page = usePage();
    return (name: string, event: OnboardingEvent) => {
        if (!page) {
            return;
        }
        const fields: OnboardingFields = {
            "Link Label": event.linkLabel,
            "Link Location": linkLocation ?? "",
            "Onboarding Type": event.onboardingType,
            ...getPageFields(page),
        };
        session.track(name, fields);
    };
}
export function useAnalyticSampleProjectDispatch(): AnalyticSampleProjectDispatched {
    const session = useAnalyticSession();
    return (name: string, event: SampleProjectEvent) => {
        const fields: SampleProjectFields = {
            "Sample Project Reference": event.sampleProjectReference,
        };
        session.track(name, fields);
    };
}
export function useAnalyticLogoDispatch(projectId?: string): AnalyticLogoDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: LogoEvent) => {
        if (!page) {
            return;
        }
        const fields: LogoFields = {
            "Action Type": Action.Save,
            "Resource Type": event.resource,
            "Logo Type": event.logoType,
            "Project Id": projectId === undefined ? undefined : session.anonymize(projectId),
            ...getPageFields(page),
        };
        session.track(name, fields);
    };
}
export function useAnalyticPagingDispatch(): AnalyticPagingDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    async function dispatch<T>(name: string, event: PagingEvent, inner: () => Promise<T>): Promise<T> {
        const start = performance.now();
        try {
            return await inner();
        }
        finally {
            const fields: PagingFields = {
                "Action Type": event.action,
                "Resource Type": event.resource,
                "Items Per Page": event.itemsPerPage,
                "Duration (ms)": (performance.now() - start).toFixed(2),
                ...getPageFields(page),
            };
            session.track(name, fields);
        }
    }
    return dispatch;
}
export function useAnalyticFilterDispatch(): AnalyticFilterDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    async function dispatch<T>(name: string, event: FilterEvent, inner: () => Promise<T>): Promise<T> {
        const start = performance.now();
        try {
            return await inner();
        }
        finally {
            const fields: FilterFields = {
                "Action Type": event.action,
                "Resource Type": event.resource,
                "Number of Filter Rules": event.numFilterRules,
                "Number of Unique Operators": event.numUniqueOperators,
                "Duration (ms)": (performance.now() - start).toFixed(2),
                ...getPageFields(page),
            };
            session.track(name, fields);
        }
    }
    return dispatch;
}
export function useAnalyticDataTableExpansionDispatch(): AnalyticDataTableExpansionDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: DataTableExpansionEvent) => {
        if (!page) {
            return;
        }
        const fields: DataTableExpansionFields = {
            "Action Type": event.action,
            "Resource Type": event.resource,
            Category: event.category,
            ...getPageFields(page),
        };
        session.track(name, fields);
    };
}
export function useAnalyticConnectTenantsDispatch(): AnalyticConnectTenantsDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: ConnectTenantsEvent) => {
        if (!page) {
            return;
        }
        const fields: ConnectTenantsFields = {
            "Action Type": event.action,
            "Resource Type": event.resource,
            "Number of Tenants": event.numTenants,
            ...getPageFields(page),
        };
        session.track(name, fields);
    };
}
export function useAnalyticCloudConnectionsDispatch(): AnalyticCloudConnectionsDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: CloudConnectionsEvent) => {
        if (!page) {
            return;
        }
        const fields: CloudConnectionsFields = {
            "Action Type": event.action,
            "Resource Type": event.resource,
            "Cloud Provider": event.cloudProvider,
            ...getPageFields(page),
        };
        session.track(name, fields);
    };
}
export function useAnalyticViewDispatch(projectId?: string | undefined): AnalyticViewDispatcher {
    const session = useAnalyticSession();
    return (name: string, event: ViewEvent) => {
        const fields: ViewFields = {
            "Resource Type": event.resource,
            "Project Id": projectId === undefined ? undefined : session.anonymize(projectId),
        };
        session.track(name, fields);
    };
}
export function useAnalyticSimpleActionDispatch(): AnalyticSimpleDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string) => {
        if (!page) {
            return;
        }
        const fields: SimpleActionFields = {
            ...getPageFields(page),
        };
        session.track(name, fields);
    };
}
export function useAnalyticDeprecationNotificationDispatch(): AnalyticDeprecationNotificationDispatcher {
    const session = useAnalyticSession();
    return (name: string, event: DeprecationNotificationEvent) => {
        const fields: DeprecationNotificationFields = {
            State: event.state,
            Deprecation: event.deprecation,
        };
        session.track(name, fields);
    };
}
export function useAnalyticConnectTenantWizardDialogDispatch(): AnalyticTenantWizardDispatcher {
    const session = useAnalyticSession();
    const page = usePage();
    return (name: string, event: TenantWizardEvent) => {
        if (!page) {
            return;
        }
        const fields: TenantsWizardFields = {
            "Action Type": event.action,
            ...getPageFields(page),
            "Resource Type": event.resource,
            Step: event.currentStepName,
        };
        session.track(name, fields);
    };
}
// <AnalyticView> element allows for the sending of a once-off view event when the element is rendered on the page
export function AnalyticView(props: ViewProps) {
    const dispatch = useAnalyticViewDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(props.name ?? nameForView(props.resource), { resource: props.resource }), []);
    return <></>;
}
export function ProjectAnalyticView(props: ProjectViewProps) {
    const dispatch = useAnalyticViewDispatch(props.projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(props.name ?? nameForView(props.resource), { resource: props.resource }), []);
    return <></>;
}
function getPropIfDefined(name: string, value?: string): object {
    return value !== undefined ? { [name]: value } : {};
}
