/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LinearProgress, IconButton } from "@octopusdeploy/design-system-components";
import { compact } from "lodash";
import * as React from "react";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import UseLabelStrategy from "~/components/LabelStrategy/LabelStrategy";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import VirtualListWithKeyboard from "~/components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import Text from "~/primitiveComponents/form/Text/Text";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import IconButtonList from "../IconButtonList/IconButtonList";
import styles from "./style.module.less";
const keycode = require("keycode");
export interface AutoCompleteProps {
    allowAnyTextValue?: boolean; // set to true if you are allowed to enter a value that does not appear in the popup
    name: string;
    label?: string;
    value: string;
    error?: string;
    placeholder?: string;
    showEmpty?: boolean;
    getOptions(searchText: string): Promise<AutoCompleteSearchResults>;
    onChange(value: string): void;
    textInputRef?(textInput: TextInput | null): void;
}
export interface AutoCompleteSearchResults {
    items: ReadonlyArray<AutoCompleteOption>;
    containsAllResults: boolean;
}
export interface AutoCompleteOption {
    Id: string;
    Name: string;
    display?: React.ReactNode;
}
interface AutoCompleteState extends DataBaseComponentState {
    open: boolean;
    displayedValue: string;
    options: AutoCompleteSearchResults | null;
    placeholder?: string;
}
const DebounceText = DebounceValue(Text);
const VirtualList = VirtualListWithKeyboard<AutoCompleteOption>();
class AutoCompleteInternal extends DataBaseComponent<AutoCompleteProps, AutoCompleteState> {
    static defaultProps: Partial<AutoCompleteProps> = {
        showEmpty: true,
    };
    private textField: TextInput | null = null;
    private textAnchor: HTMLDivElement | null = null;
    private popoverContent: HTMLDivElement | undefined | null;
    private virtualList: FocusableComponent | undefined | null;
    private timeoutId: number | undefined;
    private updatePopoverPosition: () => void = undefined!;
    constructor(props: AutoCompleteProps) {
        super(props);
        this.state = {
            open: false,
            displayedValue: props.value,
            options: null,
        };
    }
    UNSAFE_componentWillMount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(this as TextInput);
        }
    }
    componentDidMount() {
        window.document.addEventListener("keydown", this.onGlobalKeyDown);
    }
    componentWillUnmount() {
        this.cancelTimerToTrackNavigateAway();
        window.document.removeEventListener("keydown", this.onGlobalKeyDown);
        if (this.props.textInputRef) {
            this.props.textInputRef(null);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: AutoCompleteProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ displayedValue: nextProps.value });
        }
    }
    render() {
        if (this.state.open) {
            this.updatePopoverPositionWorkaround();
        }
        return (<div className={styles.autoCompleteContainer}>
                <div ref={(textAnchor) => (this.textAnchor = textAnchor)} className={styles.textContainer} onKeyDown={(ev) => this.onKeyDown(ev)}>
                    <DebounceText textInputRef={(textField) => (this.textField = textField)} name={this.props.name} label={this.props.label} value={this.state.displayedValue} error={this.props.error} placeholder={this.state.placeholder} onChange={(value) => this.onSearchTextChange(value)} onFocus={this.onFocus} onClick={async (ev) => {
                ev.stopPropagation(); // This could bubble up and close the popup, even though the same mousedown event opened it (by focusing the input)
                await this.onFocus();
            }} onBlur={(ev) => this.onBlurFromText(ev)}/>
                    <div className={styles.buttons}>
                        <IconButtonList buttons={this.buttons()}/>
                    </div>
                </div>

                <Popover open={this.state.open} anchorEl={this.textAnchor} onClose={() => this.setState({ open: false })} anchorOrigin={{ horizontal: "left", vertical: "bottom" }} transformOrigin={{ horizontal: "left", vertical: "top" }} disableAutoFocus={true} disableEnforceFocus={true} className={styles.popover} getUpdatePosition={(update) => (this.updatePopoverPosition = update)}>
                    <div onFocus={this.cancelTimerToTrackNavigateAway} onBlur={this.startTimerToTrackNavigateAway} 
        // Todo: review this change. It was added to allow annotation suggestions to be displayed
        // at a reasonable size.
        style={{ minWidth: this.textAnchor ? `${Math.max(450, this.textAnchor.offsetWidth)}px` : undefined }} ref={(popoverContent) => (this.popoverContent = popoverContent)}>
                        <div className={styles.popoverContent}>
                            <BusyFromPromise promise={this.state.busy}>{(busy) => <LinearProgress variant={"indeterminate"} show={busy}/>}</BusyFromPromise>
                            {this.renderErrors()}
                            {!this.state.options && this.state.busy && <div className={styles.popoverInfoText}>Searching...</div>}
                            {this.state.options && (<VirtualList maxHeightInRem={17.5} multiSelectRef={(el) => (this.virtualList = el)} items={this.state.options.items} renderItem={(item) => ({ primaryText: item.display || item.Name })} onSelected={(id) => this.selectOption(id)} onResized={() => {
                    // When the content's size changes, we re-render so that the
                    // popover can re-position itself based on the new `VirtualList` size
                    // if (this.updatePopoverPosition) {
                    //     this.updatePopoverPosition();
                    // }
                    this.updatePopoverPositionWorkaround();
                }} onBlur={() => this.textField?.focus()}/>)}
                        </div>
                        {/*This bit is outside of the scrollable container so that it always displays*/}
                        {this.state.options && !this.state.options.containsAllResults && <div className={styles.warning}>Only showing {this.state.options.items.length} options, for more results narrow your search criteria</div>}
                    </div>
                </Popover>
            </div>);
    }
    renderErrors() {
        const errors = this.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} scrollToPanel={false}/>;
    }
    isFocused() {
        return this.textField && this.textField.isFocused();
    }
    focus() {
        if (this.textField) {
            this.textField.focus();
        }
    }
    blur() {
        if (this.textField) {
            this.textField.blur();
        }
    }
    insertAtCursor(value: string) {
        if (!this.textField) {
            return;
        }
        this.textField.insertAtCursor(value);
    }
    setValueAndSelection = (selection: {
        start: any;
        end: any;
    }, value: string) => {
        this.textField?.setValueAndSelection(selection, value);
    };
    getSelection = () => {
        return this.textField && this.textField.getSelection();
    };
    select() {
        if (this.textField) {
            this.textField.select();
        }
    }
    private cancelTimerToTrackNavigateAway = () => {
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
    };
    private startTimerToTrackNavigateAway = () => {
        if (this.timeoutId === undefined) {
            this.timeoutId = window.setTimeout(() => this.onNavigateAwayFromAutoComplete(), 0);
        }
    };
    private focusText() {
        if (this.textField && !this.textField.isFocused()) {
            this.textField.focus();
        }
    }
    private onBlurFromText(ev: React.FocusEvent<HTMLDivElement>) {
        this.startTimerToTrackNavigateAway();
    }
    private onNavigateAwayFromAutoComplete() {
        this.setState({ placeholder: undefined, open: false }, () => {
            this.setState({ options: null, displayedValue: this.props.value });
        });
    }
    private onFocus = async () => {
        this.cancelTimerToTrackNavigateAway();
        this.setState({ placeholder: this.props.placeholder, options: null });
        if (!this.props.showEmpty && (!this.state || this.state.displayedValue === "")) {
            this.setState({ open: false, options: null });
            return;
        }
        if (!this.state.open && this.state.options === null) {
            this.setState({ open: true });
            await this.requestOptions(this.state.displayedValue ?? undefined);
        }
    };
    private onKeyDown(ev: React.KeyboardEvent<HTMLDivElement>) {
        const code = keycode(ev);
        if (code === "down") {
            if (this.state.options && this.state.options.items.length > 0) {
                this.setState({ open: true });
            }
            if (this.virtualList && this.state.options && this.state.options.items.length) {
                ev.preventDefault();
                this.virtualList.focus();
            }
        }
        if (code === "enter" && this.state.options?.items.length === 1) {
            this.selectOption(this.state.options.items[0].Id);
        }
    }
    private async onSearchTextChange(searchText: string) {
        const open = searchText !== "" || this.props.showEmpty;
        this.setState((prev: AutoCompleteState) => ({ displayedValue: searchText, open, options: prev.open ? prev.options : null }));
        if (this.props.allowAnyTextValue) {
            this.props.onChange(searchText);
        }
        if (open) {
            await this.requestOptions(searchText);
        }
    }
    private async requestOptions(searchText?: string) {
        await this.doBusyTask(async () => {
            const options = await this.props.getOptions(searchText!);
            this.setState({ options });
        });
    }
    private selectOption(id: string) {
        const option = this.state.options!.items.find((i) => i.Id === id);
        this.setState({ displayedValue: option!.Name, open: false });
        this.props.onChange(option!.Id);
    }
    private clearValue() {
        this.setState({ open: false, displayedValue: "", options: null });
        this.props.onChange("");
    }
    private onGlobalKeyDown = (event: KeyboardEvent) => {
        const code = keycode(event);
        if (code === "esc") {
            if (this.state.open) {
                this.setState({ open: false, options: null, displayedValue: this.props.value });
            }
        }
    };
    private buttons() {
        return compact([
            !!this.props.value && <IconButton icon="Cancel" onClick={() => this.clearValue()} tabIndex={-1}/>,
            <IconButton icon="ArrowDown" accessibleName={this.props.label ? `View options for ${this.props.label}` : undefined} onClick={() => this.focusText()} tabIndex={-1}/>,
        ]);
    }
    // TODO: This is a workaround to this issue - https://github.com/mui-org/material-ui/issues/16901
    // MUI Core version at the time of this workaround: 4.0.2, official MUI fix is in 4.6.0
    // What's happening on octopus? - If the list goes beyond the window, the overflow is hidden and you can scroll
    // dispatching a resize event, fires an internal update function in MUI. The `updatePopoverPosition` function in
    // no longer works, therefore same work around is use in line onResized function in virtual list
    private updatePopoverPositionWorkaround = () => {
        window.requestAnimationFrame(() => {
            window.dispatchEvent(new CustomEvent("resize"));
        });
    };
    static displayName = "AutoCompleteInternal";
}
const AutoComplete = UseLabelStrategy(AutoCompleteInternal, (fieldName) => fieldName);
export default AutoComplete;
