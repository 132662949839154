import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import type { LocationDescriptor } from "history";
import React, { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ProjectCreatedOption } from "~/areas/projects/components/Projects/AddProject";
import AddProject from "~/areas/projects/components/Projects/AddProject";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import routeLinks from "~/routeLinks";
import addProjectImage from "./assets/img-addproject.svg";
interface AddNewProjectDialogProps {
    open: boolean;
    close: (project?: ProjectResource) => void;
    projectGroupId?: string;
    cancelButtonLabel?: string;
}
function AddNewProjectDialog({ open, close, projectGroupId, cancelButtonLabel }: AddNewProjectDialogProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const [navigateTo, setNavigateTo] = useState<LocationDescriptor | undefined>(undefined);
    const onProjectCreated = (project: ProjectResource, option?: ProjectCreatedOption) => {
        const projectProcessUrl = option?.vcsRedirect ? routeLinks.project(project.Slug).settings.versionControl : routeLinks.project(project.Slug).deployments.process.root;
        setNavigateTo(`${projectProcessUrl}?newlyCreatedProject=true`);
        close(project);
    };
    const onToggleHelp = () => {
        dispatchAction("Toggle First Project Dialog Help Section", { action: Action.Toggle, resource: "Project" });
    };
    if (navigateTo) {
        return <InternalRedirect to={navigateTo}/>;
    }
    const helpPanelContent = (<span>
            <strong>Projects</strong> let you manage multiple software applications, each with its own deployment process.
        </span>);
    return (<OnboardingDialog name="add-new-project" helpPanelImage={{ src: addProjectImage, altText: "Add New Project" }} helpPanelContent={helpPanelContent} open={open} close={close} onToggleHelp={onToggleHelp} useBackwardsCompatiblePadding={true}>
            <AddProject projectCreated={onProjectCreated} hideEnvironmentConfigWarning={true} projectNameTextBoxLabel={"Project Name"} groupId={projectGroupId} onCancelled={close} cancelButtonLabel={cancelButtonLabel}/>
        </OnboardingDialog>);
}
export default AddNewProjectDialog;
