/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ResourcesById, TaskResource, RunbookRunResource, ProjectResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { withProjectContext } from "~/areas/projects/context";
import Task from "~/areas/tasks/components/Task/Task";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";
import type { WithRunbookContextInjectedProps } from "./RunbookContext";
import { withRunbookContext } from "./RunbookContext";
interface RunbookRunDetailsState extends DataBaseComponentState {
    project?: ProjectResource;
    runbookRun?: RunbookRunResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}
type RunbookRunDetailsProps = RouteComponentProps<ProjectRouteParams & {
    runbookRunId: string;
}> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;
class RunbookRunDetailsInternal extends DataBaseComponent<RunbookRunDetailsProps, RunbookRunDetailsState> {
    constructor(props: RunbookRunDetailsProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookRunDetailsProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }
        await this.doBusyTask(async () => {
            const runbookRun = await repository.RunbookRuns.get(this.props.match.params.runbookRunId);
            this.setState({ runbookRun, project });
        });
    }
    getPrimaryPageAction = (task: TaskResource<any>): PrimaryPageAction | undefined => {
        if (task.IsCompleted && !task.FinishedSuccessfully) {
            const tryAgainLink = routeLinks.project(this.state.project!).operations.runbook(this.state.runbookRun!.RunbookId).runbookSnapshot(this.state.runbookRun!.RunbookSnapshotId).runbookRuns.retry(this.state.runbookRun!.Id);
            return {
                type: "navigate",
                label: "Try again...",
                path: tryAgainLink,
                hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: this.state.runbookRun!.ProjectId, environment: this.state.runbookRun!.EnvironmentId, wildcard: true }),
            };
        }
    };
    render() {
        const runbookRun = this.state.runbookRun;
        if (runbookRun) {
            return <Task taskId={runbookRun.TaskId} delayRender={() => false} projectId={this.state.project!.Id} environmentId={runbookRun.EnvironmentId} tenantId={runbookRun.TenantId} getPrimaryPageAction={this.getPrimaryPageAction}/>;
        }
        return <ProjectPageLayout busy={this.state.busy} title="Task" errors={this.errors}/>;
    }
    static displayName = "RunbookRunDetailsInternal";
}
export default withRunbookContext(withProjectContext(RunbookRunDetailsInternal));
