import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import DeploymentProcessSettings from "../DeploymentProcessSettings";
export const DeploymentProcessSettingsPage = withPage({ page: pageIds.project().deploymentSettings })(DeploymentProcessSettings);
type DeploymentSettingsRouteProps = {
    path: string;
};
function DeploymentSettingsRoute({ path }: DeploymentSettingsRouteProps) {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    if (isRoutingVNextEnabled) {
        return null;
    }
    return (<BranchAwareRedirect>
            <ErrorContextProvider>
                <Switch>
                    <Route path={`${path}`} exact={true} render={() => <DeploymentProcessSettingsPage />}/>
                    <RedirectAs404 />
                </Switch>
            </ErrorContextProvider>
        </BranchAwareRedirect>);
}
export function NonVcsDeploymentSettingsRoute() {
    return <DeploymentSettingsRoute path={routeLinks.projectRoutes().deployments.settings}/>;
}
export default DeploymentSettingsRoute;
