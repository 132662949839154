import { css } from "@emotion/css";
import { Checkbox } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import URI from "urijs";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { AddOrCloneTenant } from "~/areas/tenants/Tenants/AddOrCloneTenant";
import CurrentFilterSelection from "~/areas/tenants/Tenants/CurrentFilterSelection/CurrentFilterSelection";
import Onboarding from "~/areas/tenants/Tenants/Onboarding";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { useTenantsState } from "~/areas/tenants/Tenants/hooks/useTenantsState";
import TenantsDataTable from "~/areas/tenants/components/DataTable/TenantsDataTable";
import { FilterBuilder } from "~/areas/tenants/components/Filtering/FilterBuilder/FilterBuilder";
import type { FilterValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { createEnvironmentFilter, createProjectFilter, createTagSetFilters, getExcludedEnvironmentValue, getExcludedProjectValue, getExcludedTagSetValues, getIncludedEnvironmentValue, getIncludedProjectValue, getIncludedTagSetValues, } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import type { TenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import { useTenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import CollapsibleFilter, { VerticalDivider } from "~/areas/tenants/components/HeaderBar/CollapsibleFilter";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
import { repository } from "~/clientInstance";
import CopyToClipboardButton from "~/components/CopyToClipboardButton/index";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Text } from "~/components/form/index";
import routeLinks from "~/routeLinks";
type TenantsProps = {
    trackAction: AnalyticActionDispatcher;
};
class TenantsInternal extends DataBaseComponent<TenantsProps> {
    constructor(props: TenantsProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (<TenantsLayout errors={this.errors} busy={this.state.busy}>
                <TenantsContent doBusyTask={this.doBusyTask}/>
            </TenantsLayout>);
    }
    static displayName = "TenantsInternal";
}
interface TenantsLayoutProps {
    busy?: Promise<void>;
    errors: Errors | undefined;
}
function TenantsLayout({ children, busy, errors }: React.PropsWithChildren<TenantsLayoutProps>) {
    const addOrCloneTenantAction: PrimaryPageAction = {
        type: "custom",
        key: "Add or clone tenant",
        content: <AddOrCloneTenant />,
        hasPermissions: isAllowed({ permission: Permission.TenantCreate }),
    };
    return (<FullWidthPageLayout areaTitleLink={routeLinks.tenants} areaTitle="Tenants" actions={<AddOrCloneTenant />} primaryAction={addOrCloneTenantAction} busy={busy} errors={errors}>
            {children}
        </FullWidthPageLayout>);
}
interface TenantsContentProps {
    doBusyTask: DoBusyTask;
}
function TenantsContent({ doBusyTask }: TenantsContentProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const { tableState, pageSize, filterState, tenantsActions, dataKey } = useTenantsState(doBusyTask);
    const filterData = useTenantFiltersData(doBusyTask);
    async function onCsvDownloadRequested() {
        dispatchAction("Download CSV", { resource: "Tenant", action: Action.Download });
        await doBusyTask(async () => repository.Tenants.tenantsCsv(filterState));
    }
    if (!tableState || !filterData) {
        return null;
    }
    const { tenants, filteredTenantCount, totalTenantCount } = tableState;
    const paginationTotalCount = filteredTenantCount === null ? totalTenantCount : filteredTenantCount;
    if (totalTenantCount === 0) {
        return <Onboarding />;
    }
    return (<div className={styles.tenantsContainer}>
            <CollapsibleFilter filteredCount={filteredTenantCount} totalCount={totalTenantCount} entityName={"Tenant"} secondaryContent={<CurrentFilterSelection filterState={filterState} onFilterChange={tenantsActions.setTenantsFilter} data={filterData}/>} actions={<TenantsActions onCsvDownloadRequested={onCsvDownloadRequested}/>} ariaLabel={"current-filter-selection"}>
                <TenantsFilter filterState={filterState} onFilterChange={tenantsActions.setTenantsFilter} tenantFiltersData={filterData}/>
            </CollapsibleFilter>
            <TenantsDataTable key={dataKey} tenants={tenants} totalItems={totalTenantCount} includeMissingVariables={filterState.includeMissingVariablesStatus} doBusyTask={doBusyTask}>
                <NumberedPagingBar totalItems={paginationTotalCount} pageNumber={filterState.pageNumber} pageSize={pageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            tenantsActions.setPageNumber(newPageNumber);
            tenantsActions.setPageSize(newPageSize);
        }} pageSizeOptions={[30, 50, 100]}/>
            </TenantsDataTable>
        </div>);
}
interface FilterProps {
    tenantFiltersData: TenantFiltersData;
    onFilterChange: (newTenantsFilter: TenantsFilterState) => void;
    filterState: TenantsFilterState;
}
function TenantsFilter({ onFilterChange, tenantFiltersData, filterState }: FilterProps) {
    const projectFilter = createProjectFilter(Array.from(tenantFiltersData.projects.values()), filterState.filterByProject, filterState.filterByExcludedProject);
    const environmentFilter = createEnvironmentFilter(Array.from(tenantFiltersData.environments.values()), filterState.filterByEnvironment, filterState.filterByExcludedEnvironment);
    const tagSetFilters = createTagSetFilters(Array.from(tenantFiltersData.tagSets.values()), filterState.filterByTags, filterState.filterByExcludedTags);
    const handleFilterChange = (newFilters: FilterValue[]) => {
        onFilterChange({
            ...filterState,
            filterByProject: getIncludedProjectValue(newFilters),
            filterByExcludedProject: getExcludedProjectValue(newFilters),
            filterByEnvironment: getIncludedEnvironmentValue(newFilters),
            filterByExcludedEnvironment: getExcludedEnvironmentValue(newFilters),
            filterByTags: getIncludedTagSetValues(newFilters),
            filterByExcludedTags: getExcludedTagSetValues(newFilters),
        });
    };
    const onIncludeMissingVariablesChanged = (include: boolean) => {
        onFilterChange({ ...filterState, includeMissingVariablesStatus: include });
    };
    return (<div className={styles.filters}>
            <IncludeMissingVariables include={filterState.includeMissingVariablesStatus} onChanged={onIncludeMissingVariablesChanged}/>
            <VerticalDivider />
            <FilterBuilder filters={[projectFilter, environmentFilter, ...tagSetFilters]} onChange={handleFilterChange}/>
        </div>);
}
interface IncludeMissingVariablesProps {
    include: boolean;
    onChanged: (value: boolean) => void;
}
export function IncludeMissingVariables({ include, onChanged }: IncludeMissingVariablesProps) {
    return (<div>
            <div className={styles.displayOptionsTitle}>Show</div>
            <Checkbox value={include} onChange={onChanged} label={"Missing variables warning"}/>
        </div>);
}
export function TenantsActions({ onCsvDownloadRequested }: {
    onCsvDownloadRequested: () => Promise<void>;
}) {
    return (<div className={styles.toolbarIconsContainer}>
            <ShareButton />
            <DownloadCsvButton onCsvDownloadRequested={onCsvDownloadRequested}/>
        </div>);
}
export function ShareButton() {
    return (<OpenDialogIconButton icon={"Share"} toolTipContent={"Share filter"}>
            <ShareDialogLayout />
        </OpenDialogIconButton>);
}
function ShareDialogLayout() {
    const currentUri = URI(window.location).valueOf();
    return (<InfoDialogLayout title={"Share filter"}>
            All users who have view permission to this page can view this filter.
            <div className={styles.shareContainer}>
                <Text value={currentUri} onChange={noOp} readOnly={true}/>
                <CopyToClipboardButton value={currentUri}/>
            </div>
        </InfoDialogLayout>);
}
export function DownloadCsvButton({ onCsvDownloadRequested }: {
    onCsvDownloadRequested: () => Promise<void>;
}) {
    return <IconButtonWithTooltip icon={"SaveAlt"} onClick={onCsvDownloadRequested} toolTipContent={"Download CSV"}/>;
}
const styles = {
    tenantsContainer: css({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "1rem",
    }),
    filters: css({
        display: "flex",
        gap: "0.6rem",
        padding: "1rem",
    }),
    shareContainer: css({
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
    }),
    displayOptionsTitle: css({
        color: themeTokens.color.text.disabled,
    }),
    toolbarIconsContainer: css({
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
    }),
};
export default function Tenants() {
    const trackAction = useAnalyticActionDispatch();
    return <TenantsInternal trackAction={trackAction}/>;
}
