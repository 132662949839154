import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { type AccountResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/Drawer/Drawer";
import type { AccountEditModel, AccountEditPropsInternal, AccountEditState } from "./AccountEditBase";
import AccountEditInternal from "./AccountEditBase";
interface DrawerState<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends AccountEditState<TAccountResource, TModel> {
    drawerOpen: boolean;
}
abstract class AccountEditBaseDrawer<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends AccountEditInternal<TAccountResource, DrawerState<TAccountResource, TModel>, TModel> {
    constructor(props: AccountEditPropsInternal<TAccountResource>) {
        super(props);
        this.state = { ...this.initialState(), drawerOpen: true };
    }
    render() {
        const accountData = this.state.accountData;
        const title = accountData?.account.Name ?? "Create Account";
        const customActions = this.getPageActions().map((action) => {
            if (action.type !== "button")
                return null;
            return <ActionButton type={action.buttonType === "secondary" ? ActionButtonType.Secondary : ActionButtonType.Primary} label={action.label} onClick={action.onClick}></ActionButton>;
        });
        // TODO: display these
        const overflowActions = accountData === undefined ? [] : this.getOverFlowActions(accountData.account);
        return (<DrawerWithForm busy={this.state.busy} errors={this.errors} actionName={title} onSubmit={() => this.saveAccount(false)} cancel={() => this.setState({ drawerOpen: false })} open={this.state.drawerOpen} customActions={...customActions}>
                {this.innerRender()}
            </DrawerWithForm>);
    }
}
export default AccountEditBaseDrawer;
