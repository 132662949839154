/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Checkbox } from "@octopusdeploy/design-system-components";
import { Permission, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import type { LibraryVariableSetResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import InternalLink from "~/components/Navigation/InternalLink";
import { PagingList } from "~/components/PagingList/PagingList";
import { PermissionCheck } from "~/components/PermissionCheck";
import { Note } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "~/routeLinks";
import styles from "./style.module.less";
interface VariableSetSelectorProps {
    selectedVariableSetIds: ReadonlyArray<string>;
    saveVariableSetsSelection(variableSetIds: ReadonlyArray<string>): Promise<void>;
}
interface VariableSetSelectorState extends DataBaseComponentState {
    libraryVariableSets?: ResourceCollection<LibraryVariableSetResource>;
    selectedVariableSetIds: ReadonlyArray<string>;
}
export default class VariableSetSelector extends DataBaseComponent<VariableSetSelectorProps, VariableSetSelectorState> {
    constructor(props: VariableSetSelectorProps) {
        super(props);
        this.state = {
            selectedVariableSetIds: [...this.props.selectedVariableSetIds],
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const libraryVariableSets = await repository.LibraryVariableSets.list({ contentType: VariableSetContentType.Variables });
            this.setState({ libraryVariableSets });
        });
    }
    render() {
        return (<SaveDialogLayout title="Select Variable Sets" busy={this.state.busy} errors={this.errors} onSaveClick={() => this.doBusyTask(() => this.props.saveVariableSetsSelection(this.state.selectedVariableSetIds))}>
                <PermissionCheck permission={Permission.LibraryVariableSetView} wildcard={true}>
                    <Note>
                        Manage your <InternalLink to={routeLinks.library.variableSets}>library variable sets</InternalLink>.
                    </Note>
                </PermissionCheck>

                {!this.props.selectedVariableSetIds.every((original) => this.state.selectedVariableSetIds.some((selected) => original === selected)) && (<Callout title="You appear to be removing a variable set." type={CalloutType.Warning}>
                        The values for any variable templates that are removed by this action will be permanently deleted from associated Tenants. There is no going back.
                    </Callout>)}

                {this.state.libraryVariableSets && (<LibraryVariableSetsList filterHintText="Filter by name or description" showPagingInNumberedStyle={true} initialData={this.state.libraryVariableSets} onFilter={filter} filterSearchEnabled={true} showFilterWithinSection={false} apiSearchParams={["partialName"]} onRow={(vs: LibraryVariableSetResource) => (<div>
                                <Checkbox value={this.state.selectedVariableSetIds.includes(vs.Id)} label={vs.Name} onChange={(checked) => this.changeSelection(checked, vs.Id)}/>
                                {vs.Description && <p className={styles.description}>{vs.Description}</p>}
                            </div>)}/>)}
            </SaveDialogLayout>);
    }
    private changeSelection(isSelected: boolean, variableSetId: string) {
        if (isSelected) {
            this.setState((prev) => ({ selectedVariableSetIds: [...prev!.selectedVariableSetIds, variableSetId] }));
        }
        else {
            this.setState((prev) => ({ selectedVariableSetIds: prev!.selectedVariableSetIds.filter((id) => id !== variableSetId) }));
        }
    }
    static displayName = "VariableSetSelector";
}
function filter(filterText: string, item: LibraryVariableSetResource) {
    return item.Name.toLowerCase().includes(filterText.toLowerCase()) || item.Description.toLowerCase().includes(filterText.toLowerCase());
}
class LibraryVariableSetsList extends PagingList<LibraryVariableSetResource> {
}
