import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { WorkerPoolsSummaryResource, WorkerPoolResource, SummaryResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import type { Dispatch, Action } from "redux";
import URI from "urijs";
import { repository } from "~/clientInstance";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import FormPage from "~/components/FormPage/FormPage";
import { EndpointCommunicationStyleMultiSelect } from "~/components/MultiSelect/EndpointCommunicationStyleMultiSelect";
import { ShellNameMultiSelect } from "~/components/MultiSelect/ShellNameMultiSelect";
import { WorkerPoolMultiSelect } from "~/components/MultiSelect/WorkerPoolMultiSelect";
import { NoResults } from "~/components/NoResults/NoResults";
import { PermissionCheck } from "~/components/PermissionCheck";
import Section from "~/components/Section";
import routeLinks from "~/routeLinks";
import { machineActions } from "../../reducers/machines";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import type { WorkerPoolSummaryMachineQuery } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolSummaryMachineQuery";
import type { WorkerPoolsSummaryFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import { createWorkerPoolsSummaryArgs, defaultWorkerPoolsSummaryFilter, workerPoolsFilterToQuery, workerPoolsQueryToFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import { BaseMachinesLayout } from "./BaseMachinesLayout";
import type { MachinesLayoutProps, MachinesLayoutState } from "./BaseMachinesLayout";
import WorkerMachinesSummarySection from "./WorkerMachinesSummarySection";
import WorkersOnboarding from "./WorkersOnboarding";
const { machineCleared } = machineActions;
interface WorkerMachinesLayoutProps extends MachinesLayoutProps<WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery>, RouteComponentProps {
    initialData: InitialData;
    defaultFilter: WorkerPoolsSummaryFilter;
    filterToQuery: (filter: WorkerPoolsSummaryFilter) => WorkerPoolSummaryMachineQuery;
    onClearMachine(): void;
}
interface WorkerMachinesLayoutState extends MachinesLayoutState<WorkerPoolsSummaryFilter> {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    filter: WorkerPoolsSummaryFilter;
}
interface InitialData {
    workerShellNames: string[];
    workerPools: WorkerPoolResource[];
    hasMachines: boolean;
    workerPoolsSummary: WorkerPoolsSummaryResource;
}
const Title = "Workers";
const WorkerMachinesLayoutFormPage = FormPage<InitialData>();
const WorkerMachinesLayout: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const query = URI(props.location.search).search(true) as WorkerPoolSummaryMachineQuery;
    const filter = workerPoolsQueryToFilter(query);
    return (<WorkerMachinesLayoutFormPage title={Title} load={async () => {
            const defaultArgs = createWorkerPoolsSummaryArgs(filter);
            const workerPools = repository.WorkerPools.all();
            const workerShellNames = repository.WorkerShells.all();
            const hasMachines = (await repository.Workers.list({ take: 0 })).TotalResults > 0;
            const workerPoolsSummary = repository.WorkerPools.summary(defaultArgs);
            return {
                workerPools: await workerPools,
                workerShellNames: await workerShellNames,
                workerPoolsSummary: await workerPoolsSummary,
                hasMachines,
            };
        }} renderWhenLoaded={(data) => <WorkerMachinesConnected initialData={data} initialFilter={filter} defaultFilter={defaultWorkerPoolsSummaryFilter} filterToQuery={workerPoolsFilterToQuery} hasMachines={data.hasMachines} {...props}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
};
WorkerMachinesLayout.displayName = "WorkerMachinesLayout"
class WorkerMachinesLayoutInternal extends BaseMachinesLayout<WorkerMachinesLayoutProps, WorkerMachinesLayoutState, WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery> {
    constructor(props: WorkerMachinesLayoutProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            filter: props.initialFilter,
            queryFilter: props.initialFilter,
            workerPoolsSummary: this.props.initialData.workerPoolsSummary,
        };
    }
    async componentDidMount() {
        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }
    protected getNameFilter(searchHintText: string): JSX.Element[] {
        return [
            <FilterSearchBox placeholder={searchHintText} value={this.state.filter.machinePartialName} onChange={(x) => {
                    this.setFilterState({ machinePartialName: x }, this.onFilterChange);
                }} autoFocus={true}/>,
        ];
    }
    protected getSummaries(): SummaryResource {
        return this.props.initialData.workerPools && this.state.workerPoolsSummary;
    }
    protected isFiltering() {
        return !isEqual(this.state.filter, this.props.defaultFilter);
    }
    protected extraFilters(): React.ReactNode[] {
        return [
            <WorkerPoolMultiSelect accessibleName="workerPoolFilter" key="workerPoolIdsMultiSelect" items={this.props.initialData.workerPools} value={this.state.filter.workerPoolIds} onChange={(x) => {
                    this.setFilterState({ workerPoolIds: x }, this.onFilterChange);
                }}/>,
            <ShellNameMultiSelect accessibleName="shellNameFilter" key="filterShellName" items={this.props.initialData.workerShellNames ? this.props.initialData.workerShellNames : []} value={this.state.filter.shellNames} onChange={(x) => {
                    this.setFilterState({ shellNames: x }, this.onFilterChange);
                }}/>,
            <EndpointCommunicationStyleMultiSelect accessibleName="communicationStyleFilter" key="filterCommunication" items={this.communicationStyles.filter((cs) => cs.Name !== "Step Package")} value={this.state.filter.commStyles} onChange={(x) => {
                    this.setFilterState({ commStyles: x }, () => {
                        this.onFilterChange();
                    });
                }}/>,
        ];
    }
    protected getActions(): JSX.Element[] {
        return [
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton href={routeLinks.infrastructure.workerMachines.new()} label="Add worker" type={NavigationButtonType.Primary}/>
            </PermissionCheck>,
        ];
    }
    protected renderOnboarding(): JSX.Element {
        return <WorkersOnboarding />;
    }
    protected renderMachinesExpander(): React.ReactNode {
        let machinesExpander: React.ReactNode = null;
        const workerPoolsSummaries = this.props.initialData.workerPools && this.state.workerPoolsSummary;
        if (workerPoolsSummaries) {
            machinesExpander = <WorkerMachinesSummarySection key="allMachines" workerPoolsSummary={workerPoolsSummaries} filter={this.state.filter} workerPools={this.props.initialData.workerPools}/>;
        }
        if (this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length === 0) {
            machinesExpander = (<Section>
                    <NoResults />
                </Section>);
        }
        return machinesExpander;
    }
}
const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        title: Title,
        itemDescriptions: "workers",
    };
};
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onClearMachine: () => {
            dispatch(machineCleared());
        },
    };
};
const WorkerMachinesConnected = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(WorkerMachinesLayoutInternal);
export default WorkerMachinesLayout;
