import * as React from "react";
import { GitHubLogo } from "./GitHubLogo";
import { OpenIdConnectLogo } from "./OpenIdConnectLogo";
import type { IssuerType } from "./getIssuerType";
type IssuerTypeLogoProps = {
    issuerType: IssuerType;
    className?: string;
    inverse?: boolean;
};
export function IssuerTypeLogo({ issuerType, className, inverse }: IssuerTypeLogoProps) {
    if (issuerType === "GitHubActions")
        return <GitHubLogo className={className} inverse={inverse ?? false}/>;
    return <OpenIdConnectLogo className={className}/>;
}
