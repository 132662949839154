import type { PageRouteDefinition, ParsedQueryParams } from "@octopusdeploy/portal-routes";
import type { ComponentType, ReactElement } from "react";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { PageIdentity } from "./PageIdentity";
export interface ConfigurationPageRegistration<RouteParams, QueryParams> extends PageRegistration<RouteParams, QueryParams> {
    render: (layout: ComponentType<Level2PageLayoutProps>, parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement;
}
export function createConfigurationPageRegistration<RouteParams, QueryParams>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (layout: ComponentType<Level2PageLayoutProps>, parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement, pageIdentity: PageIdentity, legacyRouteLink: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>) => string): ConfigurationPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        legacyRouteLink,
        pageIdentity,
    };
}
