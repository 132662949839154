import { Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import useIsMultiTenancyEnabledFeatureFlag from "~/areas/configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import AreaTitle from "~/components/AreaTitle";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import routeLinks from "../../../../routeLinks";
interface LibraryLayoutProps {
    children: React.ReactNode;
}
export function LibraryLayout({ children }: LibraryLayoutProps) {
    const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const navLinks = compact([
        Navigation.navItem("Certificates", routeLinks.library.certificates.root, undefined, { permission: Permission.CertificateView, wildcard: true }),
        Navigation.navItem("External Feeds", routeLinks.library.feeds, undefined, { permission: Permission.FeedView }),
        Navigation.navItem("Git Credentials", routeLinks.library.gitCredentials.root, undefined, { permission: [Permission.GitCredentialView], wildcard: true }),
        gitHubConnectionsEnabled && Navigation.navItem("Git Connections", routeLinks.library.gitConnections.root, undefined, { permission: [Permission.GitCredentialView], wildcard: true }),
        Navigation.navItem("Lifecycles", routeLinks.library.lifecycles, undefined, { permission: Permission.LifecycleView, wildcard: true }),
        Navigation.navItem("Packages", routeLinks.library.builtInRepository.root, undefined, { permission: Permission.FeedView }),
        Navigation.navItem("Build Information", routeLinks.library.buildInformationRepository.root, undefined, { permission: Permission.FeedView }),
        Navigation.navItem("Script Modules", routeLinks.library.scripts.root, undefined, { permission: Permission.VariableView, wildcard: true }),
        Navigation.navItem("Step Templates", routeLinks.library.stepTemplates.root, undefined, { permission: Permission.ActionTemplateView }),
        isMultiTenancyEnabled && Navigation.navItem("Tenant Tag Sets", routeLinks.library.tagSets.root, undefined, { permission: Permission.VariableView, wildcard: true }),
        Navigation.navItem("Variable Sets", routeLinks.library.variableSets, undefined, { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true }),
    ]);
    if (isPageHeaderVNextEnabledOutsideOfProjects) {
        return (<main>
                <NavigationSideBarLayoutVNext navItems={navLinks} content={children} header={<PageHeaderPrimary title="Library"/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={routeLinks.library.root} title="Library"/>
            <NavigationSidebarLayout navLinks={navLinks} content={children}/>
        </main>);
}
export default LibraryLayout;
