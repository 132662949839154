import { css } from "@emotion/css";
import { Divider, List, ListItem } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppInstallation, GitHubAppInstallations } from "@octopusdeploy/octopus-server-client";
import React, { useRef } from "react";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import routeLinks from "~/routeLinks";
import GitHubAppInstallationDisplay from "./GitHubAppInstallationDisplay";
interface GitHubAppInstallationListProps {
    installations: GitHubAppInstallations;
}
export default function GitHubAppInstallationList({ installations }: GitHubAppInstallationListProps) {
    const installationUrl = useRef(repository.GitHubApp.getInstallationUrl(window.location.href));
    const styles = {
        configureGitHubAppInstructions: css({
            marginLeft: space[16],
            marginTop: space[16],
            marginBottom: space[16],
        }),
    };
    return installations && installations.Installations.length > 0 ? (<>
            <List items={installations.Installations} rowKey={(i) => i.InstallationId} renderRow={(props) => <GitHubAppInstallationListItem installation={props.item}/>}/>
            <Divider />
            <p className={styles.configureGitHubAppInstructions}>
                If you cannot see your organisation or user{" "}
                <ExternalLink openInSelf href={installationUrl.current}>
                    configure the Octopus Deploy app in GitHub.
                </ExternalLink>
            </p>
        </>) : (<p className={styles.configureGitHubAppInstructions}>
            No GitHub organisations or users could be found.{" "}
            <ExternalLink openInSelf href={installationUrl.current}>
                Configure the Octopus Deploy app on GitHub.
            </ExternalLink>
        </p>);
}
interface GitHubAppInstallationListItemProps {
    installation: GitHubAppInstallation;
}
function GitHubAppInstallationListItem({ installation }: GitHubAppInstallationListItemProps) {
    const link = routeLinks.library.gitConnections.addGitHubConnectionSelectRepositories(installation.InstallationId);
    return (<ListItem>
            <InternalLink to={link}>
                <GitHubAppInstallationDisplay installation={installation}/>
            </InternalLink>
        </ListItem>);
}
