import { css } from "@emotion/css";
import { useLayoutEffect, useState } from "react";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { useProjectContext } from "~/areas/projects/context/index";
import { useRequiredContext } from "~/hooks/index";
interface SampleProjectTourOverlayProps {
    targetElement: HTMLDivElement | undefined;
    onOverlayClicked: () => void;
}
function SampleProjectTourOverlay(props: SampleProjectTourOverlayProps): JSX.Element {
    const sampleProjectTourContext = useRequiredContext(SampleProjectTourContext);
    const projectContext = useProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    if (props.targetElement === undefined)
        return <></>;
    if (sampleProjectTourContext.tourState !== "Focused")
        return <></>;
    const elementRect = props.targetElement.getBoundingClientRect();
    const padding = 8;
    const top = getElementTopPosition(elementRect, padding);
    const body = document.body;
    const html = document.documentElement;
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const styles = {
        container: css({
            width: "100%",
            height,
            overflow: "hidden",
            zIndex: "100",
            backgroundColor: "#00000051",
            position: "absolute",
            top: 0,
            bottom: 0,
            mixBlendMode: "hard-light",
        }),
        spotlight: css({
            height: Math.round(elementRect.height) + padding * 2,
            width: Math.round(elementRect.width) + padding * 2,
            left: Math.round(elementRect.left) - padding,
            top,
            position: "absolute",
            transition: "opacity 0.2s",
            backgroundColor: "grey",
            borderRadius: "16px",
        }),
    };
    const onOverlayClicked = () => {
        dispatchAction("Sample Project Tour: Click Away From Popover", { resource: "Sample Project Tour Overlay", action: Action.Toggle });
        props.onOverlayClicked();
    };
    return (<div className={styles.container} onClick={onOverlayClicked}>
            <div className={styles.spotlight}></div>
        </div>);
}
function getElementTopPosition(elementRect: DOMRect, offset: number): number {
    const parent = document.scrollingElement ?? document.documentElement;
    let parentTop = 0;
    if (parent instanceof HTMLElement) {
        parentTop = parent.scrollTop;
    }
    const top = (elementRect?.top ?? 0) + parentTop;
    return Math.floor(top - offset);
}
export default SampleProjectTourOverlay;
