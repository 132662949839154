/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CircularProgress } from "@octopusdeploy/design-system-components";
import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import InputWithActions from "~/components/InputWithActions/InputWithActions";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import type FormFieldProps from "~/components/form/FormFieldProps";
import { WorkerPoolIcon } from "~/primitiveComponents/dataDisplay/Icon";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "../../../routeLinks";
import WorkerPoolSelector from "./WorkerPoolSelector";
import styles from "./style.module.less";
interface WorkerPoolSelectProps extends FormFieldProps<string> {
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    items: () => Promise<WorkerPoolResource[]>;
    onRequestRefresh(): Promise<boolean>;
}
interface TextState {
    error?: string;
    showSearchDialog: boolean;
    pools: WorkerPoolResource[];
    isDataLoaded: boolean;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}
const toggleBusy = (value?: boolean) => (prev: TextState, props: WorkerPoolSelectProps) => ({ ...prev, busy: value ? value : !prev.busy });
class WorkerPoolSelect extends React.Component<WorkerPoolSelectProps, TextState> {
    constructor(props: WorkerPoolSelectProps) {
        super(props);
        this.state = {
            error: null!,
            showSearchDialog: false,
            pools: [],
            isDataLoaded: false,
            busy: false,
        };
    }
    async componentDidMount() {
        await this.loadData();
    }
    async loadData() {
        try {
            this.setState(toggleBusy(true));
            await this.props.doBusyTask(async () => {
                const pools = await this.props.items();
                this.setState({ pools, isDataLoaded: true });
            });
        }
        finally {
            this.setState(toggleBusy(false));
        }
    }
    handleChange = (poolId: string | undefined) => {
        const value = poolId === "" ? null : poolId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };
    getItems(theme: OctopusTheme) {
        return this.state.pools.map((pool) => {
            return {
                value: pool.Id,
                text: pool.Name,
                icon: <WorkerPoolIcon />,
            };
        });
    }
    selectionRenderer = (poolId: string) => {
        const pool = this.state.pools.find((p) => p.Id === poolId);
        if (!pool) {
            return poolId;
        }
        return (<div>
                <span className={styles.selectedIcon}>
                    <WorkerPoolIcon />
                </span>
                {pool.Name}
            </div>);
    };
    render() {
        if (!this.state.isDataLoaded) {
            return <CircularProgress size="small"/>;
        }
        const { onChange, onValidate, doBusyTask, onRequestRefresh, ...otherProps } = this.props;
        return withTheme((theme) => (<InputWithActions input={<Select label="Select worker pool" {...otherProps} allowFilter={true} onChange={this.handleChange} items={this.getItems(theme)} selectionRenderer={this.selectionRenderer} sortItems={false}/>} busy={this.state.busy} actions={<>
                        <OpenDialogIconButton toolTipContent="Search" wideDialog={true} icon="Search">
                            <WorkerPoolSelector onSelected={(pool) => this.handleChange(pool.Id)} selectedWorkerPoolId={this.props.value!}/>
                        </OpenDialogIconButton>
                        <IconButtonWithTooltip disabled={this.state.busy} onClick={() => onRequestRefresh()} toolTipContent="Refresh" icon="Refresh"/>
                        <IconButtonWithTooltip toolTipContent="Add" onClick={this.goToWorkerPools} icon="Add"/>
                    </>}/>));
    }
    private goToWorkerPools = () => {
        window.open(`#${routeLinks.infrastructure.workerPools.root}`, "_blank");
    };
    static displayName = "WorkerPoolSelect";
}
export default WorkerPoolSelect;
