import { css } from "@emotion/css";
import { BookIcon, CommentIcon, EnvelopeIcon, FeedbackIcon, InfoIcon, RoadmapIcon, SlackIcon } from "@octopusdeploy/design-system-components";
import { space, themeTokens, fontWeight } from "@octopusdeploy/design-system-tokens";
import classNames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useLocalStorage from "~/hooks/useLocalStorage";
import { ResourceLink } from "./ResourceLink";
const viewportHeightBreakpoint = 900;
export function HelpSidebarResources() {
    /*
     * At first glance it might look like userPreferencesExpanded and resourcesExpanded represent the same piece of state
     * and could be combined. We need to keep them separate, because we're automatically collapsing the footer for small height
     * viewports, and this behaviour should not be treated as a user preference. We only update the preference in local storage
     * when the user has actually clicked the show {more,less} button.
     */
    const [userPreferenceExpanded, setUserPreferenceExpanded] = useLocalStorage<boolean | undefined>(`Octopus.HelpSidebar.Resources.Expanded`, undefined);
    const [resourcesExpanded, setResourcesExpanded] = React.useState<boolean>(true);
    const customHelpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink);
    const customHelpSidebarSupportLinkLabel = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLinkLabel);
    useEffect(() => {
        const viewportHeight = document.documentElement.clientHeight;
        if (userPreferenceExpanded !== undefined) {
            setResourcesExpanded(userPreferenceExpanded);
        }
        else if (viewportHeight < viewportHeightBreakpoint) {
            setResourcesExpanded(false);
        }
    }, [userPreferenceExpanded]);
    return (<div className={styles.resources}>
            <div className={styles.resourcesHeader}>
                <h4 className={styles.resourcesHeading}>Resources</h4>
                <button className={styles.showHideButton} onClick={() => {
            setUserPreferenceExpanded(!resourcesExpanded);
            setResourcesExpanded(!resourcesExpanded);
        }}>
                    Show {resourcesExpanded ? "less" : "more"}
                </button>
            </div>
            <div className={styles.resourceLinks}>
                <div className={styles.fullWidth}>
                    <ResourceLink icon={<EnvelopeIcon />} label="Email Octopus Support" href="mailto:support@octopus.com"/>
                </div>
                <ResourceLink icon={<BookIcon />} label="Docs" href="https://oc.to/docs"/>
                <ResourceLink icon={<FeedbackIcon />} label="Support forum" href="https://oc.to/support-forum"/>
            </div>

            <div className={classNames(styles.moreResources)} style={resourcesExpanded ? { gridTemplateRows: "1fr" } : {}}>
                <div className={classNames(styles.moreResourcesInner, styles.fullWidth)}>
                    <ResourceLink icon={<RoadmapIcon />} label="Roadmap" href="https://oc.to/roadmap"/>
                    <ResourceLink icon={<CommentIcon />} label="Feedback" href="https://oc.to/feedback"/>
                    <div className={styles.fullWidth}>
                        <ResourceLink icon={<SlackIcon />} label="Octopus Community Slack" href="https://oc.to/community-slack"/>
                    </div>

                    {customHelpSidebarSupportLink ? (<div className={styles.fullWidth}>
                            <ResourceLink icon={<InfoIcon />} label={customHelpSidebarSupportLinkLabel || "Your organization's internal support"} href={customHelpSidebarSupportLink} info/>
                        </div>) : null}
                </div>
            </div>
        </div>);
}
const styles = {
    resources: css({
        padding: space[24],
        display: "flex",
        flexDirection: "column",
        gap: space[8],
        borderTop: `1px solid ${themeTokens.color.border.primary}`,
    }),
    resourceLinks: css({
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: space[8],
    }),
    fullWidth: css({
        display: "grid",
        gridColumn: "auto / span 2",
    }),
    resourcesHeader: css({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: space[8],
    }),
    resourcesHeading: css({
        margin: "0",
        fontWeight: fontWeight[700],
        WebkitFontSmoothing: "antialiased",
        letterSpacing: "-0.1px",
    }),
    showHideButton: css({
        border: "none",
        backgroundColor: "transparent",
        color: themeTokens.color.text.link.default,
        fontWeight: "bold",
        fontFamily: "Roboto",
        cursor: "pointer",
        "&:hover": {
            color: themeTokens.color.text.link.pressed,
        },
    }),
    moreResources: css({
        display: "grid",
        gridTemplateRows: "0fr",
        transition: "grid-template-rows .250s cubic-bezier(0.25, 0.46, 0.28, 1.01)",
    }),
    moreResourcesInner: css({
        overflow: "hidden",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: space[8],
        gridRow: "1 / span 2",
    }),
};
