import * as React from "react";
import { CloseButton } from "~/components/Button";
import styles from "./style.module.less";
export enum CalloutType {
    Information,
    Success,
    Warning,
    Danger,
    NewFeature,
    Generic
}
interface CalloutProps {
    title: React.ReactNode;
    type: CalloutType;
    canClose?: boolean;
    onClose?: () => void;
    hideTitle?: boolean;
}
export const Callout: React.StatelessComponent<CalloutProps> = (props) => {
    let iconName = null;
    let style = styles.none;
    switch (props.type) {
        case CalloutType.Information: // blue = information
            iconName = "info-circle";
            style = styles.information;
            break;
        case CalloutType.Success: // green = success
            iconName = "check-circle";
            style = styles.success;
            break;
        case CalloutType.Warning: // yellow = warning
            iconName = "exclamation-circle";
            style = styles.warning;
            break;
        case CalloutType.Danger: // red = danger
            iconName = "exclamation-triangle";
            style = styles.danger;
            break;
        case CalloutType.NewFeature: // cyan = primary
            iconName = "star";
            style = styles.newFeature;
            break;
        case CalloutType.Generic: // cyan = primary
            style = styles.information;
            break;
    }
    const iconClassName = iconName ? `fa-solid fa-${iconName}` : "";
    const iconClass = styles.icon + " " + iconClassName;
    return (<div className={style} role="alert">
            {!props.hideTitle && (<div className={styles.title}>
                    <>
                        <div>
                            <em className={iconClass} aria-hidden="true"/>
                            {props.title}
                        </div>
                        <div>{props.canClose && <CloseButton size="1.5rem" onClose={props.onClose}/>}</div>
                    </>
                </div>)}
            {props.children && <div>{props.children}</div>}
        </div>);
};
Callout.displayName = "Callout"
Callout.displayName = "Callout";
export default Callout;
