import type { AccountResource, ActionTemplateSearchResource, FeedResource, GitCredentialResource, IProcessResource, ModifyDeploymentProcessCommand, ProcessType, ProjectContextRepository, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { isDeploymentProcessResource, isRunbookProcessResource, Permission, Repository } from "@octopusdeploy/octopus-server-client";
import flatMap from "lodash/flatMap";
import uniq from "lodash/uniq";
import React from "react";
import { ProcessAccountsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessAccountsContextProvider";
import { ProcessGitCredentialsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { DevToolsTab } from "~/components/DevTools/DevToolsContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import pluginRegistry from "../../../../../components/Actions/pluginRegistry";
import type { ProcessContextModelState, ProcessPageSupportedActions } from "../types";
import { DevToolbarProcessUpload } from "./DeploymentProcessUpload";
import { ProcessActionTemplatesContextProvider } from "./ProcessActionTemplatesContextProvider";
import type { ProcessContextLookupState, ProcessContextProps, ProcessContextProviderSetupActions } from "./ProcessContext";
import { loadProcess, ProcessContext, useBoundProcessActions } from "./ProcessContext";
import type { ProcessStateSelectors } from "./ProcessContextState";
import { getProcessContextModelInitialState, getSelectors, processContextModelStateReducer } from "./ProcessContextState";
import { ProcessErrorsController } from "./ProcessErrors/ProcessErrorsContext";
import { ProcessFeedsContextProvider } from "./ProcessFeedsContextProvider";
import { ProcessSearchFilterController } from "./ProcessSearchFilter/ProcessSearchFilterContext";
import { ProcessWarningsController } from "./ProcessWarnings/ProcessWarningsContext";
interface ProcessControllerProps {
    id: string;
    doBusyTask: DoBusyTask;
    children: (renderProps: ProcessContextProps) => React.ReactNode;
    project: Readonly<ProjectResource>;
    layoutActions: ProcessPageSupportedActions;
    errors?: Errors;
    processType: ProcessType;
}
const useProcessState = () => {
    return React.useState<ProcessContextLookupState>({
        actionTemplates: "NotLoaded",
        feeds: [],
        accounts: [],
        gitCredentials: [],
    });
};
const useLoadProcessEffect = (projectContextRepository: ProjectContextRepository, processType: ProcessType, id: string, doBusyTask: DoBusyTask, onLoaded: (process: IProcessResource) => Promise<void>) => {
    return useDoBusyTaskEffect(doBusyTask, async () => {
        if (!id) {
            return;
        }
        const result: IProcessResource = await loadProcess(projectContextRepository, processType, id);
        if (onLoaded) {
            await onLoaded(result);
        }
    }, [id, processType, projectContextRepository]);
};
const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>) => {
    return {
        onActionTemplatesUpdated: (actionTemplates: ActionTemplateSearchResource[]) => setState((current) => ({ ...current, actionTemplates })),
        onFeedsUpdated: (feeds: FeedResource[]) => setState((current) => ({ ...current, feeds })),
        onAccountsUpdated: (accounts: AccountResource[]) => setState((current) => ({ ...current, accounts })),
        onGitCredentialsUpdated: (gitCredentials: GitCredentialResource[]) => setState((current) => ({ ...current, gitCredentials })),
    };
};
const useSelectors = (state: ProcessContextModelState): ProcessStateSelectors => {
    return React.useMemo(() => getSelectors(state), [state]);
};
export const ProcessController: React.FC<ProcessControllerProps> = (props: ProcessControllerProps) => {
    const { children, doBusyTask, id, processType, project, layoutActions } = props;
    const [state, dispatch] = React.useReducer(processContextModelStateReducer, getProcessContextModelInitialState(processType));
    const selectors = useSelectors(state);
    const [lookupsState, setState] = useProcessState();
    const projectContext = useProjectContext();
    const { setProcess: setProcessDispatchAction, conflictDetected: conflictDetectedDispatchAction, ...boundDispatchActions } = useBoundProcessActions(dispatch);
    const boundActions = {
        ...boundDispatchActions,
        setProcess: async (process: IProcessResource, updateCleanModel: boolean) => {
            const allPlugins = await Promise.all(uniq(flatMap(process.Steps, (step) => step.Actions).map((action) => pluginRegistry.getAction(action.ActionType, action.StepPackageVersion))));
            setProcessDispatchAction(process, updateCleanModel, allPlugins);
        },
        conflictDetected: async (serverProcess: IProcessResource, stagedProcess: IProcessResource) => {
            const allPlugins = await Promise.all(uniq(flatMap([...serverProcess.Steps, ...stagedProcess.Steps], (step) => step.Actions).map((action) => pluginRegistry.getAction(action.ActionType, action.StepPackageVersion))));
            conflictDetectedDispatchAction(serverProcess, stagedProcess, allPlugins);
        },
    };
    const refreshFromServer = useLoadProcessEffect(projectContext.state.projectContextRepository, processType, id, doBusyTask, async (process) => {
        await boundActions.setProcess(process, true);
    });
    const stateUpdaters = React.useMemo(() => getStateUpdaters(setState), [setState]);
    const refreshAccounts = useDoBusyTaskEffect(doBusyTask, async () => {
        const accounts = await repository.Accounts.all();
        stateUpdaters.onAccountsUpdated(accounts);
    }, []);
    const refreshActionTemplates = useDoBusyTaskEffect(doBusyTask, async () => {
        const templates = await repository.ActionTemplates.search();
        stateUpdaters.onActionTemplatesUpdated(templates);
    }, []);
    const refreshFeeds = useDoBusyTaskEffect(doBusyTask, async () => {
        const feeds = isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true }) ? await repository.Feeds.all() : [];
        stateUpdaters.onFeedsUpdated(feeds);
    }, []);
    const refreshGitCredentials = useDoBusyTaskEffect(doBusyTask, async () => {
        const gitCredentials = isAllowed({ permission: Permission.GitCredentialView, project: project.Id, wildcard: true }) ? (await repository.GitCredentials.list({ take: Repository.takeAll })).Items : [];
        stateUpdaters.onGitCredentialsUpdated(gitCredentials);
    }, []);
    const saveOnServer = async (projectContextRepository: ProjectContextRepository, process: ModifyDeploymentProcessCommand, onError: (errors: Errors) => void, onSuccess: () => void): Promise<IProcessResource | null> => {
        let processResult: IProcessResource | null = null;
        await doBusyTask(async () => {
            if (isDeploymentProcessResource(process)) {
                processResult = await projectContextRepository.DeploymentProcesses.modify(process);
            }
            else if (isRunbookProcessResource(process)) {
                processResult = await repository.RunbookProcess.modify(process);
            }
            if (processResult && id === processResult.Id) {
                await boundActions.setProcess(processResult, true);
            }
        }, {
            onError,
            onSuccess,
        });
        return processResult;
    };
    const actions: ProcessContextProviderSetupActions = {
        ...boundActions,
        saveOnServer,
        refreshFromServer,
        ...layoutActions,
    };
    const contextValue: ProcessContextProps = {
        state,
        actions: actions,
        selectors,
    };
    return (<ProcessFeedsContextProvider feeds={lookupsState.feeds} refreshFeeds={refreshFeeds}>
            <ProcessActionTemplatesContextProvider templates={lookupsState.actionTemplates} refreshActionTemplates={refreshActionTemplates}>
                <ProcessAccountsContextProvider accounts={lookupsState.accounts} refreshAccounts={refreshAccounts}>
                    <ProcessGitCredentialsContextProvider gitCredentials={lookupsState.gitCredentials} refreshGitCredentials={refreshGitCredentials}>
                        <ProcessContext.Provider value={contextValue}>
                            <DevToolsTab name={`Upload ${contextValue.state.processType} Process`}>
                                <DevToolbarProcessUpload processContext={contextValue} projectContext={projectContext}/>
                            </DevToolsTab>
                            <ProcessSearchFilterController processType={contextValue.state.processType} selectors={contextValue.selectors}>
                                {() => (<ProcessErrorsController>
                                        <ProcessWarningsController>{children(contextValue)}</ProcessWarningsController>
                                    </ProcessErrorsController>)}
                            </ProcessSearchFilterController>
                        </ProcessContext.Provider>
                    </ProcessGitCredentialsContextProvider>
                </ProcessAccountsContextProvider>
            </ProcessActionTemplatesContextProvider>
        </ProcessFeedsContextProvider>);
};
ProcessController.displayName = "ProcessController"
