/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButtonType, NavigationButton } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, EnvironmentsSummaryResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import type { Dispatch, Action } from "redux";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action as AnalyticAction, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { EnvironmentSummaryQuery } from "~/areas/infrastructure/components/EnvironmentsLayout/EnvironmentSummaryQuery";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import AdvancedFilterLayout, { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import type { FilterSection } from "~/components/AdvancedFilterLayout";
import { AdvancedFilterTextInput } from "~/components/AdvancedFilterLayout/Text/AdvancedFilterTextInput";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { FeatureToggle, Feature } from "~/components/FeatureToggle";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import FormPage from "~/components/FormPage/FormPage";
import DeploymentTargetTypeMultiSelect from "~/components/MultiSelect/DeploymentTargetTypeMultiselect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { MachineModelHealthStatusMultiSelect } from "~/components/MultiSelect/MachineModelHealthStatusMultiSelect";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import { ShellNameMultiSelect } from "~/components/MultiSelect/ShellNameMultiSelect";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import type { TagIndex } from "~/components/tenantTagsets";
import * as tenantTagsets from "~/components/tenantTagsets";
import routeLinks from "~/routeLinks";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import PermissionCheck, { isAllowed } from "../../../../components/PermissionCheck/PermissionCheck";
import { machineActions } from "../../reducers/machines";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import ConfirmTentacleUpgradePanel from "../MachinesLayout/ConfirmTentacleUpgradePanel";
import { AddEnvironmentsDialog } from "./AddEnvironmentsDialog";
import { defaultEnvironmentSummaryFilter, environmentSummaryFilterToQuery, environmentSummaryQueryToFilter, createEnvironmentSummaryArgs } from "./EnvironmentSummaryFilter";
import type { EnvironmentSummaryFilter } from "./EnvironmentSummaryFilter";
import EnvironmentSummarySection from "./EnvironmentSummarySection";
import EnvironmentsSorter from "./EnvironmentsSorter";
import Onboarding from "./Onboarding";
export interface EnvironmentsRouteParams {
    ids: string;
}
type SimplifiedRouteProps<T extends {
    [K in keyof T]?: string;
}> = Omit<RouteComponentProps<T>, "match">;
interface EnvironmentLayoutProps extends SimplifiedRouteProps<EnvironmentsRouteParams> {
    initialData: InitialData;
    onClearMachine(): void;
    dispatchAction: AnalyticActionDispatcher;
}
interface EnvironmentLayoutState extends DataBaseComponentState {
    environmentsSummary: EnvironmentsSummaryResource;
    filter: EnvironmentSummaryFilter;
    queryFilter?: EnvironmentSummaryFilter;
    isSearching?: boolean;
    redirectToTasks: boolean;
    currentPageIndex: number;
    redirectToEnvironmentId?: string;
}
interface InitialData {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    machineShellNames: string[];
    endpointRegistrations: EndpointRegistration[];
}
const PageSize = 20;
const Title = "Environments";
const EnvironmentQueryStringFilters = QueryStringFilters.For<EnvironmentSummaryFilter, EnvironmentSummaryQuery>();
const EnvironmentFormPage = FormPage<InitialData>();
const EnvironmentsLayout: React.FC<RouteComponentProps<EnvironmentsRouteParams>> = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatchAction = useAnalyticActionDispatch();
    return (<EnvironmentFormPage title={Title} load={async () => {
            const tagIndex = await tenantTagsets.getTagIndex();
            const args = createEnvironmentSummaryArgs(defaultEnvironmentSummaryFilter, tagIndex);
            const environmentsSummaryPromise = repository.Environments.summary(args);
            const environmentsPromise = repository.Environments.all();
            const machineRolesPromise = repository.MachineRoles.all();
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const machineShellNamesPromise = repository.MachineShells.all();
            const [environmentsSummary, environments, machineRoles, tenants, machineShellNames, endpointRegistrations] = await Promise.all([
                environmentsSummaryPromise,
                environmentsPromise,
                machineRolesPromise,
                tenantsPromise,
                machineShellNamesPromise,
                endpointRegistry.getAllRegistrations(),
            ]);
            return { environmentsSummary, environments, machineRoles, tenants, tagIndex, machineShellNames, endpointRegistrations };
        }} renderWhenLoaded={(data) => <ConnectedEnvironmentsLayout initialData={data} location={location} history={history} dispatchAction={dispatchAction}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
};
EnvironmentsLayout.displayName = "EnvironmentsLayout"
class FilterLayout extends AdvancedFilterLayout<EnvironmentSummaryFilter> {
}
class EnvironmentsLayoutInternal extends DataBaseComponent<EnvironmentLayoutProps, EnvironmentLayoutState> {
    private machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    private communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: EnvironmentLayoutProps) {
        super(props);
        this.state = {
            filter: defaultEnvironmentSummaryFilter,
            isSearching: false,
            currentPageIndex: 0,
            environmentsSummary: props.initialData.environmentsSummary,
            redirectToTasks: false,
        };
    }
    async componentDidMount() {
        this.props.onClearMachine();
    }
    render() {
        if (this.state.redirectToTasks) {
            return <InternalRedirect to={routeLinks.tasks.root} push={true}/>;
        }
        if (this.state.redirectToEnvironmentId) {
            return <InternalRedirect to={routeLinks.infrastructure.environment(this.state.redirectToEnvironmentId)} push={true}/>;
        }
        const actions = [
            this.props.initialData.environments && this.props.initialData.environments.length > 0 && (<PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                    <NavigationButton href={routeLinks.infrastructure.machines.new()} label="Add deployment target"/>
                </PermissionCheck>),
            <PermissionCheck permission={Permission.EnvironmentCreate} environment="*">
                <OpenDialogButton label="Add Environment" type={ActionButtonType.Primary} onClick={() => this.props.dispatchAction("Add Environment", { resource: "Environment", action: AnalyticAction.Add })}>
                    <AddEnvironmentsDialog saveDone={async (env) => {
                    this.setState({ redirectToEnvironmentId: env.Id });
                }}/>
                </OpenDialogButton>
            </PermissionCheck>,
            this.props.initialData.environments && this.props.initialData.environments.length > 0 && (<PermissionCheck permission={Permission.EnvironmentEdit} environment="*">
                    <OverflowMenu menuItems={[OverflowMenuItems.dialogItem("Reorder", <EnvironmentsSorter saveDone={this.refreshEnvironmentSummaryData}/>)]}/>
                </PermissionCheck>),
        ];
        const actionSection = <ActionList actions={actions}/>;
        const hasEnvironments = (environmentsSummary: EnvironmentsSummaryResource): boolean => environmentsSummary && environmentsSummary.EnvironmentSummaries.length > 0;
        const hasAnyEnvironments = hasEnvironments(this.props.initialData.environmentsSummary) || hasEnvironments(this.state.environmentsSummary);
        const environmentSummaries = this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries;
        const start = this.state.currentPageIndex * PageSize;
        const end = start + PageSize;
        const environmentExpanders = environmentSummaries.slice(start, end).map((environmentsSummary) => {
            return (<EnvironmentSummarySection endpointRegistrations={this.props.initialData.endpointRegistrations} key={environmentsSummary.Environment.Id} environmentSummary={environmentsSummary} filter={this.state.filter} isFiltering={this.isFiltering()} tenants={this.props.initialData.tenants} tagIndex={this.props.initialData.tagIndex}/>);
        });
        const filterSections: FilterSection[] = [
            {
                render: (<div>
                        <AdvancedFilterCheckbox label="Disabled only" value={this.state.filter.isDisabled} onChange={(x) => {
                        this.setFilterState({ isDisabled: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <AdvancedFilterCheckbox label="Hide empty environments" value={this.state.filter.hideEmptyEnvironments} onChange={(x) => {
                        this.setFilterState({ hideEmptyEnvironments: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <AdvancedFilterTextInput fieldName={"deployment target"} value={this.state.filter.machinePartialName || ""} onChange={(x) => {
                        this.setFilterState({ machinePartialName: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <EnvironmentMultiSelect environments={this.props.initialData.environments} value={this.state.filter.environmentIds} onChange={(x) => {
                        this.setFilterState({ environmentIds: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <RoleMultiSelect items={this.props.initialData.machineRoles ? this.props.initialData.machineRoles : []} value={this.state.filter.roles} onChange={(x) => {
                        this.setFilterState({ roles: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <MachineModelHealthStatusMultiSelect items={this.machineHealthStatuses} value={this.state.filter.healthStatuses} onChange={(x) => {
                        this.setFilterState({ healthStatuses: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                        <DeploymentTargetTypeMultiSelect key="filterDeploymentTargetType" items={this.props.initialData.endpointRegistrations ?? []} value={this.state.filter.deploymentTargetTypes} onChange={(x) => {
                        this.setFilterState({ deploymentTargetTypes: x }, this.onFilterChange);
                    }}/>
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <TenantMultiSelect value={this.state.filter.tenantIds} items={this.props.initialData.tenants} onChange={(x) => {
                        this.setFilterState({ tenantIds: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                                <TenantTagMultiSelect value={this.state.filter.tenantTags} doBusyTask={this.doBusyTask} onChange={(x) => {
                        this.setFilterState({ tenantTags: x }, () => {
                            this.onFilterChange();
                        });
                    }}/>
                            </PermissionCheck>
                        </FeatureToggle>
                        <ShellNameMultiSelect items={this.props.initialData.machineShellNames ? this.props.initialData.machineShellNames : []} value={this.state.filter.shellNames} onChange={(x) => {
                        this.setFilterState({ shellNames: x }, this.onFilterChange);
                    }}/>
                    </div>),
            },
        ];
        const tentacleUpgradesRequiredWarning = environmentSummaries.some((x) => x.TentacleUpgradesRequired === true) && (<ConfirmTentacleUpgradePanel doBusyTask={this.doBusyTask} calloutDescriptionElement={<p>One or more deployment targets are running old versions of the Tentacle deployment agent and can be upgraded.</p>} onTentacleUpgradeComplete={() => this.setState({ redirectToTasks: true })}/>);
        return (<InfrastructureLayout {...this.props}>
                <EnvironmentQueryStringFilters filter={this.state.filter} getQuery={environmentSummaryFilterToQuery} getFilter={environmentSummaryQueryToFilter} onFilterChange={(filter) => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())}/>
                <PaperLayout busy={this.state.busy} errors={this.errors} title={Title} sectionControl={actionSection}>
                    {!hasAnyEnvironments && <Onboarding />}
                    {hasAnyEnvironments && (<div>
                            {tentacleUpgradesRequiredWarning}
                            <FilterLayout filterSections={filterSections} filter={this.state.filter} queryFilter={this.state.queryFilter} defaultFilter={defaultEnvironmentSummaryFilter} initiallyShowFilter={this.isFiltering()} additionalHeaderFilters={[
                    <FilterSearchBox placeholder={"Search environments..."} value={this.state.filter.partialName} onChange={(x) => {
                            this.setFilterState({ partialName: x }, () => {
                                this.onFilterChange();
                            });
                        }} autoFocus={true}/>,
                ]} onFilterReset={(filter: EnvironmentSummaryFilter) => {
                    this.setState({ filter }, () => {
                        this.onFilterChange();
                        const location = { ...this.props.history, search: "" };
                        this.props.history.replace(location);
                    });
                }} renderContent={() => (<div>
                                        <ExpansionButtons />
                                        {environmentExpanders}
                                        <NumberedPagingBar currentPageIndex={this.state.currentPageIndex} totalItems={this.state.environmentsSummary.EnvironmentSummaries.length} pageSize={PageSize} onPageSelected={(_, currentPageIndex) => this.setState({ currentPageIndex })}/>
                                    </div>)}/>
                        </div>)}
                </PaperLayout>
            </InfrastructureLayout>);
    }
    private setFilterState<K extends keyof EnvironmentSummaryFilter>(state: Pick<EnvironmentSummaryFilter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...(prev.filter as object), ...(state as object) },
        }), callback);
    }
    private isFiltering(): boolean {
        return !isEqual(this.state.filter, defaultEnvironmentSummaryFilter);
    }
    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshEnvironmentSummaryData();
            this.setState({ isSearching: false });
        });
    }
    private refreshEnvironmentSummaryData = async () => {
        await this.doBusyTask(() => this.loadEnvironmentSummaries());
    };
    private async loadEnvironmentSummaries() {
        const args = createEnvironmentSummaryArgs(this.state.filter, this.props.initialData.tagIndex);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.summary(args), (response) => {
            this.setState({
                environmentsSummary: response as EnvironmentsSummaryResource,
                currentPageIndex: 0,
            });
        });
    }
    static displayName = "EnvironmentsLayoutInternal";
}
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onClearMachine: () => {
            dispatch(machineActions.machineCleared());
        },
    };
};
const ConnectedEnvironmentsLayout = connect(null, mapGlobalActionDispatchersToProps)(EnvironmentsLayoutInternal);
export { EnvironmentsLayout };
