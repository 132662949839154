import { useCallback } from "react";
import { session } from "~/clientInstance";
import { useUIFeatureFlagToggledDispatch } from "~/hooks/analytics/useUIFeatureFlagDispatch";
import useLocalStorage from "~/hooks/useLocalStorage";
const featureFlagKey = "Routing vNext";
export function useIsRoutingVNextEnabled(): boolean {
    const [isRoutingVNextEnabled] = useRoutingVNextFeatureFlag();
    return isRoutingVNextEnabled;
}
export function useRoutingVNextFeatureFlag() {
    // We are not using useUIOnlyFeatureFlag deliberately here.
    // This is because this feature flag could be read before a user has logged in e.g. for resolving PageUrls in InternalRedirect,
    // which throws when a user is not available.
    // This function is a copy of useUIOnlyFeatureFlag but directly gets the user from the session and null checks it.
    const currentUser = session.currentUser;
    const dispatchFeatureFlagToggled = useUIFeatureFlagToggledDispatch();
    const localStorageKey = currentUser ? `${currentUser.Id}/${featureFlagKey}` : undefined;
    const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey ?? "", { value: false });
    const setValue = useCallback((isEnabled: boolean) => {
        if (localStorageKey) {
            setLocalStorageValue({ value: isEnabled });
            dispatchFeatureFlagToggled(isEnabled, { featureFlagKey });
        }
    }, [dispatchFeatureFlagToggled, localStorageKey, setLocalStorageValue]);
    return [localStorageValue.value, setValue] as const;
}
