import { ProjectSwitcher } from "@octopusdeploy/main-navigation";
import type { IId } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import React, { useEffect, useState } from "react";
import { client } from "~/clientInstance";
import routeLinks from "~/routeLinks";
import type { IRecentSpaceProjects } from "~/utils/RecentProjects/IRecentProjects";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import AddNewProjectDialog from "../ProjectBasedActivation/AddNewProjectDialog";
interface ProjectSwitcherNavigationBarItemProps {
    projectsHref: LinkHref;
}
export function ProjectSwitcherNavigationBarItem({ projectsHref }: ProjectSwitcherNavigationBarItemProps) {
    const [recentProjectIds, setRecentProjectIds] = useState(() => getRecentProjectIds(RecentProjects.getInstance().GetRecentProjectListInSpace()));
    const [addNewProjectDialogOpen, setAddNewProjectDialogOpen] = useState(false);
    useEffect(() => {
        return RecentProjects.getInstance().SubscribeToRecentSpaceProjects((recentSpaceProjects) => {
            const updatedRecentProjectIds = getRecentProjectIds(recentSpaceProjects);
            setRecentProjectIds(updatedRecentProjectIds);
        });
    }, []);
    return (<AnalyticLinkLocationProvider location={"Project Switcher"}>
            <ProjectSwitcher projectsHref={projectsHref} getProjectHref={getProjectHref} recentProjectIds={recentProjectIds} onAddNewProjectRequested={() => setAddNewProjectDialogOpen(true)}/>
            <AddNewProjectDialog open={addNewProjectDialogOpen} close={() => {
            // TODO: Should this dispatch project create/cancelled analytics?
            setAddNewProjectDialogOpen(false);
        }}/>
        </AnalyticLinkLocationProvider>);
}
function getProjectHref(project: IId) {
    return resolveStringPathWithSpaceId(routeLinks.project(project.Id).root, client.spaceId);
}
function getRecentProjectIds(recentSpaceProjects: IRecentSpaceProjects): string[] {
    const recentProjects = recentSpaceProjects.Projects;
    recentProjects
        .sort((a, b) => {
        return RecentProjects.SortByScoreThenTime(a.Score, b.Score, a.Timestamps, b.Timestamps);
    })
        .reverse();
    const recentProjectIds = recentProjects.map((p) => p.ProjectId);
    return recentProjectIds;
}
