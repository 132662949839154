import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import { withRunbookProcess } from "../Process/Pages";
import ProcessListLayoutLoader from "../Process/ProcessListLayoutLoader";
import ProcessStepsLayoutLoader from "../Process/ProcessStepsLayoutLoader";
export const RunbookProcessStepsLayoutLoaderPage = withPage({ page: pageIds.project().runbook.process.root })(withRunbookProcess(ProcessStepsLayoutLoader));
export const RunbookProcessListLayoutLoaderPage = withPage({ page: pageIds.project().runbook.process.root })(withRunbookProcess(ProcessListLayoutLoader));
type ProcessRouteProps = {
    path: string;
};
type RunbookProcessRouteProps = RouteComponentProps<{
    projectSlug: string;
    runbookId: string;
}>;
type Props = ProcessRouteProps & RunbookProcessRouteProps;
class RunbookProcessRoute extends React.Component<Props> {
    render() {
        return (<ErrorContextProvider>
                <Switch>
                    <ReloadableRoute path={`${this.props.path}/steps`} exact={true} render={(props) => <RunbookProcessStepsLayoutLoaderPage processType={ProcessType.Runbook} {...props}/>}/>
                    <ReloadableRoute path={`${this.props.path}`} render={(props: RunbookProcessRouteProps) => <RunbookProcessListLayoutLoaderPage processType={ProcessType.Runbook}/>}/>
                    <RedirectAs404 />
                </Switch>
            </ErrorContextProvider>);
    }
    static displayName = "RunbookProcessRoute";
}
const EnhancedRunbookProcessRoute = withRouter(RunbookProcessRoute);
export default EnhancedRunbookProcessRoute;
