import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { resolvePathWithSpaceId } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import type { History } from "history";
import { forwardRef } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { client } from "~/clientInstance";
import { usePageUrlResolver } from "~/components/Navigation/usePageUrlResolver";
import { exposeComponentAsClass } from "../exposeComponentAsClass";
import { isPageUrl } from "./isPageUrl";
import styles from "./style.module.less";
type LocationDescriptor = History.LocationDescriptor;
export type FontWeight = "normal" | "bold" | "bolder" | "lighter" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
interface InternalLinkComponentProps {
    size?: number;
    weight?: FontWeight;
    width?: string;
    color?: string;
    className?: string;
    to: LocationDescriptor | LinkHref;
    openInSelf?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    accessibleName?: string;
    isDisabled?: boolean;
}
type InternalLinkProps = InternalLinkComponentProps & RouteComponentProps<{
    spaceId?: string;
}>;
const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>((props, ref) => {
    const pageUrlResolver = usePageUrlResolver();
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, {}>) => {
        if (props.isDisabled)
            return;
        if (props.onClick) {
            props.onClick();
        }
        e.stopPropagation();
    };
    const style: React.CSSProperties = {};
    if (props.size) {
        style.fontSize = props.size + "rem";
    }
    if (props.weight) {
        style.fontWeight = props.weight;
    }
    if (props.width) {
        style.width = props.width;
    }
    if (props.color) {
        style.color = props.color;
    }
    if (props.isDisabled) {
        style.color = themeTokens.color.text.disabled;
        style.pointerEvents = "none";
    }
    // The client's spaceId and the route's spaceId should be in sync,
    // but because we haven't modelled this relationship in a reliable way,
    // this is not guaranteed to be true, particularly in tests
    const spaceId = (props.match.params.spaceId || client.spaceId) ?? undefined;
    const resolvedPath = isPageUrl(props.to) ? pageUrlResolver.resolve(props.to) : resolvePathWithSpaceId(props.to, spaceId);
    /* eslint-disable react/forbid-elements */
    return (<Link aria-label={props.accessibleName} aria-disabled={props.isDisabled} className={cn(styles.internalLink, props.className)} style={style} to={resolvedPath} target={props.openInSelf ? "_self" : "_blank"} onClick={onClick} innerRef={ref}>
            {props.children}
        </Link>);
});
InternalLink.defaultProps = {
    openInSelf: true,
};
export default exposeComponentAsClass(withRouter(InternalLink));
