/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { FeedResource, PackageDescriptionResource } from "@octopusdeploy/octopus-server-client";
import { FeedType, isContainerImageRegistry } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import InputWithActions from "~/components/InputWithActions/InputWithActions";
import PaperLayout from "~/components/PaperLayout";
import { Section } from "~/components/Section/Section";
import SimpleDataTable from "~/components/SimpleDataTable";
import { Note, Text } from "~/components/form";
import routeLinks from "~/routeLinks";
import type { DataBaseComponentState } from "../../../../components/DataBaseComponent";
import { DataBaseComponent } from "../../../../components/DataBaseComponent";
import Callout, { CalloutType } from "../../../../primitiveComponents/dataDisplay/Callout";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import styles from "./feedteststyle.module.less";
interface ExternalFeedTestProps {
    feedId: string;
}
class PackageTable extends SimpleDataTable<PackageDescriptionResource> {
}
interface ExternalFeedTestState extends DataBaseComponentState {
    feed: FeedResource;
    searchTerm: string;
    searchResult: PackageDescriptionResource[];
    searchNotSupportedError: string | undefined;
}
interface ExternalFeedTestPropsInternal extends RouteComponentProps<ExternalFeedTestProps> {
    trackAction: AnalyticTrackedActionDispatcher;
}
class ExternalFeedTestInternal extends DataBaseComponent<ExternalFeedTestPropsInternal, ExternalFeedTestState> {
    constructor(props: ExternalFeedTestPropsInternal) {
        super(props);
        this.state = {
            feed: null!,
            searchTerm: "",
            searchResult: null!,
            searchNotSupportedError: undefined,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const feed = await repository.Feeds.get(this.props.match.params.feedId);
            this.setState({ feed });
        });
    }
    render() {
        const hasVersion = this.state.feed && ![FeedType.Docker, FeedType.AwsElasticContainerRegistry].includes(this.state.feed.FeedType);
        const columns = hasVersion ? ["Id", "Version", "Description"] : ["Id", "Description"];
        return (<LibraryLayout {...this.props}>
                <PaperLayout breadcrumbTitle={"External Feeds"} breadcrumbPath={routeLinks.library.feeds} title={this.state.feed ? "Test " + this.state.feed.Name : "Test external feed"} busy={this.state.busy} errors={this.errors}>
                    {this.state.feed && (<div>
                            {this.state.searchNotSupportedError && (<Callout type={CalloutType.Warning} title="Search not supported">
                                    {this.state.searchNotSupportedError}
                                    {isContainerImageRegistry(this.state.feed.FeedType) && <p>This feed may be able to be used successfully in deployment (and runbook) processes, but does not support searching for packages.</p>}
                                </Callout>)}
                            <Section>
                                <p>Test the {this.state.feed.Name} repository using the search below. Up to ten results will be shown.</p>
                                <form onSubmit={this.handleSearchClick}>
                                    <InputWithActions input={<Text value={this.state.searchTerm} onChange={(searchTerm) => this.setState({ searchTerm })} autoFocus label="Package name"/>} actions={<ActionButton type={ActionButtonType.Primary} label="SEARCH" busyLabel="SEARCHING..." onClick={this.handleSearchClick}/>}/>
                                </form>
                                <Note>A package name to search for. Note that names on local folders and file shares are case-sensitive.</Note>
                            </Section>
                            {this.state.searchResult && (<Section>
                                    <h3>Matching packages</h3>
                                    <PackageTable data={this.state.searchResult} headerColumns={columns} headerColumnClassNames={[styles.headerColumn, styles.headerColumn, styles.headerColumn]} rowColumnClassName={styles.rowColumn} onRow={(res) => this.buildResultRow(res, hasVersion)}/>
                                </Section>)}
                        </div>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private handleSearchClick = async (e: React.FormEvent) => {
        e.preventDefault();
        if (this.state.searchTerm === "") {
            this.setValidationErrors("Please enter a search term");
            return;
        }
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Test,
                resource: "External Feed",
            };
            await this.props.trackAction("Search Feed", ev, async () => {
                this.setState({ searchResult: null! });
                const result = await repository.Feeds.searchPackages(this.state.feed, {
                    term: this.state.searchTerm || "",
                    take: 10,
                });
                this.setState({ searchResult: result.Items });
            });
        }, {
            onError: (errors) => {
                if (errors && errors.statusCode && errors.statusCode === 404) {
                    this.setState({ searchNotSupportedError: errors.message });
                    this.clearErrors();
                }
            },
        });
    };
    private buildResultRow = (packageResource: PackageDescriptionResource, hasVersion: boolean) => {
        return hasVersion ? [packageResource.Id, packageResource.LatestVersion, packageResource.Description || packageResource.Name] : [packageResource.Id, packageResource.Description || packageResource.Name];
    };
    static displayName = "ExternalFeedTestInternal";
}
export function ExternalFeedTest(props: RouteComponentProps<ExternalFeedTestProps>) {
    const trackAction = useAnalyticTrackedActionDispatch();
    return <ExternalFeedTestInternal {...props} trackAction={trackAction}/>;
}
