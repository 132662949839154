import { css } from "@emotion/css";
import { useContext, useEffect } from "react";
import * as React from "react";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { PopperPlacement } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourPopper";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
export type SampleProjectStepId = "DeploymentOverview" | "Variable" | "DeploymentProcess" | "DeploymentProcessEnvironments" | "Runbooks";
export interface SampleProjectTourStep {
    title: string;
    content: string;
    tip?: string;
    scrollOffset?: number;
    popperPlacement: PopperPlacement;
    popperPlacementSmallScreen: PopperPlacement;
    target: SampleProjectStepId;
    route: string;
}
interface SampleProjectTourStepBaseProps {
    stepId: SampleProjectStepId;
}
interface SampleProjectTourStepProps extends SampleProjectTourStepBaseProps {
    children?: React.ReactNode;
}
export function SampleProjectTourStep(props: SampleProjectTourStepProps): JSX.Element {
    const sampleProjectTourContext = useContext(SampleProjectTourContext);
    useEffect(() => {
        if (sampleProjectTourContext === undefined)
            return;
        if (stepRef.current !== null)
            sampleProjectTourContext?.registerStep(props.stepId, stepRef.current);
        return () => sampleProjectTourContext?.removeRegisteredStep(props.stepId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (sampleProjectTourContext === undefined)
        return <>{props.children}</>;
    const stepRef = React.createRef<HTMLDivElement>();
    return (<FeatureToggleVisibility toggle={"SampleProjectTourToggle"} disabledContent={props.children}>
            <div ref={stepRef} id={`SampleProjectStep-${props.stepId}`}>
                {props.children}
            </div>
        </FeatureToggleVisibility>);
}
export function SampleProjectTourAbsoluteFullContainerHighlightStep(props: SampleProjectTourStepProps): JSX.Element {
    const sampleProjectTourContext = useContext(SampleProjectTourContext);
    const stepRef = React.createRef<HTMLDivElement>();
    useEffect(() => {
        if (stepRef.current !== null)
            sampleProjectTourContext?.registerStep(props.stepId, stepRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<FeatureToggleVisibility toggle={"SampleProjectTourToggle"} disabledContent={props.children}>
            <div ref={stepRef} id={`SampleProjectStep-${props.stepId}`} className={css({ position: "absolute", height: "100%", width: "100%", pointerEvents: "none" })}>
                {props.children}
            </div>
        </FeatureToggleVisibility>);
}
