import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useParams } from "react-router";
import { Action, ActionStatus, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import routeLinks from "~/routeLinks";
interface StepEditorLinkProps {
    element: UniqueActivityElement;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    branch?: GitRefResource;
    className?: string;
}
export const StepEditorLink = (props: StepEditorLinkProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const { element, className, stepsCorrelationIds, branch } = props;
    const { spaceId, projectSlug, projectId } = useParams<{
        spaceId?: string;
        projectSlug?: string;
        projectId?: string;
    }>();
    const featureToggle = isFeatureToggleEnabled("LinkProcessEditorFromDeploymentLogsFeatureToggle");
    if (!featureToggle) {
        return null;
    }
    if (!spaceId || !projectSlug) {
        return null;
    }
    if (!stepsCorrelationIds || !stepsCorrelationIds?.[element.Id]) {
        return null;
    }
    const permissions: Permission.ProcessEdit | Permission.ProcessView | false = isAllowed({
        permission: Permission.ProcessEdit,
        project: projectId,
    })
        ? Permission.ProcessEdit
        : isAllowed({
            permission: Permission.ProcessView,
            project: projectId,
        })
            ? Permission.ProcessView
            : false;
    if (!permissions) {
        return null;
    }
    const stepLink = props.branch
        ? routeLinks.forSpace(spaceId).projectBranch(projectSlug, branch).deploymentProcess.stepViaSlug(stepsCorrelationIds[element.Id])
        : routeLinks.forSpace(spaceId).project(projectSlug).deployments.process.stepViaSlug(stepsCorrelationIds[element.Id]);
    const actionStatus = element.Status === ActivityStatus.Success ? ActionStatus.Success : element.Status === ActivityStatus.Failed ? ActionStatus.Failed : element.Status === ActivityStatus.SuccessWithWarning ? ActionStatus.SuccessWithWarning : undefined;
    return (<InternalLink className={className} to={stepLink} onClick={() => dispatchAction("Click Edit Step Link", { resource: "Deployment Process", action: Action.View, isCaCenabled: props.branch === undefined ? "False" : "True", status: actionStatus })}>
            {permissions === Permission.ProcessEdit ? "Edit" : "View"}
        </InternalLink>);
};
