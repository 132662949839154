import * as React from "react";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import routeLinks from "~/routeLinks";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
export const OnboardingTextForGitConnections = "Enable smooth and secure connectivity to GitHub by installing the Octopus Deploy GitHub App in your GitHub account.";
const GitConnectionOnboarding = () => {
    return (<OnboardingPage title="Create GitHub Connection" intro={<span>{OnboardingTextForGitConnections}</span>} learnMore={<>
                    <ExternalLink href={repository.GitHubApp.getInstallationUrl(window.location.href)} openInSelf>
                        Install the Octopus Deploy GitHub App
                    </ExternalLink>
                    <InternalLink to={routeLinks.library.gitConnections.addGitHubConnection}>Create a new Connection</InternalLink>
                </>}/>);
};
export default GitConnectionOnboarding;
