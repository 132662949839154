import { Tooltip } from "@octopusdeploy/design-system-components";
import type { LeadTimeDeployment, LeadTimeTrends, TenantDeployedAgo, InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { InsightsReportTenantMode } from "@octopusdeploy/octopus-server-client";
import moment from "moment";
import React from "react";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import { CommonSparklineTrends } from "./CommonSparklineTrends";
import { TrendTable } from "./TrendTable/TrendTable";
interface TrendTablesProps {
    report: InsightsReportResource;
    trends: LeadTimeTrends;
}
export function LeadTimeTrendTables({ trends, report }: TrendTablesProps) {
    return (<div>
            <CommonSparklineTrends trends={trends} valueFormatter={formatDurationInWords} dataPointFormatter={(v) => (v ? moment.duration(v).asMinutes() : 0)} metricDescription={<>
                        average <ExternalLink href="InsightsDeploymentLeadTime">lead time</ExternalLink>
                    </>}/>
            <LongestLeadTimeTable data={trends.Longest} report={report}/>
            <TenantsNotRecentlyDeployedToTable data={trends.TenantsNotRecentlyDeployedTo} report={report}/>
        </div>);
}
interface LongestLeadTimeTableProps {
    data: LeadTimeDeployment[];
    report: InsightsReportResource;
}
function LongestLeadTimeTable({ data, report }: LongestLeadTimeTableProps) {
    const showTenantColumn = report.TenantMode !== InsightsReportTenantMode.Untenanted;
    return (<TrendTable title="Releases with the longest lead time" isEmpty={data.length === 0} note={<span>
                    The releases with the longest <ExternalLink href="InsightsDeploymentLeadTime">lead time</ExternalLink> for each project, environment and tenant combination. The first 10 in descending order by lead time are shown. Lower lead time
                    indicates an increased ability to respond to change and implies less inventory per deployment, which reduces the risk of that response.
                </span>} headerContent={<DataTableRow>
                    <DataTableHeaderColumn>Project</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Environment</DataTableHeaderColumn>
                    {showTenantColumn && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Lead time</DataTableHeaderColumn>
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.Id}>
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).root}>{r.ProjectName}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.EnvironmentName}</DataTableRowColumn>
                            {showTenantColumn && <DataTableRowColumn>{r.TenantName ?? "Untenanted"}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).root}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <Tooltip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).deployments.specific(r.Id)}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </Tooltip>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{formatDurationInWords(r.LeadTime)}</DataTableRowColumn>
                        </DataTableRow>))}
                </>}/>);
}
interface TenantsNotRecentlyDeployedToTableProps {
    data: TenantDeployedAgo[];
    report: InsightsReportResource;
}
function TenantsNotRecentlyDeployedToTable({ data, report }: TenantsNotRecentlyDeployedToTableProps) {
    if (report.TenantMode === InsightsReportTenantMode.Untenanted)
        return null;
    return (<TrendTable title="Tenants not recently deployed to" isEmpty={data.length === 0} note="The last deployment for each tenant. The first 10 in ascending order by deployment completed time are shown." headerContent={<DataTableRow>
                    <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Time since completion</DataTableHeaderColumn>
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.TenantId}>
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.tenant(r.TenantId).root}>{r.TenantName}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.release(r.ReleaseId)}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <Tooltip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.deployment(r.Id).root}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </Tooltip>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{formatDurationInWords(r.CompletedAgo)}</DataTableRowColumn>
                        </DataTableRow>))}
                </>}/>);
}
