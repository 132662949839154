import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppInstallation } from "@octopusdeploy/octopus-server-client";
import classNames from "classnames";
import React from "react";
interface GitHubAppInstallationDisplayProps {
    installation: GitHubAppInstallation;
    buttons?: React.ReactNode;
}
export default function GitHubAppInstallationDisplay({ installation, buttons }: GitHubAppInstallationDisplayProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            padding: space[16],
            gap: space[16],
            alignItems: "center",
        }),
        avatar: css({
            width: space[48],
            height: space[48],
        }),
        organizationAvatar: css({
            borderRadius: space[12],
        }),
        userAvatar: css({
            borderRadius: space[48],
        }),
        buttonContainer: css({
            flexGrow: 1,
            display: "flex",
            flexDirection: "row-reverse",
        }),
    };
    return (<div className={styles.container}>
            {installation && (<>
                    <img aria-hidden className={classNames(styles.avatar, installation.AccountType == "Organization" ? styles.organizationAvatar : styles.userAvatar)} src={installation.AccountAvatarUrl} alt={`${installation.AccountLogin} logo`}/>
                    <h3>{installation.AccountLogin}</h3>
                </>)}

            {buttons && <div className={styles.buttonContainer}>{buttons}</div>}
        </div>);
}
