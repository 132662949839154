import type { AmazonWebServicesAccountResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import Dialog from "~/components/Dialog/Dialog";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import type { PageAction } from "~/components/PageActions/PageActions";
import { ExpandableFormSection, Summary, FormSectionHeading } from "~/components/form";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import Note from "~/primitiveComponents/form/Note/Note";
import Text from "~/primitiveComponents/form/Text/Text";
import type { AccountEditModel, AccountEditPropsInternal, AccountEditRenderTypeProps } from "./AccountEditBase";
import AccountEditBaseDrawer from "./AccountEditBaseDrawer";
import AccountEditBasePaper from "./AccountEditBasePaper";
import { SaveAndTestAmazonWebServicesAccountDialog } from "./SaveAndTestAccountDialog";
interface AmazonWebServicesAccountModel extends AccountEditModel {
    accessKey: string;
    secretKey: SensitiveValue;
}
export default function AmazonWebServicesAccountEdit(props: AccountEditPropsInternal<AmazonWebServicesAccountResource> & AccountEditRenderTypeProps) {
    switch (props.type) {
        case "paper":
            return <AmazonWebServicesAccountEditPaper {...props}/>;
        case "drawer":
            return <AmazonWebServicesAccountEditDrawer {...props}/>;
    }
}
class AmazonWebServicesAccountEditPaper extends AccountEditBasePaper<AmazonWebServicesAccountResource, AmazonWebServicesAccountModel> {
    getPartialModel(account?: AmazonWebServicesAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions((performTest: boolean) => this.handleSaveClick(performTest));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, () => this.testDone());
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (accessKey) => this.setModelState({ accessKey }), (secretKey) => this.setModelState({ secretKey }), this.getFieldError);
    }
}
class AmazonWebServicesAccountEditDrawer extends AccountEditBaseDrawer<AmazonWebServicesAccountResource, AmazonWebServicesAccountModel> {
    getPartialModel(account?: AmazonWebServicesAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions((performTest: boolean) => this.handleSaveClick(performTest));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, () => this.testDone());
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (accessKey) => this.setModelState({ accessKey }), (secretKey) => this.setModelState({ secretKey }), this.getFieldError);
    }
}
function getPartialModel(account?: AmazonWebServicesAccountResource): Partial<AmazonWebServicesAccountModel> {
    if (!account) {
        return {
            accessKey: "",
            secretKey: {
                HasValue: false,
            },
        };
    }
    return {
        accessKey: account.AccessKey,
        secretKey: account.SecretKey,
    };
}
function getPartialResource(modelState: AmazonWebServicesAccountModel): (Partial<AmazonWebServicesAccountResource> & {
    AccountType: AccountType;
}) | undefined {
    return {
        AccountType: AccountType.AmazonWebServicesAccount,
        AccessKey: modelState.accessKey,
        SecretKey: modelState.secretKey,
    };
}
function accessKeySummary(accessKey: string) {
    return accessKey ? Summary.summary(accessKey) : Summary.placeholder("No access key provided");
}
function secretKeySummary(secretKey: SensitiveValue) {
    return secretKey && secretKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No secret key provided");
}
function getPageActions(handleSaveClick: (performTest: boolean) => void): PageAction[] {
    return [{ type: "button", buttonType: "secondary", onClick: () => handleSaveClick(true), label: "Save and Test" }];
}
function getTestDialog(accountId: string | undefined, showTestDialog: boolean, onOkClick: () => void): React.ReactNode {
    return (accountId !== undefined && (<Dialog open={showTestDialog}>
                <SaveAndTestAmazonWebServicesAccountDialog onOkClick={onOkClick} accountId={accountId}/>
            </Dialog>));
}
function customExpandableFormSections(modelState: AmazonWebServicesAccountModel, onAccessKeyChange: (newValue: string) => void, onSecretKeyChange: (newValue: SensitiveValue) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="accessKey" errorKey="accessKey" title="Access Key" summary={accessKeySummary(modelState.accessKey)} help="The AWS access key to use when authenticating against Amazon Web Services.">
            <Text value={modelState.accessKey} onChange={onAccessKeyChange} label="Access Key" error={getFieldError("AccessKey")}/>
            <Note>
                Refer to the <ExternalLink href="AwsDocsManagingAccessKeys">AWS documentation</ExternalLink> for information on generating access and secret keys.
            </Note>
        </ExpandableFormSection>, <ExpandableFormSection key="secretKey" errorKey="secretKey" title="Secret Key" summary={secretKeySummary(modelState.secretKey)} help="The AWS secret key to use when authenticating against Amazon Web Services.">
            <Sensitive value={modelState.secretKey} onChange={onSecretKeyChange} label="Secret Key" error={getFieldError("SecretKey")}/>
        </ExpandableFormSection>);
    return baseElements;
}
