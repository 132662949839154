/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Tooltip } from "@octopusdeploy/design-system-components";
import type { LicenseLimitStatus, LicenseMessage, LicenseResource, LicenseStatusResource, UpgradeConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { LicenseMessageDisposition, Permission, UpgradeNotificationMode } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import * as React from "react";
import { UpdateNotifications } from "~/areas/configuration/components/License/UpdateNotifications";
import { repository } from "~/clientInstance";
import { CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { Section } from "~/components/Section/Section";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { withTheme } from "~/components/Theme";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import styles from "./style.module.less";
interface LicenseState extends OptionalFormBaseComponentState<LicenseResource> {
    licenseStatus?: LicenseStatusResource;
    upgradeConfiguration?: UpgradeConfigurationResource;
    showConfirmation: boolean;
}
export class License extends FormBaseComponent<{}, LicenseState, LicenseResource> {
    constructor(props: {}) {
        super(props);
        this.state = {
            showConfirmation: false,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const getLicence = repository.Licenses.getCurrent();
            const getLicenceStatus = repository.Licenses.getCurrentStatus();
            const getUpgradeConfiguration = repository.UpgradeConfiguration.get();
            const license = await getLicence;
            this.setState({
                upgradeConfiguration: await getUpgradeConfiguration,
                licenseStatus: await getLicenceStatus,
                model: license,
                cleanModel: cloneDeep(license),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async onSaveClick() {
        const isInitialLicenseRestricted = this.state.cleanModel?.LicenseText.includes("<Permissions>Restricted</Permissions>");
        const isCurrentLicenseRestricted = this.state.model?.LicenseText.includes("<Permissions>Restricted</Permissions>");
        if (!isInitialLicenseRestricted && isCurrentLicenseRestricted) {
            this.setState({ showConfirmation: true });
        }
        else {
            return this.save();
        }
    }
    save() {
        return this.doBusyTask(async () => {
            const licence = await repository.Licenses.getCurrent();
            licence.LicenseText = this.state.model!.LicenseText;
            const updated = await repository.Licenses.modifyCurrent(licence);
            const licenseStatus = await repository.Licenses.getCurrentStatus();
            this.setState({ model: updated, licenseStatus, cleanModel: cloneDeep(updated) });
        });
    }
    render() {
        return withTheme((theme) => {
            const license = this.state.model;
            const licenseStatus = this.state.licenseStatus;
            const config = this.state.upgradeConfiguration;
            const sidebarLimits = licenseStatus && licenseStatus.Limits && licenseStatus.Limits.length > 0 && (<div key="licenseLimits">
                    <h4>License Limits</h4>
                    <DataTable>
                        <DataTableBody>
                            {licenseStatus.Limits.map((l: LicenseLimitStatus, index) => (<DataTableRow key={index}>
                                    <DataTableRowColumn>{l.Name}</DataTableRowColumn>
                                    <DataTableRowColumn>
                                        {l.CurrentUsage}/{l.LicenseLimitDescription}
                                    </DataTableRowColumn>
                                    <DataTableRowColumn>
                                        {l.Disposition === LicenseMessageDisposition.Information && (<Tooltip content={l.Message}>
                                                <em className={"fa-solid fa-circle-info"} style={{ color: theme.infoText }}/>
                                            </Tooltip>)}
                                        {l.Disposition === LicenseMessageDisposition.Notice && (<Tooltip content={l.Message}>
                                                <em className={"fa-solid fa-flag"} style={{ color: theme.infoText }}/>
                                            </Tooltip>)}
                                        {l.Disposition === LicenseMessageDisposition.Warning && (<Tooltip content={l.Message}>
                                                <em className={"fa-solid fa-triangle-exclamation"} style={{ color: theme.alertText }}/>
                                            </Tooltip>)}
                                        {l.Disposition === LicenseMessageDisposition.Error && (<Tooltip content={l.Message}>
                                                <em className={"fa-solid fa-exclamation-triangle"} style={{ color: theme.dangerText }}/>
                                            </Tooltip>)}
                                    </DataTableRowColumn>
                                </DataTableRow>))}
                        </DataTableBody>
                    </DataTable>
                </div>);
            const sidebarConfig = config && (<div key="upgradeNotifications">
                    <h4>Upgrade Notifications</h4>
                    <p>
                        The update notification that is shown in the top menu will <strong>{this.getUpgradeOption()}</strong>
                    </p>
                    <OpenDialogButton label="Change">
                        <UpdateNotifications onSaveDone={(upgradeConfiguration) => this.setState({ upgradeConfiguration })}/>
                    </OpenDialogButton>
                </div>);
            const body = licenseStatus && (<div key="licenseBody">
                    {!licenseStatus.IsCompliant && (<Section>
                            <Callout type={CalloutType.Danger} title="Your Octopus Server is not compliant with your license">
                                <p>{licenseStatus.ComplianceSummary}</p>
                                <p>
                                    Get more information or upgrade your license at <ExternalLink href="Purchase">octopus.com</ExternalLink>.
                                </p>
                            </Callout>
                        </Section>)}
                    {licenseStatus.DaysToEffectiveExpiryDate < 30 && (<Section>
                            <Callout type={licenseStatus.DaysToEffectiveExpiryDate > 0 ? CalloutType.Warning : CalloutType.Danger} title={this.getLicenceExpiryTitle(licenseStatus.DaysToEffectiveExpiryDate)}>
                                <p>Renewing your license guarantees access to:</p>

                                {licenseStatus.DoesExpiryBlockKeyActivities ? (<ul className={styles.list}>
                                        <li>Ongoing ability to create new releases and deployments</li>
                                        <li>All new features, enhancements, bug fixes, and patches</li>
                                        <li>Continued help and support</li>
                                    </ul>) : (<ul className={styles.list}>
                                        <li>All major features and enhancements</li>
                                        <li>Bug fixes and patches</li>
                                        <li>Continued help and support</li>
                                    </ul>)}
                                <p>
                                    Renewing your license is quick and easy. Visit our <ExternalLink href={`Renew?licenseKey=${license!.SerialNumber}`}>renewal and upgrade page</ExternalLink> for more information.
                                </p>
                            </Callout>
                        </Section>)}
                    <Section>
                        <div className={styles.licenceBox}>
                            <CodeEditor value={license!.LicenseText} allowFullScreen={false} language={TextFormat.XML} onChange={(v) => this.setState({ model: { ...license!, LicenseText: v } })} showToolbar={true} showCopyButton={true}/>
                        </div>
                    </Section>
                    {licenseStatus.Messages && licenseStatus.Messages.length > 0 && (<Section>
                            <DataTable>
                                <DataTableBody>
                                    {licenseStatus.Messages.map((m: LicenseMessage, index) => (<DataTableRow key={index}>
                                            <DataTableRowColumn>
                                                {m.Disposition === LicenseMessageDisposition.Information && <em className={"fa-solid fa-circle-info"} style={{ color: theme.infoText }}/>}
                                                {m.Disposition === LicenseMessageDisposition.Warning && <em className={"fa-solid fa-triangle-exclamation"} style={{ color: theme.alertText }}/>}
                                                {m.Disposition === LicenseMessageDisposition.Error && <em className={"fa-solid fa-exclamation-triangle"} style={{ color: theme.dangerText }}/>}
                                                {m.Disposition === LicenseMessageDisposition.NonBlockingError && <em className={"fa-solid fa-exclamation-triangle"} style={{ color: theme.dangerText }}/>}
                                            </DataTableRowColumn>
                                            <DataTableRowColumn>{m.Message}</DataTableRowColumn>
                                        </DataTableRow>))}
                                </DataTableBody>
                            </DataTable>
                        </Section>)}
                </div>);
            return (<FormPaperLayout title="License" busy={this.state.busy} errors={this.errors} cleanModel={this.state.cleanModel} model={this.state.model} savePermission={{ permission: Permission.AdministerSystem }} onSaveClick={() => this.onSaveClick()}>
                    <ConfirmationDialog title="Switching to restricted permissions" continueButtonLabel="Confirm" open={this.state.showConfirmation} onClose={() => this.setState({ showConfirmation: false })} onContinueClick={async () => {
                    await this.save();
                    return true;
                }}>
                        <p>
                            This license has <ExternalLink href="RestrictedPermissions">restricted permissions</ExternalLink>, which means that all users will be able to do everything in the system.
                        </p>
                        <p>Are you sure you want to continue?</p>
                    </ConfirmationDialog>
                    <SidebarLayout sideBar={[sidebarLimits, sidebarConfig]}>{body}</SidebarLayout>
                </FormPaperLayout>);
        });
    }
    getUpgradeOption() {
        switch (this.state.upgradeConfiguration!.NotificationMode) {
            case UpgradeNotificationMode.AlwaysShow:
                return "show when any update is available";
            case UpgradeNotificationMode.ShowOnlyMajorMinor:
                return "show only when major or minor updates are available";
            default:
                return "never show";
        }
    }
    getLicenceExpiryTitle(expiresIn: number) {
        if (expiresIn < 0) {
            return `Your license expired ${-expiresIn} days ago`;
        }
        if (expiresIn === 0) {
            return "Your license expires today";
        }
        return `Your license expires in ${expiresIn} days`;
    }
    static displayName = "License";
}
export default License;
