import type { LinkHref } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import React from "react";
import type { ActionEvent } from "~/analytics/Analytics";
import { useAnalyticActionDispatch } from "~/analytics/Analytics";
import InternalLink from "~/components/Navigation/InternalLink";
import { useIsPortalUrlActive } from "~/components/Navigation/useIsPortalUrlActive";
import type { TaskState } from "./ProjectStatus";
import { ProjectStatusIcon } from "./ProjectStatusIcon";
import { RightArrowIcon } from "./RightArrowIcon";
import styles from "./style.module.less";
interface ProjectStatusItemProps {
    name: string;
    href: LinkHref;
    state: TaskState;
    eventName: string;
    eventAction: ActionEvent;
}
export const ProjectStatusItem = ({ name, state, href, eventName, eventAction }: ProjectStatusItemProps): JSX.Element => {
    const dispatchAction = useAnalyticActionDispatch();
    const isUrlActive = useIsPortalUrlActive();
    if (state === "Current") {
        return (<InternalLink className={cn(styles.statusItemContainer, styles.active)} to={href} onClick={() => {
                if (!isUrlActive(href, true)) {
                    dispatchAction(eventName, eventAction);
                }
            }}>
                <ProjectStatusIcon state={state}/>
                {name}
                <RightArrowIcon />
            </InternalLink>);
    }
    return (<div className={cn(styles.statusItemContainer)}>
            <ProjectStatusIcon state={state}/>
            {name}
        </div>);
};
