import { HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import pageIds from "~/pageIds";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import VariablePreview from "./VariablePreview";
export const VariablePreviewPage = withPage({ page: pageIds.project().variables.all })(VariablePreview);
type VariablePreviewRouteProps = {
    path?: string;
};
export function VariablePreviewRoute({ path }: React.PropsWithChildren<VariablePreviewRouteProps>) {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    if (isRoutingVNextEnabled) {
        return null;
    }
    return (<BranchAwareRedirect condition={(project) => HasVariablesInGit(project.PersistenceSettings)}>
            <ErrorContextProvider>
                <Switch>
                    <Route path={path ? `${path}` : undefined} exact={true} render={(props) => <VariablePreviewPage {...props}/>}/>
                    <RedirectAs404 />
                </Switch>
            </ErrorContextProvider>
        </BranchAwareRedirect>);
}
