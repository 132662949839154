import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { ProjectInsightsDeploymentDuration } from "../ProjectInsightsDeploymentDuration/ProjectInsightsDeploymentDuration";
import { ProjectInsightsFailureRate } from "../ProjectInsightsFailureRate/ProjectInsightsFailureRate";
import { ProjectInsightsFrequency } from "../ProjectInsightsFrequency/ProjectInsightsFrequency";
import { ProjectInsightsLeadTime } from "../ProjectInsightsLeadTime/ProjectInsightsLeadTime";
import { ProjectInsightsOverview } from "../ProjectInsightsOverview/ProjectInsightsOverview";
import { ProjectInsightsTimeToRecovery } from "../ProjectInsightsTimeToRecovery/ProjectInsightsTimeToRecovery";
import { projectInsightsLinks } from "./projectInsightsLinks";
const ProjectInsightsOverviewPage = withPage({ page: pageIds.project().insights.overview })(ProjectInsightsOverview);
const ProjectInsightsLeadTimePage = withPage({ page: pageIds.project().insights.leadTime })(ProjectInsightsLeadTime);
const ProjectInsightsFrequencyPage = withPage({ page: pageIds.project().insights.frequency })(ProjectInsightsFrequency);
const ProjectInsightsFailureRatePage = withPage({ page: pageIds.project().insights.failureRate })(ProjectInsightsFailureRate);
const ProjectInsightsMTTRPage = withPage({ page: pageIds.project().insights.timeToRecovery })(ProjectInsightsTimeToRecovery);
const ProjectInsightsDeploymentDurationPage = withPage({ page: pageIds.project().insights.deploymentDuration })(ProjectInsightsDeploymentDuration);
const ProjectInsightsRoutes = () => {
    const match = useRouteMatch();
    if (!match)
        return <InternalRedirect to={routeLinks.root}/>;
    const links = projectInsightsLinks(match.path);
    return (<Switch>
            <ReloadableRoute path={links.leadTime} component={ProjectInsightsLeadTimePage}/>
            <ReloadableRoute path={links.frequency} component={ProjectInsightsFrequencyPage}/>
            <ReloadableRoute path={links.failureRate} component={ProjectInsightsFailureRatePage}/>
            <ReloadableRoute path={links.timeToRecovery} component={ProjectInsightsMTTRPage}/>
            <ReloadableRoute path={links.deploymentDuration} component={ProjectInsightsDeploymentDurationPage}/>
            <ReloadableRoute path={links.overview} component={ProjectInsightsOverviewPage}/>
            <RedirectAs404 />
        </Switch>);
};
export default ProjectInsightsRoutes;
