/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { client } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import PaperLayout from "~/components/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import routeLinks from "~/routeLinks";
const TasksLayout = () => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const scriptConsoleAction: PrimaryPageAction = {
        type: "navigate",
        label: "Script Console",
        path: routeLinks.tasks.console,
        hasPermissions: isAllowed({
            permission: [Permission.AdministerSystem, Permission.TaskCreate],
            wildcard: true,
        }),
    };
    return (<main>
            {!isPageHeaderVNextEnabled && <AreaTitle link={routeLinks.tasks.rootForSpace(client.spaceId)} title="Tasks"/>}
            <ContextualHelpLayout>
                {isPageHeaderVNextEnabled && <PageHeaderPrimary title="Tasks" primaryAction={scriptConsoleAction}/>}
                <div className={isPageHeaderVNextEnabled ? containerStylesVNext : containerStyles}>
                    <Tasks renderLayout={({ busy, errors, hasLoadedOnce, children }) => {
            return (<PaperLayout title={isPageHeaderVNextEnabled ? undefined : "Tasks"} primaryAction={isPageHeaderVNextEnabled ? undefined : scriptConsoleAction} busy={busy} enableLessIntrusiveLoadingIndicator={hasLoadedOnce} errors={errors} fullWidth={true}>
                                    {children}
                                </PaperLayout>);
        }}/>
                </div>
            </ContextualHelpLayout>
        </main>);
};
const containerStyles = css({
    margin: `${space[12]} ${space[16]} 0`,
});
const containerStylesVNext = css({
    margin: `${space[16]} ${space[32]} 0`,
});
export default TasksLayout;
