/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { ResourcesById, ResourceCollection, EnvironmentResource, LifecycleResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import MarkdownDescription from "~/components/MarkdownDescription";
import List from "~/components/PagingList";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { repository } from "../../../../../clientInstance";
import routeLinks from "../../../../../routeLinks";
import LibraryLayout from "../../LibraryLayout/LibraryLayout";
import LifecycleMap from "../LifecycleMap";
class LifecycleList extends List<LifecycleResource> {
}
interface LifecyclesState extends DataBaseComponentState {
    lifecycles: ResourceCollection<LifecycleResource>;
    environmentsById: ResourcesById<EnvironmentResource>;
}
export interface LifecycleParams {
    id: string;
}
class Lifecycles extends DataBaseComponent<RouteComponentProps<LifecycleParams>, LifecyclesState> {
    constructor(props: RouteComponentProps<LifecycleParams>) {
        super(props);
        this.state = {
            lifecycles: null!,
            environmentsById: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = Promise.all([repository.Lifecycles.list(), repository.Environments.allById()]);
            const [lifecycles, environmentsById] = await task;
            this.setState({
                lifecycles,
                environmentsById,
            });
        });
    }
    render() {
        const addLifecycleButton = (<PermissionCheck permission={Permission.LifecycleCreate}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Lifecycle" href={routeLinks.library.lifecyclesCreate}/>
            </PermissionCheck>);
        return (<LibraryLayout {...this.props}>
                <PaperLayout title="Lifecycles" sectionControl={addLifecycleButton} busy={this.state.busy} errors={this.errors}>
                    {this.state.lifecycles && (<LifecycleList initialData={this.state.lifecycles} onRow={(item) => this.buildLifeCycleRow(item, this.state.environmentsById)} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} match={this.props.match} filterHintText="Filter by name or phase name..." showPagingInNumberedStyle={false} onNewItems={this.loadLifecyclesWithPreviewPhases}/>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private filter = (filter: string, resource: LifecycleResource) => {
        if (!filter || filter.length === 0 || !resource) {
            return true;
        }
        const loweredFilter = filter.toLowerCase();
        const filterByPhaseName = () => resource.Phases.find((p) => p.Name.toLowerCase().includes(loweredFilter) || filterByOptionalDeploymentTargets(p.OptionalDeploymentTargets)) !== undefined;
        const filterByOptionalDeploymentTargets = (optionalDeploymentTargets: string[]) => optionalDeploymentTargets.find((op) => {
            const env = this.state.environmentsById[op];
            return env === undefined ? false : env.Name.toLowerCase().includes(loweredFilter);
        }) !== undefined;
        return resource.Name.toLowerCase().includes(loweredFilter) || filterByPhaseName();
    };
    private loadLifecyclesWithPreviewPhases = async (lifecycles: LifecycleResource[]): Promise<LifecycleResource[]> => {
        const ids = lifecycles.map((lifecycle) => lifecycle.Id);
        let updatedLifecycles: LifecycleResource[] = null!;
        await this.doBusyTask(async () => {
            const results = await repository.Lifecycles.previews(ids);
            updatedLifecycles = results;
            return results;
        });
        return updatedLifecycles;
    };
    private buildLifeCycleRow(lifecycle: LifecycleResource, environmentsById: {}) {
        return (<div>
                <ListTitle>{lifecycle.Name}</ListTitle>
                <MarkdownDescription markup={lifecycle.Description!}/>
                <LifecycleMap lifecyclePreview={lifecycle} environmentsById={environmentsById} limitLargeLifecycles={true}/>
            </div>);
    }
    static displayName = "Lifecycles";
}
export default Lifecycles;
