/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import type { ProjectRouteParams } from "../ProjectsRoutes/ProjectRouteParams";
import CommonVariableTemplates from "./CommonVariableTemplates";
import LibraryVariableSets from "./LibraryVariableSets";
import ProjectVariableTemplates from "./ProjectVariableTemplates";
export const ProjectVariableTemplatesPage = withPage({ page: pageIds.project().variables.projectTemplates })(ProjectVariableTemplates);
export const CommonVariableTemplatesPage = withPage({ page: pageIds.project().variables.commonTemplates })(CommonVariableTemplates);
export const LibraryVariableSetsPage = withPage({ page: pageIds.project().variables.library })(LibraryVariableSets);
export function VariablesRoute() {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    if (isRoutingVNextEnabled) {
        return null;
    }
    const projectRouteLinks = routeLinks.projectRoutes();
    return (<Switch>
            <ReloadableRoute path={`${projectRouteLinks.variables.root}/projectvariabletemplates`} render={(props: RouteComponentProps<ProjectRouteParams>) => <ProjectVariableTemplatesPage projectSlug={props.match.params.projectSlug}/>}/>
            <ReloadableRoute path={`${projectRouteLinks.variables.root}/commonvariabletemplates`} component={(props: RouteComponentProps<ProjectRouteParams>) => <CommonVariableTemplatesPage projectSlug={props.match.params.projectSlug}/>}/>
            <ReloadableRoute path={`${projectRouteLinks.variables.root}/library`} render={(props: RouteComponentProps<ProjectRouteParams>) => <LibraryVariableSetsPage projectSlug={props.match.params.projectSlug}/>}/>
        </Switch>);
}
