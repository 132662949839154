import { css } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { useEffect, useState } from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { useProjectContext } from "~/areas/projects/context/index";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { useRequiredContext } from "~/hooks/index";
function SampleProjectTourResume(): JSX.Element {
    const sampleProjectTourContext = useRequiredContext(SampleProjectTourContext);
    const projectContext = useProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const [isHovered, setIsHovered] = useState<boolean>();
    const [isElementRefsOnScreen, setIsElementRefsOnScreen] = useState<boolean>();
    useEffect(() => {
        setIsElementRefsOnScreen(sampleProjectTourContext.hasStepRefs);
    }, [sampleProjectTourContext.hasStepRefs]);
    const styles = {
        container: css({
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: space[8],
            borderRadius: "4px",
            border: "0px",
            cursor: "pointer",
            color: themeTokens.color.actionButton.text.ternary,
            backgroundColor: themeTokens.color.button.background.secondary.default,
            "&:hover": {
                backgroundColor: themeTokens.color.button.background.secondary.hover,
            },
            "&:active": {
                backgroundColor: themeTokens.color.button.background.secondary.pressed,
            },
        }),
        text: css({
            marginLeft: space[8],
            font: text.interface.body.bold.base,
        }),
    };
    const onResumeTourClicked = () => {
        sampleProjectTourContext.resumeTour();
        dispatchAction("Sample Project Tour: Click Resume Tour", { resource: "SampleProjectTourResume", action: Action.Resume });
    };
    const onDismissTourClicked = () => {
        //TODO: Cancel tour
        dispatchAction("Sample Project Tour: Click 'X' to Dismiss Resume Tour", { resource: "SampleProjectTourResume", action: Action.Dismiss });
    };
    const shouldRender = sampleProjectTourContext.tourState === "Suspended";
    return (<>
            {shouldRender && (<FeatureToggleVisibility toggle={"SampleProjectTourToggle"} disabledContent={<></>}>
                    <button type={"button"} className={styles.container} onClick={onResumeTourClicked} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <StepProgressCircleIndicator percentage={Math.ceil(((sampleProjectTourContext.stepIndex + 1) / sampleProjectTourContext.totalStepCount) * 100)} currentStep={sampleProjectTourContext.stepIndex + 1} totalSteps={sampleProjectTourContext.totalStepCount} circleWidth={35}/>
                        <div className={css({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            })}>
                            <div className={styles.text}>Resume Tour</div>
                            {isHovered && <DismissPrompt onDismissClicked={onDismissTourClicked}/>}
                        </div>
                    </button>
                </FeatureToggleVisibility>)}
        </>);
}
export interface DismissPromptProps {
    onDismissClicked: () => void;
}
export function DismissPrompt(props: DismissPromptProps) {
    return (<div>
            <IconButton accessibleName="CloseSampleProjectTour" icon={"Cancel"} onClick={props.onDismissClicked}/>
        </div>);
}
interface StepProgressCircleIndicatorProps {
    circleWidth: number;
    percentage: number;
    currentStep: number;
    totalSteps: number;
}
function StepProgressCircleIndicator(props: StepProgressCircleIndicatorProps) {
    const strokeWidth = 3;
    const radius = props.circleWidth / 2 - strokeWidth;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * props.percentage) / 100;
    return (<div>
            <svg width={props.circleWidth} height={props.circleWidth} viewBox={`0 0 ${props.circleWidth} ${props.circleWidth}`}>
                <circle cx={props.circleWidth / 2} cy={props.circleWidth / 2} strokeWidth={`${strokeWidth}px`} r={radius} className={css({
            fill: themeTokens.color.icon.inverse,
        })}/>
                <circle cx={props.circleWidth / 2} cy={props.circleWidth / 2} strokeWidth={`${strokeWidth}px`} r={radius} className={css({
            fill: "none",
            stroke: themeTokens.color.progress.background.secondary,
        })}/>
                <circle cx={props.circleWidth / 2} cy={props.circleWidth / 2} strokeWidth={`${strokeWidth}px`} r={radius} transform={`rotate(-90 ${props.circleWidth / 2} ${props.circleWidth / 2})`} className={css({
            fill: "none",
            stroke: themeTokens.color.progress.background.primary,
            strokeDasharray: `${dashArray}`,
            strokeDashoffset: `${dashOffset}`,
            strokeLinecap: "round",
            strokeLinejoin: "round",
        })}/>
                <text x={"50%"} y={"50%"} dy={"0.3em"} textAnchor={"middle"} className={css({
            font: text.interface.body.bold.xSmall,
            fill: themeTokens.color.progress.text.info,
        })}>
                    {props.currentStep}/{props.totalSteps}
                </text>
            </svg>
        </div>);
}
export default SampleProjectTourResume;
