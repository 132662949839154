import { css } from "@emotion/css";
import { Divider, List } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubRepository } from "@octopusdeploy/octopus-server-client";
import React, { useRef } from "react";
import { GitHubLogo } from "~/areas/configuration/components/Users/ServiceAccountOidcIdentities/GitHubLogo";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { GitHubAppRepositoryListItem } from "./GitHubAppRepositoryListItem";
interface BaseGitHubAppRepositoryListProps<T> {
    repositories: T[];
    selectedRepositories: string[];
    setRepositorySelected: (selected: boolean, repositoryId: string) => void;
    disableInteraction?: boolean;
}
export function GitHubAppUnknownRepositoryList({ repositories, selectedRepositories, setRepositorySelected, disableInteraction }: BaseGitHubAppRepositoryListProps<string>) {
    return (<>
            {repositories.length > 0 && (<>
                    <List items={repositories} rowKey={(i) => i} renderRow={(props) => <GitHubAppRepositoryListItem disableInteraction={disableInteraction} repository={props.item} selected={selectedRepositories.includes(props.item)} onChange={setRepositorySelected}/>}/>
                </>)}
        </>);
}
interface GitHubAppRepositoryListProps extends BaseGitHubAppRepositoryListProps<GitHubRepository> {
    hideConfigureInstallation?: boolean;
}
export default function GitHubAppRepositoryList({ repositories, selectedRepositories, setRepositorySelected, disableInteraction, hideConfigureInstallation }: GitHubAppRepositoryListProps) {
    const installationUrl = useRef(repository.GitHubApp.getInstallationUrl(window.location.href));
    const styles = {
        logo: css({
            height: space[24],
            width: space[24],
        }),
        noRepositories: css({
            textAlign: "center",
            marginTop: space[32],
            marginBottom: space[32],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: space[8],
        }),
        configureGitHubAppInstructions: css({
            marginLeft: space[16],
            marginTop: space[16],
            marginBottom: space[16],
        }),
    };
    return (<>
            {repositories.length > 0 && (<>
                    <List items={repositories} rowKey={(i) => i.RepositoryId} renderRow={(props) => <GitHubAppRepositoryListItem disableInteraction={disableInteraction} repository={props.item} selected={selectedRepositories.includes(props.item.RepositoryId)} onChange={setRepositorySelected}/>}/>
                </>)}
            {repositories.length === 0 && (<div className={styles.noRepositories}>
                    <GitHubLogo inverse={false} className={styles.logo}/> <div>No repositories found</div>
                </div>)}
            {!hideConfigureInstallation && (<>
                    <Divider />
                    <p className={styles.configureGitHubAppInstructions}>
                        If you cannot see your repository{" "}
                        <ExternalLink openInSelf href={installationUrl.current}>
                            configure repository access in the Octopus Deploy app on GitHub.
                        </ExternalLink>
                    </p>
                </>)}
        </>);
}
