/* eslint-disable @typescript-eslint/no-non-null-assertion */
import InfrastructureRoutes from "app/areas/infrastructure/components/InfrastructureRoutes/index";
import LibraryRoutes from "app/areas/library/components/LibraryRoutes/index";
import TaskRoutes from "app/areas/tasks/components/TaskRoutes/index";
import TenantRoutes from "app/areas/tenants/TenantsRoutes/TenantsRoutes";
import StyleGuide from "app/components/StyleGuide/StyleGuide";
import UxGuide from "app/components/UxGuide/UxGuide";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import DashboardOverview from "~/areas/dashboard/DashboardOverview/DashboardOverview";
import { DashboardRoutes } from "~/areas/dashboard/DashboardRoutes";
import DeploymentRoutes from "~/areas/deployments/DeploymentRoutes";
import { V3AccountRoutes } from "~/areas/infrastructure/components/InfrastructureRoutes/V3AccountRoutes";
import { V3EnvironmentRoutes } from "~/areas/infrastructure/components/InfrastructureRoutes/V3EnvironmentRoutes";
import { V3MachineRoutes } from "~/areas/infrastructure/components/InfrastructureRoutes/V3MachineRoutes";
import InsightsRoutes from "~/areas/insights/components/InsightsRoutes/InsightsRoutes";
import ProjectGroupLayout from "~/areas/projects/components/ProjectGroupLayout";
import RunbookRunRoutes from "~/areas/runbookRuns/RunbookRunRoutes";
import UserProfileRoutes from "~/areas/users/UserProfileRoutes";
import { session } from "~/clientInstance";
import { NoPermissionsPage } from "~/components/NoPermissionsPage/NoPermissionsPage";
import { NotFound, RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { UxRoutes } from "~/components/UxGuide/UxRoutes";
import pageIds from "~/pageIds";
import { AllPageRoutes } from "~/routing/AllPageRoutes";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { SpaceNotFound } from "../SpaceNotFound/SpaceNotFound";
import type { SpaceContext } from "../StandardLayout/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "../StandardLayout/SpaceLoader";
import { ChannelRedirect, ReleaseRedirect, RunbookRedirect, TriggerRedirect } from "./ProjectChildRedirect";
import { RunbookSnapshotRedirect } from "./ProjectRunbookChildRedirect";
const StyleGuidePage = withPage({ page: pageIds.styleGuide })(StyleGuide);
const UxGuidePage = withPage({ page: pageIds.uxGuide })(UxGuide);
const ProjectGroupPage = withPage({ page: pageIds.projectGroup.root })(ProjectGroupLayout);
const DashboardOverviewPage = withPage({ page: pageIds.dashboard.root })(DashboardOverview);
interface RootRoutesProps extends RouteComponentProps<{}> {
    spaceContext: SpaceContext;
}
export const typeSafeHasOwnProperty = <T extends {}>(target: T, key: keyof T) => {
    return target.hasOwnProperty(key);
};
const is404State = <T>(state: T): state is T & {
    is404: boolean;
} => {
    if (!state)
        return false;
    const hasProperty = Object.prototype.hasOwnProperty.call(state, "is404");
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = state as unknown as {
        is404: boolean;
    };
    return hasProperty && casted.is404 === true;
};
function RootRoutes({ spaceContext }: RootRoutesProps) {
    const noMatch = () => {
        if (isSpaceNotFound(spaceContext)) {
            return <ReloadableRoute key="space not found" render={() => <SpaceNotFound spaceNotFoundContext={spaceContext}/>}/>;
        }
        else if (!session.currentPermissions!.hasAnyPermissions()) {
            return <ReloadableRoute key="no permissions" component={NoPermissionsPage}/>;
        }
        return <RedirectAs404 key="404 redirect"/>;
    };
    const systemOrMixedScopeRoutes = () => {
        if (isSpaceNotFound(spaceContext)) {
            // If the space was not found, we don't want to show routes that are available in the system context.
            // We want to direct people to go to either a space-less route, or to step into a space.
            // So we should only show the Space Switcher in the navbar and disallow other routing.
            return [];
        }
        if (!session.currentPermissions!.hasAnyPermissions()) {
            return [];
        }
        return [
            <ReloadableRoute key="tasks" path={routeLinks.tasks.root} component={TaskRoutes}/>,
            <ReloadableRoute key="legacy" path={routeLinks.legacy.manage.console} render={(p) => <InternalRedirect to={{ ...p.location, pathname: routeLinks.tasks.console }}/>}/>,
        ];
    };
    const defaultRoute = () => {
        if (isSpaceNotFound(spaceContext)) {
            return [];
        }
        if (isSpecificSpaceContext(spaceContext)) {
            return [<ReloadableRoute key="dashboard default" exact path={routeLinks.root} component={DashboardOverviewPage}/>];
        }
        if (session.currentPermissions!.hasAnyPermissions()) {
            return [
                <ReloadableRoute key="configuration redirect" exact path={routeLinks.root} render={() => {
                        return <InternalRedirect push={false} to={routeLinks.configuration.root}/>;
                    }}/>,
            ];
        }
        return [];
    };
    const spaceRoutes = () => {
        if (!isSpecificSpaceContext(spaceContext)) {
            // If you are not in a space, then it does not make sense to render any space specific components
            // We are better off letting this fall through to a 404,
            // rather than try and fail to render a space specific component, and probably end up with undefined behaviour
            return [];
        }
        return [
            <ReloadableRoute key="dashboard" path={routeLinks.dashboard.root} component={DashboardRoutes}/>,
            <ReloadableRoute key="project groups" path={routeLinks.projectGroup(":ProjectGroupId").root} render={(p: ProjectGroupRouteParams) => <ProjectGroupPage spaceId={p.match.params.spaceId} projectGroupId={p.match.params.ProjectGroupId}/>}/>,
            <ReloadableRoute key="infrastructure" path={routeLinks.infrastructure.root} component={InfrastructureRoutes}/>,
            <ReloadableRoute key="tenants" path={routeLinks.tenants} component={TenantRoutes}/>,
            <ReloadableRoute key="library" path={routeLinks.library.root} component={LibraryRoutes}/>,
            <ReloadableRoute key="deployments" path={routeLinks.deployments} component={DeploymentRoutes}/>,
            <ReloadableRoute key="release redirect" path={routeLinks.release(":releaseId")} render={(p: RouteComponentProps<{
                spaceId: string;
                releaseId: string;
            }>) => <ReleaseRedirect spaceId={p.match.params.spaceId} releaseId={p.match.params.releaseId}/>}/>,
            <ReloadableRoute key="trigger redirect" path={routeLinks.trigger(":triggerId")} render={(p: RouteComponentProps<{
                spaceId: string;
                triggerId: string;
            }>) => <TriggerRedirect spaceId={p.match.params.spaceId} triggerId={p.match.params.triggerId}/>}/>,
            <ReloadableRoute key="channel redirect" path={routeLinks.channel(":channelId")} render={(p: RouteComponentProps<{
                spaceId: string;
                channelId: string;
            }>) => <ChannelRedirect spaceId={p.match.params.spaceId} channelId={p.match.params.channelId}/>}/>,
            <ReloadableRoute key="runbookruns" path={routeLinks.runbookRuns} component={RunbookRunRoutes}/>,
            <ReloadableRoute key="runbooksnapshot redirect" path={routeLinks.runbookSnapshot(":runbookSnapshotId")} render={(p: RouteComponentProps<{
                spaceId: string;
                runbookSnapshotId: string;
            }>) => <RunbookSnapshotRedirect spaceId={p.match.params.spaceId} runbookSnapshotId={p.match.params.runbookSnapshotId}/>}/>,
            <ReloadableRoute key="runbook redirect" path={routeLinks.runbook(":runbookId")} render={(p: RouteComponentProps<{
                spaceId: string;
                runbookId: string;
            }>) => <RunbookRedirect spaceId={p.match.params.spaceId} runbookId={p.match.params.runbookId}/>}/>,
            <ReloadableRoute key="legacy environments" path={routeLinks.legacy.environments} component={V3EnvironmentRoutes}/>,
            <ReloadableRoute key="legacy accounts" path={routeLinks.legacy.accounts} component={V3AccountRoutes}/>,
            <ReloadableRoute key="legacy machines" path={routeLinks.legacy.machines} component={V3MachineRoutes}/>,
            <ReloadableRoute key="insights" path={routeLinks.insights.root} component={InsightsRoutes}/>,
        ];
    };
    const legacyRoutes: React.ReactElement[] = [
        ...defaultRoute(),
        ...spaceRoutes(),
        <ReloadableRoute key="current user" path={routeLinks.currentUser.me} component={UserProfileRoutes}/>,
        <ReloadableRoute key="style guide" path={routeLinks.styleGuide} component={StyleGuidePage}/>,
        <ReloadableRoute key="ux guide" path={routeLinks.uxGuide.root} component={UxRoutes}/>,
        ...systemOrMixedScopeRoutes(),
        noMatch(),
    ];
    return (<Route render={({ location }) => {
            if (is404State(location.state)) {
                return <NotFound />;
            }
            return <AllPageRoutes legacyRoutes={legacyRoutes}/>;
        }}/>);
}
export default withRouter(RootRoutes);
type ProjectGroupRouteParams = RouteComponentProps<{
    spaceId: string;
    ProjectGroupId: string;
}>;
