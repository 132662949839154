import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "../../../routeLinks";
import DashboardConfiguration from "../DashboardConfiguration/DashboardConfiguration";
import DashboardOverview from "../DashboardOverview/DashboardOverview";
const DashboardConfigurationPage = withPage({ page: pageIds.dashboard.configure })(DashboardConfiguration);
const DashboardOverviewPage = withPage({ page: pageIds.dashboard.root })(DashboardOverview);
const DashboardRoutes: React.StatelessComponent<RouteComponentProps<{}>> = (props) => {
    return (<Switch>
            <ReloadableRoute path={routeLinks.dashboard.configure} component={DashboardConfigurationPage}/>
            <ReloadableRoute path={routeLinks.dashboard.root} component={DashboardOverviewPage}/>
        </Switch>);
};
DashboardRoutes.displayName = "DashboardRoutes"
export default DashboardRoutes;
