import type { UsernamePasswordAccountResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ExpandableFormSection, Summary, FormSectionHeading } from "~/components/form";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import Text from "~/primitiveComponents/form/Text/Text";
import type { AccountEditModel, AccountEditPropsInternal, AccountEditRenderTypeProps } from "./AccountEditBase";
import AccountEditBaseDrawer from "./AccountEditBaseDrawer";
import AccountEditBasePaper from "./AccountEditBasePaper";
interface UsernamePaswordModel extends AccountEditModel {
    username: string;
    password: SensitiveValue;
}
export default function UsernamePasswordAccountEdit(props: AccountEditPropsInternal<UsernamePasswordAccountResource> & AccountEditRenderTypeProps) {
    switch (props.type) {
        case "paper":
            return <UsernamePasswordAccountEditPaper {...props}/>;
        case "drawer":
            return <UsernamePasswordAccountEditDrawer {...props}/>;
    }
}
class UsernamePasswordAccountEditPaper extends AccountEditBasePaper<UsernamePasswordAccountResource, UsernamePaswordModel> {
    getPartialModel(account?: UsernamePasswordAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (username) => this.setModelState({ username }), (password) => this.setModelState({ password }), this.getFieldError);
    }
}
class UsernamePasswordAccountEditDrawer extends AccountEditBaseDrawer<UsernamePasswordAccountResource, UsernamePaswordModel> {
    getPartialModel(account?: UsernamePasswordAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (username) => this.setModelState({ username }), (password) => this.setModelState({ password }), this.getFieldError);
    }
}
function getPartialModel(account?: UsernamePasswordAccountResource): Partial<UsernamePaswordModel> {
    if (!account) {
        return {
            username: "",
            password: {
                HasValue: false,
            },
        };
    }
    return {
        username: account.Username,
        password: account.Password,
    };
}
function getPartialResource(modelState: UsernamePaswordModel): Partial<UsernamePasswordAccountResource> & {
    AccountType: AccountType;
} {
    return {
        AccountType: AccountType.UsernamePassword,
        Username: modelState.username,
        Password: modelState.password,
    };
}
function usernameSummary(modelState: UsernamePaswordModel) {
    return modelState.username ? Summary.summary(modelState.username) : Summary.placeholder("No username provided");
}
function passwordSummary(modelState: UsernamePaswordModel) {
    return modelState.password && modelState.password.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No password provided");
}
function customExpandableFormSections(modelState: UsernamePaswordModel, onUsernameChange: (newValue: string) => void, onPasswordChange: (newValue: SensitiveValue) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="username" errorKey="username" title="Username" summary={usernameSummary(modelState)} help="The username to use when authenticating against the remote host.">
            <Text value={modelState.username} onChange={onUsernameChange} label="Username" error={getFieldError("Username")}/>
        </ExpandableFormSection>, <ExpandableFormSection key="password" errorKey="password" title="Password" summary={passwordSummary(modelState)} help="The password to use to when authenticating against the remote host.">
            <Sensitive value={modelState.password} onChange={onPasswordChange} label="Password"/>
        </ExpandableFormSection>);
    return baseElements;
}
