import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppAuthorizationStatus } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
interface GitHubAppAuthCheckInternalProps {
    authorizationStatus: GitHubAppAuthorizationStatus;
    onAuthorizeClick: (location: string) => void;
}
export function GitHubAppAuthCheckCallout({ authorizationStatus, onAuthorizeClick }: GitHubAppAuthCheckInternalProps) {
    const styles = {
        authMessageContainer: css({
            display: "flex",
            flexDirection: "row",
            padding: space["16"],
            justifyContent: "space-between",
        }),
        authMessageInstructions: css({
            alignSelf: "center",
        }),
    };
    if (authorizationStatus.IsAuthorized)
        return null;
    return (<Callout title={"GitHub Authorization"} type={CalloutType.Information} canClose={false}>
            <div className={styles.authMessageContainer}>
                <div className={styles.authMessageInstructions}>In order for Octopus Deploy to communicate with GitHub on your behalf you must Authorize the Octopus Deploy GitHub Application.</div>
                <Button label="Authorize" onClick={() => {
            const route = repository.GitHubApp.getAuthorizeAppUrl(window.location.href);
            onAuthorizeClick(route);
        }} importance={"primary"}/>
            </div>
        </Callout>);
}
interface GitHubAppAuthCheckProps {
    doBusyTask: DoBusyTask;
}
function GitHubAppAuthCheck({ doBusyTask }: GitHubAppAuthCheckProps) {
    const [authorizationStatus, setAuthorizationStatus] = useState<GitHubAppAuthorizationStatus | undefined>(undefined);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const authorizationStatus = await repository.GitHubApp.getAuthorizationStatus();
        setAuthorizationStatus(authorizationStatus);
    }, []);
    return <>{authorizationStatus && <GitHubAppAuthCheckCallout authorizationStatus={authorizationStatus} onAuthorizeClick={(route) => window.location.replace(route)}/>}</>;
}
export default GitHubAppAuthCheck;
