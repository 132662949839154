import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router";
import NewVariableSet from "~/areas/library/components/VariableSets/NewVariableSet";
import VariableSet from "~/areas/library/components/VariableSets/VariableSet";
import VariableSets from "~/areas/library/components/VariableSets/VariableSets";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "../../../../routeLinks";
const NewVariableSetPage = withPage({ page: pageIds.library.variableSets.create })(NewVariableSet);
const VariableSetPage = withPage({ page: pageIds.library.variableSet })(VariableSet);
const ListVariableSetPage = withPage({ page: pageIds.library.variableSets.root })(VariableSets);
export default class VariableSetsRoutes extends React.Component<RouteComponentProps> {
    render() {
        const libraryLinks = routeLinks.library;
        return (<Switch>
                <ReloadableRoute path={`${libraryLinks.variableSets}/new`} component={NewVariableSetPage}/>
                <ReloadableRoute path={libraryLinks.variableSet(":variableSetId")} render={(routeProps) => <VariableSetPage variableSetId={routeProps.match.params.variableSetId}/>}/>
                <ReloadableRoute component={ListVariableSetPage}/>
            </Switch>);
    }
    static displayName = "VariableSetsRoutes";
}
