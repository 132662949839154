import { css } from "@emotion/css";
import type { BreadcrumbItem } from "@octopusdeploy/design-system-components";
import { LinearProgress } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import type { History } from "history";
import type { ReactElement, PropsWithChildren } from "react";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import type { MenuItem, OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PageHeaderSecondary } from "~/components/PageHeaderSecondary";
import { useIsPageHeaderVNextEnabled, useIsPageHeaderVNextEnabledOutsideOfProjects, useIsProjectsPage } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import SectionTitle from "~/components/SectionTitle/index";
import Sticky, { StickyStatus } from "~/components/Sticky/Sticky";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import PageTitleHelper from "~/utils/PageTitleHelper";
import BusyFromPromise from "../BusyFromPromise/BusyFromPromise";
import styles from "./style.module.less";
type LocationDescriptor = History.LocationDescriptor;
export type BreadcrumbProps = LegacyBreadcrumbProps & VNextBreadcrumbProps;
interface LegacyBreadcrumbProps {
    breadcrumbTitle?: string; // If specified, this text will display above your layout's title.
    breadcrumbPath?: string; // If specified, this will link your breadcrumb title.
    breadcrumbOverflow?: OverflowMenuNavLink[];
    breadcrumbChip?: JSX.Element;
}
interface VNextBreadcrumbProps {
    breadcrumbsItems?: BreadcrumbItem[];
    showBreadcrumbBackIcon?: boolean;
}
interface SharedPaperLayoutProps extends BreadcrumbProps {
    title?: string;
    titlePath?: LocationDescriptor;
    titleLogo?: JSX.Element;
    sectionControl?: React.ReactNode;
    busy?: Promise<unknown> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
    errors?: Errors;
    callout?: React.ReactNode;
    className?: string;
    innerClassName?: string;
    fullWidth?: boolean;
    flatStyle?: boolean;
    disableAnimations?: boolean;
    disableHeaderAnimations?: boolean;
    disableSettingPageTitle?: boolean;
    hideHelpIcon?: boolean;
    disableScrollToActiveError?: boolean;
    hideErrors?: boolean;
    disableStickyHeader?: boolean;
    statusSection?: React.ReactNode;
    titleChip?: ReactElement;
    titleAccessory?: ReactElement;
}
interface PaperLayoutWithSectionControlProps extends SharedPaperLayoutProps {
    /**
     * @deprecated This prop is deprecated and will soon be removed. Please use
     * pageActions and overflowActions instead.
     */
    sectionControl: React.ReactNode;
}
interface PaperLayoutWithActionsProps extends SharedPaperLayoutProps {
    primaryAction?: PrimaryPageAction;
    pageActions?: PageAction[];
    overflowActions?: Array<MenuItem | MenuItem[]>;
}
export type PaperLayoutProps = PaperLayoutWithSectionControlProps | PaperLayoutWithActionsProps;
type PaperLayoutInnerProps = PaperLayoutProps & {
    isPageHeaderVNextEnabled: boolean;
    isPageHeaderVNextEnabledOutsideOfProjects: boolean;
    isProjectsPage: boolean;
};
interface PaperLayoutState {
    stickyState: StickyStatus;
}
function isPaperLayoutWithSectionControl(props: PaperLayoutProps): props is PaperLayoutWithSectionControlProps {
    return "sectionControl" in props && props.sectionControl !== undefined;
}
export const headerId = "paperlayoutstickyheader";
class PaperLayoutInner extends React.Component<PaperLayoutInnerProps, PaperLayoutState> {
    constructor(props: PaperLayoutInnerProps) {
        super(props);
        if (!props.disableSettingPageTitle) {
            PageTitleHelper.setPageTitle(props.title, props.breadcrumbTitle);
        }
        this.state = {
            stickyState: 0,
        };
    }
    componentDidUpdate(prevProps: PaperLayoutInnerProps) {
        // For various screen, we update the title dynamically...
        if ((prevProps.title !== this.props.title || prevProps.breadcrumbTitle !== this.props.breadcrumbTitle) && !prevProps.disableSettingPageTitle) {
            PageTitleHelper.setPageTitle(this.props.title, this.props.breadcrumbTitle);
        }
    }
    renderHeader() {
        if (this.props.title || this.props.sectionControl) {
            const commonHeader = (<div id={headerId} className={styles.headerContainer}>
                    {this.renderBusy()}
                    {this.renderHeaderSecondary()}
                </div>);
            if (this.props.disableStickyHeader) {
                return commonHeader;
            }
            return (<Sticky top="#toppanel" innerZ={10} onStateChange={(x) => this.setState({ stickyState: x.status })}>
                    {this.props.statusSection && <>{this.props.statusSection}</>}
                    {commonHeader}

                    {/* UX: Render errors _inside_ our sticky header so these are always visible */}
                    {this.renderErrors()}
                    {this.props.callout}
                </Sticky>);
        }
        return this.renderBusy();
    }
    renderHeaderSecondary() {
        const headerProps = isPaperLayoutWithSectionControl(this.props) ? { sectionControl: this.props.sectionControl } : { primaryAction: this.props.primaryAction, pageActions: this.props.pageActions, overflowActions: this.props.overflowActions };
        const currentPageShouldApplyVNextHeaders = this.props.isProjectsPage || this.props.isPageHeaderVNextEnabledOutsideOfProjects;
        const shouldShowPageHeaderVNext = this.props.isPageHeaderVNextEnabled && currentPageShouldApplyVNextHeaders;
        const vNextBreadcrumbs = this.props.breadcrumbsItems ?? (this.props.breadcrumbTitle && this.props.breadcrumbPath ? [{ label: this.props.breadcrumbTitle, pageUrl: this.props.breadcrumbPath }] : undefined);
        return shouldShowPageHeaderVNext ? (<PageHeaderSecondary breadcrumbsItems={vNextBreadcrumbs} showBreadcrumbBackIcon={this.props.showBreadcrumbBackIcon} title={this.props.title} titleStatusIcon={this.props.titleLogo} titleChip={this.props.titleChip} titleAccessory={this.props.titleAccessory} {...headerProps} isStickied={this.state.stickyState === StickyStatus.STATUS_FIXED}/>) : (<SectionTitle title={<TitleWithChip title={this.props.title} titleChip={this.props.titleChip}/>} titlePath={this.props.titlePath} titleLogo={this.props.titleLogo} breadcrumbTitle={this.props.breadcrumbTitle} breadcrumbPath={this.props.breadcrumbPath} breadcrumbOverflow={this.props.breadcrumbOverflow} breadcrumbChip={this.props.breadcrumbChip} sectionControl={isPaperLayoutWithSectionControl(this.props) ? <div className={styles.sectionControlContainer}>{this.props.sectionControl}</div> : undefined} primaryAction={isPaperLayoutWithSectionControl(this.props) ? undefined : this.props.primaryAction} pageActions={isPaperLayoutWithSectionControl(this.props) ? undefined : this.props.pageActions} overflowActions={isPaperLayoutWithSectionControl(this.props) ? undefined : this.props.overflowActions} className={cn(styles.title, { [styles.stickied]: this.state.stickyState === StickyStatus.STATUS_FIXED })} busy={this.props.busy} enableLessIntrusiveLoadingIndicator={this.props.enableLessIntrusiveLoadingIndicator} hideHelpIcon={this.props.hideHelpIcon} titleAccessory={this.props.titleAccessory}/>);
    }
    renderBusy() {
        // Note: Don't show the horizontal loading bar on subsequent auto-reloads (it "blips" too quickly and annoys everybody).
        return (<div className={styles.busyFromPromise}>
                <BusyFromPromise promise={this.props.busy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy && !this.props.enableLessIntrusiveLoadingIndicator}/>}</BusyFromPromise>
            </div>);
    }
    renderErrors() {
        if (this.props.hideErrors) {
            return null;
        }
        const errors = this.props.errors;
        if (!errors) {
            return null;
        }
        let scrollToActiveErrorPanel = true; // default
        if (this.props.disableScrollToActiveError) {
            scrollToActiveErrorPanel = false;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode} scrollToPanel={scrollToActiveErrorPanel}/>;
    }
    render() {
        return (<div className={cn(styles.container, this.props.className)}>
                <div className={cn(this.props.flatStyle ? styles.paperContentFlat : styles.paperContent, this.props.fullWidth && styles.paperFullWidth, this.props.innerClassName)}>
                    {this.renderHeader()}
                    <TransitionAnimation disableAnimation={this.props.disableAnimations}>
                        <>{this.props.children}</>
                    </TransitionAnimation>
                </div>
            </div>);
    }
    static displayName = "PaperLayoutInner";
}
function PaperLayout(props: PropsWithChildren<PaperLayoutProps>) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const isProjectsPage = useIsProjectsPage();
    return <PaperLayoutInner {...props} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled} isPageHeaderVNextEnabledOutsideOfProjects={isPageHeaderVNextEnabledOutsideOfProjects} isProjectsPage={isProjectsPage}/>;
}
interface TitleWithChipProps {
    title?: React.ReactNode;
    titleChip?: React.ReactNode;
}
function TitleWithChip({ title, titleChip }: TitleWithChipProps) {
    return (<div className={titleWithChipStyles}>
            <span>{title}</span>
            {titleChip}
        </div>);
}
const titleWithChipStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space[8],
});
export default PaperLayout;
