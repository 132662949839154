/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { CommunicationStyle, DeploymentTargetResource, MachineResource, NewDeploymentTargetResource } from "@octopusdeploy/octopus-server-client";
import { isStepPackageEndpointResource, Permission, TenantedDeploymentMode, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { useLocation, useParams } from "react-router";
import type { Action, Dispatch } from "redux";
import URI from "urijs";
import type { AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action as AnalyticAction, AnalyticView, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { BaseMachineSettingsProps, GlobalProps, MachineSettingsInitialData } from "~/areas/infrastructure/components/BaseMachineSettings/BaseMachineSettings";
import { BaseMachineSettingsLayout, LoadMachineSettingsData } from "~/areas/infrastructure/components/BaseMachineSettings/BaseMachineSettings";
import { machineActions } from "~/areas/infrastructure/reducers/machines";
import { repository } from "~/clientInstance";
import { AdvancedTenantsAndTenantTagsSelector } from "~/components/AdvancedTenantSelector";
import { environmentChipList, RoleChip } from "~/components/Chips";
import type { Errors } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import type { PageAction } from "~/components/PageActions/PageActions";
import type { BreadcrumbProps } from "~/components/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TenantedDeploymentParticipationSelector from "~/components/TenantedDeploymentParticipationSelector";
import { ExpandableFormSection, FormSectionHeading, Note } from "~/components/form";
import Summary from "~/components/form/Sections/Summary";
import type { SummaryNode } from "~/components/form/Sections/Summary";
import routeLinks from "~/routeLinks";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import { getMachineIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
import { TenantsOrTenantTagsSelectedOnUntenantedDeploymentMode } from "~/utils/TenantedDeploymentParticipationHelper/TenantsOrTenantTagsSelectedOnUntenantedDeploymentMode";
import ExternalLink from "../../../../components/Navigation/ExternalLink";
import CreateNewMachineResource from "../BaseMachineSettings/NewMachineResourceFactory";
interface DeploymentTargetSettingsProps extends BaseMachineSettingsProps<DeploymentTargetResource, NewDeploymentTargetResource> {
    trackAction: AnalyticTrackedActionDispatcher;
}
type DeploymentTargetProps = BaseMachineSettingsProps<DeploymentTargetResource, NewDeploymentTargetResource>;
export interface MachineSettingsRouteParams {
    machineId: string;
}
const IsNew = "IsNew";
const MachineSettingsFormPage = FormPage<MachineSettingsInitialData>();
const DeploymentTargetPage: React.FC<DeploymentTargetProps> = (props: DeploymentTargetProps) => {
    const { machineId } = useParams<MachineSettingsRouteParams>();
    const location = useLocation();
    const query = URI(location.search);
    const trackAction = useAnalyticTrackedActionDispatch();
    return (<MachineSettingsFormPage title="Settings" load={async () => {
            const machine = machineId ? await props.repository.get(machineId) : IsNew;
            const communicationStyle = machine !== IsNew ? machine.Endpoint.CommunicationStyle : (query.search(true).type as CommunicationStyle);
            const machineSettingsData = await LoadMachineSettingsData(machine, communicationStyle);
            return {
                ...machineSettingsData,
                workerPools: await repository.WorkerPools.all(),
            };
        }} renderWhenLoaded={(initialData) => <DeploymentTargetSettingsLayoutInternal {...props} trackAction={trackAction} initialData={initialData} query={query}/>}/>);
};
DeploymentTargetPage.displayName = "DeploymentTargetPage"
class DeploymentTargetSettingsLayoutInternal extends BaseMachineSettingsLayout<DeploymentTargetSettingsProps, DeploymentTargetResource, NewDeploymentTargetResource> {
    constructor(props: DeploymentTargetSettingsProps) {
        super(props);
    }
    protected enableDisablePermission(): Permission {
        return Permission.MachineEdit;
    }
    protected createPermission(): Permission {
        return Permission.MachineCreate;
    }
    protected editPermission(): Permission {
        return Permission.MachineEdit;
    }
    protected deletePermission(): Permission {
        return Permission.MachineDelete;
    }
    protected machineLink(machineId: string): string {
        return routeLinks.infrastructure.machine(machineId).root;
    }
    protected getModel(location: URI, defaultMachinePolicyId: string): NewDeploymentTargetResource {
        const query = location.search(true);
        const environmentId = query.environment;
        return {
            ...CreateNewMachineResource(location, defaultMachinePolicyId),
            TenantedDeploymentParticipation: TenantedDeploymentMode.Untenanted,
            Roles: [],
            EnvironmentIds: environmentId ? [environmentId] : [],
            TenantIds: [],
            TenantTags: [],
        };
    }
    protected mapToModel(model: DeploymentTargetResource): NewDeploymentTargetResource {
        return {
            ...model,
        };
    }
    protected renderTypeSpecificComponents(): JSX.Element {
        return (<div>
                <FormSectionHeading title="Deployment"/>

                {!this.state.saved && this.state.machine && <AnalyticView resource="Deployment Target"/>}

                <ExpandableFormSection errorKey="EnvironmentIds" title="Environments" summary={this.environmentsSummary()} help={"Choose at least one environment for the deployment target."}>
                    <EnvironmentMultiSelect environments={this.props.initialData.environments} onChange={(EnvironmentIds) => this.setModelState({ EnvironmentIds })} value={this.state.model.EnvironmentIds}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Roles" title="Target Roles" summary={this.rolesSummary()} help={"Choose at least one role that this deployment target will provide."}>
                    <RoleMultiSelect onChange={(Roles) => this.setModelState({ Roles })} value={this.state.model.Roles} canAdd={true} label="Roles (type to add new)" items={this.props.initialData.machineRoles}/>
                    <Note>
                        <ExternalLink href="TargetRoles">Target roles</ExternalLink> are used as tags to select the deployment targets to execute a deployment or runbook against.
                    </Note>
                    <Note>
                        For example: <code>acme-web-server</code>
                    </Note>
                </ExpandableFormSection>
            </div>);
    }
    protected override getSecondaryPageActions(): PageAction[] {
        return [
            {
                type: "navigate",
                buttonType: "secondary",
                path: routeLinks.infrastructure.machines.new(),
                label: "Add another",
                hasPermissions: isAllowed({ permission: Permission.MachineCreate, environment: "*", tenant: "*" }),
            },
        ];
    }
    protected renderTenantComponent(): JSX.Element | null {
        if (!(this.props.isMultiTenancyEnabled || this.state.cleanModel.TenantedDeploymentParticipation !== TenantedDeploymentMode.Untenanted)) {
            return null;
        }
        return (<PermissionCheck permission={Permission.TenantView} tenant="*">
                <FormSectionHeading title="Restrictions"/>
                <ExpandableFormSection errorKey="TenantedDeploymentParticipation" title="Tenanted Deployments" summary={this.tenantDeploymentModeSummary()} help={"Choose the kind of deployments where this deployment target should be included."}>
                    <TenantedDeploymentParticipationSelector tenantMode={this.state.model.TenantedDeploymentParticipation} resourceTypeLabel="deployment target" onChange={(x) => this.setModelState({ TenantedDeploymentParticipation: x as TenantedDeploymentMode })}/>
                </ExpandableFormSection>
                {this.state.model.TenantedDeploymentParticipation !== TenantedDeploymentMode.Untenanted && (<ExpandableFormSection errorKey="Tenants" title="Associated Tenants" summary={this.tenantSummary()} help={"Choose tenants this deployment target should be associated with."}>
                        <AdvancedTenantsAndTenantTagsSelector tenants={this.props.initialData.tenants} selectedTenantIds={this.state.model.TenantIds} selectedTenantTags={this.state.model.TenantTags} doBusyTask={this.doBusyTask} onChange={(TenantIds, TenantTags) => this.setModelState({ TenantIds, TenantTags })} showPreviewButton={true}/>
                    </ExpandableFormSection>)}
            </PermissionCheck>);
    }
    protected async handleSaveClick(): Promise<boolean> {
        if (TenantsOrTenantTagsSelectedOnUntenantedDeploymentMode(this.state.model)) {
            this.setValidationErrors("Tenanted deployment mode", { TenantedDeploymentParticipation: "Please remove any associated tenants or tenant tags to use Untenanted deployment mode." });
            return false;
        }
        const machineType = this.state.model.Endpoint.CommunicationStyle;
        const target = machineType ? EndpointsHelper.getFriendlyName(machineType) : undefined;
        // const label = this.state.machine ? "Edit" : "Add";
        return await this.props.trackAction("Save Deployment Target", { resource: "Deployment Target", action: AnalyticAction.Save, data: { target } }, async (cb: AnalyticErrorCallback) => {
            const res = await super.handleSaveClick((e: Errors) => {
                cb(e);
            });
            return res;
        });
    }
    private tenantDeploymentModeSummary(): SummaryNode {
        return CommonSummaryHelper.tenantDeploymentModeSummary(this.state.model.TenantedDeploymentParticipation, this.state.model.TenantIds, this.state.model.TenantTags);
    }
    private tenantSummary() {
        return CommonSummaryHelper.tenantSummary(this.state.model.TenantIds, this.state.model.TenantTags, this.props.initialData.tenants);
    }
    private environmentsSummary() {
        return this.state.model.EnvironmentIds && this.state.model.EnvironmentIds.length > 0 ? Summary.summary(environmentChipList(this.props.initialData.environments, this.state.model.EnvironmentIds)) : Summary.placeholder("No environments");
    }
    private rolesSummary() {
        return this.state.model.Roles && this.state.model.Roles.length > 0 ? Summary.summary(this.state.model.Roles.map((role) => <RoleChip role={role} key={"role-" + role}/>)) : Summary.placeholder("No roles");
    }
}
const mapGlobalStateToPropsForDeploymentTargets = (state: GlobalState, props: RouteComponentProps<MachineSettingsRouteParams>): GlobalProps<DeploymentTargetResource, NewDeploymentTargetResource> => {
    const query = URI(props.location.search).search(true);
    const machineId = props.match && props.match.params ? props.match.params.machineId : null;
    const breadcrumbs: BreadcrumbProps = machineId
        ? {}
        : {
            breadcrumbPath: routeLinks.infrastructure.machines.new(query.environment),
            breadcrumbTitle: "New Deployment Target",
        };
    return {
        breadcrumbs,
        rootLink: routeLinks.infrastructure.machines.root,
        repository: repository.Machines,
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
        isBuiltInWorkerEnabled: state.configurationArea.features.isBuiltInWorkerEnabled,
        isWorkerMachine: false,
    };
};
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action<{}>>) => {
    return {
        onMachineSaved: (machine: MachineResource) => {
            const machineIconUrl = getMachineIconUrl(machine);
            dispatch(machineActions.machineSaved({
                id: machine.Id,
                name: machine.Name,
                machineType: EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle),
                machineIconUrl,
                isDisabled: machine.IsDisabled,
                isStepPackage: isStepPackageEndpointResource(machine.Endpoint),
                healthStatus: machine.HealthStatus,
            }));
        },
    };
};
export const DeploymentTargetSettingsLayout = connect(mapGlobalStateToPropsForDeploymentTargets, mapGlobalActionDispatchersToProps)(DeploymentTargetPage);
