/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton } from "@octopusdeploy/design-system-components";
import type { AccountResource, ResourceCollection, TagSetResource, EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission, AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import { PagingList } from "~/components/PagingList/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "../../../routeLinks";
import AccountSummary from "./AccountSummary";
interface AccountListProps {
    accounts?: ResourceCollection<AccountResource>;
    accountTypes?: AccountType[];
    selectedAccountId?: string;
    tenantId?: string;
    doBusyTask: (action: () => Promise<void>) => Promise<boolean>;
    onSelected?: (account: AccountResource) => void;
    empty?: React.ReactNode;
}
interface AccountListState {
    accounts: ResourceCollection<AccountResource>;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tenantTags: TagSetResource[];
    selectedId: string;
    busy: boolean;
}
export class AccountList extends PagingList<AccountResource> {
}
export default class AccountSearch extends BaseComponent<AccountListProps, AccountListState> {
    constructor(props: AccountListProps) {
        super(props);
        this.state = {
            accounts: this.props.accounts!,
            environments: [],
            tenants: [],
            tenantTags: [],
            busy: false,
            selectedId: this.props.selectedAccountId!,
        };
    }
    async componentDidMount() {
        await this.getData();
    }
    async getData(fetchAccounts: boolean = false) {
        await this.props.doBusyTask(async () => {
            let accounts = this.state.accounts;
            if (!accounts || fetchAccounts) {
                accounts = await repository.Accounts.list({ accountType: this.props.accountTypes });
            }
            const environmentIds = accounts.Items.map((c) => c.EnvironmentIds).reduce((list, ids) => list.concat(ids), []);
            const tenantIds = accounts.Items.map((c) => c.TenantIds).reduce((list, ids) => list.concat(ids), []);
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ ids: tenantIds }) : Promise.resolve([]);
            const [environments, tenants, tenantTags] = await Promise.all([repository.Environments.all({ ids: environmentIds }), tenantsPromise, repository.TagSets.all()]);
            this.setState({
                accounts,
                environments,
                tenants,
                tenantTags,
            });
        });
    }
    buildRow = (account: AccountResource) => {
        return [
            <AccountSummary onClick={() => {
                    if (!this.props.onSelected) {
                        return;
                    }
                    this.setState({ selectedId: account.Id }, () => {
                        if (this.props.onSelected) {
                            this.props.onSelected(account);
                        }
                    });
                }} showSelection={this.props.onSelected ? true : false} selected={account.Id === this.state.selectedId} key={account.Id} account={account} environments={this.state.environments} tenants={this.state.tenants} tenantTags={this.state.tenantTags}/>,
        ];
    };
    rowClicked = (account: AccountResource) => {
        if (this.props.onSelected) {
            return null;
        }
        else {
            return routeLinks.infrastructure.account(account.Id);
        }
    };
    applyFilter(filter: string, resource: AccountResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    getPrimaryAccountType = () => {
        if (this.props.accountTypes?.every((t) => t === AccountType.AmazonWebServicesAccount)) {
            return AccountType.AmazonWebServicesAccount;
        }
        if (this.props.accountTypes?.every((t) => t === AccountType.AzureServicePrincipal || t === AccountType.AzureSubscription || t === AccountType.AzureOidc)) {
            return AccountType.AzureSubscription;
        }
        if (this.props.accountTypes?.every((t) => t === AccountType.GoogleCloudAccount)) {
            return AccountType.GoogleCloudAccount;
        }
        return null;
    };
    getFriendlyAccountTypeAndCreationLink = (accountType: AccountType | null) => {
        let friendlyAccountType = null;
        switch (accountType) {
            case AccountType.AmazonWebServicesAccount:
                friendlyAccountType = "AWS Accounts";
                break;
            case AccountType.GoogleCloudAccount:
                friendlyAccountType = "Google Cloud Accounts";
                break;
            case AccountType.AzureSubscription:
            case AccountType.AzureServicePrincipal:
            case AccountType.AzureOidc:
                friendlyAccountType = "Azure Accounts";
                break;
        }
        return {
            friendlyAccountType: friendlyAccountType ?? "Accounts",
            createAccountLink: friendlyAccountType ? `${routeLinks.infrastructure.accounts.create}?accountType=${accountType}` : routeLinks.infrastructure.accounts.root,
        };
    };
    renderAccountListNotFoundCallout() {
        const primaryAccountType = this.getPrimaryAccountType();
        const { friendlyAccountType, createAccountLink } = this.getFriendlyAccountTypeAndCreationLink(primaryAccountType);
        return (<Callout title="No Accounts found" type={CalloutType.Information}>
                <p>
                    We couldn't find any {friendlyAccountType}, add one{" "}
                    <InternalLink to={createAccountLink} openInSelf={false} accessibleName="Add new account">
                        here
                    </InternalLink>
                    .
                </p>
                <ActionButton label="Refresh Accounts" accessibleName="refresh accounts" onClick={() => this.getData(true)}/>
            </Callout>);
    }
    render() {
        // Note: This is wrapped in a <div> on purpose for CSS transition animations.
        return (<div>
                {this.state.accounts && (<AccountList initialData={this.state.accounts} onRow={this.buildRow} onRowRedirectUrl={this.rowClicked} onFilter={this.applyFilter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name" empty={this.renderAccountListNotFoundCallout()}/>)}
            </div>);
    }
    static displayName = "AccountSearch";
}
