import type { TooltipContent } from "@octopusdeploy/design-system-components";
import { Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import navigationChipStyles from "./NavigationChip.module.less";
import { StyledMaterialChip } from "./StyledMaterialChip";
export interface NavigationChipProps {
    to: string;
    accessibleName: string;
    description?: string | TooltipContent;
}
export const NavigationChip: React.FC<NavigationChipProps> = ({ to, accessibleName, children, description }) => {
    return (<InternalLink to={to} accessibleName={accessibleName}>
            <StyledMaterialChip className={navigationChipStyles.clickable} label={<Tooltip content={<span>{description || children}</span>}>{children}</Tooltip>}/>
        </InternalLink>);
};
NavigationChip.displayName = "NavigationChip"
export default NavigationChip;
