import type { ParametersOfPartialRouteDefinition, PartialRouteDefinition, RequiredRouteParameter, RouteTemplate, UnknownPartialRouteDefinition, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import { isRouteParameter, projectAllVariablesPartialRoute, projectBranchDeploymentPartialRoute, projectBranchPartialRoute, projectBranchVariablesPartialRoute, projectDeploymentPartialRoute, projectDeploymentProcessPartialRoute, projectDeploymentSettingsPartialRoute, projectPartialRoute, projectsImportExportPartialRoute, projectsPartialRoute, projectVariablesPartialRoute, projectVariablesPartialRouteWrapper, projectVariablesPreviewPartialRoute, useQueryStringParams, } from "@octopusdeploy/portal-routes";
import LegacyConfigurationRoutes from "app/areas/configuration/components/ConfigurationRoutes/ConfigurationRoutes";
import type { ComponentType, ReactElement } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch, useLocation } from "react-router-dom";
import ConfigurationLayout from "~/areas/configuration/components/ConfigurationLayout/ConfigurationLayout";
import { NonVcsDeploymentSettingsRoute as LegacyProjectDeploymentSettingsRoutes } from "~/areas/projects/components/DeploymentProcessSettings/Routing/DeploymentSettingsRoute";
import { DeploymentsRoute as LegacyProjectDeploymentRoutes } from "~/areas/projects/components/DeploymentsRoute/DeploymentsRoute";
import { ProjectDeploymentProcessRoutes as LegacyProjectDeploymentProcessRoutes } from "~/areas/projects/components/Process/Routing/ProcessRoute";
import { ProjectLayout } from "~/areas/projects/components/ProjectLayout/ProjectLayout";
import BranchAwareRedirect from "~/areas/projects/components/ProjectsRoutes/BranchAwareRedirect";
import { ProjectBranchDeploymentRoutes as LegacyProjectBranchDeploymentRoutes, ProjectBranchRoutes as LegacyVcsProjectRoutes, ProjectBranchVariablesRoutes as LegacyVariablesVcsRoutes, ProjectNonBranchRoutes as LegacyNonVcsProjectRoutes, ProjectsImportExportRoutes as LegacyProjectsImportExportRoutes, ProjectsRoutes as LegacyProjectsRoutes, } from "~/areas/projects/components/ProjectsRoutes/ProjectsRoutes";
import { VariablesBranchAwareRedirect } from "~/areas/projects/components/ProjectsRoutes/VariablesBranchAwareRedirect";
import { VariablesRoute as LegacyVariablesNonVcsRoutes } from "~/areas/projects/components/Variables";
import { AllVariablesRoute as LegacyAllVariablesRoutes } from "~/areas/projects/components/Variables/AllVariables/AllVariablesRoute";
import { ProjectVariablesRoute as LegacyProjectVariablesRoutes } from "~/areas/projects/components/Variables/ProjectVariables/ProjectVariablesRoute";
import { VariablePreviewRoute as LegacyPreviewVariablesRoutes } from "~/areas/projects/components/Variables/VariablePreview/VariablePreviewRoute";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import { RedirectProjectIfNotSlug } from "~/components/SlugSafeRedirect/SlugSafeRedirect";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import type { ProjectPageRegistration } from "~/routing/pageRegistrations/ProjectPageRegistration";
import { allPageRegistrations, allPages } from "./pageRegistrations/allPageRegistrations";
interface AllRoutesProps {
    legacyRoutes: ReactElement[];
}
export function AllPageRoutes({ legacyRoutes }: AllRoutesProps) {
    return (<Switch>
            <ReloadableRoute path={formatRoutePath(projectsPartialRoute.template)} render={() => <ProjectsRoutes />}/>

            <ReloadableRoute path={formatRoutePath(allPages.system.childGroups.configuration.partialRoute)} render={() => <ConfigurationRoutes />}/>

            {legacyRoutes}
        </Switch>);
}
type RoutePropsFor<T extends UnknownPartialRouteDefinition> = RouteComponentProps<T extends PartialRouteDefinition<infer RouteProps> ? RouteProps : never>;
const branchNameKey: Extract<keyof ParametersOfPartialRouteDefinition<typeof projectBranchPartialRoute>, "branchName"> = "branchName";
function ProjectsRoutes() {
    const location = useLocation();
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    const projectPages: UnknownProjectPageRegistration[] = isRoutingVNextEnabled ? allPageRegistrations.projectPages : [];
    return (<Switch>
            {renderProjectPartialRouteChildren(projectsPartialRoute, projectPages)}

            <ReloadableRoute path={formatRoutePath(projectsImportExportPartialRoute.template)} render={() => (<Switch>
                        {renderProjectPartialRouteChildren(projectsImportExportPartialRoute, projectPages)}
                        <LegacyProjectsImportExportRoutes />
                    </Switch>)}/>

            <ReloadableRoute path={formatRoutePath(projectPartialRoute.template)} render={(projectRouteProps: RoutePropsFor<typeof projectPartialRoute>) => (<RedirectProjectIfNotSlug spaceId={projectRouteProps.match.params.spaceId}>
                        <Switch>
                            <ReloadableRoute path={formatRoutePath(projectBranchPartialRoute.template)} doNotReloadWhenTheseKeysChange={[branchNameKey]} render={(projectBranchRouteProps: RoutePropsFor<typeof projectBranchPartialRoute>) => (<ProjectLayout spaceId={projectBranchRouteProps.match.params.spaceId} projectSlug={projectBranchRouteProps.match.params.projectSlug} branchName={decodeRouteParameter(projectBranchRouteProps.match.params.branchName)} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                        <Switch>
                                            <ReloadableRoute path={formatRoutePath(projectBranchDeploymentPartialRoute.template)} render={() => {
                    return (<ErrorContextProvider>
                                                            <Switch>
                                                                {renderProjectPartialRouteChildren(projectBranchDeploymentPartialRoute, projectPages)}
                                                                <LegacyProjectBranchDeploymentRoutes />
                                                            </Switch>
                                                        </ErrorContextProvider>);
                }}/>

                                            <ReloadableRoute path={formatRoutePath(projectBranchVariablesPartialRoute.template)} render={() => (<ErrorContextProvider>
                                                        <Switch>
                                                            {renderProjectPartialRouteChildren(projectBranchVariablesPartialRoute, projectPages)}
                                                            <LegacyVariablesVcsRoutes />
                                                        </Switch>
                                                    </ErrorContextProvider>)}/>

                                            {renderProjectPartialRouteChildren(projectBranchPartialRoute, projectPages)}
                                            <LegacyVcsProjectRoutes />
                                        </Switch>
                                    </ProjectLayout>)}/>
                            <ReloadableRoute render={() => (<ProjectLayout spaceId={projectRouteProps.match.params.spaceId} projectSlug={projectRouteProps.match.params.projectSlug} branchName={undefined} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                        <Switch>
                                            <ReloadableRoute path={formatRoutePath(projectDeploymentPartialRoute.template)} render={() => (<Switch>
                                                        <ReloadableRoute path={formatRoutePath(projectDeploymentProcessPartialRoute.template)} render={() => {
                        return (<BranchAwareRedirect>
                                                                        <ErrorContextProvider>
                                                                            <Switch>
                                                                                {renderProjectPartialRouteChildren(projectDeploymentProcessPartialRoute, projectPages)}
                                                                                <LegacyProjectDeploymentProcessRoutes />
                                                                            </Switch>
                                                                        </ErrorContextProvider>
                                                                    </BranchAwareRedirect>);
                    }}/>
                                                        <ReloadableRoute path={formatRoutePath(projectDeploymentSettingsPartialRoute.template)} render={() => {
                        return (<BranchAwareRedirect>
                                                                        <ErrorContextProvider>
                                                                            <Switch>
                                                                                {renderProjectPartialRouteChildren(projectDeploymentSettingsPartialRoute, projectPages)}
                                                                                <LegacyProjectDeploymentSettingsRoutes />
                                                                            </Switch>
                                                                        </ErrorContextProvider>
                                                                    </BranchAwareRedirect>);
                    }}/>
                                                        {renderProjectPartialRouteChildren(projectDeploymentPartialRoute, projectPages)}
                                                        <LegacyProjectDeploymentRoutes />;
                                                    </Switch>)}/>
                                            <ReloadableRoute path={formatRoutePath(projectVariablesPartialRoute.template)} render={() => {
                    return (<ErrorContextProvider>
                                                            <Switch>
                                                                <ReloadableRoute path={formatRoutePath(projectVariablesPartialRouteWrapper.template)} exact={true} render={() => {
                            return (<VariablesBranchAwareRedirect>
                                                                                <Switch>
                                                                                    {renderProjectPartialRouteChildren(projectVariablesPartialRouteWrapper, projectPages)}
                                                                                    <LegacyProjectVariablesRoutes />
                                                                                </Switch>
                                                                            </VariablesBranchAwareRedirect>);
                        }}/>

                                                                <ReloadableRoute path={formatRoutePath(projectAllVariablesPartialRoute.template)} render={() => {
                            return (<VariablesBranchAwareRedirect>
                                                                                <Switch>
                                                                                    {renderProjectPartialRouteChildren(projectAllVariablesPartialRoute, projectPages)}
                                                                                    <LegacyAllVariablesRoutes />
                                                                                </Switch>
                                                                            </VariablesBranchAwareRedirect>);
                        }}/>

                                                                <ReloadableRoute path={formatRoutePath(projectVariablesPreviewPartialRoute.template)} render={() => {
                            return (<VariablesBranchAwareRedirect>
                                                                                <Switch>
                                                                                    {renderProjectPartialRouteChildren(projectVariablesPreviewPartialRoute, projectPages)}
                                                                                    <LegacyPreviewVariablesRoutes />
                                                                                </Switch>
                                                                            </VariablesBranchAwareRedirect>);
                        }}/>

                                                                {renderProjectPartialRouteChildren(projectVariablesPartialRoute, projectPages)}
                                                                <LegacyVariablesNonVcsRoutes />
                                                            </Switch>
                                                        </ErrorContextProvider>);
                }}/>
                                            {renderProjectPartialRouteChildren(projectPartialRoute, projectPages)}
                                            <LegacyNonVcsProjectRoutes />;
                                        </Switch>
                                    </ProjectLayout>)}/>
                        </Switch>
                    </RedirectProjectIfNotSlug>)}/>

            <LegacyProjectsRoutes />
        </Switch>);
}
function ConfigurationRoutes() {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    return (<ConfigurationLayout>
            {(PageLayout) => (<Switch>
                    {isRoutingVNextEnabled && renderConfigurationPages(allPages.system.childGroups.configuration.pages, PageLayout)}
                    <LegacyConfigurationRoutes />
                </Switch>)}
        </ConfigurationLayout>);
}
function renderConfigurationPages(allConfigurationPages: typeof allPages.system.childGroups.configuration.pages, PageLayout: ComponentType<Level2PageLayoutProps>) {
    return Object.values(allConfigurationPages).map((r) => {
        const routePath = formatRoutePath(r.route.template);
        return <ReloadableRoute key={routePath} path={routePath} exact={true} render={(props) => <ConfigurationPageFromRouteDefinition PageLayout={PageLayout} routeDefinition={r} routeProps={props}/>}/>;
    });
}
function ConfigurationPageFromRouteDefinition({ routeDefinition, routeProps, PageLayout, }: {
    routeDefinition: (typeof allPages.system.childGroups.configuration.pages)[keyof typeof allPages.system.childGroups.configuration.pages];
    routeProps: RouteComponentProps;
    PageLayout: ComponentType<Level2PageLayoutProps>;
}) {
    const [queryParams, setQueryParams] = useQueryStringParams(routeDefinition.route.queryParameters);
    return routeDefinition.render(PageLayout, routeProps.match.params, queryParams, setQueryParams);
}
function renderProjectPartialRouteChildren(parentPartialRoute: UnknownPartialRouteDefinition, allRegisteredRoutes: UnknownProjectPageRegistration[]) {
    return allRegisteredRoutes
        .filter((r) => r.route.parentPartialRoute === parentPartialRoute)
        .map((r) => {
        const routePath = formatRoutePath(r.route.template);
        return <ReloadableRoute key={routePath} path={routePath} exact={true} render={(props) => <ProjectPageFromRouteDefinition routeDefinition={r} routeProps={props}/>}/>;
    });
}
function ProjectPageFromRouteDefinition({ routeDefinition, routeProps }: {
    routeDefinition: UnknownProjectPageRegistration;
    routeProps: RouteComponentProps;
}) {
    const [queryParams, setQueryParams] = useQueryStringParams(routeDefinition.route.queryParameters);
    return routeDefinition.render(routeProps.match.params, queryParams, setQueryParams);
}
function formatRoutePath<T>(routeTemplate: RouteTemplate<T>) {
    return routeTemplate.map((part) => (typeof part === "string" ? part : isRouteParameter(part) ? formatRouteParameter(part) : optionalSpaceIdForSystemRoutes)).join("");
}
const optionalSpaceIdForSystemRoutes = "/:spaceId(Spaces-[0-9]+)?";
function formatRouteParameter<T extends string>(routeParameter: RequiredRouteParameter<T>) {
    // React router route parameters are prefixed with a ":"
    return `:${routeParameter.name}`;
}
function decodeRouteParameter(routeParameter: string | undefined): string | undefined {
    return routeParameter ? decodeURIComponent(routeParameter) : undefined;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownProjectPageRegistration = ProjectPageRegistration<any, UnknownQueryParam[]>;
