import type { SettingsMetadataItemResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ReactNode } from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { SimplePagingList } from "../../../../components/PagingList";
import routeLinks from "../../../../routeLinks";
interface SettingsState extends DataBaseComponentState {
    settingsResponse?: ResourceCollection<SettingsMetadataItemResource>;
}
class SettingsList extends SimplePagingList<SettingsMetadataItemResource> {
}
export class Settings extends DataBaseComponent<RouteComponentProps<{}>, SettingsState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const settingsResponse = await repository.Settings.list();
            this.setState({ settingsResponse });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        return (<PaperLayout title="Settings" busy={this.state.busy} errors={this.errors}>
                {this.state.settingsResponse && (<SettingsList items={this.state.settingsResponse.Items} onRow={(item: SettingsMetadataItemResource) => this.buildSettingsRow(item)} onRowRedirectUrl={(setting) => routeLinks.configuration.setting(setting.Id)}/>)}
            </PaperLayout>);
    }
    private buildSettingsRow(settingsMetadata: SettingsMetadataItemResource): ReactNode {
        return (<div>
                <ListTitle>{settingsMetadata.Name}</ListTitle>
                <div>{settingsMetadata.Description}</div>
            </div>);
    }
    static displayName = "Settings";
}
