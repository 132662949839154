import type { IProcessResource, ProcessType, ProjectContextRepository } from "@octopusdeploy/octopus-server-client/dist/src/index";
import * as React from "react";
import { loadProcess } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProjectContext } from "~/areas/projects/context/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/index";
import routeLinks from "~/routeLinks";
type RedirectToProcessEditorViaSlugProps = {
    processType: ProcessType;
    stepSlug?: string;
    projectSlug: string;
};
const useLoadProcessEffect = (projectContextRepository: ProjectContextRepository, processType: ProcessType, id: string, doBusyTask: DoBusyTask, onLoaded: (process: IProcessResource) => Promise<void>) => {
    return useDoBusyTaskEffect(doBusyTask, async () => {
        if (!id) {
            return;
        }
        const result: IProcessResource = await loadProcess(projectContextRepository, processType, id);
        if (onLoaded) {
            await onLoaded(result);
        }
    }, [id, processType, projectContextRepository]);
};
const RedirectToProcessEditorViaSlug = (props: RedirectToProcessEditorViaSlugProps & {
    doBusyTask: DoBusyTask;
}) => {
    const projectContext = useProjectContext();
    const [process, setProcess] = React.useState<IProcessResource | undefined>(undefined);
    useLoadProcessEffect(projectContext.state.projectContextRepository, props.processType, projectContext.state.model.DeploymentProcessId, props.doBusyTask, async (process) => {
        await setProcess(process);
    });
    if (!process)
        return <></>;
    const stepSlug = props.stepSlug;
    if (!stepSlug) {
        return <InternalRedirect to={routeLinks.project(props.projectSlug).deployments.process.root}/>;
    }
    const step = process.Steps.find((s) => s.Slug === stepSlug);
    const actionId = step?.Actions?.[0].Id;
    if (!actionId) {
        return <InternalRedirect to={routeLinks.project(props.projectSlug).deployments.process.root}/>;
    }
    return <InternalRedirect to={routeLinks.project(props.projectSlug).deployments.process.step(actionId)}/>;
};
class RedirectToProcessEditorViaSlugPage extends DataBaseComponent<RedirectToProcessEditorViaSlugProps> {
    render() {
        return <RedirectToProcessEditorViaSlug doBusyTask={this.doBusyTask} {...this.props}/>;
    }
    static displayName = "RedirectToProcessEditorViaSlugPage";
}
export default RedirectToProcessEditorViaSlugPage;
