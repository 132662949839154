import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { useAnalyticActionDispatch, Action as AnalyticsAction } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import { Summary } from "~/components/form/Sections/index";
import { ExpandableFormSection, FormSectionHeading } from "~/components/form/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import styles from "./style.module.less";
interface SharedGitProps {
    PageLayout: React.ComponentType<Level2PageLayoutProps>;
}
interface GitInternalProps extends SharedGitProps {
    dispatchAction: AnalyticActionDispatcher;
}
interface GitState {
    hasClearedCache: boolean;
}
export class GitInternal extends DataBaseComponent<GitInternalProps, GitState> {
    constructor(props: GitInternalProps) {
        super(props);
        this.state = {
            hasClearedCache: false,
        };
    }
    private onClearCacheClick = async () => {
        this.props.dispatchAction("Clear Local Cache", { resource: "Features", action: AnalyticsAction.Clear });
        this.setState({ hasClearedCache: false });
        await this.doBusyTask(async () => {
            logger.info("Clearing local git cache");
            await repository.ServerConfiguration.clearCache();
            this.setState({ hasClearedCache: true });
        });
        return;
    };
    render() {
        const PageLayout = this.props.PageLayout;
        return (<PageLayout title="Git Configuration" errors={this.errors} busy={this.state.busy}>
                <FormSectionHeading title="Git"/>
                <ExpandableFormSection errorKey={"ClearGitCache"} title={"Clear Git Cache"} summary={Summary.summary("Purge all cloned repositories and force Octopus to re-clone them the next time it needs them")} help="Octopus keeps local clones of git repositories used in version controlled projects. This will completely purge all cloned repositories and force Octopus to re-clone them the next time it needs them.">
                    {this.state.hasClearedCache && (<div className={styles.successCallout}>
                            <Callout type={CalloutType.Success} title={"Cache Cleared"}/>
                        </div>)}
                    <ActionButton type={ActionButtonType.Secondary} title="Clear Local Git Cache" label="Clear Local Cache" onClick={() => this.onClearCacheClick()} busyLabel={"Clearing..."} disabled={this.state.busy}/>
                </ExpandableFormSection>
            </PageLayout>);
    }
    static displayName = "GitInternal";
}
type GitProps = SharedGitProps;
// Wrap the component and inject the analytic dispatch action.
export default function Git(props: GitProps) {
    const dispatchAction = useAnalyticActionDispatch();
    return <GitInternal dispatchAction={dispatchAction} PageLayout={props.PageLayout}/>;
}
