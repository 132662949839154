import * as React from "react";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import routeLinks from "~/routeLinks";
export const importExportMenuItems = [
    OverflowMenuItems.navItem("Import Projects", routeLinks.import.root),
    OverflowMenuItems.navItem("Export Projects", routeLinks.export.root),
    OverflowMenuItems.navItem("View Import Export Tasks", routeLinks.importExport.root),
];
export default class ImportExportMenu extends React.Component {
    render() {
        return <OverflowMenu tabIndex={-1} menuItems={importExportMenuItems}/>;
    }
    static displayName = "ImportExportMenu";
}
