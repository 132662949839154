/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css, cx } from "@emotion/css";
import { List, ListItem } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { match as Match } from "react-router";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import { NoResults } from "~/components/NoResults/NoResults";
import NumberedPagingBar from "../PagingBaseComponent/NumberedPagingBar";
import { getNavigationUrl } from "../PagingBaseComponent/PagingBaseComponent";
import { Section } from "../Section/Section";
import { ListInternalLink } from "./ListInternalLink";
interface SimpleListProps<R> {
    empty?: React.ReactNode;
    match?: Match;
    items: R[];
    filterHintText?: string;
    autoFocusOnFilterSearch?: boolean;
    pageSize?: number;
    listClassName?: string;
    selectedIndex?: number;
    hideItemPadding?: boolean;
    onRow(item: R, index: number): React.ReactNode;
    onRowRedirectUrl?(item: R): string | null;
    onFilter?(filter: string, item: R): boolean;
    onRowKey?: (item: R) => string;
    includeRowGaps?: boolean;
}
interface SimplePagingListState {
    filter?: string;
    currentPageIndex: number;
}
//eslint-disable-next-line react/no-unsafe
export abstract class SimplePagingList<R> extends React.Component<SimpleListProps<R>, SimplePagingListState> {
    constructor(props: SimpleListProps<R>) {
        super(props);
        this.state = { currentPageIndex: 0 };
    }
    UNSAFE_componentWillReceiveProps(nextProps: SimpleListProps<R>) {
        if (nextProps.pageSize) {
            const newLastPageIndex = nextProps.items.length === 0 ? 1 : Math.ceil(nextProps.items.length / nextProps.pageSize);
            if (newLastPageIndex < this.state.currentPageIndex) {
                this.setState({ currentPageIndex: newLastPageIndex });
            }
        }
    }
    onFilter(item: R): boolean {
        const filter = this.state.filter;
        if (!filter || filter === "" || !this.props.onFilter) {
            return true;
        }
        return this.props.onFilter(this.state.filter!, item);
    }
    render() {
        if (!this.props.items) {
            return null;
        }
        let filteredList = this.props.items.filter((item) => this.onFilter(item));
        const filteredListCount = filteredList.length;
        if (this.props.pageSize) {
            const firstItemIndex = this.props.pageSize * this.state.currentPageIndex;
            filteredList = filteredList.slice(firstItemIndex, firstItemIndex + this.props.pageSize);
        }
        return (<>
                {this.props.onFilter && this.renderFilterSearchComponents()}
                <List includeRowGaps={this.props.includeRowGaps} includeVerticalPadding empty={this.props.empty ? <Section>{this.props.empty}</Section> : <NoResults />} items={filteredList} renderRow={({ item, index }) => this.buildItem(item, index)} rowKey={this.props.onRowKey}/>
                {this.props.pageSize && (<NumberedPagingBar currentPageIndex={this.state.currentPageIndex} totalItems={filteredListCount} pageSize={this.props.pageSize} onPageSelected={(skip, currentPageIndex) => this.setState({ currentPageIndex })}/>)}
            </>);
    }
    private buildItem(item: R, index: number) {
        return (<ListItem isSelected={this.props.selectedIndex === index}>
                <NavigateListItemContent item={item} match={this.props.match} onRowRedirectUrl={this.props.onRowRedirectUrl}>
                    <SimpleListItemContent includeItemPadding={!this.props.hideItemPadding}>{this.props.onRow(item, index)}</SimpleListItemContent>
                </NavigateListItemContent>
            </ListItem>);
    }
    private renderFilterSearchComponents() {
        return (<Section>
                <FilterSearchBox placeholder="Filter..." onChange={(filter) => this.setState({ filter, currentPageIndex: 0 })} autoFocus={this.props.autoFocusOnFilterSearch}/>
            </Section>);
    }
    static displayName = "SimplePagingList";
}
type SimpleListItemContentProps = {
    includeItemPadding: boolean;
};
function SimpleListItemContent({ children, includeItemPadding = true }: React.PropsWithChildren<SimpleListItemContentProps>) {
    return <div className={cx(simpleListItemContentStyles, includeItemPadding ? simpleListItemContentPaddingStyles : undefined)}>{children}</div>;
}
const simpleListItemContentStyles = css({
    lineHeight: "16px",
    fontSize: "0.875rem",
});
const simpleListItemContentPaddingStyles = css({
    padding: space[16],
});
function NavigateListItemContent<T>({ item, onRowAccessibleName, match, onRowRedirectUrl, children }: React.PropsWithChildren<{
    item: T;
    onRowAccessibleName?: (item: T) => string;
    match?: Match;
    onRowRedirectUrl?: (item: T) => string | null;
}>) {
    const accessibleName = onRowAccessibleName?.(item);
    const redirectUrl = getNavigationUrl({ match, onRowRedirectUrl }, item);
    if (redirectUrl) {
        // Make these actual href links, so people can still right-click > open new window (this is expected usability for things you can click).
        return (<ListInternalLink href={redirectUrl} accessibleName={accessibleName}>
                {children}
            </ListInternalLink>);
    }
    return <>{children}</>;
}
