/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { projectsRoute } from "@octopusdeploy/portal-routes";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import { repository } from "../../clientInstance";
import InternalRedirect from "../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../routeLinks";
interface RunbookRunToRunbookTaskRedirectProps {
    spaceId: string;
    runbookRunId: string;
}
interface RunbookRunToRunbookTaskRedirectState extends DataBaseComponentState {
    redirectTo: string;
}
export default class RunbookRunToRunbookTaskRedirect extends DataBaseComponent<RunbookRunToRunbookTaskRedirectProps, RunbookRunToRunbookTaskRedirectState> {
    constructor(props: RunbookRunToRunbookTaskRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const runbookRunId = this.props.runbookRunId;
            const runbookRun = await repository.RunbookRuns.get(runbookRunId);
            const runbookSnapshot = await repository.RunbookSnapshots.get(runbookRun.RunbookSnapshotId);
            const path = routeLinks.project(runbookSnapshot.ProjectId).operations.runbook(runbookSnapshot.RunbookId).runbookSnapshot(runbookSnapshot).runbookRuns.specific(runbookRun);
            this.setState({
                redirectTo: path,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main>
                    <AreaTitle link={projectsRoute.generateUrl({ spaceId: this.props.spaceId })} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={{ pathname: redirectTo }}/>;
    }
    static displayName = "RunbookRunToRunbookTaskRedirect";
}
