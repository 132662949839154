import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import PaperLayout from "~/components/PaperLayout";
type TenantTasksProps = RouteComponentProps<{
    tenantId: string;
}>;
const TenantTasks: React.FC<TenantTasksProps> = (props) => {
    const tenantId = props.match.params.tenantId;
    if (!tenantId) {
        logger.warn("Failed to find tenantId from the route. This should not happen.");
        return null;
    }
    return (<Tasks restrictToTenantId={tenantId} renderLayout={({ busy, errors, hasLoadedOnce, children }) => {
            return (<PaperLayout title={"Tasks"} busy={busy} enableLessIntrusiveLoadingIndicator={hasLoadedOnce} errors={errors} fullWidth={true}>
                        {children}
                    </PaperLayout>);
        }}/>);
};
TenantTasks.displayName = "TenantTasks"
export default TenantTasks;
