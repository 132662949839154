import { Checkbox, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { KubernetesStatusCheckComponentProperties, KubernetesStatusCheckProperties } from "@octopusdeploy/legacy-action-properties/src/KubernetesStatusCheckComponentProperties";
import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import { useEffect, useState } from "react";
import * as React from "react";
import styles from "~/components/Actions/kubernetes/style.module.less";
import TimeoutFormSection from "~/components/Actions/kubernetes/timeoutFormSection";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import Chip from "~/components/Chips/Chip";
import { NewFeatureChip } from "~/components/Chips/index";
import KubernetesDeploymentFeedback from "~/components/K8sStatusFeedback/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { withTheme } from "~/components/Theme/index";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { Note, Summary } from "~/components/form/index";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
export enum StatusCheckOption {
    enabled = "enabled",
    disabled = "disabled",
    legacyWait = "legacyWait"
}
interface KubernetesStatusCheckComponentProps extends ActionEditProps<KubernetesStatusCheckComponentProperties> {
    showLegacyWait: boolean;
    statusCheckSupported: boolean;
    timeoutSupported: boolean;
    jobsSupported: boolean;
    notSupportedReason?: JSX.Element;
}
const defaultDeploymentTimeout = "180";
export const statusCheckOptionLabels: {
    [key in StatusCheckOption]: string;
} = {
    enabled: "Check that Kubernetes objects are running successfully",
    legacyWait: "Check that the rollout was successful",
    disabled: "Don't do any verification checks",
};
type FullNameOf<S extends string> = `Octopus.Action.Kubernetes.${S}`;
type ShortNameOf<T> = T extends FullNameOf<infer Name> ? Name : never;
type FullNames = keyof KubernetesStatusCheckProperties;
type ShortNames = {
    [key in FullNames]: ShortNameOf<key>;
}[FullNames];
const toFullName = <T extends ShortNames>(shortName: T): FullNameOf<T> => `Octopus.Action.Kubernetes.${shortName}`;
const RecommendedOptionChip = () => withTheme((theme) => (<Chip noTooltip={true} backgroundColor={theme.successBackground} labelColor={theme.successText}>
            Recommended
        </Chip>));
const LegacyOptionChip = () => withTheme((theme) => (<Chip noTooltip={true} backgroundColor={theme.chipBackground} labelColor={theme.chipText}>
            Legacy
        </Chip>));
const timeoutHelp = (<>
        <div>The step timeout is the maximum amount of time a deployment step is allowed to run before being terminated.</div>
        <br />
        <div>This setting is intended to prevent a step from running indefinitely or causing delays in the overall deployment process.</div>
        <br />
        <ExternalLink href="KOS">Docs</ExternalLink>
    </>);
const waitForJobsHelp = (<>
        <div>By default, Octopus won’t wait for the Kubernetes Jobs created during a step to run to completion before marking the step as complete.</div>
        <br />
        <div>If you enable wait for Jobs to complete during the step, Octopus will ensure the Jobs have successfully finished executing before it considers the step successful.</div>
        <br />
        <div>When combining this option with the step timeout, it's important to allocate a sufficient step timeout period.</div>
        <br />
        <ExternalLink href="KOS">Docs</ExternalLink>
    </>);
export const KubernetesStatusCheckComponent = (props: KubernetesStatusCheckComponentProps) => {
    const getKubernetesVariable = <T extends ShortNames>(shortName: T): KubernetesStatusCheckProperties[FullNameOf<T>] => props.properties[toFullName(shortName)];
    const setKubernetesVariable = <T extends ShortNames>(shortName: T, value: KubernetesStatusCheckProperties[FullNameOf<T>]) => props.setProperties({ [toFullName(shortName)]: value });
    const getDeploymentWait = (): string => props.properties["Octopus.Action.KubernetesContainers.DeploymentWait"];
    const setDeploymentWait = (wait: string) => props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentWait"]: wait });
    const toStatusCheckOption = (resourceStatusCheck: "True" | "False", deploymentWait: string): StatusCheckOption => {
        if (resourceStatusCheck === "True") {
            return StatusCheckOption.enabled;
        }
        if (deploymentWait === "Wait") {
            return StatusCheckOption.legacyWait;
        }
        return StatusCheckOption.disabled;
    };
    const toStatusCheckVariableValues = (statusCheckOption: StatusCheckOption): [
        resourceStatusCheck: "True" | "False",
        deploymentWait: string
    ] => {
        switch (statusCheckOption) {
            case StatusCheckOption.enabled:
                return ["True", "NoWait"];
            case StatusCheckOption.legacyWait:
                return ["False", "Wait"];
            case StatusCheckOption.disabled:
                return ["False", "NoWait"];
        }
    };
    const setStatusCheck = (statusCheckOption: StatusCheckOption) => {
        const [resourceStatusCheck, deploymentWait] = toStatusCheckVariableValues(statusCheckOption);
        setKubernetesVariable("ResourceStatusCheck", resourceStatusCheck);
        setDeploymentWait(deploymentWait);
        setStatusCheckOption(statusCheckOption);
    };
    const getSummary = () => {
        const summary = statusCheckOption === StatusCheckOption.enabled ? Summary.default : Summary.summary;
        return summary(<span>{statusCheckOptionLabels[statusCheckOption]}</span>);
    };
    const getDefaultStatusCheckOption = () => {
        const option = toStatusCheckOption(getKubernetesVariable("ResourceStatusCheck"), getDeploymentWait());
        // this indicates we are in an existing step that did not support the wait option,
        // such as a Job or a blue/green deployment.
        // For these projects the value of "DeploymentWait" was incorrectly set to "Wait".
        if (option === StatusCheckOption.legacyWait && !props.showLegacyWait) {
            return StatusCheckOption.disabled;
        }
        return option;
    };
    const timeout = getKubernetesVariable("DeploymentTimeout");
    const [statusCheckOption, setStatusCheckOption] = useState<StatusCheckOption>(getDefaultStatusCheckOption());
    useEffect(() => {
        if (statusCheckOption === StatusCheckOption.legacyWait && !props.showLegacyWait) {
            setStatusCheckOption(StatusCheckOption.disabled);
        }
    }, [statusCheckOption, props.showLegacyWait]);
    useEffect(() => {
        if (!props.statusCheckSupported) {
            setStatusCheckOption(StatusCheckOption.disabled);
        }
    }, [props.statusCheckSupported]);
    const renderContent = () => {
        if (!props.statusCheckSupported) {
            return props.notSupportedReason;
        }
        return (<>
                <KubernetesDeploymentFeedback storageKey="Octopus.Callout.KubernetesStatusCheckComponentFeedback.DateDismissed"/>
                <RadioButtonGroup<StatusCheckOption> onChange={(option) => setStatusCheck(option)} value={statusCheckOption}>
                    <RadioButton value={StatusCheckOption.enabled} label={<span>
                                <strong>{statusCheckOptionLabels.enabled}</strong>
                                <RecommendedOptionChip />
                            </span>} accessibleName={statusCheckOptionLabels.enabled}/>
                    <div className={styles.radioButtonSection}>
                        <Note>Get real-time status updates while this step is running and validate the status of your Kubernetes objects before considering this step successful.</Note>
                        {statusCheckOption === StatusCheckOption.enabled && props.timeoutSupported && (<>
                                <TimeoutFormSection enableCheckBox={true} optionEnabled={timeout !== undefined && timeout !== null} onOptionEnabledChange={(enabled) => setKubernetesVariable("DeploymentTimeout", enabled ? defaultDeploymentTimeout : null)} value={timeout ?? "0"} onValueChange={(value) => setKubernetesVariable("DeploymentTimeout", value)} label={"Step timeout"} note={"Timeout period in seconds"} help={timeoutHelp} error={props.getFieldError("Octopus.Action.Kubernetes.DeploymentTimeout")} localNames={props.localNames}/>
                            </>)}
                        {statusCheckOption === StatusCheckOption.enabled && props.jobsSupported && (<div className={styles.inputWithHelp}>
                                <Checkbox noMargin value={getKubernetesVariable("WaitForJobs") === "True"} onChange={(value) => setKubernetesVariable("WaitForJobs", value ? "True" : "False")} label={"Wait for Jobs to complete during this step"}/>
                                &nbsp;
                                <PopoverHelp trigger={"click"} placement={"right-start"}>
                                    {waitForJobsHelp}
                                </PopoverHelp>
                            </div>)}
                    </div>
                    {props.showLegacyWait && (<RadioButton value={StatusCheckOption.legacyWait} label={<span>
                                    <strong>{statusCheckOptionLabels.legacyWait}</strong>
                                    <LegacyOptionChip />
                                </span>} accessibleName={statusCheckOptionLabels.legacyWait}/>)}

                    {props.showLegacyWait && (<Note>
                            Use the <code>kubectl rollout status</code> command to get confirmation that your pods have updated. This option doesn't show status updates in real-time and may be deprecated in future.
                        </Note>)}

                    <RadioButton value={StatusCheckOption.disabled} label={<span>
                                <strong>{statusCheckOptionLabels.disabled}</strong>
                            </span>} accessibleName={statusCheckOptionLabels.disabled}/>
                    <Note>Octopus will mark this step as complete without waiting for any updates from Kubernetes about the deployment.</Note>
                </RadioButtonGroup>
            </>);
    };
    return (<ExpandableFormSection title="Kubernetes Object Status Check" summary={getSummary()} errorKey="Octopus.Action.Kubernetes.ResourceStatusCheck|Octopus.Action.KubernetesContainers.DeploymentWait" help={<span>
                    Configure how to validate the status of your Kubernetes objects <NewFeatureChip />
                </span>}>
            {renderContent()}
        </ExpandableFormSection>);
};
export const InitialStatusCheckProperties: ActionProperties = {
    "Octopus.Action.Kubernetes.ResourceStatusCheck": "True",
};
const InitialTimeoutProperties: ActionProperties = {
    "Octopus.Action.Kubernetes.DeploymentTimeout": `${defaultDeploymentTimeout}`,
};
export const InitialStatusCheckWithTimeoutProperties: ActionProperties = {
    ...InitialStatusCheckProperties,
    ...InitialTimeoutProperties,
};
export default KubernetesStatusCheckComponent;
