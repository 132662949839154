import { SpaceSwitcher } from "@octopusdeploy/main-navigation";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { useCallback, useState } from "react";
import * as React from "react";
import AddSpaceDialog from "~/areas/configuration/components/Spaces/AddSpaceDialog";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { SpaceLimitReachedDialogLayout } from "~/components/LicenseLimitReachedDialogLayout/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/StandardLayout/SpaceLoader";
import type { SpaceContext } from "~/components/StandardLayout/SpaceLoader";
import routeLinks from "~/routeLinks";
interface SpaceSwitcherUpliftProps {
    spaceContext: SpaceContext;
}
export function SpaceSwitcherNavigationBarItem({ spaceContext }: SpaceSwitcherUpliftProps) {
    const navigation = useSpaceAwareNavigation();
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const { isOpen: isAddSpaceDialogOpen, openDialog: openAddSpaceDialog, closeDialog: closeAddSpaceDialog } = useDialogTrigger();
    const currentSpace = isSpecificSpaceContext(spaceContext) ? spaceContext : undefined;
    const onUpgradeSpacesDialogRequested = useCallback(() => {
        setIsUpgradeDialogOpen(true);
    }, []);
    return (<AnalyticLinkLocationProvider location={"Space Switcher"}>
            <SpaceSwitcher currentSpace={currentSpace} onNewSpaceRequested={openAddSpaceDialog} onUnlockMoreSpacesRequested={onUpgradeSpacesDialogRequested} getSpaceHref={getSpaceHref}/>
            <Dialog open={isUpgradeDialogOpen}>
                <SpaceLimitReachedDialogLayout onActionClick={() => setIsUpgradeDialogOpen(false)}/>
            </Dialog>
            <Dialog open={isAddSpaceDialogOpen}>
                <AddSpaceDialog onSpaceCreated={(space) => {
            closeAddSpaceDialog();
            const routeLinksRoot = isSpaceNotFound(spaceContext) ? routeLinks.withoutSpace() : routeLinks;
            navigation.navigate(routeLinksRoot.configuration.space(space.Id));
        }}/>
            </Dialog>
        </AnalyticLinkLocationProvider>);
}
function getSpaceHref(space: SpaceResource) {
    return routeLinks.space(space.Id);
}
