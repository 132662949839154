import { css } from "@emotion/css";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { Collapse } from "@material-ui/core";
import { Button } from "@octopusdeploy/design-system-components";
import { borderWidth, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { noOp } from "@octopusdeploy/utilities";
import pluralize from "pluralize";
import type { PropsWithChildren } from "react";
import React, { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
interface CollapsibleFilterProps {
    filteredCount?: number | null;
    totalCount: number;
    secondaryContent?: JSX.Element;
    actions?: JSX.Element;
    entityName?: string;
    ariaLabel?: string;
    withoutSideBorder?: boolean;
}
export default function CollapsibleFilter({ filteredCount, totalCount, children, secondaryContent, actions, entityName, ariaLabel, withoutSideBorder = false }: PropsWithChildren<CollapsibleFilterProps>) {
    const dispatchAction = useAnalyticActionDispatch();
    const [expanded, setExpanded] = useState(false);
    const handleOnExpandCollapse = () => {
        setExpanded(!expanded);
        dispatchAction("Toggle Filters", { resource: "Filters", action: Action.View });
    };
    const toolBarStyle = withoutSideBorder ? styles.toolbarWithoutSideBorder : styles.toolbar;
    return (<div className={toolBarStyle}>
            <div className={styles.toolbarHeader} aria-label={ariaLabel}>
                <ResultCount filteredCount={filteredCount} totalCount={totalCount} entityName={entityName}/>
                {secondaryContent ? <VerticalDivider /> : null}
                {secondaryContent}
                <div className={styles.alignRight}>
                    {actions}
                    {actions && children ? <VerticalDivider /> : null}
                    {children ? <ExpandCollapseToggle expanded={expanded} onExpandCollapse={handleOnExpandCollapse}/> : null}
                </div>
            </div>
            {children ? (<Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                    {children}
                </Collapse>) : null}
        </div>);
}
interface CountProps {
    filteredCount?: number | null;
    totalCount: number;
    entityName?: string;
}
function ResultCount({ totalCount, entityName = "result", filteredCount = null }: CountProps) {
    const isFiltered = filteredCount !== null;
    return isFiltered ? (<div className={styles.filteredResult}>
            {filteredCount} of {pluralize(entityName.toLowerCase(), totalCount, true)}
        </div>) : (<div className={styles.result}>{pluralize(entityName.toLowerCase(), totalCount, true)}</div>);
}
interface ExpandCollapseToggleProps {
    expanded: boolean;
    onExpandCollapse?: () => void;
}
function ExpandCollapseToggle({ expanded, onExpandCollapse }: ExpandCollapseToggleProps) {
    const icon = expanded ? <ThirdPartyIcon iconType={ThirdPartyIconType.Collapse}/> : <ThirdPartyIcon iconType={ThirdPartyIconType.Expand}/>;
    return (<div className={styles.alignRight}>
            <Button label={expanded ? "Collapse filters" : "Expand filters"} onClick={onExpandCollapse ?? noOp} importance="quiet" rightIcon={icon}/>
        </div>);
}
export function VerticalDivider() {
    return <div className={styles.divider}>&nbsp;</div>;
}
const borderColor = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const styles = {
    toolbar: css({
        backgroundColor: themeTokens.color.background.primary.default,
        border: borderColor,
    }),
    toolbarWithoutSideBorder: css({
        backgroundColor: themeTokens.color.background.primary.default,
        borderTop: borderColor,
        borderBottom: borderColor,
    }),
    toolbarHeader: css({
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        flexDirection: "row",
        padding: "0 1rem",
    }),
    alignRight: css({
        marginLeft: "auto",
        display: "inherit",
        gap: "1rem",
    }),
    result: css({
        font: text.interface.heading.small,
        color: themeTokens.color.text.primary,
    }),
    filteredResult: css({
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
    }),
    divider: css({
        position: "relative",
        paddingTop: "0.2rem",
        paddingBottom: "0.2rem",
        ":after": {
            content: "''",
            position: "absolute",
            right: 0,
            top: "0",
            height: "100%",
            borderRight: borderColor,
        },
    }),
};
