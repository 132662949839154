import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useEffect, useState } from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import { OnboardingDialogLayout } from "~/components/OnboardingDialog/OnboardingDialogLayout";
const ImageTourComplete = require("./Assets/TourComplete.svg");
export interface SampleProjectCompleteDialogProps {
    projectId: string;
    open: boolean;
    onCloseDialog: () => void;
}
export function SampleProjectCompleteDialog({ projectId, open, onCloseDialog }: SampleProjectCompleteDialogProps) {
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    useEffect(() => {
        dispatchAction("Sample Project Tour: Completed", { action: Action.View, resource: "SampleProjectTourComplete" });
    });
    const styles = {
        title: css({
            font: text.interface.heading.medium,
            margin: 0,
        }),
        contentPanel: css({
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }),
        content: css({
            display: "flex",
            flexDirection: "column",
            gap: space[16],
        }),
        text: css({ font: text.interface.body.default.base }),
        checklist: css({
            backgroundColor: themeTokens.color.background.secondary.default,
            padding: space[12],
            display: "flex",
            flexDirection: "column",
            gap: space[12],
        }),
    };
    const handleCloseDialog = () => {
        dispatchAction("Sample Project Tour: 'Let's go' Clicked", { action: Action.Select, resource: "SampleProjectTourComplete" });
        onCloseDialog();
    };
    return (<OnboardingDialog name="sample-project-complete-dialog" open={open} close={onCloseDialog} contentPanelHeight={600} helpPanelContent={<TourComplete />} helpPanelShowToggle={false}>
            <OnboardingDialogLayout onCloseDialog={onCloseDialog} title={<h2 className={styles.title}>Congratulations!</h2>} actions={<ActionButton label={"LET'S GO"} type={ActionButtonType.Primary} onClick={handleCloseDialog}/>}>
                <div className={styles.contentPanel}>
                    <div className={styles.content}>
                        <div className={styles.text}>You now know the basics of a project. We covered:</div>
                        <div className={styles.checklist}>
                            <ListItem>Viewing your software releases</ListItem>
                            <ListItem>Progressing releases through environments</ListItem>
                            <ListItem>Building your deployment process</ListItem>
                            <ListItem>Managing variables</ListItem>
                            <ListItem>Performing operations with runbooks</ListItem>
                        </div>
                        <div className={styles.text}>It's your turn to play around and apply what you learned. If you need any assistance along the way, look to the Help sidebar on the right to search our docs and watch video tutorials.</div>
                    </div>
                    <Feedback dispatchAction={dispatchAction}/>
                </div>
            </OnboardingDialogLayout>
        </OnboardingDialog>);
}
function TourComplete() {
    const styles = {
        wrapper: css({
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }),
    };
    return (<div className={styles.wrapper}>
            <img src={ImageTourComplete} alt="Tour complete"/>
        </div>);
}
function ListItem({ children }: {
    children: string;
}) {
    const style = css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: space[8],
        font: text.interface.body.default.base,
    });
    return (<div className={style}>
            <CheckIcon />
            <span>{children}</span>
        </div>);
}
function CheckIcon() {
    const style = css({
        color: themeTokens.color.icon.success,
        lineHeight: "initial",
        height: 24,
        width: 24,
    });
    return <i className={`fa-solid fa-check-circle fa-lg ${style}`}></i>;
}
type FeedbackStates = "show-question" | "hidden-question" | "hidden-thankyou" | "show-thankyou";
function Feedback({ dispatchAction }: {
    dispatchAction: AnalyticActionDispatcher;
}) {
    const [contentState, setContentState] = useState<FeedbackStates>("show-question");
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: space[8],
            padding: space[12],
            height: 92,
            transition: "opacity 300ms",
            transitionTimingFunction: "ease-in-out",
        }),
        text: css({
            font: text.interface.body.default.medium,
            color: themeTokens.color.text.muted,
            textAlign: "center",
        }),
        buttonGroup: css({
            display: "flex",
            flexDirection: "row",
            gap: space[8],
            justifyContent: "center",
        }),
    };
    const nextState = (currentState: FeedbackStates) => {
        switch (currentState) {
            case "show-question":
                return "hidden-question";
            case "hidden-question":
                return "hidden-thankyou";
            case "hidden-thankyou":
                return "show-thankyou";
            default:
                return undefined;
        }
    };
    const animate = (state: FeedbackStates) => {
        const next = nextState(state);
        if (next !== undefined) {
            setContentState(next);
            setTimeout(() => animate(next), 300);
        }
    };
    const handleSelection = (value: "Yes" | "No") => {
        dispatchAction(`Sample Project Tour: Helpfulness '${value}' Clicked`, { action: Action.Select, resource: "SampleProjectTourComplete" });
        animate(contentState);
    };
    const showQuestion = contentState === "show-question" || contentState === "hidden-question";
    const showThankyou = contentState === "hidden-thankyou" || contentState === "show-thankyou";
    const hideContent = () => contentState === "hidden-question" || contentState === "hidden-thankyou";
    return (<>
            {showQuestion && (<div className={styles.container} style={hideContent() ? { opacity: 0 } : {}}>
                    <div className={styles.text}>Have you learnt enough to try creating your own project?</div>
                    <div className={styles.buttonGroup}>
                        <ActionButton label="YES" type={ActionButtonType.Secondary} onClick={() => handleSelection("Yes")}></ActionButton>
                        <ActionButton label="NO" type={ActionButtonType.Secondary} onClick={() => handleSelection("No")}></ActionButton>
                    </div>
                </div>)}

            {showThankyou && (<div className={styles.container} style={hideContent() ? { opacity: 0 } : {}}>
                    <div className={styles.text}>Thank you for your feedback.</div>
                </div>)}
        </>);
}
