import { Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { withTheme } from "~/components/Theme";
interface MissingVariablesIcon {
    show: boolean;
}
const MissingVariablesIcon: React.StatelessComponent<MissingVariablesIcon> = (props) => {
    if (!props.show) {
        return null;
    }
    return withTheme((theme) => (<Tooltip content={`Some of the required variable values have not been set`}>
            <em className={"fa-solid fa-triangle-exclamation"} style={{ color: theme.alertText }}/>
        </Tooltip>));
};
MissingVariablesIcon.displayName = "MissingVariablesIcon"
export default MissingVariablesIcon;
