import { NavigationButtonType, NavigationButton } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import { PermissionCheck } from "~/components/PermissionCheck";
import routeLinks from "~/routeLinks";
import { useRunbookContext } from "./RunbookContext";
export type RunNowButtonProps = {
    projectSlug: string;
    projectId: string;
    runbookId: string;
    isDisabled?: boolean;
};
export const RunNowButton: React.FC<RunNowButtonProps> = ({ projectSlug, isDisabled, projectId, runbookId }) => {
    return (<PermissionCheck permission={Permission.RunbookRunCreate} project={projectId} wildcard={true}>
            <NavigationButton type={NavigationButtonType.CreateRelease} label="Run..." href={routeLinks.project(projectSlug).operations.runbook(runbookId).runNow.root} disabled={isDisabled}/>
        </PermissionCheck>);
};
RunNowButton.displayName = "RunNowButton"
type ContextualRunNowButtonProps = Omit<RunNowButtonProps, "projectId" | "projectSlug" | "runbookId">;
export const ContextualRunNowButton: React.FC<ContextualRunNowButtonProps> = (props) => {
    const { state: { runbook }, } = useRunbookContext();
    const { state: { model: project }, } = useProjectContext();
    if (!project || !runbook) {
        return null;
    }
    return <RunNowButton projectSlug={project.Slug} projectId={runbook.ProjectId} runbookId={runbook.Id} {...props}/>;
};
ContextualRunNowButton.displayName = "ContextualRunNowButton"
export default ContextualRunNowButton;
