/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ActionTemplateResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import routeLinks from "~/routeLinks";
import type { GlobalState } from "~/store/reducers";
import { actionTemplateFetch, newActionTemplateSelected } from "../../../reducers/libraryArea";
interface ActionTemplateLayoutProps {
    templateId: string;
    actionTemplateName?: string;
    actionTemplateLogo?: string;
    actionTemplatePendingUpdates?: number;
    onFetchActionTemplate(actionTemplate: ActionTemplateResource): void;
    onNewActionTemplateSelected(): void;
    isPageHeaderVNextEnabled: boolean;
}
class ActionTemplateLayoutInternal extends BaseComponent<ActionTemplateLayoutProps, never> {
    constructor(props: ActionTemplateLayoutProps) {
        super(props);
    }
    async componentDidMount() {
        if (this.props.templateId) {
            const actionTemplate = await repository.ActionTemplates.get(this.props.templateId);
            this.props.onFetchActionTemplate(actionTemplate);
        }
        else {
            this.props.onNewActionTemplateSelected();
        }
    }
    render() {
        const navLinks = [];
        if (this.props.templateId) {
            navLinks.push(Navigation.navItem("Details", routeLinks.library.stepTemplate(this.props.templateId).root, true)!);
            navLinks.push(Navigation.navItem(<UsageNavbarItemLabel totalPendingUpdates={this.props.actionTemplatePendingUpdates}/>, routeLinks.library.stepTemplate(this.props.templateId).usage)!);
        }
        if (this.props.isPageHeaderVNextEnabled) {
            return (<main>
                    <PageHeaderPrimary title={this.props.actionTemplateName || "New step template"} navUpLink={{ label: "Step Templates", linkHref: routeLinks.library.stepTemplates.root }} logo={this.props.actionTemplateLogo ? { href: this.props.actionTemplateLogo, accessibleName: "Logo" } : undefined}/>
                    {this.props.templateId ? <NavigationSideBarLayoutVNext navItems={navLinks} content={this.props.children}/> : <div className={paperContainerStyles}>{this.props.children}</div>}
                </main>);
        }
        return (<main>
                <AreaTitle link={routeLinks.library.stepTemplates.root} title="Step Templates" breadcrumbTitle={"Library"} breadcrumbPath={routeLinks.library.root}/>
                <NavigationSidebarLayout name={this.props.actionTemplateName || "New step template"} logoUrl={this.props.actionTemplateLogo} navLinks={navLinks} content={this.props.children}/>
            </main>);
    }
    static displayName = "ActionTemplateLayoutInternal";
}
interface UsageWithPendingUpdatesLabelProps {
    totalPendingUpdates: number | undefined;
}
function UsageNavbarItemLabel({ totalPendingUpdates = 0 }: UsageWithPendingUpdatesLabelProps) {
    return (<div className={usageWithPendingUpdatesLabelStyles}>
            Usage
            {totalPendingUpdates > 0 && <PendingUsages totalPendingUpdates={totalPendingUpdates}/>}
        </div>);
}
function PendingUsages({ totalPendingUpdates }: React.PropsWithChildren<{
    totalPendingUpdates: number;
}>) {
    const tooltipContent = `${totalPendingUpdates} action ${pluralize(totalPendingUpdates, "template usage has", "template usages have")} pending changes.`;
    return (<Tooltip content={tooltipContent}>
            <em className={"fa-solid fa-triangle-exclamation"} style={{ color: themeTokens.color.alert.text.warning }}/>
        </Tooltip>);
}
function pluralize(count: number, singular: string, plural: string) {
    return count > 1 ? plural : singular;
}
const paperContainerStyles = css({
    padding: `${space[16]} ${space[32]}`,
});
const usageWithPendingUpdatesLabelStyles = css({
    display: "flex",
    flexDirection: "row",
    gap: space[8],
    alignItems: "center",
});
interface ActionTemplateLayoutProps {
    children: React.ReactNode;
}
export default function ActionTemplateLayout({ children }: ActionTemplateLayoutProps) {
    const { templateId } = useParams<{
        templateId: string;
    }>();
    const dispatch: ThunkDispatch<GlobalState, void, AnyAction> = useDispatch();
    const currentActionTemplate = useSelector((state: GlobalState) => state.libraryArea.currentActionTemplate);
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const dispatchFetchActionTemplate = React.useCallback((actionTemplate: ActionTemplateResource) => {
        dispatch(actionTemplateFetch(actionTemplate));
    }, [dispatch]);
    const dispatchNewActionTemplateSelected = React.useCallback(() => {
        dispatch(newActionTemplateSelected());
    }, [dispatch]);
    return (<ActionTemplateLayoutInternal templateId={templateId} actionTemplateName={currentActionTemplate?.name} actionTemplateLogo={currentActionTemplate?.logo} actionTemplatePendingUpdates={currentActionTemplate?.pendingUpdates} onFetchActionTemplate={dispatchFetchActionTemplate} onNewActionTemplateSelected={dispatchNewActionTemplateSelected} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}>
            {children}
        </ActionTemplateLayoutInternal>);
}
