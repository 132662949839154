import type { LinkHref } from "@octopusdeploy/portal-routes";
import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import { useLocation, useParams } from "react-router";
import { isPageUrl } from "./InternalLink/isPageUrl";
import { usePageUrlResolver } from "./usePageUrlResolver";
export function useIsPortalUrlActive(): (targetUrl: LinkHref, exact: boolean | undefined) => boolean {
    const pageUrlResolver = usePageUrlResolver();
    const location = useLocation();
    const routeParams = useParams<{
        spaceId: string | undefined;
    }>();
    const spaceId = routeParams.spaceId ?? undefined;
    const path = location.pathname;
    return (targetUrl: LinkHref, exact: boolean | undefined) => {
        if (!targetUrl) {
            return false;
        }
        const resolvedToUrl = isPageUrl(targetUrl) ? pageUrlResolver.resolve(targetUrl) : resolveStringPathWithSpaceId(targetUrl, spaceId);
        const toUrlWithoutQueryStrings = resolvedToUrl === null ? "" : resolvedToUrl.split("?")[0];
        return exact ? path === toUrlWithoutQueryStrings : path.startsWith(toUrlWithoutQueryStrings);
    };
}
