/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Permission, canCommitTo, HasVariablesInGit, isGitBranchResource, TenantedDeploymentMode, VariableType } from "@octopusdeploy/octopus-server-client";
import type { GitBranchResource, GitRefResource, ProjectResource, VariableResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import type { Dictionary } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { SampleProjectTourContextProps } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import VariableEditor from "~/areas/variables/VariableEditor/VariableEditor";
import { default as VariableSaveConfirmationDialog } from "~/areas/variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { useRequiredContext } from "~/hooks/index";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import FormBaseComponent from "../../../../../components/FormBaseComponent";
import type { OptionalFormBaseComponentState } from "../../../../../components/FormBaseComponent/FormBaseComponent";
import type { ReadonlyVariableResource } from "../../../../variables/ReadonlyVariableResource";
import { createDialogContent, createViewModel, getVariableResources } from "../../../../variables/VariableEditor/conversions";
import type { VariableSaveConfirmationContent } from "../../../../variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import type { VariableModel } from "../../../../variables/VariablesModel/VariablesModel";
import groupVariablesByName from "../../../../variables/groupVariablesByName";
import { ProjectContextFormPaperLayout } from "../../Process/CustomPaperLayouts/ProjectContextFormPaperLayout";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
import type { GetCommitButtonProps } from "../../VersionControl/CommitButton";
import { GetCommitButton } from "../../VersionControl/CommitButton";
import type { CommitMessageWithDetails } from "../../VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "../../VersionControl/CommitMessageWithDetails";
import MigrateProjectVariablesBanner from "./MigrateProjectVariablesToGit/MigrateProjectVariablesBanner";
import styles from "./style.module.less";
interface ProjectVariablesState extends OptionalFormBaseComponentState<ProjectVariablesModel> {
    variableSet?: VariableSetResource;
    sensitiveVariableSet?: VariableSetResource;
    project?: ProjectResource;
    model?: ProjectVariablesModel;
    dialogContent?: VariableSaveConfirmationContent;
    newBranch?: GitBranchResource;
    initialVariables?: ReadonlyArray<VariableModel>;
    groupedVariableResources?: Dictionary<ReadonlyVariableResource[]>;
    cellFocusResetKey: string;
    disableDirtyFormChecking?: boolean;
    commitMessage: CommitMessageWithDetails;
}
interface ProjectVariablesModel {
    readonly variables: ReadonlyArray<VariableModel>;
}
type ProjectVariablesInternalProps = {
    projectContext: ProjectContextProps;
    trackAction: AnalyticTrackedActionDispatcher;
    branchProtectionsAreEnabled: boolean;
    onboardingContext?: SampleProjectTourContextProps;
};
class ProjectVariablesInternal extends FormBaseComponent<ProjectVariablesInternalProps, ProjectVariablesState, ProjectVariablesModel> {
    private openCommitDialog?: () => void;
    constructor(props: ProjectVariablesInternalProps) {
        super(props);
        this.state = {
            cellFocusResetKey: DateFormatter.timestamp(),
            commitMessage: { summary: "", details: "" },
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: ProjectVariablesInternalProps) {
        if (prevProps.projectContext.state.gitRef !== this.props.projectContext.state.gitRef) {
            await this.reload();
        }
    }
    private reload = async () => await this.doBusyTask(async () => {
        const project = this.props.projectContext.state.model;
        const variableSet = await this.props.projectContext.state.projectContextRepository.Variables.get();
        if (HasVariablesInGit(project.PersistenceSettings)) {
            const sensitiveVariableSet = await this.props.projectContext.state.projectContextRepository.Variables.getSensitive();
            this.resetState(project, variableSet, sensitiveVariableSet);
        }
        else {
            this.resetState(project, variableSet);
        }
    }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    private getCommitButtonProps(): Omit<GetCommitButtonProps, "actionButtonProps"> {
        return {
            project: this.props.projectContext.state.model,
            gitRef: this.props.projectContext.state.gitRef?.CanonicalName,
            canCommitToGitRef: !this.props.branchProtectionsAreEnabled || canCommitTo(this.props.projectContext.state.gitRef),
            defaultCommitMessage: "Update variables",
            commitMessage: this.state.commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage }),
            commitMessageAccessibleName: "Commit message for saving the variables",
            commitDetailsAccessibleName: "Commit details for saving the variables",
            commitButtonAccessibleName: "Commit changes to the variables",
            onNewBranchCreating: (branchName) => this.saveVariablesToNewBranch(branchName),
            onInitializing: (openCommitDialog: () => void) => (this.openCommitDialog = openCommitDialog),
        };
    }
    private getOverflowActions(project?: ProjectResource) {
        if (!project) {
            return [];
        }
        if (HasVariablesInGit(project.PersistenceSettings)) {
            return [
                OverflowMenuItems.downloadItem("Download Text as JSON", project.Slug + "-text-variables.json", this.props.projectContext.state.projectContextRepository.Variables.resolveResourceLink()),
                OverflowMenuItems.downloadItem("Download Sensitive as JSON", project.Slug + "-sensitive-variables.json", this.props.projectContext.state.projectContextRepository.Variables.resolveSensitiveResourceLink()),
            ];
        }
        else {
            return [OverflowMenuItems.downloadItem("Download as JSON", project.Slug + "-variables.json", this.props.projectContext.state.projectContextRepository.Variables.resolveResourceLink())];
        }
    }
    render() {
        const commitButtonProps = { ...this.getCommitButtonProps() };
        const overFlowActions = this.getOverflowActions(this.state.project);
        const gitVariables = this.state.project ? HasVariablesInGit(this.state.project.PersistenceSettings) : false;
        const onSaveClick = async (isNavigationConfirmation?: boolean | undefined, onSavedCallback?: (() => void) | undefined, newBranch?: GitRefResource | undefined, commitMessage?: CommitMessageWithDetails | undefined) => {
            if (newBranch !== undefined && !isGitBranchResource(newBranch)) {
                return;
            }
            const newBranchResource: GitBranchResource | undefined = newBranch;
            if (isNavigationConfirmation && this.openCommitDialog && gitVariables) {
                this.openCommitDialog();
            }
            else {
                const dialogContent = createDialogContent(this.state.model!.variables, this.state.initialVariables!, this.state.variableSet!.Variables);
                if (dialogContent && dialogContent.hasContent) {
                    this.setState({ dialogContent, newBranch: newBranchResource });
                }
                else {
                    await this.doBusyTask(() => this.saveVariables(newBranchResource));
                    if (onSavedCallback) {
                        onSavedCallback();
                    }
                }
            }
        };
        return (<ProjectContextFormPaperLayout busy={this.state.busy} errors={this.errors} fullWidth={true} model={this.state.model} cleanModel={this.state.cleanModel} title={"Project Variables"} breadcrumbTitle={this.state.project?.Name} overFlowActions={overFlowActions} saveButtonLabel={gitVariables ? "Commit" : "Save"} saveButtonBusyLabel={gitVariables ? "Committing" : "Saving"} customPrimaryAction={gitVariables ? (primaryActionProps) => ({ type: "custom", content: <GetCommitButton {...commitButtonProps} actionButtonProps={primaryActionProps}/>, key: "Get Commit" }) : undefined} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>} disableDirtyFormChecking={this.state.disableDirtyFormChecking} onSaveClick={onSaveClick} savePermission={{
                permission: Permission.VariableEdit,
                project: this.state.project && this.state.project.Id,
                wildcard: true,
            }}>
                <ProjectGitVariablesFeedback project={this.state.project}/>
                <MigrateProjectVariablesBanner />
                {this.state.model && (<TransitionAnimation>
                        <VariableEditor initialVariables={this.state.initialVariables!} scopeValues={this.state.variableSet!.ScopeValues} isTenanted={this.isProjectTenanted()} doBusyTask={this.doBusyTask} onVariablesChanged={(variables: any) => this.setState({ model: { variables } })} cellFocusResetKey={this.state.cellFocusResetKey} scope="Project" gitVariables={gitVariables}/>
                        <VariableSaveConfirmationDialog key={"VariableEditorConfirmDialogs"} content={this.state.dialogContent} onClosed={() => this.setState({ dialogContent: null! })} onSaveClick={() => this.saveVariables(this.state.newBranch)}/>
                    </TransitionAnimation>)}
            </ProjectContextFormPaperLayout>);
    }
    private isProjectTenanted() {
        return this.state.project ? this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted : false;
    }
    private resetState(project: ProjectResource, variableSet: VariableSetResource, sensitiveVariableSet?: VariableSetResource) {
        const allVariables = [...variableSet.Variables];
        if (sensitiveVariableSet) {
            allVariables.push(...sensitiveVariableSet.Variables);
        }
        const groupedVariableResources = groupVariablesByName(allVariables, (v) => v.Name);
        const variables = createViewModel(groupedVariableResources);
        const model: ProjectVariablesModel = { variables };
        this.setState({
            project,
            variableSet,
            sensitiveVariableSet,
            groupedVariableResources,
            initialVariables: [...variables],
            model,
            cleanModel: { ...model },
            cellFocusResetKey: DateFormatter.timestamp(),
        });
        setTimeout(() => {
            this.props.onboardingContext?.continueSampleProjectTour();
        }, 300);
    }
    async saveVariablesToNewBranch(branchName: string) {
        if (!this.state.project) {
            throw "Can not save if there is no project configured";
        }
        this.setState({ disableDirtyFormChecking: true });
        const newBranch = await this.props.projectContext.state.projectContextRepository.Branches.createBranch(this.props.projectContext.state.model, branchName, this.props.projectContext.state.gitRef?.CanonicalName ?? "");
        await this.saveVariables(newBranch);
        this.props.projectContext.actions.changeGitRef(newBranch.Name);
        this.setState({ disableDirtyFormChecking: false });
    }
    async saveVariables(newBranch?: GitBranchResource) {
        const oldVariableCount = this.state.variableSet!.Variables.length;
        const variables = getVariableResources(this.state.model!.variables, this.state.groupedVariableResources!);
        const newVariableCount = variables.length;
        const actionMeta = oldVariableCount < newVariableCount ? "Added variables" : oldVariableCount === newVariableCount ? "Modified variables" : "Delete variables";
        const actionEvent: ActionEvent = {
            action: Action.Save,
            resource: "Variables",
            actionMeta: actionMeta,
        };
        const project = this.props.projectContext.state.model;
        if (HasVariablesInGit(project.PersistenceSettings)) {
            await this.saveVariablesGit(project, variables, actionEvent, newBranch);
        }
        else {
            await this.saveVariablesDatabase(project, variables, actionEvent);
        }
    }
    private async saveVariablesDatabase(project: ProjectResource, variables: VariableResource[], actionEvent: ActionEvent) {
        actionEvent.action = Action.Save;
        await this.props.trackAction("Save Project Variables", actionEvent, async () => {
            const repository = this.props.projectContext.state.projectContextRepository;
            const variableSet = await repository.Variables.modify({ ...this.state.variableSet!, Variables: variables });
            this.resetState(project, variableSet);
        });
    }
    private async saveVariablesGit(project: ProjectResource, variables: VariableResource[], actionEvent: ActionEvent, newBranch?: GitBranchResource) {
        actionEvent.action = Action.Commit;
        actionEvent.isDefaultBranch = this.props.projectContext.state.isDefaultBranch;
        actionEvent.commitMessage = this.state.commitMessage.summary.length > 0;
        await this.props.trackAction("Save Project Variables", actionEvent, async () => {
            const textVariables = variables.filter((variable) => variable.Type !== VariableType.Sensitive);
            const sensitiveVariables = variables.filter((variable) => variable.Type === VariableType.Sensitive);
            let variableSet = this.state.variableSet!;
            if (newBranch) {
                variableSet = {
                    ...variableSet,
                    Links: {
                        ...variableSet.Links,
                        Self: newBranch.Links.Variables,
                    },
                };
            }
            const repository = this.props.projectContext.state.projectContextRepository;
            const formattedCommitMessage = getFormattedCommitMessage(this.state.commitMessage, "Update variables");
            variableSet = await repository.Variables.modify({ ...variableSet, Variables: textVariables, ChangeDescription: formattedCommitMessage });
            const sensitiveVariableSet = await repository.Variables.modify({ ...this.state.sensitiveVariableSet!, Variables: sensitiveVariables });
            this.resetState(project, variableSet, sensitiveVariableSet);
        });
    }
    static displayName = "ProjectVariablesInternal";
}
type ProjectGitVariablesFeedbackProps = {
    project?: ProjectResource;
};
function ProjectGitVariablesFeedback(props: ProjectGitVariablesFeedbackProps) {
    if (props.project && HasVariablesInGit(props.project?.PersistenceSettings)) {
        return (<FeatureToggleVisibility toggle="GitVariablesNotificationFeatureToggle">
                <div className={styles.feedbackSubtitle}>
                    Variables are version controlled. Please provide <ExternalLink href="CaCEAPFeedbackForm">feedback</ExternalLink> on the Config as Code feature.
                </div>
            </FeatureToggleVisibility>);
    }
    return null;
}
function ProjectVariables() {
    const projectContext = useProjectContext();
    const onboardingContext = useRequiredContext(SampleProjectTourContext);
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    const branchProtectionsAreEnabled = isFeatureToggleEnabled("BranchProtectionsFeatureToggle");
    return <ProjectVariablesInternal onboardingContext={onboardingContext} projectContext={projectContext} trackAction={trackAction} branchProtectionsAreEnabled={branchProtectionsAreEnabled}/>;
}
export default ProjectVariables;
