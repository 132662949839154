import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { ProjectCurrentBranchDisplay } from "~/areas/projects/components/ProjectCurrentBranchDisplay/ProjectCurrentBranchDisplay";
import { useProjectContext } from "~/areas/projects/context";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import ActionList from "~/components/ActionList";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
import { LastPublishedChip } from "../../Runbooks/LastPublishedChip";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import PublishButton, { usePublishedRunbookSnapshotSetup } from "../../Runbooks/PublishButton";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import { useOptionalRunbookContext, useRunbookContext } from "../../Runbooks/RunbookContext";
import ContextAddStepNavigationButton from "../../Steps/ContextAddStepNavigationButton";
import { GitRepositoryErrorCallout } from "../../Variables/GitRepositoryErrorCallout";
import { useProcessContext } from "../Contexts/ProcessContext";
import { ProcessPaperLayout } from "./ProcessPaperLayout";
interface ProcessContextPaperLayoutPropsInternal {
    processType: ProcessType;
    overflowMenuItems: Array<MenuItem | MenuItem[]>;
    doBusyTask: DoBusyTask;
}
type ProcessContextPaperLayoutProps = ProcessContextPaperLayoutPropsInternal & PaperLayoutProps & RouteComponentProps<{
    projectSlug: string;
}>;
const ProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps> = ({ children, doBusyTask, ...rest }) => {
    const processContext = useProcessContext();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const hasSteps = processContext.selectors.hasSteps();
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    React.useEffect(() => {
        projectContext.actions.refreshGitVariableErrors();
        // We only want to check that the variables when this component is created. No need for any dependencies to trigger a re-check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (rest.processType === ProcessType.Runbook) {
        return (<RunbooksProcessContextPaperLayout title={runbookName} breadcrumbTitle="Runbooks" hasSteps={hasSteps} project={project} doBusyTask={doBusyTask} {...rest}>
                {children}
            </RunbooksProcessContextPaperLayout>);
    }
    else {
        return (<DeploymentProcessContextPaperLayout title={"Process"} doBusyTask={doBusyTask} statusSection={<ProjectStatus doBusyTask={doBusyTask}/>} {...rest}>
                {children}
            </DeploymentProcessContextPaperLayout>);
    }
};
ProcessContextPaperLayout.displayName = "ProcessContextPaperLayout"
export const EnhancedProcessContextPaperLayout = withRouter(ProcessContextPaperLayout);
const DeploymentProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps> = (props) => {
    const processContext = useProcessContext();
    const actions = processContext.selectors.hasSteps() ? [<ContextAddStepNavigationButton />] : [];
    const isPageVersionControlled = useIsPageVersionControlled();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const showCurrentBranch = isPageVersionControlled && !isPageHeaderVNextEnabled;
    const overflowMenu = props.overflowMenuItems && props.overflowMenuItems.length > 0 ? <OverflowMenu menuItems={props.overflowMenuItems}/> : undefined;
    if (overflowMenu) {
        actions.push(overflowMenu);
    }
    return (<ProcessPaperLayout sectionControl={<ActionList actions={actions}/>} titleAccessory={showCurrentBranch ? <ProjectCurrentBranchDisplay /> : undefined} {...props}>
            {props.children}
        </ProcessPaperLayout>);
};
DeploymentProcessContextPaperLayout.displayName = "DeploymentProcessContextPaperLayout"
const RunbooksProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps & {
    project: ProjectResource;
    hasSteps: boolean;
    doBusyTask: DoBusyTask;
}> = ({ project, hasSteps, doBusyTask, ...props }) => {
    const runbooksContext = useRunbookContext();
    const runbook = runbooksContext.state.runbook;
    if (!runbook) {
        throw Error("runbook value should exist");
    }
    const publishedRunbookAndTemplate = usePublishedRunbookSnapshotSetup(doBusyTask, runbook);
    const publishedRunbookSnapshot = publishedRunbookAndTemplate?.publishedRunbookSnapshot;
    const runbookRunTemplate = publishedRunbookAndTemplate?.runbookRunTemplate;
    const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
    const actions = [<PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>, <ContextualRunNowButton isDisabled={!hasSteps}/>, <ContextAddStepNavigationButton />];
    if (props.overflowMenuItems.length > 0) {
        actions.push(<OverflowMenu menuItems={props.overflowMenuItems}/>);
    }
    return (<RunbooksPaperLayout title={runbooksContext.state.runbook?.Name} titleChip={titleChip} breadcrumbTitle={"Runbooks"} breadcrumbPath={routeLinks.project(project.Slug).operations.runbooks} breadcrumbsItems={[{ label: "Runbooks", pageUrl: routeLinks.project(project.Slug).operations.runbooks }]} sectionControl={<ActionList actions={actions}/>} {...props}>
            <GitRepositoryErrorCallout calloutType={CalloutType.Danger} additionalIntroText="The runbook can not be published until the error is resolved."></GitRepositoryErrorCallout>
            {props.children}
        </RunbooksPaperLayout>);
};
RunbooksProcessContextPaperLayout.displayName = "RunbooksProcessContextPaperLayout"
