/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButtonType, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useRouteMatch } from "react-router";
import type { match } from "react-router-dom";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { SampleProjectTourContextProps } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { SampleProjectTourStep } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import MarkdownDescription from "~/components/MarkdownDescription";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PagingList from "~/components/PagingList";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useRequiredContext } from "~/hooks/index";
import ListItem from "~/primitiveComponents/dataDisplay/ListItem";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import type { SpaceAwareNavigation } from "../../../../components/Navigation/SpaceAwareNavigation/SpaceAwareNavigation";
import { BranchSelector } from "../BranchSelector/BranchSelector";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import AddRunbook from "./AddRunbook";
import RunbooksOnboarding from "./RunbooksOnboarding";
import styles from "./styles.module.less";
interface RunbooksLayoutState extends DataBaseComponentState {
    runbooks: ResourceCollection<RunbookResource>;
}
class RunbooksLayoutList extends PagingList<RunbookResource> {
}
interface RunbooksLayoutProps {
    projectContext: ProjectContextProps;
    onboardingContext: SampleProjectTourContextProps;
    history: SpaceAwareNavigation;
    match: match<{}>;
}
class RunbooksLayoutInternal extends DataBaseComponent<RunbooksLayoutProps, RunbooksLayoutState> {
    constructor(props: RunbooksLayoutProps) {
        super(props);
        this.state = {
            runbooks: null!,
        };
    }
    componentDidMount() {
        this.reload(() => this.props.onboardingContext.continueSampleProjectTour());
    }
    reload(onReloadDone?: () => void) {
        // noinspection JSIgnoredPromiseFromCall
        this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const runbooks = await repository.Projects.getRunbooks(project, { skip: 0, take: 30 });
            this.setState({
                runbooks,
            });
            if (onReloadDone)
                onReloadDone();
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) });
    }
    render() {
        const project = this.props.projectContext.state.model;
        const renderSectionControl = () => {
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Runbook);
            if (isVersionControlled) {
                return (<div className={styles.sectionControlContainer}>
                        <BranchSelector isPageVersionControlled={false}/>
                        {addProcessButton}
                    </div>);
            }
            return addProcessButton;
        };
        const addProcessButton = (<PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
                <OpenDialogButton label="Add Runbook" type={ActionButtonType.Primary}>
                    <AddRunbook projectId={project.Id} onProcessCreated={async (id) => {
                this.props.history.navigate(routeLinks.project(project.Slug).operations.runbook(id).root);
            }}/>
                </OpenDialogButton>
            </PermissionCheck>);
        return (<ProjectPageLayout busy={this.state.busy} errors={this.errors} title="Runbooks" breadcrumbTitle={this.props.projectContext.state.model.Name} sectionControl={renderSectionControl()} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                {this.state.runbooks && this.state.runbooks.Items.length === 0 && <RunbooksOnboarding />}
                {this.state.runbooks && this.state.runbooks.Items.length > 0 && (<RunbooksLayoutList initialData={this.state.runbooks} onRow={(item) => this.buildRow(item)} match={this.props.match} onRowRedirectUrl={(runbook: RunbookResource) => routeLinks.project(this.props.projectContext.state.model.Slug).operations.runbook(runbook.Id).root} onRowAccessibleName={(runbook: RunbookResource) => `${runbook.Name}`} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..." listWrapper={(children: React.ReactNode) => <SampleProjectTourStep stepId={"Runbooks"}>{children}</SampleProjectTourStep>}/>)}
            </ProjectPageLayout>);
    }
    private filter(filter: string, resource: RunbookResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private clonePermission(): PermissionCheckProps {
        const project = this.props.projectContext.state.model;
        return {
            permission: Permission.RunbookEdit,
            projectGroup: project && project.ProjectGroupId,
            wildcard: true,
        };
    }
    private buildRow(runbook: RunbookResource) {
        const project = this.props.projectContext.state.model;
        const runbookDescription = runbook.Description && <MarkdownDescription markup={runbook.Description}/>;
        const CloneDialog = () => <AddRunbook projectId={project.Id} onProcessCreated={async (id) => this.reload()} cloneId={runbook.Id}/>;
        const overFlowActions: Array<MenuItem | MenuItem[]> = [];
        overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
        overFlowActions.push([
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.runbookEventsForProject(project.Id, runbook.Id), {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
        const runAction = isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, projectGroup: project.ProjectGroupId, wildcard: true }) ? (<NavigationButton label="Run..." href={routeLinks.project(project.Slug).operations.runbook(runbook.Id).runNow.root} type={NavigationButtonType.Secondary}/>) : null;
        return (<ListItem overflowMenuItems={overFlowActions} secondaryAction={runAction!}>
                <ListTitle>{runbook.Name}</ListTitle>
                {runbookDescription}
            </ListItem>);
    }
    static displayName = "RunbooksLayoutInternal";
}
const RunbooksLayout: React.FC = () => {
    const history = useSpaceAwareNavigation();
    const projectContext = useProjectContext();
    const onboardingContext = useRequiredContext(SampleProjectTourContext);
    const match = useRouteMatch();
    if (!match) {
        // It should always be defined because we aren't passing any params to `useRouteMatch`
        throw new Error("The match object was not defined in RunbooksLayout.");
    }
    return <RunbooksLayoutInternal projectContext={projectContext} onboardingContext={onboardingContext} history={history} match={match}/>;
};
RunbooksLayout.displayName = "RunbooksLayout"
export default RunbooksLayout;
