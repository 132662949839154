import type { MenuTargetAriaAttributes } from "@octopusdeploy/design-system-components";
import { ActionButton, ActionButtonType, Divider, NavigationButton, NavigationButtonType, Tooltip } from "@octopusdeploy/design-system-components";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import { ActionListContainer } from "~/components/ActionList/ActionList";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
interface PageActionsProps {
    primaryAction?: PrimaryPageAction;
    actions?: PageAction[];
    overflowActions?: Array<MenuItem | MenuItem[]>;
    alignment?: "start" | "end";
}
export function PageActions({ primaryAction, actions, overflowActions, alignment = "end" }: PageActionsProps) {
    const actionsWithPermissions = React.useMemo(() => actions?.filter((x) => x.hasPermissions === undefined || x.hasPermissions).map(toInternalPageAction) ?? [], [actions]);
    if (actionsWithPermissions.length === 0 && overflowActions?.length === 0 && primaryAction === undefined) {
        return null;
    }
    const pageContainsActions = actionsWithPermissions.length > 0 || primaryAction !== undefined;
    const pageHasOverflowActions = overflowActions && overflowActions.length > 0;
    return (<ActionListContainer alignStart={alignment === "start"}>
            {actionsWithPermissions.map((item) => (<ExtraContextForPageActionComponent key={item.type === "custom" ? item.key : item.label} extraContext={item.extraContext}>
                    <PageActionComponent item={item}/>
                </ExtraContextForPageActionComponent>))}
            {primaryAction && (<ExtraContextForPageActionComponent key="primary" extraContext={primaryAction.extraContext}>
                    <PageActionComponent key="primary" item={toInternalPrimaryPageAction(primaryAction)}/>
                </ExtraContextForPageActionComponent>)}
            {pageContainsActions && pageHasOverflowActions && <Divider orientation={"vertical"}/>}
            {pageHasOverflowActions && <OverflowMenu menuItems={overflowActions}/>}
        </ActionListContainer>);
}
function toInternalPrimaryPageAction(action: PrimaryPageAction): InternalPageAction {
    return {
        ...action,
        buttonType: "primary",
    };
}
function toInternalPageAction(action: PageAction): InternalPageAction {
    switch (action.type) {
        case "dialog":
            return { ...action, buttonType: action.buttonType ?? "secondary" };
        case "button":
            return { ...action, buttonType: action.buttonType ?? "secondary" };
        case "navigate":
            return { ...action, buttonType: action.buttonType ?? "secondary" };
        case "custom":
            return action;
        default:
            exhaustiveCheck(action, "Not all action types have been handled");
    }
}
interface SharedPageActionProps {
    hasPermissions?: boolean;
}
type RestrictButtonType<T extends {
    buttonType: unknown;
}, K extends T["buttonType"]> = Omit<T, "buttonType"> & {
    buttonType: K;
};
type ExcludeButtonType<T> = T extends {
    buttonType: unknown;
} ? Omit<T, "buttonType"> : T;
type ConsumerAllowedNavigationButtonTypes = "secondary" | "tertiary" | "loud";
type InternalAllowedNavigationButtonTypes = "primary" | ConsumerAllowedNavigationButtonTypes;
interface InternalNavigatePageAction extends SharedPageActionProps {
    type: "navigate";
    label: string;
    path: string;
    onClick?: () => void;
    disabled?: boolean;
    accessibleName?: string;
    buttonType: InternalAllowedNavigationButtonTypes;
}
type NavigatePageAction = RestrictButtonType<InternalNavigatePageAction, ConsumerAllowedNavigationButtonTypes>;
type ConsumerAllowedButtonTypes = "secondary" | "tertiary" | "destructive";
type InternalAllowedButtonTypes = ConsumerAllowedButtonTypes | "primary";
interface InternalButtonPageAction extends SharedPageActionProps {
    type: "button";
    label: string;
    accessibleName?: string;
    disabled?: boolean | Promise<unknown>;
    onClick: (event: React.MouseEvent | undefined) => Promise<unknown> | void;
    menuButtonAttributes?: MenuTargetAriaAttributes;
    buttonType: InternalAllowedButtonTypes;
    busyLabel?: string;
}
type ButtonPageAction = RestrictButtonType<InternalButtonPageAction, ConsumerAllowedButtonTypes>;
interface InternalDialogPageAction extends SharedPageActionProps {
    /**
     * This is a temporary placeholder to manage showing dialogs. The work to investigate how
     * we open and manage dialogs will happen in the future which may mean that we have a similar
     * means to show and manage the dialog similar to how we deal with useMenuStage which also
     * would mean that this type of action can be a simple button type instead.
     */
    type: "dialog";
    label: string;
    disabled?: boolean;
    onClick?: () => void;
    renderDialog: (triggerDialogProps: {
        isOpen: boolean;
        closeDialog: () => void;
        openDialog: () => void;
    }) => React.ReactNode;
    buttonType: InternalAllowedButtonTypes;
}
type DialogPageAction = RestrictButtonType<InternalDialogPageAction, ConsumerAllowedButtonTypes>;
interface ExtraContextForPageAction {
    extraContext?: string;
}
interface InternalCustomPageAction extends SharedPageActionProps {
    type: "custom";
    key: string;
    content: React.ReactNode;
}
type CustomPageAction = InternalCustomPageAction;
type AllPageActions = NavigatePageAction | ButtonPageAction | DialogPageAction | CustomPageAction;
export type PageAction = AllPageActions & ExtraContextForPageAction;
type AllInternalPageActions = InternalNavigatePageAction | InternalButtonPageAction | InternalDialogPageAction | InternalCustomPageAction;
type InternalPageAction = AllInternalPageActions & ExtraContextForPageAction;
export type PrimaryPageAction = ExcludeButtonType<AllInternalPageActions> & ExtraContextForPageAction;
interface PageActionComponentProps {
    item: InternalPageAction;
}
function PageActionComponent({ item }: PageActionComponentProps) {
    switch (item.type) {
        case "dialog":
            return <DialogPageActionComponent item={item}/>;
        case "button":
            return <ButtonPageActionComponent item={item}/>;
        case "navigate":
            return <NavigatePageActionComponent item={item}/>;
        case "custom":
            return <CustomPageActionComponent item={item}/>;
        default:
            exhaustiveCheck(item, "Unexpected simple menu item type");
    }
}
interface DialogPageActionComponentProps {
    item: InternalDialogPageAction;
}
function DialogPageActionComponent({ item }: DialogPageActionComponentProps) {
    const triggerDialogProps = useDialogTrigger();
    const { openDialog } = triggerDialogProps;
    const onClickButton = React.useCallback((_: React.MouseEvent) => {
        openDialog();
        item && item.onClick && item.onClick();
    }, [item, openDialog]);
    return (<>
            <ActionButton label={item.label} onClick={onClickButton} disabled={item.disabled} type={getButtonType(item.buttonType)}/>
            {item.renderDialog(triggerDialogProps)}
        </>);
}
interface ButtonPageActionComponentProps {
    item: InternalButtonPageAction;
}
function ButtonPageActionComponent({ item }: ButtonPageActionComponentProps) {
    return <ActionButton label={item.label} busyLabel={item.busyLabel} onClick={item.onClick} type={getButtonType(item.buttonType)} accessibleName={item.accessibleName} disabled={item.disabled} menuButtonAttributes={item.menuButtonAttributes}/>;
}
function getButtonType(buttonType: InternalAllowedButtonTypes): ActionButtonType {
    switch (buttonType) {
        case "primary":
            return ActionButtonType.Primary;
        case "destructive":
            return ActionButtonType.Delete;
        case "secondary":
            return ActionButtonType.Secondary;
        case "tertiary":
            return ActionButtonType.Ternary;
        default:
            exhaustiveCheck(buttonType, "Not all button types have been handled");
    }
}
interface NavigatePageActionComponentProps {
    item: InternalNavigatePageAction;
}
function getNavigationButtonType(buttonType: InternalAllowedNavigationButtonTypes) {
    switch (buttonType) {
        case "primary":
            return NavigationButtonType.Primary;
        case "secondary":
            return NavigationButtonType.Secondary;
        case "tertiary":
            return NavigationButtonType.Ternary;
        case "loud":
            return NavigationButtonType.Loud;
        default:
            exhaustiveCheck(buttonType, "Not all navigation button types have been handled");
    }
}
function NavigatePageActionComponent({ item }: NavigatePageActionComponentProps) {
    return <NavigationButton type={getNavigationButtonType(item.buttonType)} label={item.label} onClick={item.onClick} href={item.path}/>;
}
function ExtraContextForPageActionComponent({ extraContext, children }: React.PropsWithChildren<ExtraContextForPageAction>) {
    if (extraContext) {
        return <Tooltip content={extraContext}>{children}</Tooltip>;
    }
    return <>{children}</>;
}
interface CustomPageActionComponentProps {
    item: InternalCustomPageAction;
}
function CustomPageActionComponent({ item }: CustomPageActionComponentProps) {
    return <>{item.content}</>;
}
