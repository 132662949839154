import { css } from "@emotion/css";
import { ChevronLeftIcon, DrawnOctopusIllustration } from "@octopusdeploy/design-system-components";
import { text } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { Route } from "react-router-dom";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import routeLinks from "~/routeLinks";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
export const RedirectAs404 = () => <Route render={({ location }) => <InternalRedirect to={{ ...location, state: { is404: true } }}/>}/>;
export function NotFound() {
    const action = (<InternalLink className={goBackLinkStyles} to={routeLinks.root}>
            <ChevronLeftIcon />
            Go back home
        </InternalLink>);
    return (<main>
            <EmptyPage title="Unable to locate page..." image={<DrawnOctopusIllustration />} description={"Our badly drawn octopus suggests retracing your steps."} action={action}/>
        </main>);
}
const goBackLinkStyles = css({
    display: "flex",
    alignItems: "center",
    font: text.link.bold.medium,
});
