import { compact } from "lodash";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle";
import type { NavLink } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import routeLinks from "~/routeLinks";
import { NavigationSideBarLayoutVNext } from "../NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "../RootRoutes/useIsPageHeaderVNextEnabled";
import { UxGuidelinesLogo } from "./UxGuide";
interface UxGuidelinesLayoutProps {
    children: React.ReactNode;
}
const UxGuidelinesLayout: React.FC<UxGuidelinesLayoutProps> = ({ children }) => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const navLinks: Array<NavLink> = compact([
        Navigation.navItem("Overview", routeLinks.uxGuide.guidelines.overview),
        Navigation.navItem("Cheat Sheet", routeLinks.uxGuide.guidelines.cheatsheet),
        Navigation.navItem("Guidelines", routeLinks.uxGuide.guidelines.guidelines),
    ]);
    if (isPageHeaderVNextEnabled) {
        return (<main>
                <NavigationSideBarLayoutVNext navItems={navLinks} content={children} header={<PageHeaderPrimary title="UX Guidlines" navUpLink={{ label: "UX Guide", linkHref: routeLinks.uxGuide.root }}/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={routeLinks.uxGuide.guidelines.overview} title={"UX Guidelines"} breadcrumbTitle={"UX Guide"} breadcrumbPath={routeLinks.uxGuide.root}/>
            <NavigationSidebarLayout navLinks={navLinks} image={UxGuidelinesLogo} content={children}/>
        </main>);
};
UxGuidelinesLayout.displayName = "UxGuidelinesLayout"
export default UxGuidelinesLayout;
