/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import type { SshEndpointResource, ProxyResource, AccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { WarningChip } from "~/components/Chips/index";
import { SshConnection } from "~/components/Images/MachineSettings/SshConnection";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Text, ExpandableFormSection, UnstructuredFormSection, FormSectionHeading, Summary, Select, Note } from "~/components/form";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import ParseHelper from "~/utils/ParseHelper";
import { EndpointCard } from "./EndpointCard";
import LinuxCateogry from "./LinuxCategoryDefinition";
import MacCategory from "./MacCategoryDefinition";
import type { MachineRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import styles from "./styles.module.less";
interface SshEndpointProps {
    endpoint: SshEndpointResource;
    proxies: ProxyResource[];
    accounts: AccountResource[];
    refreshAccounts: () => Promise<{}>;
    onChange(newValue: SshEndpointResource): void;
}
interface SshEndpointState {
    shouldUseProxy: boolean;
    monoInstalled: boolean;
}
class SshEndpoint extends React.Component<SshEndpointProps, SshEndpointState> {
    public static dotnetCorePlatforms = ["linux-x64", "osx-x64", "linux-arm", "linux-arm64"];
    public static experimentalPlatforms = ["linux-arm", "linux-arm64"];
    constructor(props: SshEndpointProps) {
        super(props);
        this.state = {
            shouldUseProxy: !!this.props.endpoint.ProxyId,
            monoInstalled: !this.props.endpoint.DotNetCorePlatform,
        };
    }
    render() {
        return (<div>
                <ExpandableFormSection errorKey="Account" title="Account" focusOnExpandAll summary={CommonSummaryHelper.resourceSummary(this.props.endpoint.AccountId, this.props.accounts, "account")} help="Account">
                    <AccountSelect onRequestRefresh={this.props.refreshAccounts} value={this.props.endpoint.AccountId} type={[AccountType.UsernamePassword, AccountType.SshKeyPair]} allowClear={true} onChange={(x) => {
                const endpoint = this.props.endpoint;
                endpoint.AccountId = x;
                this.props.onChange(endpoint);
            }} items={this.props.accounts}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Host" title="Host" summary={this.props.endpoint.Host ? Summary.summary(this.props.endpoint.Host) : Summary.placeholder("No host")} help="The hostname or IP address of the deployment target to connect to.">
                    <Text label="Host" placeholder="hostname.com or 10.0.0.1" value={this.props.endpoint.Host} onChange={(x) => {
                const endpoint = this.props.endpoint;
                endpoint.Host = x;
                this.props.onChange(endpoint);
            }} type="url"/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Port" title="Port" summary={CommonSummaryHelper.portSummary(this.props.endpoint.Port)} help="Enter a port number.">
                    <Text label="Port" placeholder="The port to use when connecting to the remote host." value={this.props.endpoint.Port ? this.props.endpoint.Port.toString() : ""} onChange={(x) => {
                const endpoint = this.props.endpoint;
                endpoint.Port = ParseHelper.safeParseInt(x);
                this.props.onChange(endpoint);
            }} type="number"/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Fingerprint" title="Fingerprint" summary={this.props.endpoint.Fingerprint ? Summary.summary(this.props.endpoint.Fingerprint) : Summary.placeholder("No fingerprint")} help="Enter the host fingerprint to be verified.">
                    <Text label="Fingerprint" placeholder="xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx" value={this.props.endpoint.Fingerprint} onChange={(x) => {
                const endpoint = this.props.endpoint;
                endpoint.Fingerprint = x;
                this.props.onChange(endpoint);
            }}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Proxy" title="Proxy" summary={CommonSummaryHelper.resourceSummary(this.props.endpoint.ProxyId, this.props.proxies, "proxy")} help="Select whether to use a proxy to communicate with this SSH target.">
                    <BooleanRadioButtonGroup title="Connection method" onChange={(shouldUseProxy) => {
                this.setState({ shouldUseProxy });
                const endpoint = this.props.endpoint;
                endpoint.ProxyId = undefined;
                this.props.onChange(endpoint);
            }} value={this.state.shouldUseProxy}>
                        <BooleanRadioButton value={false} label="Connect directly" isDefault={true}/>
                        <BooleanRadioButton value={true} label="Connect through a proxy server"/>
                    </BooleanRadioButtonGroup>
                    {this.state.shouldUseProxy && (<Select label="The proxy to use to communicate with this SSH endpoint." onChange={(x) => {
                    const endpoint = this.props.endpoint;
                    endpoint.ProxyId = x;
                    this.props.onChange(endpoint);
                }} value={this.props.endpoint.ProxyId} items={this.props.proxies.map((p) => ({ value: p.Id, text: p.Name }))}/>)}
                </ExpandableFormSection>

                <FormSectionHeading title=".NET"/>

                <UnstructuredFormSection>
                    <p>
                        <ExternalLink href="Calamari">Calamari</ExternalLink> (the Octopus deployment executable) is built on Microsoft .NET.
                    </p>
                    <p>If Mono is installed on the target server, a version of Calamari built against the full .NET framework can be used. If Mono is not installed, a self-contained version of Calamari which bundles .NET Core can be used.</p>
                    <p>
                        See the <ExternalLink href="SshSelfContainedCalamari">SSH target documentation</ExternalLink> for more information.
                    </p>
                    {this.state.monoInstalled && (<Callout type={CalloutType.Warning} title={"Mono is selected and deprecated"}>
                            The usage of 'Mono' is being deprecated. Please see <ExternalLink href="MonoDeprecation">https://g.octopushq.com/MonoDeprecation</ExternalLink> for more details..
                        </Callout>)}
                </UnstructuredFormSection>
                {this.getTargetFrameworkSection()}
                {!this.state.monoInstalled && (<ExpandableFormSection errorKey="Platform" title="Platform" summary={this.getDotnetCorePlatformSummary()} help="Select the platform">
                        <Select label="Platform" onChange={(x) => {
                    const endpoint = this.props.endpoint;
                    endpoint.DotNetCorePlatform = x;
                    this.props.onChange(endpoint);
                }} value={this.props.endpoint.DotNetCorePlatform} items={SshEndpoint.dotnetCorePlatforms.map((p) => ({ value: p, text: p }))}/>
                        {this.props.endpoint && this.isExperimentalPlatform(this.props.endpoint.DotNetCorePlatform) && (<Callout type={CalloutType.Warning} title="Experimental">
                                <b>{this.props.endpoint.DotNetCorePlatform}</b> support is currently experimental.
                                <br />
                                <ExternalLink href="ExperimentalSshTargetsFeedbackForm">Share with us</ExternalLink> your experience using this feature.
                                <br />
                                <i>
                                    Let us know why you use <b>{this.props.endpoint.DotNetCorePlatform}</b>, and if there are other platforms you would like to see supported.
                                </i>
                            </Callout>)}
                        <Note>A platform-specific, self-contained version of Calamari will be used. This means Mono is not required to be installed on the target server.</Note>
                    </ExpandableFormSection>)}
            </div>);
    }
    private getTargetFrameworkSection() {
        const deprecationChip = <WarningChip title="Deprecated" description={`Support for Mono is being deprecated. Please Update to "Self-contained Calamari" (.NET Core) .`}/>;
        const summary = this.state.monoInstalled ? Summary.summary(<>Calamari on Mono {deprecationChip}</>) : Summary.default("Self-contained Calamari");
        return (<ExpandableFormSection errorKey="Framework" title="Target Runtime" summary={summary} help="Select the Target Runtime">
                <BooleanRadioButtonGroup value={this.state.monoInstalled} onChange={(monoInstalled) => {
                if (monoInstalled) {
                    this.clearDotNetCorePlatform();
                }
                else {
                    this.setDefaultDotNetCorePlatform();
                }
                this.setState({ monoInstalled });
            }}>
                    <BooleanRadioButton value={false} label="Self-contained Calamari" isDefault={true}/>
                    <Note>Mono framework is not installed on the target server. A self-contained version of Calamari will be used.</Note>
                    <BooleanRadioButton value={true} label={<>Calamari on Mono {deprecationChip}</>}/>
                    <Note>Mono framework version 3.10 or greater is installed on the target server.</Note>
                </BooleanRadioButtonGroup>
            </ExpandableFormSection>);
    }
    private clearDotNetCorePlatform() {
        const endpoint = this.props.endpoint;
        endpoint.DotNetCorePlatform = undefined;
        this.props.onChange(endpoint);
    }
    private setDefaultDotNetCorePlatform() {
        const endpoint = this.props.endpoint;
        endpoint.DotNetCorePlatform = SshEndpoint.dotnetCorePlatforms[0];
        this.props.onChange(endpoint);
    }
    private getDotnetCorePlatformSummary() {
        const isExperimentalMessage = this.isExperimentalPlatform(this.props.endpoint.DotNetCorePlatform) ? " (experimental)" : "";
        return this.props.endpoint && this.props.endpoint.DotNetCorePlatform ? Summary.summary(this.props.endpoint.DotNetCorePlatform + isExperimentalMessage) : Summary.placeholder("No platform specified");
    }
    private isExperimentalPlatform(platform: string | undefined) {
        return platform && SshEndpoint.experimentalPlatforms.indexOf(platform) > -1;
    }
    static displayName = "SshEndpoint";
}
export default SshEndpoint;
const sshEndpointRegistration: MachineRegistration = {
    key: EndpointRegistrationKey.Ssh,
    displayOrder: 50,
    communicationStyle: CommunicationStyle.Ssh,
    name: "SSH Connection",
    discoverable: true,
    categories: [LinuxCateogry, MacCategory],
    renderCard: ({ registration, category, onNavigate }) => (<EndpointCard logo={<SshConnection className={styles.centreThumbnail} title={registration.name}/>} registrationName={registration.name} description="Connect to this target via Secure Shell (SSH)." onNavigate={onNavigate}/>),
};
export { sshEndpointRegistration };
