import type { ChannelResource, RunbookResource, TriggerResource } from "@octopusdeploy/octopus-server-client";
import { TriggerActionType } from "@octopusdeploy/octopus-server-client";
import URI from "urijs";
import type { ProcessFilter } from "./Process/types";
export function trigger(root: string) {
    return (triggerId: string | TriggerResource, triggerType: TriggerActionType = TriggerActionType.AutoDeploy) => {
        if (!!triggerId && typeof triggerId !== "string") {
            triggerId = triggerId.Id;
        }
        if (triggerType === TriggerActionType.AutoDeploy) {
            return `${root}/edit/${triggerId}`;
        }
        return `${root}/scheduled/edit/${triggerId}`;
    };
}
export function channel(root: string) {
    return (channelId: string | ChannelResource) => {
        if (!!channelId && typeof channelId !== "string") {
            channelId = channelId.Id;
        }
        return `${root}/edit/${channelId}`;
    };
}
export function scheduledTrigger(root: string) {
    return (triggerId: string | TriggerResource) => {
        if (!!triggerId && typeof triggerId !== "string") {
            triggerId = triggerId.Id;
        }
        return `${root}/scheduled/edit/${triggerId}`;
    };
}
export function process(root: string) {
    const processRoot = `${root}/steps`; // @UX: This allows the root process screen to be independent, important for save-unsaved-changes UX (play carefully if changing this).
    return {
        root,
        stepTemplates: `${processRoot}?stepTemplates=1`,
        childStepTemplates: (parentStepId: string) => ({
            root: `${processRoot}?childStepTemplates=1&parentStepId=${parentStepId}`,
        }),
        new: (actionType: string, parentStepId?: string, templateId?: string) => {
            const base = `${processRoot}`;
            const search: ProcessFilter = {};
            if (parentStepId) {
                search.parentStepId = parentStepId;
            }
            search.new = "1";
            search.actionType = actionType;
            if (templateId) {
                search.templateId = templateId;
            }
            return `${base}?${new URI().search(search).search()}`;
        },
        step: (actionId: string | null, parentStepId?: string) => {
            if (actionId) {
                return `${processRoot}?actionId=${actionId}`;
            }
            else {
                return `${processRoot}?parentStepId=${parentStepId}`;
            }
        },
        stepViaSlug: (stepSlug: string) => {
            return `${processRoot}/${stepSlug}`;
        },
        loadSampleProcess: (processType: "ps" | "bash") => `${root}?loadSampleProcess=${processType}`,
    };
}
export function runbook(root: string) {
    return (runbookId: string | RunbookResource) => {
        if (!!runbookId && typeof runbookId !== "string") {
            runbookId = runbookId.Id;
        }
        return `${root}/runbooks/${runbookId}`;
    };
}
