import type { BranchSpecifier, ProjectResource, ProjectSummary } from "@octopusdeploy/octopus-server-client";
import { ShouldUseDefaultBranch, HasVariablesInGit, Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { NavDivider } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import { Navigation } from "~/components/NavigationSidebarLayout/index";
import type { NavLink } from "~/components/NavigationSidebarLayout/index";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";
import { IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import { GitProjectLink, GitProjectLinkWithErrorStatus, IndentedProjectLink } from "./GitProjectLink";
export function ProjectLinksVNext(branch: BranchSpecifier, project: Readonly<ProjectResource>, projectSummary: ProjectSummary, isMultiTenancyEnabled: boolean | undefined, hasConnectionError: boolean) {
    const projectId = project.Id;
    const projectLinks = routeLinks.project(project.Slug);
    const links: (NavLink | NavDivider | null)[] = [];
    if (IsAllowedToSeeDeploymentsOverview(projectId)) {
        links.push(NavigationItem("Deployments", projectLinks.deployments.root, true, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        }));
        links.push(GitNavigationItem("Process", projectLinks.deployments.process.root, routeLinks.projectBranch(project.Slug, branch).deploymentProcess.root, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        }));
        links.push(NavigationItem("Channels", projectLinks.deployments.channels, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        }));
        links.push(NavigationItem("Releases", projectLinks.deployments.releases, undefined, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        }));
        links.push(NavigationItem("Triggers", projectLinks.triggers, undefined, {
            permission: Permission.TriggerView,
            project: projectId,
        }));
        links.push(GitNavigationItem("Settings", projectLinks.deployments.settings, routeLinks.projectBranch(project.Slug, branch).deploymentSettings));
        links.push("divider");
    }
    links.push(NavigationItem("Operations", projectLinks.operations.root, true, {
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    }));
    links.push(NavigationItem("Runbooks", projectLinks.operations.runbooks, undefined, {
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    }));
    links.push(NavigationItem("Runbook Triggers", projectLinks.operations.triggers, undefined, {
        permission: Permission.TriggerView,
        project: projectId,
    }));
    links.push("divider");
    const variablesLinks = ShouldUseDefaultBranch(branch) || !HasVariablesInGit(project.PersistenceSettings) ? projectLinks.variables : routeLinks.projectBranch(project.Slug, branch).variables;
    const variableLinksPermissions = {
        permission: Permission.VariableView,
        project: projectId,
        wildcard: true,
    };
    links.push(GitVariablesNavigationItem("Project Variables", variablesLinks.root, variablesLinks.root, true, variableLinksPermissions));
    if (isMultiTenancyEnabled) {
        links.push(NavigationItem("Project Templates", projectLinks.variables.projectTemplates));
        links.push(NavigationItem("Common Templates", projectLinks.variables.commonTemplates));
    }
    links.push(NavigationItem("Library Variable Sets", projectLinks.variables.library));
    links.push(GitVariablesNavigationItem("All Variables", variablesLinks.all, variablesLinks.all, undefined, variableLinksPermissions));
    links.push(GitVariablesNavigationItem("Variable Preview", variablesLinks.preview, variablesLinks.preview, undefined, variableLinksPermissions));
    links.push("divider");
    links.push(Navigation.navItem("Tenants", projectLinks.tenants, undefined, {
        permission: Permission.TenantView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    }));
    links.push("divider");
    links.push(Navigation.navItem("Tasks", projectLinks.tasks, undefined, {
        permission: Permission.TaskView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    }));
    links.push("divider");
    links.push(NavigationItem("Insights", projectLinks.insights.overview, undefined));
    links.push("divider");
    links.push(NavigationItem("Project Settings", projectLinks.settings.root, true));
    links.push(GitNavigationItemWithErrorStatus("Version Control", projectLinks.settings.versionControl, projectLinks.settings.versionControl));
    if (isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle")) {
        links.push(NavigationItem(<React.Fragment>Dynamic Environments</React.Fragment>, projectLinks.settings.dynamicEnvironments));
    }
    return compact(links);
    // Helpers
    function GitVariablesNavigationItem(label: string | JSX.Element, dbLink: string, gitLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = HasVariablesInGit(project.PersistenceSettings) ? <GitProjectLink label={label}/> : <IndentedProjectLink label={label}/>;
        const link = HasVariablesInGit(project.PersistenceSettings) ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function GitNavigationItem(label: string | JSX.Element, dbLink: string, gitLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLink label={label}/> : <IndentedProjectLink label={label}/>;
        const link = project.IsVersionControlled ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function GitNavigationItemWithErrorStatus(label: string | JSX.Element, dbLink: string, gitLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLinkWithErrorStatus label={label} hasError={hasConnectionError}/> : <IndentedProjectLink label={label}/>;
        const link = project.IsVersionControlled ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function NavigationItem(label: string | JSX.Element, dbLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        return Navigation.navItem(<IndentedProjectLink label={label}/>, dbLink, exact, permission);
    }
}
