import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import RunbooksNavigationTabs from "../RunbooksNavigationTabs";
import type { TabbedPaperLayoutProps } from "./TabbedPaperLayout";
import TabbedPaperLayout from "./TabbedPaperLayout";
type RunbooksPaperLayoutProps = TabbedPaperLayoutProps;
const RunbooksPaperLayout: React.FC<RunbooksPaperLayoutProps> = ({ tabNavigation = RunbooksNavigationTabs, breadcrumbTitle = "Runbooks", title = StringHelper.ellipsis, disableAnimations = true, fullWidth = true, ...rest }) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    return (<TabbedPaperLayout breadcrumbTitle={breadcrumbTitle} breadcrumbsItems={rest.breadcrumbsItems ?? [{ label: "Runbooks", pageUrl: routeLinks.project(project.Slug).operations.runbooks }]} title={title} tabNavigation={tabNavigation} disableAnimations={disableAnimations} fullWidth={fullWidth} {...rest}/>);
};
RunbooksPaperLayout.displayName = "RunbooksPaperLayout"
export default RunbooksPaperLayout;
export { RunbooksPaperLayout };
