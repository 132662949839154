import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MachineModelHealthStatus, MachineResource } from "@octopusdeploy/octopus-server-client";
import { isStepPackageEndpointResource, Permission, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import { DisabledChip } from "~/components/Chips";
import Chip from "~/components/Chips/Chip";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import type { NavLink } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import type { UnknownStepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import routeLinks from "~/routeLinks";
import { getMachineIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
import { machineActions } from "../../reducers/machines";
import { getCurrentMachine } from "../../reducers/selectors";
import { MachineIcon } from "../MachineIcon/MachineIcon";
type MachineLayoutRouteProps = {
    machineId: string;
};
interface MachineLayoutInternalProps {
    machineId: string;
    machineName?: string;
    machineType?: string;
    machineIconUrl?: string;
    machineIsDisabled?: boolean;
    isStepPackage?: boolean;
    machineHealthStatus?: MachineModelHealthStatus;
    title: string;
    rootLink: string;
    extraNavLinks: NavLink[];
    getMachine(id: string): Promise<MachineResource>;
    getMachineLinks(id: string): MachineLinks;
    onFetchMachine(machine: MachineResource): void;
    isPageHeaderVNextEnabled: boolean;
}
interface MachineLinks {
    settings: string;
    connection: string;
    events: string;
}
interface MachineLayoutInternalState {
    stepPackageDeploymentTarget: UnknownStepPackageDeploymentTarget | undefined;
}
class MachineLayoutInternal extends BaseComponent<MachineLayoutInternalProps, MachineLayoutInternalState> {
    constructor(props: MachineLayoutInternalProps) {
        super(props);
        this.state = {
            stepPackageDeploymentTarget: undefined,
        };
    }
    async componentDidMount() {
        if (this.props.machineId) {
            const machine = await this.props.getMachine(this.props.machineId);
            if (isStepPackageEndpointResource(machine.Endpoint)) {
                const deploymentTarget = await endpointRegistry.getStepPackageDeploymentTarget(machine.Endpoint.DeploymentTargetTypeId, machine.Endpoint.StepPackageVersion);
                this.setState((prevState) => ({ ...prevState, stepPackageDeploymentTarget: deploymentTarget }));
            }
            this.props.onFetchMachine(machine);
        }
    }
    render() {
        const machineId = this.props.machineId;
        const navLinks: Array<NavLink | null> = [];
        if (machineId) {
            const machineLinks = this.props.getMachineLinks(machineId);
            navLinks.push(Navigation.navItem("Settings", machineLinks.settings));
            navLinks.push(Navigation.navItem(<React.Fragment>Connectivity {this.props.machineHealthStatus && <MachineHealthStatusIcon healthStatus={this.props.machineHealthStatus}/>}</React.Fragment>, machineLinks.connection));
            if (this.props.extraNavLinks) {
                navLinks.push.apply(navLinks, this.props.extraNavLinks);
            }
            navLinks.push(Navigation.navItem("Events", machineLinks.events, undefined, { permission: Permission.EventView, wildcard: true }));
        }
        const machineImage = this.props.machineIconUrl ? <MachineIcon imageUrl={this.props.machineIconUrl}/> : null;
        let machineType = this.props.machineType;
        if (this.props.isStepPackage) {
            machineType = this.state.stepPackageDeploymentTarget?.name ?? "";
        }
        if (this.props.isPageHeaderVNextEnabled) {
            const machineChips = [];
            if (machineType) {
                machineChips.push(<MachineChip label={machineType}/>);
            }
            if (this.props.machineIsDisabled) {
                machineChips.push(<MachineChip label={"Disabled"}/>);
            }
            return (<main>
                    <NavigationSideBarLayoutVNext navItems={compact(navLinks)} content={this.props.children} header={<PageHeaderPrimary navUpLink={{ label: "Infrastructure", linkHref: this.props.rootLink }} title={this.props.machineName ?? this.props.title} logo={this.props.machineIconUrl ? { href: this.props.machineIconUrl, accessibleName: `${this.props.machineName} icon` } : undefined} titleChips={machineChips}/>}/>
                </main>);
        }
        return (<main>
                <AreaTitle link={this.props.rootLink} title={this.props.title} breadcrumbTitle={"Infrastructure"} breadcrumbPath={routeLinks.infrastructure.root}/>
                <NavigationSidebarLayout name={this.props.machineName} resourceType={machineType} image={machineImage} navLinks={compact(navLinks)} preNavbarComponent={this.props.machineIsDisabled && (<div>
                                <DisabledChip />
                            </div>)} content={this.props.children}/>
            </main>);
    }
    static displayName = "MachineLayoutInternal";
}
function MachineChip({ label }: {
    label: string;
}) {
    return (<Chip noMargin={true} noTooltip={true} backgroundColor={themeTokens.color.chip.filled.background.primary} labelColor={themeTokens.color.chip.filled.text.primary}>
            {label}
        </Chip>);
}
interface SharedMachineLayoutProps {
    title: string;
    rootLink: string;
    getExtraNavLinks?: (machineId: string) => NavLink[];
    getMachine(id: string): Promise<MachineResource>;
    getMachineLinks(id: string): MachineLinks;
    children: React.ReactNode;
}
function SharedMachineLayout({ title, rootLink, getExtraNavLinks, getMachine, getMachineLinks, children }: SharedMachineLayoutProps) {
    const match = useRouteMatch<MachineLayoutRouteProps>();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const machineId = match?.params?.machineId;
    const machine = useSelector(getCurrentMachine);
    const dispatchFetchMachine = useDispatchFetchMachine();
    if (!machineId) {
        // This should never happen, machineId is a required route parameter.
        // When this page is moved to the vNext routing infrastructure, this can be changed to be a required prop instead, and this null check removed.
        return null;
    }
    return (<MachineLayoutInternal machineId={machineId} machineName={machine?.name} machineType={machine?.machineType} machineIconUrl={machine?.machineIconUrl} machineIsDisabled={machine?.isDisabled} title={title} extraNavLinks={machineId && getExtraNavLinks ? getExtraNavLinks?.(machineId) : []} rootLink={rootLink} getMachine={getMachine} getMachineLinks={getMachineLinks} machineHealthStatus={machine?.healthStatus} onFetchMachine={dispatchFetchMachine} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}>
            {children}
        </MachineLayoutInternal>);
}
export function WorkerMachineLayout({ children }: {
    children: React.ReactNode;
}) {
    return (<SharedMachineLayout title="Workers" rootLink={routeLinks.infrastructure.workerMachines.root} getMachine={(id: string) => repository.Workers.get(id)} getMachineLinks={(id: string) => routeLinks.infrastructure.workerMachine(id)}>
            {children}
        </SharedMachineLayout>);
}
export function MachineLayout({ children }: {
    children: React.ReactNode;
}) {
    const getExtraNavLinks = (machineId: string) => compact([
        Navigation.navItem("Deployments", routeLinks.infrastructure.deploymentTarget(machineId).deployments, undefined, { permission: Permission.TaskView, wildcard: true }),
        Navigation.navItem("Runbook Runs", routeLinks.infrastructure.deploymentTarget(machineId).runbookRuns, undefined, { permission: Permission.TaskView, wildcard: true }),
    ]);
    return (<SharedMachineLayout title="Deployment Targets" getExtraNavLinks={getExtraNavLinks} rootLink={routeLinks.infrastructure.machines.root} getMachine={(id: string) => repository.Machines.get(id)} getMachineLinks={(id: string) => routeLinks.infrastructure.machine(id)}>
            {children}
        </SharedMachineLayout>);
}
const useDispatchFetchMachine = () => {
    const dispatch: ThunkDispatch<GlobalState, void, AnyAction> = useDispatch();
    return React.useCallback((machine: MachineResource) => {
        const machineIconUrl = getMachineIconUrl(machine);
        dispatch(machineActions.machineFetched({
            id: machine.Id,
            name: machine.Name,
            machineIconUrl,
            machineType: EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle),
            isDisabled: machine.IsDisabled,
            isStepPackage: isStepPackageEndpointResource(machine.Endpoint),
            healthStatus: machine.HealthStatus,
        }));
    }, [dispatch]);
};
