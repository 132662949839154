import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/WithPage";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import ChannelsRoute from "../Channels/ChannelsRoute";
import { DeploymentsOverview } from "../DeploymentsOverview";
import DeploymentProcessRoute from "../Process/Routing/ProcessRoute";
import { ReleasesRoute } from "../Releases";
export const DeploymentsOverviewPage = withPage({ page: pageIds.project().overview })(DeploymentsOverview);
export function DeploymentsRoute() {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    const projectRouteLinks = routeLinks.projectRoutes();
    return (<Switch>
            <DeploymentProcessRoute path={projectRouteLinks.deployments.process.root} processType={ProcessType.Deployment}/>
            {!isRoutingVNextEnabled && <ChannelsRoute path={projectRouteLinks.deployments.channels}/>}
            <ReloadableRoute path={projectRouteLinks.deployments.releases} component={ReleasesRoute}/>
            {!isRoutingVNextEnabled && <DeploymentsOverviewPage />}
        </Switch>);
}
export default DeploymentsRoute;
