import type { KubernetesSecretProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import { KubernetesSecretComponent } from "~/components/Actions/kubernetes/kubernetesSecretComponent";
import KubernetesStatusCheckComponent, { InitialStatusCheckProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import type { ScriptPackageProperties } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import StringKeyValueEditList from "~/components/EditList/KeyValueEditList";
import { default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { JsonUtils } from "~/utils/jsonUtils";
import { getKubernetesTargetDiscoveryCloudProviders } from "./getKubernetesTargetDiscoveryCloudProviders";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";
class KubernetesDeploySecretActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Deploy a secret resource to Kubernetes</div>;
    }
    static displayName = "KubernetesDeploySecretActionSummary";
}
class KubernetesDeploySecretActionEdit extends BaseComponent<ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, never> {
    constructor(props: ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>) {
        super(props);
    }
    render() {
        return (<div>
                <KubernetesStatusCheckComponent jobsSupported={false} timeoutSupported={false} statusCheckSupported={true} showLegacyWait={false} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <KubernetesSecretComponent properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} errors={this.props.errors} projectId={this.props.projectId} gitRef={this.props.gitRef} expandedByDefault={this.props.expandedByDefault} importLabels={true}/>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentLabels" isExpandedByDefault={this.props.expandedByDefault} title="Secret Labels" summary={this.labelsSummary()} help={"The labels applied to the secret resource"}>
                    <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]} name="Label" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={(ns) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })}/>
            </div>);
    }
    private labelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));
        if (labels.length === 0) {
            return Summary.placeholder("The config map resource labels");
        }
        return Summary.summary(<span>
                Add the label{labels.length > 1 && <span>s</span>}{" "}
                {_.chain(labels)
                .flatMap((pair) => [
                <strong>
                            {pair[0]}: {pair[1]}
                        </strong>,
                <span>, </span>,
            ])
                .slice(0, -1)
                .value()}
            </span>);
    }
    static displayName = "KubernetesDeploySecretActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeploySecret",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeploySecretActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: { default: KubernetesDeploySecretActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => InitialStatusCheckProperties,
    canUseExecutionTimeouts: false,
});
